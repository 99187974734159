import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Accordion from '../Accordion/Accordion';
import { updatePathway as _updatePathway } from '../../slices/InvestmentSlice';

import './CleanTechOverviewSection.css';

const mapStateToProps = state => ({
  pathway: state.investmentSlice.pathway,
});
const dispatchProps = { updatePathway: _updatePathway };

const CleanTechOverviewSection = ({
  isOpen,
  selectTech,
  title,
  color,
  icon,
  pathway,
  updatePathway,
  pathways,
}) => {
  const toggle = (
    <div className="clean-tech-overview-section__title">
      {icon || <div className="clean-tech-overview-section__circle" />}
      {title}
    </div>
  );

  const renderPathway = text => {
    const isSelected = text === pathway;
    const pathClass = classNames('clean-tech-overview-section__pathway', {
      selected: isSelected,
    });

    return (
      <div
        key={text}
        className={pathClass}
        onClick={() => updatePathway({ pathway: text })}
        role="button"
      >
        {isSelected && <div className="clean-tech-overview-section__bullet" />}
        <div>{text}</div>
      </div>
    );
  };

  const accordionChildren = (
    <>
      <div className="clean-tech-overview-section__pathways-title small-caps">
        PATHWAYS
      </div>
      <div className="clean-tech-overview-section__pathways">
        <div className="clean-tech-overview-section__pathways-border" />
        {pathways.map(renderPathway)}
      </div>
    </>
  );

  const className = classNames('clean-tech-overview-section', {
    open: isOpen,
    'box-shadow': isOpen,
  });

  return (
    <div className={className} style={{ '--cto-section-color': color }}>
      <Accordion
        isOpenOverride={isOpen}
        toggleEl={toggle}
        onToggle={selectTech}
      >
        {pathways.length > 0 && accordionChildren}
      </Accordion>
    </div>
  );
};

CleanTechOverviewSection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectTech: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.node,
  pathways: PropTypes.arrayOf(PropTypes.string).isRequired,
  pathway: PropTypes.string,
  updatePathway: PropTypes.func.isRequired,
};

CleanTechOverviewSection.defaultProps = {
  icon: null,
  pathway: null,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CleanTechOverviewSection);
