/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import './TabGroup.css';

export const TabItem = props => <div {...props} />;

export const TabGroup = ({ activeTab, onTabClick, children }) => {
  return (
    <>
      <div className="tab_menu">
        {children.map(({ props: { index, label, name: tabName } }) => (
          <button
            type="button"
            key={`tab-btn-${index}`}
            onClick={() => onTabClick(tabName)}
            className={tabName === activeTab ? 'focus' : ''}
          >
            {label}
          </button>
        ))}
      </div>
      <div>
        {children.map(({ props }) => (
          <div
            {...props}
            className={`tab_content ${
              props.name === activeTab ? 'selected' : ''
            }`}
            key={`tab_content_${props.name}`}
          />
        ))}
      </div>
    </>
  );
};

TabGroup.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.elementType).isRequired,
};

TabGroup.defaultProps = {
  onTabClick: null,
};
