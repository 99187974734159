import React from 'react';
import { buildComputePos } from '../lib/scales';
import {
  DataAccessor,
  StyleProps,
  Getter,
  KeyAccessor,
  AnimationProps,
} from '../lib/types';
import { createAnimatedAttrs } from '../lib/utils';
import { AnimatedDataset } from './AnimatedDataset';
import { useSanitizedCascadingAnimation } from './Animation';
import { useCartesianContext } from './internal';
import { useComputableStyle } from './Style';

interface Props<T> extends StyleProps<T>, AnimationProps<T> {
  data: T[];
  dataX?: DataAccessor<T>;
  dataY?: DataAccessor<T>;
  dataKey?: KeyAccessor<T>;

  positionX?: Getter<T, number>;
  positionY?: Getter<T, number>;

  offsetX?: Getter<T, number>;
  offsetY?: Getter<T, number>;

  text: DataAccessor<T>;
}

export function LabelsData<T>({
  data,
  dataX,
  dataY,
  dataKey,
  positionX,
  positionY,
  offsetX,
  offsetY,
  text,

  enter = {},
  delay,
  duration,
  ...props
}: Props<T>) {
  const { xScale, yScale, colorScale } = useCartesianContext();
  const [style, computeStyle] = useComputableStyle(props, colorScale);
  const animation = useSanitizedCascadingAnimation({ delay, duration });

  const computeXPos = buildComputePos(dataX, positionX, offsetX, xScale, 'X');
  const computeYPos = buildComputePos(dataY, positionY, offsetY, yScale, 'Y');

  const labels = data.map((d, i) => ({
    key: dataKey?.(d, i) ?? i,
    x: computeXPos(d),
    y: computeYPos(d),
    text: text(d),
    datum: d,
    ...style,
    ...computeStyle(d),
  }));

  return (
    <AnimatedDataset
      tag="text"
      dataset={labels}
      attrs={createAnimatedAttrs(labels)}
      init={enter}
      keyFn={d => d.key}
      {...(animation as any)}
    />
  );
}
