import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CostCapacityShift from '../CostCapacityShift/CostCapacityShift';
import { CLEAN_TECH_OVERVIEW_DATA, getPathwayData } from '../../data/constants';
import { formatNumber } from '../../lib/utils';

const mapStateToProps = state => ({
  technology: state.investmentSlice.technology,
  pathway: state.investmentSlice.pathway,
  tier: state.investmentSlice.tier,
});

const computeCostReductionData = dataset => {
  return dataset.years.map((year, i) => ({
    year: Math.round(year),
    reference: dataset.ref[i],
    catalyst: dataset.cat[i],
  }));
};

const CostReductionChart = ({ technology, pathway, tier }) => {
  const pathwayData = getPathwayData({ tier, pathway }).ppcrg;
  const dataset = computeCostReductionData(pathwayData);

  return (
    <CostCapacityShift
      data={dataset}
      maxDelta={12_000_000}
      formatY={value => formatNumber(value, 2)}
      yAxisUnit={`$/${pathwayData.uom}`}
      technologyColor={CLEAN_TECH_OVERVIEW_DATA[technology].color}
      section="Cost"
    />
  );
};

CostReductionChart.propTypes = {
  pathway: PropTypes.string,
  technology: PropTypes.string,
  tier: PropTypes.number.isRequired,
};

CostReductionChart.defaultProps = {
  pathway: null,
  technology: null,
};

export default connect(mapStateToProps)(CostReductionChart);
