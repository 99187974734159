import React from 'react';
import PropTypes from 'prop-types';
import { useCartesianContext } from '../../lib/react-composable-charts/components/internal';
import { element } from '../../lib/react-composable-charts/components/element';
import { computePos } from '../../lib/react-composable-charts/lib/scales';
import { COLORS } from '../../data/constants';
import Portal from '../Portal/Portal';
import style from './XAxes.module.css';

const XAxes = ({ yValue, portalRef }) => {
  const { yScale, xScale } = useCartesianContext();

  const padding = 10;
  const y = yScale(yValue) + 1;
  const xs = xScale.domain().map(techId => {
    const left = computePos(techId, xScale, 'start') - padding;
    const right = computePos(techId, xScale, 'end') + padding;

    return { id: techId, left, right };
  });
  return (
    <>
      {xs.map(({ id, left, right }) => (
        <g key={id}>
          {[left, right].map(d => (
            <element.circle
              key={d}
              cx={d}
              cy={y}
              r={2.5}
              fill={COLORS.venusMist}
            />
          ))}
          <element.line
            x1={left}
            x2={right}
            y1={y}
            y2={y}
            stroke={COLORS.venusMist}
            strokeWidth={1}
          />
        </g>
      ))}
      <Portal el={portalRef}>
        <div
          className={style.label}
          style={{ top: y + 5, left: xs[0].left - 13 }}
        >
          Cost at parity
        </div>
      </Portal>
    </>
  );
};

XAxes.propTypes = {
  yValue: PropTypes.number.isRequired,
  portalRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
};

export default XAxes;
