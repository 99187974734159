import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EmissionAreaChart from '../EmissionAreaChart/EmissionAreaChart';
import allData from '../../data/TieredFrontEndResults';
import { TECHNOLOGIES } from '../../data/constants';
import { formatNumber } from '../../lib/utils';

const mapStateToProps = state => ({
  tier: state.investmentSlice.tier,
});
const paddingTop = 50;

const EmissionReduction = ({ tier }) => {
  const data = TECHNOLOGIES.flatMap(tecnology =>
    allData.tiers[tier].erpg.years.map((year, i) => ({
      year,
      category: tecnology.id,
      emissions: allData.tiers[tier].erpg.erp[tecnology.id][i],
    }))
  );

  return (
    <EmissionAreaChart
      data={data}
      categories={TECHNOLOGIES.map(t => t.id)}
      colors={TECHNOLOGIES.map(t => t.color)}
      formatCategory={c => c.toUpperCase()}
      formatValue={v => formatNumber(v, 1)}
      investment={tier}
      paddingTop={paddingTop}
      strokeArea="white"
    />
  );
};

EmissionReduction.propTypes = {
  tier: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(EmissionReduction);
