import React from 'react';
import PropTypes from 'prop-types';
import style from './ChartLabel.module.css';

const translateX = {
  start: '0',
  middle: '-50%',
  end: '-100%',
};
const translateY = {
  hanging: '0',
  middle: '-50%',
  auto: '-100%',
};

const ChartLabel = React.forwardRef(
  ({ children, textAnchor, dominantBaseline }, ref) => {
    return (
      <div
        ref={ref}
        className={style.label}
        style={{
          transform: `translate(${translateX[textAnchor]}, ${translateY[dominantBaseline]})`,
        }}
      >
        {children}
      </div>
    );
  }
);

ChartLabel.propTypes = {
  children: PropTypes.node.isRequired,
  textAnchor: PropTypes.oneOf(Object.keys(translateX)),
  dominantBaseline: PropTypes.oneOf(Object.keys(translateY)),
};

ChartLabel.defaultProps = {
  textAnchor: 'start',
  dominantBaseline: 'auto',
};

export default ChartLabel;
