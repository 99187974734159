import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { curveMonotoneX, line } from 'd3-shape';
import 'd3-transition';
import { AnimatedDataset } from '../../lib/react-composable-charts/components/AnimatedDataset';
import { useCascadingAnimation } from '../../lib/react-composable-charts/components/Animation';
import { useCartesianContext } from '../../lib/react-composable-charts/components/internal';
import {
  animated,
  animatedGroup,
} from '../../lib/react-composable-charts/components/react-animated-dom';
import { addSubscript, createPathElement, findY } from '../../data/utils';
import Portal from '../Portal/Portal';
import style from './SelectedYear.module.css';
import ChartLabel from '../ChartLabel/ChartLabel';

const SelectedYear = ({
  selectedDatum,
  data,
  color,
  getX,
  getY,
  portalRef,
  formatY,
  yAxisUnit,
}) => {
  const { xScale, yScale } = useCartesianContext();
  const animation = useCascadingAnimation();
  const ref = useRef();

  const pathD = line()
    .x(d => xScale(getX(d)))
    .y(d => yScale(getY(d)))
    .curve(curveMonotoneX)(data);
  const pathEl = createPathElement(pathD);

  const tween = (attrKey, fn) => {
    return function tweenFactory() {
      const el = this;
      return () => {
        return fn(+el.getAttribute(attrKey));
      };
    };
  };

  const dataX = xScale(getX(selectedDatum));
  const xRange = xScale.range();

  return (
    <>
      <AnimatedDataset
        tag="circle"
        {...animation}
        dataset={[{ ...selectedDatum, key: pathD }]}
        attrs={{
          cx: d => xScale(getX(d)),
          fill: 'white',
          stroke: color,
          strokeWidth: 2,
          r: 6,
          opacity: 1,
        }}
        tweens={{
          cy: tween('cx', x => findY(pathEl, x)),
        }}
        init={{ opacity: 0 }}
        keyFn={d => d.key}
      />

      <Portal el={portalRef}>
        <animatedGroup.div>
          <animated.div
            className={style.label}
            data-x={dataX}
            styleTweens={{
              top: tween('data-x', x => `${findY(pathEl, x) - 10}px`),
              left: tween('data-x', x => {
                const maxOffset =
                  ref.current.getBoundingClientRect().width / 2 || 50;
                const fn = a => a ** 3;
                const toFnDomain = value => scaleLinear(xRange, [-1, 1])(value);
                const toChartRange = value => value * maxOffset;
                const offset = toChartRange(fn(toFnDomain(x)));

                return `${x - offset}px`;
              }),
            }}
            {...animation}
          >
            <ChartLabel ref={ref} textAnchor="middle">
              <div className={style.centeredContent}>
                <div>
                  {formatY(getY(selectedDatum))}{' '}
                  <span className={style.lightText}>
                    {addSubscript(yAxisUnit)}
                  </span>
                </div>
              </div>
            </ChartLabel>
          </animated.div>
        </animatedGroup.div>
      </Portal>
    </>
  );
};

SelectedYear.propTypes = {
  selectedDatum: PropTypes.shape({
    year: PropTypes.number,
    catalyst: PropTypes.number,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  color: PropTypes.string.isRequired,
  getX: PropTypes.func.isRequired,
  getY: PropTypes.func.isRequired,
  yAxisUnit: PropTypes.string.isRequired,
  formatY: PropTypes.func.isRequired,
  // React ref
  portalRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default SelectedYear;
