import React, { useRef } from 'react';
import { BiSlider } from 'react-icons/bi';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dollarsToEmissions, tierToStr } from '../../data/constants';
import Accordion from '../Accordion/Accordion';
import InvestmentSlider from '../InvestmentSlider/InvestmentSlider';

import './InvestmentSliderDrawer.css';
import useOpenClose from '../../hooks/useOpenClose/useOpenClose';

const mapStateToProps = state => ({
  tier: state.investmentSlice.tier,
});
const InvestmentSliderDrawer = ({ tier }) => {
  const { isOpen, toggle } = useOpenClose();
  const tooltipRef = useRef(<></>);
  const dollars = tierToStr(tier);
  const emissions = dollarsToEmissions(tier);

  // we need a bunch of spans here because otherwise the CO2 text throws off
  // the vertical alignment
  const toggleEl = (
    <div className="investment-slider-drawer__toggle">
      <span className="investment-slider-drawer__text-start">
        Data is based on an investment of&nbsp;
      </span>
      <span className="investment-slider-drawer__number">{dollars}</span>
      <span className="investment-slider-drawer__text-end">
        &nbsp;with a total of&nbsp;
      </span>
      <span className="investment-slider-drawer__number">
        {emissions} MMtCO<sub>2</sub>
      </span>
      <span className="investment-slider-drawer__text-end">
        &nbsp;catalyzed emissions
      </span>
      <div className="investment-slider-drawer__explore">
        <BiSlider />
        explore
      </div>
    </div>
  );

  return (
    <div className="investment-slider-drawer__wrapper">
      <Accordion
        className="investment-slider-drawer box-shadow"
        toggleClassName="investment-slider-drawer__toggle-wrapper box-shadow"
        toggleEl={toggleEl}
        onToggle={toggle}
        isOpenOverride={isOpen}
        withDrawerAnimation
      >
        <InvestmentSlider tooltipRef={tooltipRef} hideTooltip={!isOpen} />
      </Accordion>
      <div ref={tooltipRef} />
    </div>
  );
};

InvestmentSliderDrawer.propTypes = {
  tier: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(InvestmentSliderDrawer);
