import { formatPrefix } from 'd3-format';

export const formatNumber = (number, decimals = 0) => {
  const abs = Math.abs(number);
  if (abs === 0) return 0;

  const exponent = Math.floor(Math.log10(abs) / 3) * 3;
  const formatter = formatPrefix(`.${decimals}`, 10 ** Math.max(0, exponent));
  const formatted = formatter(number);
  return formatted;
};

export const getDecimalsFormatter = (exponent, order) =>
  exponent > 0 ? Math.max(0, order - exponent) : Math.abs(exponent);

export const getExponent = n => Math.floor(Math.log10(Math.abs(n)));

export const getOrder = n => Math.floor(getExponent(n) / 3) * 3;

export const buildTickFormatter = ticks => {
  const tickStep = ticks.length > 2 ? Math.abs(ticks[1] - ticks[0]) : 0;
  const stepExponent = getExponent(tickStep);

  return d => {
    const order = getOrder(d);
    const decimals = getDecimalsFormatter(stepExponent, order);
    const formatter = formatPrefix(`.${decimals}`, 10 ** Math.max(0, order));
    const formatted = formatter(d);
    return formatted;
  };
};

export const formatCost = cost => `${formatNumber(cost)} $`;

/**
 * Calls the specified callback function for all the elements in an array.
 * The return value of the callback function is the accumulated result,
 * and is provided as an argument in the next call to the callback function.
 * The function returns an array with all values produced by the callback.
 *
 * example:
 * ```
 * const sum = (acc, value) => acc + value;
 * const cumulativeSum = scan([1,2,3,4], sum, 0)
 * cumulativeSum // [1,3,6,10]
 * ```
 * @param {T[]} array
 *
 * @param {(previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U} callbackfn A function that accepts up to four arguments.
 * The reduce method calls the callbackfn function one time for each element in the array.
 *
 * @param {U[]} initialValue  initialValue is used as the
 * initial value to start the accumulation.
 *
 * @template T,U
 *
 * @see http://zvon.org/other/haskell/Outputprelude/scanl1_f.html
 */
export function scan(array, callbackfn, initialValue) {
  const res = [];
  let accumulator = initialValue;
  for (let i = 0; i < array.length; i += 1) {
    accumulator = callbackfn(accumulator, array[i], i, array);
    res.push(accumulator);
  }
  return res;
}
