/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { computePos } from '../lib/scales';
import {
  AnimationProps,
  CartesianScale,
  CommonStyleProps,
  Component,
  DataValue,
  isScaleContinuous,
} from '../lib/types';
import { createAnimatedAttrs, xor } from '../lib/utils';
import { AnimatedDataset } from './AnimatedDataset';
import { useSanitizedCascadingAnimation } from './Animation';
import {
  GridContext,
  useCartesianContext,
  useChartContext,
  useGridContext,
} from './internal';
import { Range } from './Range';
import { useCascadingStyle } from './Style';

type GridComponent = Component<{
  children?: React.ReactNode;
  xAnchor?: 'bottom' | 'top' | 'none';
  yAnchor?: 'left' | 'right' | 'none';

  /** Ideal number di pixel per tick */
  tickSize?: number;

  /** Ideal total number of ticks */
  tickCount?: number;
}> & {
  XAxes: Component<CommonStyleProps>;
  YAxes: Component<CommonStyleProps>;
  XLabels: Component<
    CommonStyleProps &
      AnimationProps<DataValue> & {
        format?: (
          allValues: DataValue[]
        ) => (value: DataValue, i: number) => string;
        padding?: number;
        threshold?: number;
        inner?: boolean;
        ticks?: DataValue[];
        filter?: (
          value: DataValue,
          index: number,
          array: DataValue[]
        ) => boolean;
      }
  >;
  YLabels: Component<
    CommonStyleProps &
      AnimationProps<DataValue> & {
        format?: (
          allValues: DataValue[]
        ) => (value: DataValue, i: number) => string;
        padding?: number;
        threshold?: number;
        inner?: boolean;
        ticks?: DataValue[];
        filter?: (
          value: DataValue,
          index: number,
          array: DataValue[]
        ) => boolean;
      }
  >;
  XLines: Component<
    CommonStyleProps & AnimationProps<DataValue> & { ticks?: DataValue[] }
  >;
  YLines: Component<
    CommonStyleProps & AnimationProps<DataValue> & { ticks?: DataValue[] }
  >;
};

type AxesAnchor = 'start' | 'end' | 'none';

const computeAxesPos = (
  scale: CartesianScale,
  range: [number, number],
  anchor: AxesAnchor = 'none'
) => {
  const [start, end] = range;
  return {
    start: () => start,
    end: () => end,
    none: () => {
      const zeroPos = isScaleContinuous(scale) ? scale(0) : scale.range()[0];
      return Range.clampValue(zeroPos, [start, end]);
    },
  }[anchor]();
};

const computeTicks = (scale: CartesianScale, count: number) =>
  isScaleContinuous(scale) ? scale.ticks(count) : scale.domain();

export const Grid = (({
  children,
  tickSize: tickResolution = 50,
  tickCount,
  xAnchor = 'none',
  yAnchor = 'none',
}) => {
  const { top, left, bottom, right, width, height } = useChartContext();
  const { yScale, xScale } = useCartesianContext();

  const xCount = tickCount ?? width / tickResolution;
  const yCount = tickCount ?? height / tickResolution;

  const xAxesAnchor = ({ bottom: 'start', top: 'end', none: 'none' } as const)[
    xAnchor
  ];
  const yAxesAnchor = ({ left: 'start', right: 'end', none: 'none' } as const)[
    yAnchor
  ];
  return (
    <GridContext.Provider
      value={{
        xAxes: computeAxesPos(xScale, [left, right], yAxesAnchor),
        yAxes: computeAxesPos(yScale, [bottom, top], xAxesAnchor),
        xTicks: computeTicks(xScale, xCount),
        yTicks: computeTicks(yScale, yCount),
      }}
    >
      {children}
    </GridContext.Provider>
  );
}) as GridComponent;

Grid.XAxes = props => {
  const { left, right } = useChartContext();
  const { yAxes } = useGridContext();
  const style = useCascadingStyle(props);
  // TODO: add animation
  return <line {...style} x1={left} x2={right} y1={yAxes} y2={yAxes} />;
};

Grid.YAxes = props => {
  const { top, bottom } = useChartContext();
  const { xAxes } = useGridContext();

  const style = useCascadingStyle(props);
  // TODO: add animation
  return <line {...style} x1={xAxes} x2={xAxes} y1={top} y2={bottom} />;
};

// TODO: use LabelsData component to render Grid.XLabels
Grid.XLabels = ({
  format: buildFormatter = () => value => String(value),
  padding = 0,
  threshold = 0,
  inner: _inner = false,
  filter = () => true,
  ticks: _ticks,
  dataKey,
  enter = {},
  delay,
  duration,
  ...props
}) => {
  const { top } = useChartContext();
  const { xScale } = useCartesianContext();
  const { yAxes, xTicks } = useGridContext();
  const style = useCascadingStyle(props);
  const animation = useSanitizedCascadingAnimation({ delay, duration });

  const ticks = _ticks ?? xTicks;
  const filteredTicks = ticks.filter(filter);
  const reverse = yAxes <= top + threshold;
  const inner = xor(_inner, reverse);
  const formatter = buildFormatter(filteredTicks);

  const texts = filteredTicks.map((tick, i) => {
    const key = dataKey?.(tick, i) ?? tick;
    const x = computePos(tick, xScale);
    const y = yAxes + (inner ? -padding : padding);
    const textAnchor = 'middle';
    const dominantBaseline = inner ? 'auto' : 'hanging';
    const text = formatter(tick, i);

    return {
      key,
      x,
      y,
      textAnchor,
      dominantBaseline,
      text,
      opacity: 1,
      datum: tick,
      ...style,
    };
  });

  return (
    <>
      <AnimatedDataset
        tag="text"
        dataset={texts}
        attrs={createAnimatedAttrs(texts)}
        init={{ opacity: 0, ...enter }}
        keyFn={d => d.key}
        {...(animation as any)}
      />
    </>
  );
};

Grid.YLabels = ({
  format: buildFormatter = () => value => String(value),
  padding = 0,
  threshold = 0,
  inner: _inner = false,
  filter = () => true,
  ticks: _ticks,
  dataKey,
  delay,
  duration,
  enter,
  ...props
}) => {
  const { right } = useChartContext();
  const { yScale } = useCartesianContext();
  const { xAxes, yTicks } = useGridContext();
  const style = useCascadingStyle(props);
  const animation = useSanitizedCascadingAnimation({ delay, duration });

  const ticks = _ticks ?? yTicks;
  const filteredTicks = ticks.filter(filter);
  const reverse = xAxes >= right - threshold;
  const inner = xor(_inner, reverse);
  const formatter = buildFormatter(filteredTicks);

  const texts = filteredTicks.map((tick, i) => {
    const key = dataKey?.(tick, i) ?? tick;
    const x = xAxes + (inner ? padding : -padding);
    const y = computePos(tick, yScale);
    const textAnchor = inner ? 'start' : 'end';
    const dominantBaseline = 'middle';
    const text = formatter(tick, i);

    return {
      key,
      x,
      y,
      textAnchor,
      dominantBaseline,
      text,
      opacity: 1,
      datum: tick,
      ...style,
    };
  });

  return (
    <AnimatedDataset
      tag="text"
      dataset={texts}
      attrs={createAnimatedAttrs(texts)}
      init={{ opacity: 0, ...enter }}
      keyFn={d => d.key}
      {...(animation as any)}
    />
  );
};

Grid.XLines = ({ ticks, dataKey, delay, duration, enter, ...props }) => {
  const { top, bottom } = useChartContext();
  const { xScale } = useCartesianContext();
  const xTicks = ticks ?? useGridContext().xTicks;
  const style = useCascadingStyle(props);
  const animation = useSanitizedCascadingAnimation({ delay, duration });

  const lines = xTicks.map((tick, i) => {
    const x = computePos(tick, xScale);
    const key = dataKey?.(tick, i) ?? tick;

    return {
      key,
      x1: x,
      x2: x,
      y1: top,
      y2: bottom,
      opacity: 1,
      datum: tick,
      ...style,
    };
  });

  return (
    <AnimatedDataset
      tag="line"
      dataset={lines}
      attrs={createAnimatedAttrs(lines)}
      init={{ opacity: 0, ...enter }}
      keyFn={d => d.key}
      {...(animation as any)}
    />
  );
};

Grid.YLines = ({ ticks, dataKey, enter, duration, delay, ...props }) => {
  const { left, right } = useChartContext();
  const { yScale } = useCartesianContext();
  const yTicks = ticks ?? useGridContext().yTicks;
  const style = useCascadingStyle(props);
  const animation = useSanitizedCascadingAnimation({ delay, duration });

  const lines = yTicks.map((tick, i) => {
    const y = computePos(tick, yScale);
    const key = dataKey?.(tick, i) ?? tick;

    return {
      key,
      x1: left,
      x2: right,
      y1: y,
      y2: y,
      opacity: 1,
      datum: tick,
      ...style,
    };
  });

  return (
    <AnimatedDataset
      tag="line"
      dataset={lines}
      attrs={createAnimatedAttrs(lines)}
      init={{ opacity: 0, ...enter }}
      keyFn={d => d.key}
      {...(animation as any)}
    />
  );
};
