import React from 'react';
import { CONTACT_US_EMAIL } from '../../data/constants';

import './ContactUsTooltip.css';

const ContactUsTooltip = () => {
  const icon = (
    <svg width="22" height="22">
      <defs>
        <linearGradient id="gradient" y2="100%">
          <stop stopColor="#3ef08f" offset="0" />
          <stop stopColor="#27daf0" offset="1" />
        </linearGradient>
      </defs>

      <circle
        cx="11"
        cy="11"
        r="9"
        stroke="url(#gradient)"
        strokeWidth="2"
        fill="white"
      />

      <text
        className="text"
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        fill="url(#gradient)"
      >
        !
      </text>
    </svg>
  );

  return (
    <div className="contact-us-tooltip">
      <a
        className="box-shadow reset-link-styles"
        href={CONTACT_US_EMAIL}
        target="_blank"
        rel="noreferrer"
      >
        <div className="contact-us-tooltip__title">{icon} over $400M</div>
        <div>
          <span className="contact-us-tooltip__email">Contact us</span>
          &nbsp;for more information about how to have a bigger impact
        </div>
      </a>
    </div>
  );
};

export default ContactUsTooltip;
