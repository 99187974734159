import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ el, children }) =>
  el.current ? ReactDOM.createPortal(children, el.current) : null;

Portal.propTypes = {
  // React ref
  el: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default Portal;
