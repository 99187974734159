import React from 'react';
import { line } from 'd3-shape';
import { AnimationProps, CommonStyleProps, DataAccessor } from '../lib/types';
import { useCartesianContext } from './internal';
import { useCascadingStyle } from './Style';
import { computePos } from '../lib/scales';
import { buildCurveFn, CurveType } from '../lib/curve';
import { AnimatedDataset } from './AnimatedDataset';
import { createAnimatedAttrs } from '../lib/utils';
import { useSanitizedCascadingAnimation } from './Animation';

interface LineDataProps<T> extends CommonStyleProps, AnimationProps<T> {
  data: T[];
  x: DataAccessor<T>;
  y: DataAccessor<T>;
  curve?: CurveType;
}

export function LineData<T>({
  data,
  x,
  y,
  curve: type = 'line',
  dataKey,
  delay,
  duration,
  enter,
  easing,
  ...props
}: LineDataProps<T>) {
  const { xScale, yScale } = useCartesianContext();
  const style = useCascadingStyle(props);
  const curveFn = buildCurveFn(type);
  const animation = useSanitizedCascadingAnimation({ duration, delay, easing });

  const points = data
    .map(d => [computePos(x(d), xScale), computePos(y(d), yScale)])
    .filter(d => d.every(p => !isNaN(p)));
  const path = line<number[]>().curve(curveFn)(points)!;

  const attrs = { d: path, fill: 'none', ...style };
  return (
    <AnimatedDataset
      tag="path"
      dataset={[attrs]}
      attrs={createAnimatedAttrs([attrs])}
      init={enter}
      keyFn={() => 1}
      {...(animation as any)}
    />
  );
}
