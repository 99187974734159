import { first, isNil } from 'lodash';
import { useRef } from 'react';

export const xand = (a: boolean, b: boolean) => a === b;
export const xor = (a: boolean, b: boolean) => !xand(a, b);

export const useConst = <T>(initial: T) => useRef(initial).current;
export const useId = () => useConst(Math.random().toString(16).split('.')[1]);

export const removeEmptyKeys = <T extends object>(o: T): T =>
  Object.fromEntries(Object.entries(o).filter(([k, v]) => !isNil(v))) as T;

type Line = { x1: number; x2: number; y1: number; y2: number };
type Rect = { x: number; y: number; width: number; height: number };

export const line2rect = (line: Line): Rect => ({
  x: Math.min(line.x1, line.x2),
  y: Math.min(line.y1, line.y2),
  width: Math.abs(line.x1 - line.x2),
  height: Math.abs(line.y1 - line.y2),
});

export function createAnimatedAttrs<T extends object>(attrs: T[]) {
  const firstAttr = first(attrs);
  if (!firstAttr) return {};

  const keys = Object.keys(firstAttr) as (keyof T)[];
  const filteredKeys = keys.filter(
    k => !['key', 'datum'].includes(k as string)
  );
  return Object.fromEntries(filteredKeys.map(key => [key, (d: T) => d[key]]));
}
