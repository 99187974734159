import React from 'react';
import PropTypes from 'prop-types';
import style from './Toggle.module.css';

function Toggle({
  className,
  inputClassName,
  style: styleProp,
  disabled,
  checked,
  onChange,
}) {
  function handleChange(event) {
    if (disabled) return;
    onChange(event.currentTarget.checked);
  }

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <label
      style={styleProp}
      className={`
        ${className} 
        ${style.wrapper} 
        ${disabled ? style.disabled : ''}`}
    >
      <div
        className={`
          ${inputClassName}
          ${style.wrapperInner} 
          ${checked ? style.checked : ''}`}
      >
        <input
          className={style.input}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        <div className={style.dot} style={{ left: checked ? '55%' : '5%' }} />
      </div>
    </label>
  );
}

Toggle.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Toggle.defaultProps = {
  className: '',
  inputClassName: '',
  style: {},
  disabled: false,
  checked: false,
  onChange: () => {},
};

export default Toggle;
