import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import logo from '../../images/logo.svg';

import { AUTH_COOKIE, AUTH_PW } from '../../data/constants';

import './Login.css';

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = event => {
    setPassword(event.target.value);
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();

      if (password === AUTH_PW) {
        Cookies.set(AUTH_COOKIE, AUTH_PW);
      } else {
        setErrorMessage('Please enter the correct password');
      }
      onLogin();
    }
  };
  return (
    <>
      <div className="login-navbar">
        <img alt="Breakthrough Energy logo" src={logo} className="login-logo" />
      </div>
      <div className="login__wrapper">
        <div className="login">
          <h2 className="App__header">
            Welcome to the Emerging Climate Technology Framework
          </h2>
          <form className="login-form" onSubmit={handleSubmit}>
            <span className="login-form__label">Password:&nbsp;</span>
            <input
              className="login-form__input"
              name="password"
              type="password"
              value={password}
              onChange={handleInputChange}
              required
            />
            <input
              className="login-form__submit"
              type="submit"
              value="Enter"
              placeholder="password"
            />
            <div className="login-form__error">{errorMessage}</div>
          </form>
          <p className="login__desc">
            Many companies have credible transition plans to cut their emissions
            by 2030. To achieve net zero by 2050, most companies will rely on
            emerging climate technology (ECT) that currently exists at a higher
            price point (the ‘Green Premium’) compared to its fossil fuel
            incumbent. Investment is needed now to enable ECT to be deployed at
            scale. The Emerging Climate Technology Framework (ECTF) estimates,
            monitors, and attributes climate impacts of project finance
            investment. This dashboard can be used by any investor to estimate
            potential climate impacts of investments in ECT.
          </p>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default Login;
