import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CostCapacityShift from '../CostCapacityShift/CostCapacityShift';
import ExpectedButton from '../ExpectedButton/ExpectedButton';
import {
  CLEAN_TECH_OVERVIEW_DATA,
  MAKE_LAYOUT_CONFIG,
  getPathwayData,
} from '../../data/constants';
import {
  formatNumber,
  getDecimalsFormatter,
  getExponent,
  getOrder,
} from '../../lib/utils';

const mapStateToProps = state => ({
  technology: state.investmentSlice.technology,
  pathway: state.investmentSlice.pathway,
  tier: state.investmentSlice.tier,
});

const computeCapacityIncreaseData = (dataset, isDeltaView) => {
  const isLiter = dataset.uom === 'L';
  return {
    uom: isLiter ? 'GL' : dataset.uom,
    data: dataset.years.map((year, i) => {
      const reference = dataset.ref[i] / (isLiter ? 10 ** 9 : 1);
      const catalyst = dataset.cat[i] / (isLiter ? 10 ** 9 : 1);
      return {
        year: Math.round(year),
        reference: isDeltaView ? 0 : reference,
        catalyst: isDeltaView ? catalyst - reference : catalyst,
      };
    }),
  };
};

const formatY = y => {
  const exponent = getExponent(y);
  const order = getOrder(y);
  const decimals = getDecimalsFormatter(exponent, order);
  return formatNumber(y, decimals);
};

function CapacityIncreaseChart({ technology, pathway, tier }) {
  const [isDeltaView, setIsDeltaView] = useState(true);
  const pathwayData = getPathwayData({ tier, pathway }).ppcig;
  const dataset = computeCapacityIncreaseData(pathwayData, isDeltaView);
  const paddingTop = 50;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ position: 'relative' }}>
        <CostCapacityShift
          data={dataset.data}
          maxDelta={2}
          formatY={formatY}
          yAxisUnit={`${dataset.uom}/Year`}
          technologyColor={CLEAN_TECH_OVERVIEW_DATA[technology].color}
          section="Capacity"
        />
        <ExpectedButton
          onClick={() => setIsDeltaView(isDelta => !isDelta)}
          top={paddingTop - 20}
          right={MAKE_LAYOUT_CONFIG.root.padding.right}
          text={isDeltaView ? 'Show all capacity' : 'Show Catalyzed capacity'}
          width={235}
        />
      </div>
    </div>
  );
}

CapacityIncreaseChart.propTypes = {
  pathway: PropTypes.string,
  technology: PropTypes.string,
  tier: PropTypes.number.isRequired,
};

CapacityIncreaseChart.defaultProps = {
  pathway: null,
  technology: null,
};

export default connect(mapStateToProps)(CapacityIncreaseChart);
