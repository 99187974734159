import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useOpenClose from '../../hooks/useOpenClose/useOpenClose';

import './Accordion.css';

/* This component can either manage its own state or be managed by a parent
 * It renders its children even when closed so we can transition the height when opening / closing
 */
const Accordion = ({
  className,
  toggleClassName,
  childClassName,
  isOpenOverride,
  toggleEl,
  onToggle,
  withDrawerAnimation,
  children,
}) => {
  const { isOpen, toggle } = useOpenClose(null);
  const showChild = isOpen || isOpenOverride;
  const childRef = useRef(null);

  /* When the accordion opens and closes we transition the height smoothly.
   * To do this, we need to change it from height A to height B. Before we
   * render for the first time, we don't have a childRef to get the height,
   * so we temporarily set the height to null. Immediately after render we
   * replace it with the child's exact height.
   */
  const [childHeight, setChildHeight] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const scrollHeight = `${childRef.current.scrollHeight}px`;
    if (scrollHeight !== childHeight) {
      setChildHeight(scrollHeight);
    }
  });

  const onToggleClick = () => {
    if (isOpenOverride === null) {
      toggle();
    }
    if (onToggle) {
      onToggle();
    }
  };

  const accordionClass = classNames(className, 'accordion', {
    open: showChild,
  });
  const toggleClass = classNames(toggleClassName, 'accordion__toggle');
  const childClass = classNames(childClassName, 'accordion__child', {
    closed: !showChild,
    'animate-drawer': withDrawerAnimation,
  });

  // prettier-ignore
  return (
    <div className={accordionClass}>
      <div className={toggleClass} onClick={onToggleClick} role="button">
        {toggleEl}
      </div>
      <div
        className={childClass}
        ref={childRef}
        style={{ '--accordion-child-height': `${childHeight}` }}
      >
        {withDrawerAnimation
          ? <div className="accordion__drawer">{children}</div>
          : children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  toggleClassName: PropTypes.string,
  childClassName: PropTypes.string,
  isOpenOverride: PropTypes.bool,
  toggleEl: PropTypes.node.isRequired,
  onToggle: PropTypes.func,
  withDrawerAnimation: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Accordion.defaultProps = {
  className: null,
  toggleClassName: null,
  childClassName: null,
  isOpenOverride: null,
  onToggle: null,
  withDrawerAnimation: false,
};

export default Accordion;
