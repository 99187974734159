import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Toggle from '../Toggle/Toggle';
import style from './InvestmentToggle.module.css';
import { formatCost } from '../../lib/utils';

const mapStateToProps = state => ({
  tier: state.investmentSlice.tier,
});

const InvestmentToggle = ({
  isCatalystActive,
  onCatalystActiveChange,
  tier,
}) => {
  return (
    <div className={style.toggleWrapper}>
      <div>
        Without <br />
        investment
      </div>
      <Toggle
        checked={isCatalystActive}
        onChange={() => onCatalystActiveChange(!isCatalystActive)}
      />
      <div>
        With your{' '}
        <span className={style.toggleCostLabel}>
          {formatCost(tier * 1_000_000)}
        </span>{' '}
        investment
      </div>
    </div>
  );
};

InvestmentToggle.propTypes = {
  isCatalystActive: PropTypes.bool.isRequired,
  onCatalystActiveChange: PropTypes.func.isRequired,
  tier: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(InvestmentToggle);
