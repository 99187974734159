import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SliderUnstyled from '@mui/core/SliderUnstyled';

import { extent } from 'd3';
import { makeLayout } from 'yogurt-layout';
import useForceRender from '../../hooks/useForceRender/useForceRender';
import { Cartesian } from '../../lib/react-composable-charts/components/Cartesian';
import { Chart } from '../../lib/react-composable-charts/components/Chart';
import { Grid } from '../../lib/react-composable-charts/components/Grid';
import { LineData } from '../../lib/react-composable-charts/components/LineData';
import { AreaData } from '../../lib/react-composable-charts/components/AreaData';
import { Style } from '../../lib/react-composable-charts/components/Style';

import Portal from '../Portal/Portal';
import style from './CostCapacityShift.module.css';
import { COLORS, MAKE_LAYOUT_CONFIG } from '../../data/constants';
import DebugYogurtLayout from '../DebugYogurtLayout/DebugYogurtLayout';
import SelectedYear from './SelectedYear';
import YAxesLabel from '../YAxesLabel/YAxesLabel';
import { buildTickFormatter } from '../../lib/utils';
import { addSubscript } from '../../data/utils';

const CostCapacityShift = ({
  data,
  formatY,
  // maxDelta,
  yAxisUnit,
  technologyColor,
  section,
}) => {
  useForceRender();

  const labelsYpadding = 10;

  const { root, labelsX, labelsY } = MAKE_LAYOUT_CONFIG;

  const layout = makeLayout({
    id: 'root',
    width: root.width,
    height: root.height,
    padding: { ...root.padding, top: 50 },
    children: [
      { id: 'labelsY', width: labelsY.width },
      {
        id: 'vertical-wrapper',
        direction: 'column',
        children: [{ id: 'chart' }, { id: 'labelsX', height: labelsX.height }],
      },
    ],
  });

  const ref = useRef();

  const [selectedYear, setSelecterYear] = useState(data[0].year);
  // const gradientId = `gradient-${useId()}`;

  const getYear = d => d.year;
  const getCatalyst = d => d.catalyst;
  const getReference = d => d.reference;

  const xDomain = extent(data.map(getYear));
  const yDomain = extent([...data.map(getCatalyst)]);

  const selectedDatum = data.find(d => d.year === selectedYear);
  const uom = yAxisUnit;

  return (
    <div className={style.wrapper}>
      <svg
        width={root.width}
        height={root.height}
        viewBox={`0 0 ${root.width} ${root.height}`}
      >
        <Chart
          top={layout.chart.top}
          left={layout.chart.left}
          width={layout.chart.width}
          height={layout.chart.height}
        >
          <Cartesian
            x={{ scale: 'linear', domain: xDomain }}
            y={{ scale: 'linear', domain: yDomain }}
            nice="y"
          >
            <YAxesLabel
              portalRef={ref}
              uom={addSubscript(uom)}
              x={layout.labelsY.left}
              y={layout.labelsY.top}
              section={section}
            />
            <g>
              <Grid tickCount={5} xAnchor="bottom">
                <Grid.XAxes stroke={COLORS.stormyBay} />
                <Grid.YLines stroke={COLORS.narwhalGrey} opacity={0.08} />

                <Grid.XLines
                  ticks={[selectedYear]}
                  stroke={COLORS.stormyBay}
                  dataKey={(_, i) => i}
                />

                <Style fill={COLORS.stormyBay} fontSize={14}>
                  <Grid.XLabels
                    padding={22}
                    filter={year => Math.abs(year - selectedYear) > 2}
                  />

                  <Grid.YLabels
                    padding={labelsYpadding}
                    format={buildTickFormatter}
                  />
                </Style>
                <Grid.XLabels
                  padding={22}
                  ticks={[selectedYear]}
                  fill="#f7f9fa"
                  stroke="#f7f9fa"
                  strokeWidth="4"
                  dataKey={(_, i) => i}
                />
                <Grid.XLabels
                  padding={22}
                  ticks={[selectedYear]}
                  fill="black"
                  dataKey={(_, i) => i}
                />
              </Grid>
            </g>

            <LineData
              data={data}
              curve="monotone-x"
              x={getYear}
              y={getReference}
              stroke={COLORS.polishedSilver}
              strokeWidth="2"
            />

            <LineData
              data={data}
              curve="monotone-x"
              x={getYear}
              y={getCatalyst}
              stroke={technologyColor}
              strokeWidth="2"
            />

            <AreaData
              data={data}
              curve="monotone-x"
              x={getYear}
              y={{ base: getCatalyst, to: getReference }}
              fill={technologyColor}
              opacity={0.3}
            />

            <SelectedYear
              selectedDatum={selectedDatum}
              data={data}
              color={technologyColor}
              getX={getYear}
              getY={getCatalyst}
              formatY={formatY}
              yAxisUnit={addSubscript(yAxisUnit)}
              portalRef={ref}
            />

            <Portal el={ref}>
              <div
                className={style.slider}
                style={{
                  top: layout.labelsX.bottom,
                  left: layout.labelsX.left,
                  width: layout.labelsX.width,
                }}
              >
                <SliderUnstyled
                  value={selectedYear}
                  onChange={e => setSelecterYear(+e.target.value)}
                  min={xDomain[0]}
                  max={xDomain[1]}
                  step={1}
                  marks
                />
              </div>
            </Portal>
          </Cartesian>
        </Chart>

        <DebugYogurtLayout layout={layout.chart} color="blue" />
        <DebugYogurtLayout layout={layout.labelsX} color="blue" />
        <DebugYogurtLayout layout={layout.labelsY} color="blue" />
        <DebugYogurtLayout layout={layout.root} color="blue" noFill />
      </svg>

      <div ref={ref} />
    </div>
  );
};

CostCapacityShift.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  formatY: PropTypes.func,
  // maxDelta: PropTypes.number,
  yAxisUnit: PropTypes.string.isRequired,
  technologyColor: PropTypes.string.isRequired,
  section: PropTypes.string,
};

CostCapacityShift.defaultProps = {
  formatY: y => y,
  section: '',
  // maxDelta: 0,
};

export default CostCapacityShift;
