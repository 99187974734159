import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { wideToNarrow } from '../../lib/react-composable-charts/lib/reshapeData';
import EmissionAreaChart from '../EmissionAreaChart/EmissionAreaChart';
import Toggle from '../Toggle/Toggle';
import style from './EmissionAvoided.module.css';
import ChartCaption from './ChartCaption.js';
import {
  CLEAN_TECH_OVERVIEW_DATA,
  getPathwayData,
  MAKE_LAYOUT_CONFIG,
} from '../../data/constants';
import { formatNumber } from '../../lib/utils';

const mapStateToProps = state => ({
  technology: state.investmentSlice.technology,
  pathway: state.investmentSlice.pathway,
  tier: state.investmentSlice.tier,
});

const computeEmissionsAvoidedData = (dataset, isDeltaView) => {
  const { years, ref: referenceData, cat: catalystData } = dataset;

  const categories = ['Expected', 'Catalyst'];

  const data = years.map((year, i) => ({
    year,
    [categories[0]]: isDeltaView ? 0 : referenceData[i],
    [categories[1]]: catalystData[i],
  }));

  const narrowData = wideToNarrow(data, categories, 'category', 'emissions');

  return {
    data: narrowData,
    categories,
  };
};

const EmissionAvoided = ({ technology, pathway, tier }) => {
  const pathwayData = getPathwayData({ tier, pathway }).ppeag;
  const [isDeltaView, setIsDeltaView] = useState(true);

  const dataset = computeEmissionsAvoidedData(pathwayData, isDeltaView);
  const paddingTop = 50;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        className={style.wrapper}
        style={{ width: MAKE_LAYOUT_CONFIG.root.width }}
      >
        <div
          className={style.toggle}
          style={{ right: MAKE_LAYOUT_CONFIG.root.padding.right }}
        >
          <div>Show All</div>
          <div style={{ margin: '0 10px' }}>
            <Toggle
              checked={isDeltaView}
              onChange={() => setIsDeltaView(isDelta => !isDelta)}
              style={{ color: 'red' }}
            />
          </div>
          <div>Show Catalyst Only</div>
        </div>
        <EmissionAreaChart
          data={dataset.data}
          categories={dataset.categories}
          colors={['#D4E5ED', CLEAN_TECH_OVERVIEW_DATA[technology].color]}
          formatValue={(value, category) =>
            category === dataset.categories[1]
              ? `+${formatNumber(value, 2)}`
              : formatNumber(value, 2)
          }
          investment={tier}
          paddingTop={paddingTop}
        />
      </div>

      <ChartCaption isDeltaView={isDeltaView} />
    </div>
  );
};

EmissionAvoided.propTypes = {
  pathway: PropTypes.string,
  technology: PropTypes.string,
  tier: PropTypes.number.isRequired,
};

EmissionAvoided.defaultProps = {
  pathway: null,
  technology: null,
};

export default connect(mapStateToProps)(EmissionAvoided);
