import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';

import { AUTH_COOKIE, AUTH_PW } from '../data/constants';
import CleanTechOverview from '../components/CleanTechOverview/CleanTechOverview';
import GraphTab from '../components/GraphTab/GraphTab';
import investmentSlice from '../slices/InvestmentSlice';
import InvestmentSliderDrawer from '../components/InvestmentSliderDrawer/InvestmentSliderDrawer';
import Login from '../components/Login/Login';
import Navbar from '../components/Navbar/Navbar';
import RightSidebar from '../components/RightSidebar/RightSidebar';

import './App.css';

const checkAuthCookie = () => {
  const cookie = Cookies.get(AUTH_COOKIE);
  return cookie && cookie === AUTH_PW;
};

function App() {
  const [authenticated, setAuthenticated] = useState(checkAuthCookie());

  const onLogin = () => setAuthenticated(checkAuthCookie());

  // Initiate store
  const store = configureStore({
    reducer: combineReducers({ investmentSlice }),
  });

  const appContent = (
    <div className="App__content">
      <div className="App_left">
        <Navbar />
        <h2 className="App__header">
          Emerging Climate
          <br />
          Technology Framework
        </h2>
        <CleanTechOverview />
      </div>
      <div className="App__right-wrapper">
        <div className="investment-slider__wrapper">
          <InvestmentSliderDrawer />
        </div>
        <div className="App__graph-and-r-sidebar">
          <div className="graphTab__wrapper">
            <GraphTab />
          </div>
          <div className="right-sidebar__wrapper">
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Provider store={store}>
      <div className="App">
        {authenticated ? appContent : <Login onLogin={onLogin} />}
      </div>
    </Provider>
  );
}

export default App;
