import React from 'react';
import PropTypes from 'prop-types';
import { GRAPH_INFO } from '../../data/constants';

import './GraphDescription.css';

const GraphDescription = ({ graph, technology, pathway }) => {
  const renderPathwayContent = () => {
    return (
      <>
        <div className="graph-description">
          {GRAPH_INFO[graph][technology].description}
        </div>
        <div className="graph-description">
          {GRAPH_INFO[graph][technology][pathway]}
        </div>
      </>
    );
  };

  const renderAllTechContent = () => (
    <div className="graph-description">{GRAPH_INFO[graph].description}</div>
  );

  return (
    <>
      <h2 className="graph-description__title">{GRAPH_INFO[graph].title}</h2>
      {pathway ? renderPathwayContent() : renderAllTechContent()}
    </>
  );
};

GraphDescription.propTypes = {
  graph: PropTypes.string.isRequired,
  technology: PropTypes.string,
  pathway: PropTypes.string,
};

GraphDescription.defaultProps = {
  technology: null,
  pathway: null,
};

export default GraphDescription;
