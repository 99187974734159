import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { round, clamp } from 'lodash';
import SliderUnstyled from '@mui/core/SliderUnstyled';
import PropTypes from 'prop-types';
import {
  BUTTON_TIERS,
  dollarsToEmissions,
  emissionsToDollars,
  INVESTMENT_TIERS,
  MAX_INVESTMENT,
  MIN_INVESTMENT,
  tierToStr,
} from '../../data/constants';
import './InvestmentSlider.css';
import ContactUsTooltip from '../ContactUsTooltip/ContactUsTooltip';
import { updateTier as _updateTier } from '../../slices/InvestmentSlice';
import Portal from '../Portal/Portal';

const mapStateToProps = state => ({
  tier: state.investmentSlice.tier,
});
const dispatchProps = { updateTier: _updateTier };

// Note: we pass in tooltipRef so that we can render the tooltip
// in a portal outside the accordion, which needs to have overflow: hidden
const InvestmentSlider = ({ tooltipRef, hideTooltip, tier, updateTier }) => {
  // eslint-disable-next-line no-unused-vars
  const [emissionsInputVal, setEmissionsInputVal] = useState(null);
  const shouldHighlightDollarLabel = BUTTON_TIERS.includes(tier);

  const onSliderChange = (event, idx) => {
    // Don't allow min donation below min allowed investment
    updateTier({ tier: Math.max(INVESTMENT_TIERS[idx], MIN_INVESTMENT) });
  };

  // calculates a percent value that lets us do { left: 30.5% }
  const calculatePositionOnSlider = (dollars, minPct) => {
    const investmentRange = MAX_INVESTMENT - MIN_INVESTMENT;
    const pct = round(((dollars - MIN_INVESTMENT) / investmentRange) * 100, 1);
    return clamp(pct, minPct, 100);
  };

  // Create emissions label and dot
  const emissions = emissionsInputVal || dollarsToEmissions(tier);
  const dollars = emissionsInputVal
    ? emissionsToDollars(emissionsInputVal)
    : tier;

  const emissionsLabelPosition = {
    left: `${calculatePositionOnSlider(dollars, 6)}%`,
  };
  const emissionsLabel = (
    <div
      className="investment-slider__emissions-label"
      style={emissionsLabelPosition}
    >
      <span>{emissions}</span>
      &nbsp;MMtCO<sub>2</sub>
    </div>
  );

  const emissionsDotPosition = {
    left: `${calculatePositionOnSlider(dollars, 0)}%`,
  };
  const emissionsDot = (
    <span
      className="investment-slider__emissions-circle emissions-circle"
      style={emissionsDotPosition}
    />
  );

  const className = classNames('investment-slider', {
    'highlight-label': shouldHighlightDollarLabel,
  });

  // prettier-ignore
  return (
    <div className={className}>
      <div className="investment-slider__slider-wrapper">
        <SliderUnstyled
          value={INVESTMENT_TIERS.indexOf(tier)}
          valueLabelDisplay="on"
          valueLabelFormat={value => tierToStr(INVESTMENT_TIERS[value])}
          onChange={onSliderChange}
          marks={INVESTMENT_TIERS.map((label, value) => ({
            label: tierToStr(label),
            value,
          }))}
          step={null}
          max={INVESTMENT_TIERS.length - 1}
        />
        {emissionsDot}
        {dollars <= MAX_INVESTMENT
          ? emissionsLabel
          : <Portal el={tooltipRef}>
              {!hideTooltip && <ContactUsTooltip />}
            </Portal>}
      </div>
      {/* NOTE: This code has been requested to be kept around
      <EmissionsInput
        value={emissionsInputVal}
        onChange={e => setEmissionsInputVal(Math.abs(floor(e.target.value, 1)))}
      /> */}
    </div>
  );
};

InvestmentSlider.propTypes = {
  tooltipRef: PropTypes.node.isRequired,
  hideTooltip: PropTypes.bool.isRequired,
  tier: PropTypes.number.isRequired,
  updateTier: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, dispatchProps)(InvestmentSlider);
