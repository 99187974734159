import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './GraphTab.css';
import { TabGroup, TabItem } from '../TabGroup/TabGroup';
import EmissionReduction from '../EmissionReduction/EmissionReduction';
import GreenPremiumChangeChart from '../GreenPremiumChangeChart/GreenPremiumChangeChart';
import GraphDescription from '../GraphDescription/GraphDescription';
import {
  BLOB_STORAGE_URL,
  CLEAN_TECH_OVERVIEW_DATA,
  GRAPH_INFO,
  IMAGES_DIR,
} from '../../data/constants';
import EmissionAvoided from '../EmissionAvoided/EmissionAvoided';
import CostReductionChart from '../CostReductionChart/CostReductionChart';
import CapacityIncreaseChart from '../CapacityIncreaseChart/CapacityIncreaseChart';
import { updateGraph as _updateGraph } from '../../slices/InvestmentSlice';

const mapStateToProps = state => ({
  pathway: state.investmentSlice.pathway,
  technology: state.investmentSlice.technology,
  graph: state.investmentSlice.graph,
});
const dispatchProps = { updateGraph: _updateGraph };

const GraphTab = ({ pathway, technology, graph, updateGraph }) => {
  const tabGraphs = {
    emissionsAvoidedAllTech: <EmissionReduction />,
    greenPremium: <GreenPremiumChangeChart />,
    yearsAccelerated: null,
    emissionsAvoided: <EmissionAvoided />,
    costReduction: <CostReductionChart />,
    learningCurve: null,
    capacityIncrease: <CapacityIncreaseChart />,
    definitions: null,
  };

  const tabImages = {
    intro: `${BLOB_STORAGE_URL}${IMAGES_DIR}/intro.png`,
    overview: `${BLOB_STORAGE_URL}${IMAGES_DIR}/overview.png`,
    catalystProgram: `${BLOB_STORAGE_URL}${IMAGES_DIR}/catalystProgram.png`,
    pvCaseStudy: `${BLOB_STORAGE_URL}${IMAGES_DIR}/pvCaseStudy.png`,
  };

  const renderTabItem = (tab, idx) => {
    const { tabTitle, title } = GRAPH_INFO[tab];
    let content;
    if (tabGraphs[tab] !== undefined) {
      content = tabGraphs[tab];
    } else if (tabImages[tab] !== undefined) {
      content = (
        <div className="graph__img-wrapper">
          <img src={tabImages[tab]} alt={title} />
        </div>
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(`ERROR: No graph or image found for selected tab ${tab}`);
      return null;
    }

    return (
      <TabItem label={tabTitle} index={`${idx}`} name={tab}>
        {content}
      </TabItem>
    );
  };

  return (
    <>
      <h2>{CLEAN_TECH_OVERVIEW_DATA[technology].title}</h2>
      <TabGroup activeTab={graph} onTabClick={g => updateGraph({ graph: g })}>
        {CLEAN_TECH_OVERVIEW_DATA[technology].tabs.map(renderTabItem)}
      </TabGroup>

      <div className="graph_description__wrapper">
        <GraphDescription
          pathway={pathway}
          technology={technology}
          graph={graph}
        />
      </div>
    </>
  );
};

GraphTab.propTypes = {
  pathway: PropTypes.string,
  technology: PropTypes.string,
  graph: PropTypes.string.isRequired,
  updateGraph: PropTypes.func.isRequired,
};

GraphTab.defaultProps = {
  pathway: null,
  technology: null,
};

export default connect(mapStateToProps, dispatchProps)(GraphTab);
