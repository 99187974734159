import React from 'react';
import PropTypes from 'prop-types';
import style from './ChartCaption.module.css';

const EmissionAvoidedChartCaption = ({ isDeltaView }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.text}>
        {isDeltaView
          ? `The graph is showing Catalyzed emissions avoided only.`
          : `The graph is showing all emissions avoided.`}
      </div>
    </div>
  );
};

EmissionAvoidedChartCaption.propTypes = {
  isDeltaView: PropTypes.bool.isRequired,
};

export default EmissionAvoidedChartCaption;
