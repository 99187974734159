import React from 'react';
import PropTypes from 'prop-types';
import { useCartesianContext } from '../../lib/react-composable-charts/components/internal';
import { computePos } from '../../lib/react-composable-charts/lib/scales';
import { formatNumber } from '../../lib/utils';
import style from './Labels.module.css';
import ChartLabel from '../ChartLabel/ChartLabel';
import {
  animated,
  animatedGroup,
} from '../../lib/react-composable-charts/components/react-animated-dom';

const InvestmentLabels = ({ investments, delay, duration }) => {
  const { xScale, yScale } = useCartesianContext();
  const topOffset = 5;

  const labels = investments.map(datum => {
    const { group, investment, percentage } = datum;
    return {
      key: group,
      left: computePos(group, xScale, 'center'),
      top: computePos(percentage, yScale, 'center') + topOffset,
      label: `${formatNumber(investment, 1)}`,
      datum,
    };
  });

  return (
    <animatedGroup.div>
      {labels.map(({ key, left, top, label, datum }, i) => (
        <animated.div
          key={key}
          className={style.label}
          style={{ top: `${top}px`, left: `${left}px` }}
          delay={delay(datum, i)}
          duration={duration}
        >
          <ChartLabel dominantBaseline="hanging" textAnchor="middle">
            <span className={style.dollar}>$ </span>
            {label}
          </ChartLabel>
        </animated.div>
      ))}
    </animatedGroup.div>
  );
};

InvestmentLabels.propTypes = {
  delay: PropTypes.func.isRequired,
  duration: PropTypes.func.isRequired,
  investments: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      investment: PropTypes.number,
      unit: PropTypes.string,
    })
  ).isRequired,
};

export default InvestmentLabels;
