import React from 'react';
import PropTypes from 'prop-types';
import style from './ExpectedButton.module.css';

const ExpectedButton = ({ onClick, top, right, text, width }) => {
  return (
    <div
      className={style.button}
      role="button"
      onClick={onClick}
      style={{
        top,
        right,
        width,
      }}
    >
      {text}
    </div>
  );
};

ExpectedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  top: PropTypes.number,
  right: PropTypes.number,
  width: PropTypes.number,
};
ExpectedButton.defaultProps = {
  top: 0,
  right: 0,
  width: 150,
};

export default ExpectedButton;
