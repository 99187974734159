import React, { useState } from 'react';
import classNames from 'classnames';
import { FaArrowRight } from 'react-icons/fa';
import { floor } from 'lodash';
import {
  BUTTON_TIERS,
  BUTTON_TIER_NAMES,
  CONTACT_US_EMAIL,
  COST_PER_TON,
  DEFAULT_BUTTON_TIER,
  DEFAULT_INVESTMENT,
  tierToStr,
  TOTAL_EMISSIONS_REDUCTION,
} from '../../data/constants';

import './RightSidebar.css';

// Component
const RightSidebar = () => {
  const [selectedDollars, setSelectedDollars] = useState(DEFAULT_BUTTON_TIER);
  // TODO: clamp inputDollars between 50M and 3B
  const [inputDollars, setInputDollars] = useState(null);
  const emissionsCatalyzed = (
    (selectedDollars / DEFAULT_INVESTMENT) *
    TOTAL_EMISSIONS_REDUCTION
  ).toFixed(2);

  const renderButtonTier = buttonTier => {
    const className = classNames('right-sidebar__tier-button flex-center', {
      active: buttonTier === selectedDollars,
    });

    const onButtonClick = () => {
      setSelectedDollars(buttonTier);
      setInputDollars(null);
    };

    return (
      <div className={className} onClick={onButtonClick} role="button">
        <span className="right-sidebar__button-num">
          {tierToStr(buttonTier)}
        </span>
        &nbsp;{BUTTON_TIER_NAMES[buttonTier]}
      </div>
    );
  };

  return (
    <div className="right-sidebar">
      <div className="right-sidebar__title">
        Become a<br />
        Catalyst Partner
      </div>

      <div className="right-sidebar__desc">
        Select your investment
        <br />
        to learn more
      </div>

      {BUTTON_TIERS.map(renderButtonTier)}

      <label className="right-sidebar__input" htmlFor="right-sidebar__input">
        <span>Custom amount:</span>
        <span>
          $
          <input
            type="number"
            id="emissions-input"
            placeholder="1 - 3,000"
            value={inputDollars || ''}
            onChange={e => setInputDollars(Math.abs(floor(e.target.value, 1)))}
          />
          M
        </span>
      </label>

      <div
        className="right-sidebar__calculate-button flex-center"
        onClick={() => inputDollars && setSelectedDollars(inputDollars)}
        role="button"
      >
        Calculate
      </div>

      <div className="right-sidebar__stats">
        <div className="right-sidebar__emissions">
          Emissions catalyzed:
          <div className="right-sidebar__emissions-val">
            <span className="right-sidebar__emissions-num">
              {emissionsCatalyzed}
            </span>
            &nbsp;Millions of Tons of C0<sub>2</sub>
          </div>
        </div>

        <div className="right-sidebar__cost">
          Cost per ton of CO<sub>2</sub> catalyzed:
          <div className="right-sidebar__cost-val">
            $<span className="right-sidebar__cost-num">{COST_PER_TON}</span>
          </div>
        </div>
      </div>

      <a
        className="right-sidebar__contact-us reset-link-styles flex-center"
        href={CONTACT_US_EMAIL}
        target="_blank"
        rel="noreferrer"
      >
        Contact us
        <div className="right-sidebar__contact-us-arrow flex-center">
          <FaArrowRight />
        </div>
      </a>
    </div>
  );
};

export default RightSidebar;
