import React from 'react';
import { computePos, scaleZero } from '../lib/scales';
import {
  CartesianScale,
  DataAccessor,
  StyleProps,
  DataGetter,
  toAccessor,
  AnimationProps,
} from '../lib/types';
import { createAnimatedAttrs, line2rect } from '../lib/utils';
import { AnimatedDataset } from './AnimatedDataset';
import { useSanitizedCascadingAnimation } from './Animation';
import { useCartesianContext } from './internal';
import { useComputableStyle } from './Style';

type BarAxesConfig<T> =
  | DataAccessor<T>
  | { base?: DataGetter<T>; to: DataGetter<T> };

function sanitizeAxisConfig<T>(
  config: BarAxesConfig<T>,
  scale: CartesianScale
): { base: DataAccessor<T>; to: DataAccessor<T> } {
  if (typeof config === 'function') {
    return { to: config, base: config };
  }
  const { to, base = () => scaleZero(scale) } = config;
  return {
    to: toAccessor(to),
    base: toAccessor(base),
  };
}

interface Props<T> extends StyleProps<T>, AnimationProps<T> {
  data: T[];
  x: BarAxesConfig<T>;
  y: BarAxesConfig<T>;
}

export function BarData<T>({
  data,
  x: _x,
  y: _y,
  dataKey,
  delay,
  duration,
  enter = {},
  easing,
  ...props
}: Props<T>) {
  const { xScale, yScale, colorScale } = useCartesianContext();
  const [style, computeStyle] = useComputableStyle(props, colorScale);

  const x = sanitizeAxisConfig(_x, xScale);
  const y = sanitizeAxisConfig(_y, yScale);
  const animation = useSanitizedCascadingAnimation({ delay, duration, easing });

  const rects = data.map((d, i) => {
    const xBase = x.base(d);
    const yBase = y.base(d);

    const xTo = x.to(d);
    const yTo = y.to(d);

    const x1 = computePos(xBase, xScale, 'start');
    const y1 = computePos(yBase, yScale, 'end');

    const x2 = computePos(xTo, xScale, 'end');
    const y2 = computePos(yTo, yScale, 'start');

    const rect = line2rect({ x1, x2, y1, y2 });
    const key = dataKey?.(d, i) ?? i;
    return { datum: d, key, ...rect, ...style, ...computeStyle(d) };
  });

  return (
    <AnimatedDataset
      tag="rect"
      dataset={rects}
      attrs={createAnimatedAttrs(rects)}
      init={enter}
      keyFn={d => d.key}
      {...(animation as any)}
    />
  );
}
