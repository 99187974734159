import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CLEAN_TECH_OVERVIEW_DATA } from '../../data/constants';
import CleanTechOverviewSection from '../CleanTechOverviewSection/CleanTechOverviewSection';
import { updateTechnology as _updateTechnology } from '../../slices/InvestmentSlice';

import './CleanTechOverview.css';

const mapStateToProps = state => ({
  technology: state.investmentSlice.technology,
});
const dispatchProps = { updateTechnology: _updateTechnology };

const CleanTechOverview = ({ technology, updateTechnology }) => {
  const renderSection = section => {
    const { title, color, icon, pathways } = CLEAN_TECH_OVERVIEW_DATA[section];
    return (
      <CleanTechOverviewSection
        key={section}
        isOpen={section === technology}
        selectTech={() => updateTechnology({ technology: section })}
        title={title}
        color={color}
        icon={icon}
        pathways={pathways}
      />
    );
  };

  return (
    <div className="clean-tech-overview">
      {Object.keys(CLEAN_TECH_OVERVIEW_DATA).map(renderSection)}
    </div>
  );
};

CleanTechOverview.propTypes = {
  technology: PropTypes.string,
  updateTechnology: PropTypes.func.isRequired,
};

CleanTechOverview.defaultProps = {
  technology: null,
};

export default connect(mapStateToProps, dispatchProps)(CleanTechOverview);
