import React from 'react';
import style from './Disclaimer.module.css';

const Disclaimer = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.text}>
        Green Premium Change: Green premium change is due to the combined impact
        of your investment and baseline investments assumed to occur during the
        same period of time.
      </div>
    </div>
  );
};

export default Disclaimer;
