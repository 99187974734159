import React from 'react';

export const PATHWAY_INFO = {
  saf: {
    'Power to liquid': `
      This technological pathway is one of many possible ways to manufacture
      SAF. In this pathway, liquid hydrocarbons are produced using renewable
      electric energy, water, and CO2. Specifically, sustainable aviation fuel
      is synthesized in this pathway by a chemical reaction called the Fischer-Tropsch
      reaction of H2 with CO, followed by the fuel upgrading through several
      manufacturing steps, such as hydrocracking, isomerization, and distillation.
      It is critical that the production must be obtained through the usage of
      renewable sources.`,
    'Alcohol to jet': `
      In the Alcohol-to-Jet pathway, various hydrocarbons are produced via
      feedstock fermentation followed by dehydration, oligomerization, and
      hydro-processing procedures. This pathway and its large production
      feasibility are significantly dependent on the type of feedstock used
      in the fermentation procedure. Alcohols, such as ethanol, butanol and
      isobutanol, are mainly produced by fermentation of several feedstocks
      (e.g., sugars, corn, corn stover, switchgrass) and subsequentially
      upgraded to a mix of hydrocarbons through dehydration, oligomerization
      and hydroprocessing. The cost of feedstock contributes substantially
      to the final price.`,
    'Biomass gasification': `
      Feedstocks (biomass or organic wastes) are converted into synthesis
      gas (i.e., syn-gas), a mix of CO and H2. Then, the syn-gas reacts with
      a catalyst to produce several liquid fuels. Fuels resulting from BTL
      are "drop-in" and can use existing fossil fuel infrastructure and there
      is no theoretical blending limit for this type of fuel. The feedstock
      used to produce biomass-to-liquid (BTL), such as wood, straw, residues
      from forestry and agriculture, municipal solid waste, are not food crops,
      avoiding direct competition with food production.`,
    description: `
      Sustainable aviation fuel is fuel used to power aircraft that has
      similar properties to conventional, fossil-based jet fuel, but with
      greatly reduced life-cycle GHG emissions. Sustainable aviation fuel
      can be produced using multiple possible feedstocks and technological pathways.`,
  },
  dac: {
    'Aqueous solution': `
      Aqueous solution refers to a variety of designs where
      the ambient air is pushed through a liquid solution and the CO2 is captured
      through absorption or adsorption with a sorbent or solvent. The main drawback
      of the process is the high thermal energy need, as the captured CO2 must be
      separated from the precipitate. The different methods employ different chemicals
      to manage the energy need.`,
    'Solid sorbent': `
      Solid sorbent refers to a highly versatile procedure
      in direct air capture where a chemical species that chemically bind with
      CO2 is deposited into the pores or on the surface of a solid support.
      After the CO2 is adsorbed from ambient air, the filters are heated releasing
      the concentrated CO2 which can be captured for storage or use.`,
    description: `
      Direct Air Capture (DAC) is a technology that pulls in air from the atmosphere,
      performs a series of chemical reactions and extracts the carbon dioxide (CO2)
      from the air. The carbon dioxide that is removed from the air can then be stored
      underground or reused.`,
  },
  gh: {
    'Renewables-based electrolysis': `
      Green hydrogen is a hydrogen-produced fuel obtained from electrolysis of water with
      electricity generated by low-carbon power sources. According to BloombergNEF "hydrogen
      offers the greatest potential to decarbonize difficult-to-abate sectors like steel,
      cement and heavy duty transport.`,
    description: `
      Green hydrogen is a hydrogen-produced fuel obtained from electrolysis of water with
      electricity generated by low-carbon power sources. According to BloombergNEF "hydrogen
      offers the greatest potential to decarbonize difficult-to-abate sectors like steel,
      cement and heavy duty transport.`,
  },
  ldes: {
    'Flow Batteries': `
      Flow batteries or redox flow batteries (RFB) are electrochemical energy storage systems.
      Chemical energy is supplied by two chemicals (dissolved in liquids) that are separated into
      two tanks; the electric current is produced by pumping the liquids past a membrane where
      ion exchange occurs.
      RFBs are suitable for a range of applications, including load balancing (to store excess
      electrical power during off-peak hours), storing energy from renewable sources
      (for discharge during periods of peak demand), peak shaving (where spikes of demand are
      met by the battery), stand-alone power systems (e.g., mobile phone base stations where
      no grid power is available).`,
    'Modular pumped hydro': `
      Pumped-storage hydroelectricity (PSH) or pumped hydroelectric energy storage (PHES)
      facilities utilize the gravitational force for storing energy (gravity storage).
      Hydroelectric energy storage is conventionally used by electric power systems for
      load balancing. In this load balancing capacity PHES plants store excess electrical
      power during low demand to release it when demand rises. 9 Pumped-storage
      hydroelectricity can store excess electricity from continuous base-load sources,
      such as nuclear, or energy from intermittent renewable sources (such as solar, wind)
      to be saved for periods of high demand. Storage facilities store the (excess) energy
      in the form of water in an upper reservoir, pumped from another reservoir from a lower
      elevation (Figure 2). When electricity is required, the water is released from the upper
      reservoir and runs through turbines which create electricity.`,
    description: `
      As solar, wind, and other forms of clean energy gain an increasing market share in
      electricity provision, in order to maintain safe electricity supply and to counterbalance
      the intermittency risk of renewable energy (RE), grids require longer durations of storage. `,
  },
};

export const INTRO_DESC = `
  Some clean energy technologies – wind, solar – are now cheap enough to
  compete with the high emissions technologies they were designed to replace.
  But the decline in price took decades. Today, many emerging climate
  technologies (ECTs) that could significantly reduce emissions are in need of
  catalytic investment that will accelerate production and drive down costs
  rapidly. We developed a new framework to support investment that accelerates
  deployment in ECT. This dashboard helps estimate, monitor, and attribute the
  climate impacts of project investments.`;

export const OVERVIEW_DESC = (
  <>
    <p>
      An increasing number of companies have credible transition plans to cut
      their emissions by 2030. To achieve net zero by 2050, most companies will
      rely on emerging climate technology (ECT) that exists at a higher price
      point (the ‘Green Premium’) to its fossil fuel incumbent and therefore not
      yet available at a mass market level. Investment is needed now to enable
      ECT to be deployed at scale - but why take the risk when the reward and
      recognition is so far in the future? This market failure needs to be
      addressed by incentivizing investment into ECT. Gaining recognition
      through impact measurement and disclosure can constitute an important
      incentive.
    </p>
    <p>
      Many clean energy innovations – wind, solar – are cheap enough to compete
      with the high emissions technologies they were designed to replace. But
      the decline in price took decades. Today, ECTs that could significantly
      reduce emissions are either in need of maturation or face deployment
      challenges because profits from these key innovations are still years
      away. If we are to reach net zero by 2050, we cannot wait decades for this
      next generation of clean technologies to become competitive with fossil
      fuel incumbents. We urgently need to build the financing structures and
      recognition mechanisms that will help turn them into viable commercial
      products much more quickly. Markets need to develop more quickly. This
      does not mean skipping any steps - it is a process that can be
      accelerated, just like with the Covid-19 vaccine. Availability of funding
      and concerted action speed-up the process that would normally take many
      years.
    </p>
    <p>
      To support investment in ECT, the new ECT Framework, which can be explored
      using this dashboard, helps to estimate, monitor, and attribute climate
      impacts of project finance investment that accelerates ECT deployment. The
      Framework is technology neutral and is applicable to a wide variety of
      ECT. It can be used by any investor to estimate potential climate impacts
      of investments, covering both avoided emissions and emissions reduction
      potential.
    </p>
    <p>
      At its heart, the ECT Framework is an impact investment framework
      supporting long-term transition planning, by helping investors and
      corporates to identify and articulate the potential future impact of
      investing into emerging climate technology. Historically, the impact of
      these investments has been difficult to quantify and, in a world focused
      on short term returns, there are very few mechanisms for receiving
      recognition where returns are 10-20 years in the future. Investing in line
      with the ECT Framework helps raise the bar, change behaviors and supports
      leaders in business and finance to make the right choices for a better
      world and a healthy planet.
    </p>
  </>
);

export const CATALYST_PROOGRAM_DESC = (
  <>
    <p>
      Many clean energy innovations available today – like wind, solar, and
      lithium-ion batteries – are starting to compete with the
      emissions-intensive technologies they were designed to replace. After
      decades of research, development, demonstration, and deployment, these
      technologies are now cheap enough for productive markets to help them get
      to scale. We can’t wait decades for the next generation of clean
      technologies to become competitive with fossil-fuel incumbents. Meeting
      our climate goals and reaching net-zero emissions by 2050 means
      transforming our entire physical economy, and that will require
      accelerating the innovation pipeline at an unprecedented pace. We have to
      invest heavily now to bring down the costs of critical clean technologies.
      And we need to build the creative financing structures that will help them
      develop and deploy more quickly. That’s why we’ve launched Catalyst. We’re
      working with partners across the public and private sectors to provide a
      platform for rapidly commercializing emerging technologies. Through a
      creative, blended financing approach, Catalyst will make targeted
      investments into clean technology projects to significantly decrease the
      price of new clean products, increase their availability in the market,
      and demonstrate how to finance the infrastructure of decarbonization at
      scale.
    </p>
    <p>
      Catalyst will start by focusing on four critical technologies for
      decarbonization:
      <ul>
        <li>Direct air capture (DAC)</li>
        <li>Green hydrogen</li>
        <li>Long-duration energy storage (LDS)</li>
        <li>Sustainable aviation fuel (SAF)</li>
      </ul>
    </p>
    <p>
      These clean innovations have already proven their potential at a small
      scale, but right now, the timelines to go to commercial scale are still
      way too long. They are at the critical moment where an influx of capital
      can turn them into viable commercial products much more quickly.
    </p>
    <p>
      Catalyst is jumpstarting this process by raising money from
      philanthropists, governments, and companies looking for ways to transition
      to cleaner business models. We will use a blended financing facility to
      make the large capital investments needed to bring down the cost of these
      emerging technologies and help businesses negotiate agreements with
      suppliers by buying down any remaining extra cost associated with the
      clean product. (We call this the Green Premium). We expect investments in
      these early commercial projects will help technologies mature to the point
      where private markets and public policy will bring them to scale.
    </p>
  </>
);

export const PV_CASE_STUDY_DESC = (
  <>
    <p>
      To understand both the urgency and promise of investing at the catalytic
      stage, it’s useful to look at another clean technology that’s come a long
      way since its early days: solar power.
    </p>
    <p>
      In 1975, it cost about $100 to produce a watt of electricity from a solar
      panel. Today, it can cost only $1 (or about 21 cents in 1975 dollars).
      This decrease is incredible – and it’s still happening – which is why
      solar power is rapidly being deployed around the world.
    </p>
    <p>
      But its path here was anything but steady or pre-ordained. Although the
      government invested in solar research and development at first, funding
      plateaued in the ‘80s and ‘90s. Only in the past two decades has
      investment in solar increased substantially, driving solar to the lower
      cost and faster pace of deployment that we see today (and still, we should
      be deploying it even faster).
    </p>
    <p>
      So, at Breakthrough Energy we asked the question: what if we had
      accelerated investment in solar earlier? What would have been the impact,
      for example, of $5 billion more of funding between 1985 and 1990 (that’s
      about $12 billion in today’s dollars) when it was at the catalytic phase?
      The impact would have been substantial. Solar would have reached scale
      eight years sooner and allowed us to reduce emissions by an additional 70%
      through 2030.
    </p>
    <p>
      Additional investment from Catalyst will accelerate technology cost
      declines and market adoption. If we had invested $5B in 1985, solar costs
      would have come down sooner. As a result of these cost declines, solar
      would have been adopted more quickly into the market.
    </p>
    <p>
      Eight years sooner might not seem like a lot, but consider that we are now
      operating on a timeline of just 30 years to reach net-zero emissions and
      avoid a climate disaster. Any action today that accelerates innovation –
      whether it’s the invention of a new solution or the maturation of an
      existing technology – means fewer emissions and less climate harm. We have
      to make the right investments today for the next set of critical climate
      technologies, and every year counts. We can learn from the solar journey
      to design solutions for scaling low-carbon solutions in the future.
    </p>
  </>
);

export const DEFINITION_DESC = (
  <>
    <p>
      <strong>Baseline scenario:</strong> Scenario used for purposes of
      assessing Emissions Avoided. Potential of new products, where low-carbon
      products do not exist at a scale that would achieve net-zero by 2050 due
      to markets being dominated by the incumbent high-carbon products
    </p>
    <p>
      <strong>Catalyzed scenario:</strong> The alternative scenario for the
      reference deployment of an emerging climate technology that considers the
      effects of the investment of catalytic capital in emerging climate
      technologies.
    </p>
    <p>
      <strong>Emerging climate technology:</strong> A commercially promising
      technology that addresses climate mitigation challenges but needs to
      attract enough investment to deploy the technology and develop business
      models and markets for the product or services it produces. Eventually it
      may become a successful innovation deployed at scale, generating new
      markets or profoundly disrupting established (fossil-based) ones. This
      corresponds to levels 5 to 10 in the Technology Readiness Level scale
      applied by the IEA.
    </p>
    <p>
      <strong>Emissions Avoided:</strong> A forward-looking (ex-ante)
      quantification of the potential to reduce greenhouse gas emissions over a
      specified time horizon, compared to a baseline scenario. In the context of
      this framework, this metric is equally applicable and is calculated in the
      same way for technologies that remove carbon from the atmosphere.
    </p>
    <p>
      <strong>Green Premium:</strong> The difference in the final consumer price
      of a low-carbon solution and the final consumer price of the fossil-fuel
      intensive incumbent solution.
    </p>
  </>
);

export const EMISSIONS_REDUCTION_DESC = `
Early investment in emerging climate technologies will spur
economy-wide changes that reduce emissions in the long run.
Here we show how early investments in Direct Air Capture,
Long Duration Energy Storage, Green Hydrogen and Sustainable
Aviation Fuel are expected to reduce emissions between now
and 2050. These early investments allow emerging climate
technologies to achieve economies of scale faster and phase
out fossil-fuel incumbents more rapidly, which means
emissions are avoided.`;

export const GREEN_PREMIUM_DESC = `
The Green Premium is the additional cost of choosing a clean
technology over one that emits a greater amount of greenhouse
gases. Ultimately, we need the premiums to be so low that everyone
can afford to choose the clean alternative. Early investment
in emerging climate technologies will allow those technologies
to scale up and increase capacity more rapidly than would be
possible otherwise. As more of the technology becomes available,
costs are expected to drop due to economies of scale. Here we
show how early investments in clean technology are expected
to impact the green premiums for for these clean technologies`;

export const YEARS_ACCELERATED_DESC = `
We don’t have decades to wait for clean technologies to commercialize
and achieve economies of scale. If we are to achieve net-zero emissions
by 2050, we need to accelerate deployment timelines for climate technologies.
Early investment will drive more rapid scaling of climate technologies
and will speed up the timelines for these technologies by many years.`;
