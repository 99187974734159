// prettier-ignore
export default {
  "tiers": {
    "1000": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.06576818569679745,
            0.16312422542729035,
            0.2832342059695025,
            0.42003913078291155,
            0.5691238092960702,
            0.7271117346069764,
            0.764000057617638,
            0.7947223920735458,
            0.8201518419553882,
            0.8409930669799135,
            0.8578263182875092,
            0.8711373399797375,
            0.881338341552595,
            0.8887831289040178,
            0.8937782936634324,
            0.8965916689496167,
            0.8974588430489671,
            0.8965882631567996,
            0.8941652953772627,
            0.890355498852238,
            0.8853072984505401,
            0.8791541909641059,
            0.8720165843509984,
            0.864003345188352,
            0.8552131114818864,
            0.8457354146865035,
            0.835651645539703,
            0.8250358905598716
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.11451410781807567,
            0.23064726148767067,
            0.34824140397958525,
            0.46725914840161203,
            0.5877154549274964,
            0.7096555078944452,
            0.7141249516670519,
            0.7187024646312512,
            0.7234180054921314,
            0.728298176723601,
            0.7333675544319801,
            0.7386495020487849,
            0.7441666829945155,
            0.7499413875601394,
            0.7559957388080653,
            0.7623518152913168,
            0.7690317132503364,
            0.7760575621080936,
            0.783451501672275,
            0.791235626091255,
            0.7994318974112656,
            0.8080620301149062,
            0.8171473470243817,
            0.8267086062655137,
            0.8367657984993814,
            0.8473379133076087,
            0.8584426734194404,
            0.8700962353550523
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.19965860339551,
            0.41556338306479446,
            0.6470856378526564,
            0.8929014430834689,
            1.1532324638404994,
            1.4292548343529385,
            1.4733563581655302,
            1.5174553095634957,
            1.5466835633505522,
            1.575472310517576,
            1.6038542761866617,
            1.631844531918681,
            1.6594447775432575,
            1.6866462135780127,
            1.7134315266006337,
            1.7397762995497705,
            1.7656500404981228,
            1.7910169538721648,
            1.8158365360677824,
            1.8400640510429318,
            1.8636509246596251,
            1.8865450854676327,
            1.9086912721396396,
            1.9300313227479806,
            1.9505044573834398,
            1.970047563097125,
            1.9885954882245176,
            2.0060813516463702
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.25340553162944157,
            0.5118336243235077,
            0.7730620026314552,
            0.8433890968073787,
            0.91175131168152,
            0.9782767284011287,
            0.9782208785359519,
            0.9776651605611568,
            0.9767118710247903,
            0.9754366765398599,
            0.973897083936371,
            0.9721377821501425,
            0.9701941489263031,
            0.9680946313360783,
            0.9658624086132779,
            0.9635165825678802,
            0.9610730483584684,
            0.9585451434122807,
            0.9559441392049923,
            0.9532796195740043,
            0.9505597755910137,
            0.9477916381783638,
            0.9449812636920277,
            0.9421338833487282,
            0.9392540247733743,
            0.9363456115846591,
            0.9334120456308573,
            0.9304562754415461
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 286.23723152873856,
            "pct_change": 0.3635205395681615
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9657506394910733,
            "pct_change": 0.16456406232356335
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1715146986648386,
            "pct_change": 0.24338703446831647
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 70.35253354801262,
            "pct_change": 0.07127633855102583
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.758904109589,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-04T00:00:00",
            "x3": 0.24383561643835616,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.7287671232875,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-09-23T00:00:00",
            "x3": 0.273972602739726,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.9506849315067,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-12-13T00:00:00",
            "x3": 0.052054794520547946,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2049.345205479452,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-05-06T00:00:00",
            "x3": 0.6575342465753424,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.7671232876714,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-07T00:00:00",
            "x3": 0.2356164383561644,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.6657534246574,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-08-31T00:00:00",
            "x3": 0.336986301369863,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.9534246575345,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-12-14T00:00:00",
            "x3": 0.049315068493150684,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2049.328767123288,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-04-30T00:00:00",
            "x3": 0.673972602739726,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 1000000000.0,
        "tech_funds": {
          "dac": 250000000.0,
          "saf": 250000000.0,
          "gh": 250000000.0,
          "ldes": 250000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.18604814752955462,
                0.37745588832249993,
                0.5724851291340612,
                0.5776651722748567,
                0.5820594487012289,
                0.5858709112800378,
                0.5892317693694582,
                0.5922329794204086,
                0.5949397964042704,
                0.597400616469293,
                0.5996523110613462,
                0.6017236017355961,
                0.6036372810215945,
                0.6054117229176394,
                0.6070619395765691,
                0.6086003386469039,
                0.6100372776765465,
                0.6113814775485141,
                0.6126403359386211,
                0.6138201684509457,
                0.6149263966210164,
                0.6159636962042333,
                0.616936115448299,
                0.6178471703181406,
                0.6186999218911253,
                0.6194970397455118,
                0.620240854311673,
                0.6209334003651976
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                5519507.169047945,
                7916831.239405599,
                10442751.852515075,
                12505156.928492159,
                14647718.694672951,
                16871162.13840457,
                19176644.40859736,
                21565638.46663316,
                24039862.402027413,
                26601234.391821876,
                29251842.96486744,
                31993926.861578904,
                34829861.16031488,
                37762147.64032824,
                40793408.095541425,
                43926379.758042715,
                47163912.2654486,
                50508965.782001294,
                53964609.998523735,
                57534023.81376346,
                61220495.552755855,
                65027423.61495004,
                68958317.47121917,
                73016798.9479074,
                77206603.74998038,
                81531583.18563962,
                85995706.06245671,
                90603060.73088531
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.051982042086881286,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.23384706914737,
                117.58942952810159,
                116.39294348990843,
                115.64360136535045,
                115.00454356686285,
                114.4476645890473,
                113.95423807491875,
                113.51119006115049,
                113.10904884542207,
                112.74074319838559,
                112.40086095974253,
                112.0851720307708,
                111.79031048326776,
                111.51355627208235,
                111.25268142904615,
                111.00583923141508,
                110.7714827471448,
                110.54830391571618,
                110.33518727187888,
                110.13117429750612,
                109.93543561167398,
                109.74724902550248,
                109.56598204313686,
                109.39107777402944,
                109.22204349145123,
                109.05844126460003,
                108.89988023081997,
                108.74601017632484
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.07318441502551082,
                0.1458337609371439,
                0.21774916441990486,
                0.2887973028200025,
                0.3588701416765677,
                0.42787186496710433,
                0.4248972946102422,
                0.42173283858334165,
                0.4183874185033812,
                0.4148678359672639,
                0.41117956392303856,
                0.40732724054631625,
                0.40331499141291005,
                0.39914664758135965,
                0.3948258978455974,
                0.3903563977737422,
                0.38574184937995776,
                0.38098606019920656,
                0.3760929874499301,
                0.3710667710665004,
                0.36591175817639304,
                0.36063252078971336,
                0.3552338679533689,
                0.3497208532657035,
                0.3440987783983272,
                0.33837319311313946,
                0.3325498921451488,
                0.32663490923728483
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1423508487.2560554,
                1947040543.1081097,
                2485216652.8148255,
                3037954332.2976704,
                3605241755.6117268,
                4187096609.819725,
                4755380695.277885,
                5336978549.713444,
                5931909381.504466,
                6540183704.963889,
                7161800834.612642,
                7796746945.137677,
                8444993498.5499525,
                9106495923.498646,
                9781192477.88773,
                10469003252.75009,
                11169829291.527626,
                11883551809.02036,
                12610031500.761068,
                13349107937.830057,
                14100599044.955248,
                14864300661.631346,
                15639986187.238691,
                16427406311.936928,
                17226288835.573963,
                18036338577.067986,
                18857237376.74509,
                19688644193.98549
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09209349743107918,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1768320058236919,
                1.1529346330886445,
                1.1346928461199373,
                1.1199270794645475,
                1.107511269059225,
                1.0967889193189764,
                1.08776121417667,
                1.0796498081556627,
                1.072280590006233,
                1.065524899180539,
                1.059285038813731,
                1.05348512512421,
                1.048065078446274,
                1.042976546049464,
                1.0381800573163935,
                1.0336429902645123,
                1.029338087030403,
                1.0252423497840155,
                1.021336205912583,
                1.0176028674140725,
                1.0140278327441667,
                1.0105984947527646,
                1.0073038287234328,
                1.004134141657245,
                1.0010808689219484,
                0.9981364079201163,
                0.9952939809719732,
                0.9925475214616745
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.06735738409988698,
                0.13437773600100772,
                0.20057687349739392,
                0.265723924532522,
                0.3296918629802911,
                0.39240581712109096,
                0.38898910916649365,
                0.3854321811407481,
                0.3817720746205199,
                0.37803606007056684,
                0.37424477287502483,
                0.3704141804145464,
                0.36655686790470854,
                0.3626829084184388,
                0.3588004690367088,
                0.35491624392097626,
                0.351035770681922,
                0.3471636658637666,
                0.3433038032663713,
                0.3394594511230587,
                0.33563337896999723,
                0.33182794197413046,
                0.32804514824372877,
                0.32428671303058754,
                0.320554102882249,
                0.31684857183914733,
                0.31317119131918436,
                0.30952287507634846
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9502272.85763933,
                13088654.752827188,
                16780114.709290974,
                20571181.83899539,
                24459024.839754935,
                28442224.23368569,
                32209326.874947123,
                36057830.06858306,
                39988017.77766655,
                44000390.32106642,
                48095615.695954174,
                52274496.10694034,
                56537944.25206438,
                60886966.10131428,
                65322648.13085854,
                69846147.69724576,
                74458685.67589617,
                79161540.76578113,
                83956045.0423687,
                88843580.4608704,
                93825576.09350266,
                98903505.94121285,
                104078887.2004518,
                109353278.894426,
                114728280.79931353,
                120205532.61149804,
                125786713.3135328,
                131473540.70537657
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.07963774978277904,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.55281283115367,
                116.11667261074365,
                115.03749206988736,
                114.17492374774456,
                113.45725914081649,
                112.84306100068733,
                112.34449788338753,
                111.89808075886137,
                111.49383902896902,
                111.12437991039587,
                110.78407506827364,
                110.46854362743126,
                110.17431101104734,
                109.89857656008675,
                109.63905089271137,
                109.39383936432479,
                109.16135682378281,
                108.94026411769964,
                108.72942002350716,
                108.527844331763,
                108.33468911957164,
                108.14921613232514,
                107.97077878265375,
                107.79880768279232,
                107.63279891166457,
                107.47230442058793,
                107.31692412750095,
                107.16629935618165
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.019089090289241994,
                0.038041774289763575,
                0.05680480109137851,
                0.0753429897129942,
                0.09362800293189535,
                0.11163477540845688,
                0.1108629116745649,
                0.11004132725484038,
                0.10917240098685892,
                0.10825793910599346,
                0.10729938931966403,
                0.10629797393579282,
                0.10525477645313534,
                0.1041708000290554,
                0.10304700821712487,
                0.10188435409110518,
                0.10068380149832433,
                0.09944634080699016,
                0.09817300067093629,
                0.09686485683564444,
                0.09552303866845287,
                0.09414873389097819,
                0.09274319184806151,
                0.09130772555097165,
                0.08984371266926043,
                0.08835259559900123,
                0.08683588070944617,
                0.08529513684056346
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1411148992.3122911,
                1921652234.165303,
                2446138359.6286235,
                2984524953.978383,
                3536797883.509875,
                4102972098.084282,
                4669260473.45227,
                5248859180.903967,
                5841788466.805339,
                6448060327.280002,
                7067675892.147376,
                7700623415.422976,
                8346876656.21019,
                9006393525.420284,
                9679114924.070982,
                10364963727.924519,
                11063843890.65199,
                11775639648.54484,
                12500214816.723877,
                13237412171.327785,
                13987052915.17087,
                14748936226.367199,
                15522838890.747215,
                16308515019.746164,
                17105695855.952406,
                17914089668.754253,
                18733381742.57471,
                19563234460.075237
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.030191853640900105,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6638542954323676,
                1.6550471375794633,
                1.6483027194090638,
                1.6428329921959945,
                1.6382279983607626,
                1.6342476479732466,
                1.630817425771905,
                1.6277411485969606,
                1.6249505826225998,
                1.6223955356454272,
                1.6200380378573944,
                1.61784868989962,
                1.6158042776369066,
                1.6138861611338418,
                1.612079154984286,
                1.6103707306984498,
                1.6087504361558682,
                1.6072094649740207,
                1.6057403316684908,
                1.6043366229098348,
                1.6029928044629973,
                1.6017040695048086,
                1.6004662181221654,
                1.599275560606979,
                1.598128839124667,
                1.597023163720856,
                1.5959559596276165,
                1.5949249235557237
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.19965860339551,
                0.41556338306479446,
                0.6470856378526564,
                0.8929014430834689,
                1.1532324638404994,
                1.4292548343529385,
                1.4733563581655302,
                1.5174553095634957,
                1.5466835633505522,
                1.575472310517576,
                1.6038542761866617,
                1.631844531918681,
                1.6594447775432575,
                1.6866462135780127,
                1.7134315266006337,
                1.7397762995497705,
                1.7656500404981228,
                1.7910169538721648,
                1.8158365360677824,
                1.8400640510429318,
                1.8636509246596251,
                1.8865450854676327,
                1.9086912721396396,
                1.9300313227479806,
                1.9505044573834398,
                1.970047563097125,
                1.9885954882245176,
                2.0060813516463702
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5199348437.177333,
                7054041599.596981,
                9020953833.339123,
                11101357602.346386,
                13297838274.872938,
                15613769870.930462,
                18032677201.768547,
                20577157944.34437,
                23251729832.57031,
                26061171124.666115,
                29010486077.741596,
                32104879303.08547,
                35349735290.7474,
                38750600867.10273,
                42313169166.81399,
                46043264175.4486,
                49946825186.15069,
                54029890693.9565,
                58298581368.60974,
                62759081826.06462,
                67417620974.978355,
                72280450756.35744,
                77353823127.76959,
                82643965171.8231,
                88157052234.38153,
                93899179022.89696,
                99876328620.47226,
                106094339397.62901
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.10974823015775823,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7918351591085555,
                3.63104970919747,
                3.5072944246168194,
                3.4067845670961554,
                3.322151247630178,
                3.2490135119424957,
                3.185060013877446,
                3.12779534464399,
                3.075902418955843,
                3.028415069397503,
                2.9846046260167065,
                2.9439087041462346,
                2.9058847078656584,
                2.870178452485196,
                2.8365023840503847,
                2.8046200870664078,
                2.77433502743654,
                2.7454822175345024,
                2.717921940879638,
                2.691534956292671,
                2.666218783038604,
                2.6418847879938396,
                2.6184558761825976,
                2.595864641002878,
                2.5740518687418477,
                2.552965319052282,
                2.5325587224820842,
                2.5127909502644625
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.02224060250332286,
                0.04677172626076319,
                0.07368743846830192,
                0.10311885586861534,
                0.13521731031903336,
                0.170148867518884,
                0.1783647453822448,
                0.1869282987930691,
                0.19585818600189128,
                0.20517240165034362,
                0.21488860118927752,
                0.2250242875666758,
                0.2355969151284702,
                0.2466239399497243,
                0.258122832745343,
                0.2701110634264694,
                0.2826060623720543,
                0.29562516110189685,
                0.3091855135514085,
                0.32330399818911015,
                0.33799710056641974,
                0.35328077543421466,
                0.36917028722295125,
                0.3856800274488385,
                0.40282330743179373,
                0.42061212459546804,
                0.4390569005648453,
                0.4581661892772041
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                340630223.9093619,
                475178266.87817603,
                621614137.5249348,
                780823337.2466179,
                953800990.2080466,
                1141643244.5197318,
                1333766083.373722,
                1541356793.8008544,
                1765607228.748141,
                2007802895.5711346,
                2269328825.92551,
                2551676031.4756637,
                2856448483.168621,
                3185370579.149108,
                3540295079.963522,
                3923211495.668178,
                4336254910.801763,
                4781715231.389457,
                5262046833.954052,
                5779878590.258934,
                6338024233.31963,
                6939493020.085478,
                7587500634.022175,
                8285480256.480357,
                9037093719.04433,
                9846242629.830536,
                10717079344.753576,
                11654017629.91183
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 4,
              "pct_change_gp": 0.160181735738589,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.236290506492379,
                2.1389698429273323,
                2.0641106755729113,
                2.0030297674724804,
                1.9512248751572565,
                1.9060714405872525,
                1.8680333536863798,
                1.8334917601315754,
                1.8017735321743944,
                1.7723815686461226,
                1.7449388766075933,
                1.7191531492858159,
                1.6947934605127724,
                1.6716744320191983,
                1.649645178323651,
                1.6285814019295182,
                1.6083796223679083,
                1.588952885073376,
                1.570227518172539,
                1.552140645266931,
                1.5346382527956077,
                1.5176736704034959,
                1.501206363118643,
                1.485200961890971,
                1.4696264784374775,
                1.4544556640992914,
                1.4396644823177507,
                1.425231671554085
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.035182508926445734,
                0.08721213407918912,
                0.15137946328302407,
                0.2244533026594399,
                0.3040783884015151,
                0.3884531887814771,
                0.4081313241749235,
                0.42451973105128615,
                0.4380840415678701,
                0.4492001067492625,
                0.45817745521874215,
                0.4652752257272051,
                0.47071334406041215,
                0.4746805881026441,
                0.4773405528232375,
                0.47883615851242217,
                0.479293124040867,
                0.47882268848655263,
                0.477523776470683,
                0.47548474439068295,
                0.47278480606995665,
                0.4694952096030025,
                0.4656802185398923,
                0.46139793742656293,
                0.45670101223085,
                0.4516372289850159,
                0.446250029113248,
                0.4405789557932712
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1290918.6957934124,
                2358427.289513667,
                3685378.8766939384,
                5243872.3972824905,
                7012841.559267573,
                8975720.579972085,
                11029668.44252777,
                13239880.920989314,
                15597794.377054717,
                18095946.065265723,
                20727746.400666982,
                23487312.280284982,
                26369342.01019711,
                29369019.41282673,
                32481938.920796465,
                35704046.10074232,
                39031589.74424116,
                42461082.78067635,
                45989270.02245451,
                49613101.275003135,
                53329708.7117454,
                57136387.677963756,
                61030580.27962308,
                65009861.25530352,
                69071925.73585588,
                73214578.57715042,
                77435725.01324421,
                81733362.42531702
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.2928545085240688,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                355.3551351777217,
                321.17678388904096,
                298.79867404397214,
                282.70141429689966,
                270.3991228772863,
                260.59317545947147,
                252.82873292880817,
                246.25347949287988,
                240.5881729047337,
                235.63778158331922,
                231.26140812681254,
                227.35437138386192,
                223.83703998157966,
                220.64760496895767,
                217.73724874086375,
                215.06682472461753,
                212.60451946431905,
                210.32417103189337,
                208.2040364985605,
                206.22587322976148,
                204.37424368636485,
                202.63598214280373,
                200.9997805255906,
                199.4558631230216,
                197.995728451194,
                196.61194246522817,
                195.29797145236893,
                194.04804589950282
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.03058567677035171,
                0.07591209134810123,
                0.13185474268647843,
                0.1955858281234716,
                0.2650454208945551,
                0.33865854582549937,
                0.3558687334427145,
                0.3702026610222596,
                0.38206780038751803,
                0.39179296023065097,
                0.3996488630687671,
                0.4058621142525325,
                0.41062499749218284,
                0.4141025408013737,
                0.41643774084019486,
                0.4177555104371946,
                0.41816571900810007,
                0.41776557467024694,
                0.41664151890657974,
                0.414870754461555,
                0.41252249238058347,
                0.4096589813611034,
                0.40633636581110605,
                0.402605407761789,
                0.39851209925103637,
                0.3940981857014876,
                0.3894016164264549,
                0.3844569347666004
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1286688.3506874235,
                2347726.109222426,
                3666336.2462032586,
                5214865.210797103,
                6972423.936225831,
                8922581.250635942,
                10972183.820587208,
                13178299.93119279,
                15532332.329671277,
                18026791.900607258,
                20655067.92085452,
                23411260.029966116,
                26290052.222169694,
                29286616.288918972,
                32396536.42899748,
                35615749.41297263,
                38940496.40226154,
                42367283.652970515,
                45892850.09805331,
                49514140.32844201,
                53228281.86497242,
                57032565.87895941,
                60924430.71304583,
                64901447.697163835,
                68961308.86172435,
                73101816.23150024,
                77320872.4460583,
                81616472.50094545
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.30701574550454674,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                407.33413307383813,
                365.3883463616879,
                337.89959318673255,
                318.11627024881426,
                302.99225259121465,
                290.9346470921624,
                281.3487912038479,
                273.23532012755214,
                266.2476006892485,
                260.14377722978224,
                254.74926971072236,
                249.93446192574683,
                245.60081222378452,
                241.67187230692835,
                238.08728864950777,
                234.79868266079671,
                231.76675157915327,
                228.95918436240555,
                226.34913486306388,
                223.9140842487857,
                221.6349805105653,
                219.49557861671514,
                217.48192822112088,
                215.58197141641875,
                213.78522361616436,
                212.08251797506117,
                210.4657989005431,
                208.92795387362213
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9112544.279105522,
      "catalyzed_erp": 109.7388357599464
    },
    "1250": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.08234052968997274,
            0.20368481521056747,
            0.35314709277284057,
            0.5232492434910082,
            0.7085346139202789,
            0.9048265297259854,
            0.9504179526626402,
            0.9883835190061784,
            1.0198009898927645,
            1.0455407442499565,
            1.0663198626580026,
            1.0827388874162533,
            1.09530762727537,
            1.1044637886160942,
            1.1105867620677459,
            1.1140080470462421,
            1.1150192860823545,
            1.1138785629923127,
            1.110815415002874,
            1.106034876227187,
            1.0997207794770658,
            1.0920384825699787,
            1.0831371417922635,
            1.0731516251698432,
            1.062204135986895,
            1.0504056006528413,
            1.0378568635841285,
            1.0246497222880933
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.1427699946332848,
            0.28754915981515705,
            0.4341456184174597,
            0.5825144332262449,
            0.7326749843024263,
            0.8846839941609537,
            0.8902496383046212,
            0.8959509391103808,
            0.9018250326781851,
            0.9079049440483874,
            0.9142212158416273,
            0.9208029059385598,
            0.927678216469134,
            0.9348748948573877,
            0.9424204861582517,
            0.9503424829552898,
            0.9586684005914348,
            0.9674257946390314,
            0.9766422309406615,
            0.9863452143770604,
            0.9965620798403332,
            1.0073198470826805,
            1.0186450398866764,
            1.0305634691506471,
            1.043099978887918,
            1.0562781537473342,
            1.0701199864073405,
            1.0846455030772666
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.24704187621260643,
            0.5141730744146763,
            0.8006217676548554,
            1.1047518237020582,
            1.4268382599777476,
            1.7683365418639516,
            1.822891662311434,
            1.8774443329873052,
            1.9135990115168127,
            1.9492102927758128,
            1.984318513212502,
            2.01894220796304,
            2.0530833950628815,
            2.0867311209720842,
            2.1198639115643547,
            2.15245151348292,
            2.1844561645622607,
            2.2158335464090424,
            2.246533520162078,
            2.276500714152741,
            2.305675011267565,
            2.333991970208849,
            2.361383205663591,
            2.3877767460585635,
            2.413097383181421,
            2.4372670247328485,
            2.4602050585076696,
            2.4818287351163133
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.31389538981877196,
            0.6337965067368497,
            0.957063394260132,
            1.0443907003264572,
            1.129306162811106,
            1.211958371480797,
            1.2117963257283795,
            1.211027581412662,
            1.2097756515912481,
            1.2081320987987865,
            1.2061666379287412,
            1.2039335276245373,
            1.2014757767365583,
            1.198828006300985,
            1.1960184526117523,
            1.1930704035610202,
            1.1900032509330924,
            1.1868332756492155,
            1.1835742435776577,
            1.1802378643485616,
            1.1768341493112526,
            1.1733716942552028,
            1.169857905006912,
            1.1662991793614434,
            1.1627010550649937,
            1.1590683311555385,
            1.1554051682744595,
            1.1517151721730416
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 285.57363834633884,
            "pct_change": 0.36499611082220224
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9641352142055687,
            "pct_change": 0.16596150829248651
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.170607474226567,
            "pct_change": 0.24370313537846255
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 70.21984885528144,
            "pct_change": 0.07302790892720043
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.7013698630135,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-09-13T00:00:00",
            "x3": 0.3013698630136986,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.6602739726027,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-08-29T00:00:00",
            "x3": 0.3424657534246575,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.9397260273972,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-12-09T00:00:00",
            "x3": 0.06301369863013699,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2049.1917808219177,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-03-11T00:00:00",
            "x3": 0.810958904109589,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.709589041096,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-09-16T00:00:00",
            "x3": 0.29315068493150687,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.586301369863,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-08-02T00:00:00",
            "x3": 0.41643835616438357,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.9424657534246,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-12-10T00:00:00",
            "x3": 0.06027397260273973,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2049.167123287671,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-03-02T00:00:00",
            "x3": 0.8356164383561644,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 1250000000.0,
        "tech_funds": {
          "dac": 312500000.0,
          "saf": 312500000.0,
          "gh": 312500000.0,
          "ldes": 312500000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.23006110369124144,
                0.46657684467701127,
                0.7074917037426591,
                0.7137815247525746,
                0.7191294800525118,
                0.7237760019405938,
                0.7278785134267994,
                0.7315457456432094,
                0.7348559518059637,
                0.73786731743552,
                0.7406242667000653,
                0.7431614664629512,
                0.7455064691186833,
                0.7476815147428888,
                0.7497047946010449,
                0.7515913585134391,
                0.7533537802264264,
                0.7550026543682129,
                0.7565469737404483,
                0.7579944199377742,
                0.7593515901659597,
                0.7606241763448782,
                0.7618171080824726,
                0.7629346678900825,
                0.7639805848950366,
                0.7649581116579233,
                0.7658700876399558,
                0.7667189919846196
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                5642472.430865435,
                8173521.862131363,
                10843630.892334081,
                12921831.501963176,
                15080289.768460361,
                17319828.517038785,
                19641674.620792087,
                22047354.02738249,
                24538627.308546994,
                27117448.26714948,
                29785936.451209273,
                32546358.46270233,
                35401115.05195374,
                38352732.147818245,
                41403854.64409558,
                44557242.16602409,
                47815766.29202446,
                51182408.867220454,
                54660261.15161521,
                58252523.61754448,
                61962506.2604312,
                65793629.321515456,
                69749424.34593752,
                73833535.51742311,
                78049721.2239191,
                82401855.81823954,
                86893931.54505515,
                91530060.61105227
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.05420959520868938,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.13051160415375,
                117.44870330387855,
                116.2344441564302,
                115.50974953085762,
                114.88877441408691,
                114.3456897503848,
                113.86310991603881,
                113.42879987429211,
                113.0338386588625,
                112.67153096220696,
                112.33672965337223,
                112.025396645611,
                111.73430840102235,
                111.46085261558856,
                111.20288429177008,
                110.95862160016974,
                110.7265690668439,
                110.50545994084374,
                110.29421228840003,
                110.09189508294486,
                109.89770168894368,
                109.71092889286714,
                109.53096014983218,
                109.35725207198152,
                109.18932343674821,
                109.02674617347047,
                108.86913791753567,
                108.7161558171634
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.09124104616219711,
                0.1818079345435739,
                0.2714557153500165,
                0.36001907672959726,
                0.44736368724534203,
                0.5333702579502893,
                0.5296525432921171,
                0.5256985239740742,
                0.5215192075285329,
                0.5171230020163047,
                0.5125166857388067,
                0.5077060134099548,
                0.5026961121285972,
                0.49749174972772303,
                0.4920975223038749,
                0.486517988620884,
                0.48075776837962547,
                0.47482161510605686,
                0.46871447065051286,
                0.46244150594614647,
                0.45600815119076854,
                0.44942011763095185,
                0.44268341249288035,
                0.4358043481593563,
                0.42878954639393735,
                0.4216459382142874,
                0.4143807598704609,
                0.40700154528557875
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1429490721.2485366,
                1959327540.380516,
                2504127477.3060007,
                3063808124.4794865,
                3638358781.588416,
                4227798431.0884295,
                4797045759.591754,
                5379608472.99543,
                5975505251.35042,
                6584745873.810925,
                7207328763.322884,
                7843239078.524428,
                8492447162.314069,
                9154907236.343962,
                9830556275.03516,
                10519313018.527256,
                11221077099.60917,
                11935728269.464611,
                12663125713.359901,
                13403107451.534447,
                14155489823.302872,
                14920067054.211733,
                15696610907.302303,
                16484870420.298141,
                17284571730.981182,
                18095417993.221897,
                18917089386.141403,
                19749243218.74399
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09278362312137611,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1765083408249877,
                1.1524602726019573,
                1.134131488568919,
                1.1193088160143498,
                1.1068525671167009,
                1.096099726664984,
                1.0871455295531403,
                1.0790930562682604,
                1.0717721440668977,
                1.065056779133653,
                1.0588511041017237,
                1.0530805527498839,
                1.0476860134810644,
                1.0426198605462482,
                1.0378431796884515,
                1.0333237818133707,
                1.0290347508272657,
                1.024953362251183,
                1.021060264648987,
                1.0173388508518664,
                1.0137747685599228,
                1.0103555348503528,
                1.0070702292146598,
                1.0039092466887662,
                1.0008640974947374,
                0.9979272430607551,
                0.9950919607694326,
                0.9923522315966025
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.08383428612753051,
                0.16721966205983843,
                0.24957169051747286,
                0.3306091755738826,
                0.4101766827585942,
                0.48818236954020333,
                0.48391781230158004,
                0.4794818357694528,
                0.4749196997852845,
                0.47026478136326644,
                0.4655423712286759,
                0.4607720611615861,
                0.4559693076178749,
                0.4511464915580962,
                0.4463136580107075,
                0.4414790450475812,
                0.4366494707066661,
                0.43183062128100264,
                0.4270272698372094,
                0.42224344441078754,
                0.41748255914529303,
                0.41274751791032455,
                0.4080407969244393,
                0.403364511471361,
                0.39872047016995704,
                0.3941102194976153,
                0.38953508063450365,
                0.38499618018842213
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9568086.483877676,
                13223892.25657418,
                16988106.85379738,
                20855150.914592333,
                24822159.162923153,
                28887717.136801768,
                32664532.2729198,
                36522768.37184238,
                40462738.48268109,
                44484965.13780291,
                48590133.88687019,
                52779061.22199906,
                57052671.779991575,
                61411981.74226637,
                65858086.502530724,
                70392151.35073945,
                75015404.3377123,
                79729130.74801752,
                84534668.7801506,
                89433406.14748512,
                94426777.39159386,
                99516261.753929,
                104703381.49039824,
                109989700.54113948,
                115376823.48808914,
                120866394.74797045,
                126460097.95959955,
                132159655.53295493
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08054501433784825,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.52130831040888,
                116.07142160468048,
                114.98476318604051,
                114.11757529018351,
                113.39680396672075,
                112.78038258553369,
                112.28868224222231,
                111.84776449332115,
                111.44802358432177,
                111.08231280460116,
                110.7451756530545,
                110.43235478606763,
                110.14046665364869,
                109.86677911133528,
                109.60905530811182,
                109.3654415331101,
                109.13438499254234,
                108.91457243967577,
                108.70488363281086,
                108.50435553057297,
                108.31215439037773,
                108.12755377043251,
                107.94991700099878,
                107.77868308064136,
                107.6133552266818,
                107.45349150376401,
                107.29869709496005,
                107.14861788256458
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.023780667002138215,
                0.04739045650918456,
                0.07076344923481102,
                0.09385593937269374,
                0.11663267219223836,
                0.1390624436097838,
                0.1380996933991914,
                0.1370750598318892,
                0.1359914908560281,
                0.13485122698696153,
                0.13365606568778154,
                0.13240752591851568,
                0.1311069552424864,
                0.12975560218776933,
                0.12835466668966009,
                0.12690533616883487,
                0.12540881186803193,
                0.12386632836638485,
                0.12227916816446142,
                0.12064867259739931,
                0.11897624992589852,
                0.1172633811981168,
                0.11551162429143688,
                0.11372261643410711,
                0.11189807541765487,
                0.11003979966565328,
                0.10814966727508322,
                0.106229634129479
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1414070441.4184856,
                1927653711.4444156,
                2455376390.188654,
                2997156017.5352974,
                3552979002.8047447,
                4122860950.2092237,
                4689621744.620966,
                5269693695.011871,
                5863096809.092458,
                6469842737.345159,
                7089932183.825255,
                7723352913.516669,
                8370078144.415914,
                9030065202.11169,
                9703254363.928095,
                10389567848.170519,
                11088908921.13817,
                11801161105.225588,
                12526187478.25905,
                13263830058.678907,
                14013909274.143667,
                14776223513.11037,
                15550548760.254803,
                16336638317.434284,
                17134222612.394014,
                17943009097.660522,
                18762682242.110325,
                19592903617.58851
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.030307301453321986,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6637946358101012,
                1.654959221199306,
                1.6481983250289898,
                1.6427177412943466,
                1.6381049879588416,
                1.63411875796921,
                1.630702551481752,
                1.6276374638224194,
                1.6248560382776616,
                1.6223086000121818,
                1.6199575367773396,
                1.6177737041157085,
                1.6157340744295414,
                1.613820147382607,
                1.6120168440835982,
                1.6103117187004168,
                1.6086943842194505,
                1.607156086223136,
                1.605689381195188,
                1.6042878900616273,
                1.6029461068160815,
                1.6016592480998753,
                1.6004231336583807,
                1.5992340903719586,
                1.598088874496062,
                1.5969846081164314,
                1.5959187268105435,
                1.5948889362236616
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.24704187621260643,
                0.5141730744146763,
                0.8006217676548554,
                1.1047518237020582,
                1.4268382599777476,
                1.7683365418639516,
                1.822891662311434,
                1.8774443329873052,
                1.9135990115168127,
                1.9492102927758128,
                1.984318513212502,
                2.01894220796304,
                2.0530833950628815,
                2.0867311209720842,
                2.1198639115643547,
                2.15245151348292,
                2.1844561645622607,
                2.2158335464090424,
                2.246533520162078,
                2.276500714152741,
                2.305675011267565,
                2.333991970208849,
                2.361383205663591,
                2.3877767460585635,
                2.413097383181421,
                2.4372670247328485,
                2.4602050585076696,
                2.4818287351163133
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5202834903.203953,
                7061444863.646758,
                9032706473.521538,
                11117907366.884142,
                13319653993.64574,
                15641344940.634102,
                18061695620.78128,
                20607670862.216206,
                23283791694.84811,
                26094839196.835033,
                29045820103.2866,
                32141941239.646088,
                35388589091.42767,
                38791312286.50965,
                42355805578.521835,
                46087894391.81986,
                49993519274.05711,
                54078719781.61612,
                58349617439.86635,
                62812397499.2045,
                67473289263.7375,
                72338544811.20227,
                77414415955.88812,
                82707129334.57509,
                88222859521.25024,
                93967700101.27617,
                99947632660.92233,
                106168493674.55383
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.10989076657312472,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.791472743397036,
                3.6305109617289766,
                3.506652829762257,
                3.4060756521008444,
                3.3213945065287747,
                3.2482206213879983,
                3.184354766985108,
                3.127159691305362,
                3.075323310987504,
                3.0278827848524483,
                2.984111737841432,
                2.943449417980123,
                2.9054544215118305,
                2.8697734538756454,
                2.836119638715871,
                2.8042570851668627,
                2.773989671450619,
                2.7451527383365226,
                2.7176068340357395,
                2.691232933017634,
                2.6659287319548666,
                2.6416057449660513,
                2.6181870002693532,
                2.5956051950982464,
                2.5738012038521414,
                2.5527228614192925,
                2.532323962956018,
                2.512563435470308
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.027748281468949473,
                0.058350768762398565,
                0.09192645383263215,
                0.12863941712395383,
                0.1686786248648458,
                0.2122512926008806,
                0.22249740161331283,
                0.23317735530441736,
                0.2443143342936242,
                0.2559307150451212,
                0.26804846441503904,
                0.2806893666100894,
                0.2938751490980503,
                0.30762754294189537,
                0.3219682971647167,
                0.3369191581655709,
                0.35250182034377725,
                0.36873785116658975,
                0.38564859212568725,
                0.4032550358335145,
                0.4215776787236661,
                0.4406363482536119,
                0.4604500031023592,
                0.4810365045571837,
                0.5024123570763258,
                0.5245924158673937,
                0.5475895592617964,
                0.5714143236622089
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                342448344.5826589,
                479118800.16895944,
                628013136.0163482,
                790053919.690966,
                966277989.4449325,
                1157827865.2841868,
                1351255839.300024,
                1560252138.752006,
                1786016575.4249218,
                2029843102.192124,
                2293125737.637805,
                2577365069.42458,
                2884175277.350156,
                3215291642.7800565,
                3572578523.869934,
                3958037781.465589,
                4373817641.594694,
                4822221978.440318,
                5305719997.3049965,
                5826956290.652013,
                6388761231.954201,
                6994161661.772147,
                7646391808.129116,
                8348904368.7067995,
                9105381665.481453,
                9919746762.965046,
                10796174419.01716,
                11739101712.064888
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 4,
              "pct_change_gp": 0.16164822747779764,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2346933188901397,
                2.1366204157399524,
                2.061319519179798,
                1.999941431557478,
                1.9479169684860618,
                1.9025898891278872,
                1.8648898292516707,
                1.830618487797259,
                1.7991211003972112,
                1.7699129026196116,
                1.7426254873226168,
                1.716972702083223,
                1.6927281441669157,
                1.6697098324398338,
                1.647769480935285,
                1.6267848142901304,
                1.6066539480732227,
                1.5872912038825222,
                1.5686239421814538,
                1.5505901304850704,
                1.5331364517250383,
                1.5162168153975837,
                1.4997911731475146,
                1.4838245673189427,
                1.4682863598123976,
                1.4531496019515575,
                1.4383905156902002,
                1.4239880635149167
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.044059841704947755,
                0.10891361561503217,
                0.18876096727899402,
                0.27961558274026355,
                0.3785676760245574,
                0.4833894894623138,
                0.5077015165592562,
                0.5279462491378398,
                0.5446982246487005,
                0.5584215059267785,
                0.5694984735624778,
                0.5782494007281384,
                0.584946197380528,
                0.5898223356024901,
                0.5930801954220114,
                0.5948966200374718,
                0.5954271979460315,
                0.5948096200934762,
                0.5931663518400107,
                0.5906067887319477,
                0.5872290170083064,
                0.5831212673990976,
                0.5783631274852444,
                0.5730265620857307,
                0.5671767791562234,
                0.5608729700443391,
                0.5541689468204134,
                0.5471136944467655
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1300634.0773844037,
                2382912.0022051884,
                3728858.989794972,
                5310018.516481837,
                7104925.574236553,
                9096711.003353558,
                11160489.092817102,
                13379970.91839806,
                15746668.24509022,
                18253177.54088528,
                20892956.72767488,
                23660161.51639929,
                26549522.422566675,
                29556250.359822232,
                32675962.816438414,
                35904625.184063084,
                39238503.45805116,
                42674125.6191969,
                46208249.74492773,
                49837837.408694,
                53560031.28647243,
                57372136.14784324,
                61271602.597666726,
                65256013.07392596,
                69323069.71194477,
                73470583.76462436,
                77696466.32931995,
                81998720.17927776
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.29411064373715423,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                354.8995558483313,
                320.63209206405304,
                298.24167107119393,
                282.1534253752712,
                269.86643426880335,
                260.07718282172414,
                252.39569904111255,
                245.88307803694724,
                240.26644310968163,
                235.35477630476,
                231.0098224024972,
                227.12870299506173,
                223.6330544048636,
                220.4619824234649,
                217.5673443866601,
                214.91050151173508,
                212.46002897851443,
                210.1900668548431,
                208.07911008928758,
                206.1091057178396,
                204.26476910904813,
                202.53305904605998,
                200.90276976772387,
                199.36421033958197,
                197.9089500657139,
                196.5296144275423,
                195.21972009679718,
                193.97354046633149
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.03828068798502499,
                0.09477119959553529,
                0.16438612549384654,
                0.2436336607507447,
                0.32996693789572157,
                0.4214370402636716,
                0.442716436103384,
                0.46043726986833855,
                0.47510276524406386,
                0.487119238323178,
                0.49682138909552476,
                0.5044894866881149,
                0.5103614298948419,
                0.514641453013604,
                0.5175065666457344,
                0.5191114270087704,
                0.5195920881363231,
                0.5190689428988365,
                0.5176490631628632,
                0.5154280874952392,
                0.5124917624687594,
                0.508917215170881,
                0.504774014307019,
                0.5001250630841124,
                0.49502735683067145,
                0.4895326306085023,
                0.48368791676371514,
                0.4775360278413278
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1295311.1781298467,
                2369512.729164281,
                3705079.8035383783,
                5273858.014575055,
                7054599.399393349,
                9030600.036204515,
                11089017.58434823,
                13303444.33642251,
                15665351.048972327,
                18167301.975995008,
                20802729.187056594,
                23565767.266358867,
                26451129.19401593,
                29454011.132143926,
                32570018.01298519,
                35795104.43009231,
                39125527.00730738,
                42557805.525502294,
                46088690.834699124,
                49715138.09601767,
                53434284.26219758,
                57243428.96679271,
                61140018.1826534,
                65121630.15123129,
                69185963.1898735,
                73330825.06442623,
                77554123.67597626,
                81853858.85825297
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.308204336416438,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                406.83503469782437,
                364.78906654239034,
                337.2853740589554,
                317.5111543865062,
                302.4034906469789,
                290.3639675354394,
                280.8701718513943,
                272.82613713306193,
                265.89233434299115,
                259.83138071478476,
                254.47163724841377,
                249.68549240304287,
                245.37581303030822,
                241.46716644417478,
                237.89994801565885,
                234.62634227427915,
                231.6074772313249,
                228.8113764390021,
                226.21145720220414,
                223.78541067787262,
                221.51435409156014,
                219.3821801751616,
                217.37505175701952,
                215.48100468487448,
                213.68963263928288,
                211.99183457693223,
                210.37961059104853,
                208.84589557468274
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9184384.56420345,
      "catalyzed_erp": 136.1005727996115
    },
    "1500": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.09894821028152365,
            0.2441382388785605,
            0.42268965008438747,
            0.6257403286447332,
            0.8468152910066127,
            1.0809541236101297,
            1.1350547711945627,
            1.1801001328592127,
            1.2173676063536194,
            1.247889424325579,
            1.2725164403300546,
            1.2919615241313802,
            1.306830049535316,
            1.3176419224963993,
            1.324847891865664,
            1.328841889354846,
            1.32997054492504,
            1.3285406491621718,
            1.324825094387601,
            1.3190676692141707,
            1.3114869749871887,
            1.3022796604789009,
            1.2916231200855377,
            1.2796777650924553,
            1.266588951276703,
            1.252488627224373,
            1.2374967536042016,
            1.2217225328438506
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.17081896949504655,
            0.3440257887283616,
            0.5193964490687318,
            0.6968787554122602,
            0.8764963530882337,
            1.058317149431793,
            1.0649489473029932,
            1.071743262447396,
            1.0787442446951472,
            1.0859912096458595,
            1.0935205533745853,
            1.1013669266995187,
            1.1095639759934317,
            1.1181448155341542,
            1.1271423244271865,
            1.1365893224524761,
            1.1465186575073416,
            1.1569632245314054,
            1.1679559280545768,
            1.179529595611767,
            1.1917168460925534,
            1.2045499149742616,
            1.2180604369289134,
            1.232279185300085,
            1.2472357672399104,
            1.2629582728278077,
            1.2794728762090914,
            1.2968033866426083
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.2935451086818284,
            0.610946744971066,
            0.9512948321759458,
            1.3126475599988012,
            1.6953325828409278,
            2.1010795867893206,
            2.165889471738061,
            2.2306972967304843,
            2.2736460253354123,
            2.3159495477164334,
            2.357655613066243,
            2.398786113937872,
            2.439343349163413,
            2.479314226821326,
            2.5186731701657505,
            2.5573841825212096,
            2.595402353979047,
            2.632674991312622,
            2.6691424908714234,
            2.704739035916271,
            2.739393175058206,
            2.773028322382785,
            2.8055632088810682,
            2.836912307380473,
            2.866986247884418,
            2.895692236463354,
            2.922934488027814,
            2.9486146811381135
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.37329748601644497,
            0.7535010591042433,
            1.1375917577714045,
            1.2417071294740363,
            1.3429773870426618,
            1.441568509115081,
            1.4412697170384616,
            1.4402633592637861,
            1.4386927833404557,
            1.4366644757464015,
            1.4342596534485796,
            1.4315416178417153,
            1.4285606065178316,
            1.4253571012903827,
            1.421964147494668,
            1.418409019946127,
            1.4147144450242326,
            1.410899513871599,
            1.4069803760285096,
            1.402970774125993,
            1.3988824614968358,
            1.3947255321747694,
            1.3905086845362673,
            1.3862394339728863,
            1.3819242860623402,
            1.37756887860546,
            1.3731780991340052,
            1.3687561827657464
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 284.9270913531962,
            "pct_change": 0.36643377802971905
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9625530217544244,
            "pct_change": 0.16733020573875787
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1697201661530454,
            "pct_change": 0.24401229690210322
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 70.09498101220439,
            "pct_change": 0.07467628908597064
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.6438356164385,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-08-23T00:00:00",
            "x3": 0.3589041095890411,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.594520547945,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-08-05T00:00:00",
            "x3": 0.40821917808219177,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.928767123288,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-12-05T00:00:00",
            "x3": 0.07397260273972603,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2049.041095890411,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-01-15T00:00:00",
            "x3": 0.9616438356164384,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.654794520548,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-08-27T00:00:00",
            "x3": 0.34794520547945207,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.504109589041,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-07-03T00:00:00",
            "x3": 0.4986301369863014,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.928767123288,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-12-05T00:00:00",
            "x3": 0.07397260273972603,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2049.0109589041094,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-01-04T00:00:00",
            "x3": 0.9917808219178083,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 1500000000.0,
        "tech_funds": {
          "dac": 375000000.0,
          "saf": 375000000.0,
          "gh": 375000000.0,
          "ldes": 375000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.2731276322990652,
                0.5537310130763502,
                0.839469213846168,
                0.8468087527079072,
                0.8530624832433842,
                0.8585045544259073,
                0.8633153016715549,
                0.8676197352308315,
                0.8715080872027506,
                0.8750476105739802,
                0.8782897546548581,
                0.8812747358078912,
                0.8840345626822379,
                0.8865951040634723,
                0.8889775416988717,
                0.89119941552159,
                0.8932753913563586,
                0.8952178351438129,
                0.8970372494586826,
                0.8987426101409653,
                0.9003416293052686,
                0.9018409632296345,
                0.9032463784395032,
                0.9045628856698804,
                0.9057948489026221,
                0.9069460748004328,
                0.9080198866546123,
                0.9090191859159643
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                5762793.5232803365,
                8424547.644108275,
                11235515.640762307,
                13329049.756489147,
                15502960.883618752,
                17758160.209165964,
                20095938.432073023,
                22517870.54913043,
                25025756.82794719,
                27621584.622969337,
                30307502.886728637,
                33085804.77482672,
                35958915.61183023,
                38929384.5255301,
                41999878.663376875,
                45173179.272419095,
                48452179.15443888,
                51839881.15666368,
                55339397.45689131,
                58953949.46855883,
                62686868.23738103,
                66541595.23362023,
                70521683.467242,
                74630798.87003466,
                78872721.90112899,
                83251349.34153649,
                87770696.25021273,
                92434898.0593623
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.05628066165037273,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.03191354787891,
                117.31598882136934,
                116.08598229220311,
                115.38372004540297,
                114.77934214256588,
                114.2490008990446,
                113.77649290627619,
                113.35033044025253,
                112.96208717072969,
                112.60540770788549,
                112.27538592738952,
                111.96815919284634,
                111.68063461552222,
                111.41029910572537,
                111.15508431254396,
                110.91326852213486,
                110.68340404938435,
                110.46426259346141,
                110.25479349426925,
                110.05409141279709,
                109.86137100191903,
                109.67594683516641,
                109.49721734076032,
                109.32465182218365,
                109.15777988272542,
                108.99618274080237,
                108.83948604594288,
                108.68735389584697
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.10920270121349177,
                0.2175902802901266,
                0.3248729881187032,
                0.4308540223667701,
                0.5353730277582308,
                0.6382874303106587,
                0.6338268850329283,
                0.62908402019907,
                0.6240718763131019,
                0.618800433290761,
                0.6132777515173011,
                0.6075106862720471,
                0.6015053550458386,
                0.5952674545949423,
                0.588802482720624,
                0.5821158973368254,
                0.5752132328441311,
                0.5681001864769109,
                0.5607826828739594,
                0.553266922361954,
                0.54555941668529,
                0.5376670147631571,
                0.5295969202948712,
                0.5213567025182569,
                0.5129543010685209,
                0.5043980256511021,
                0.49569655106868804,
                0.4868589080284267
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1435441489.2847152,
                1971549018.7498758,
                2522936442.928051,
                3089521496.4327626,
                3671294603.0341506,
                4268276014.458946,
                4838479775.577702,
                5422000496.440291,
                6018856315.488303,
                6629056269.41679,
                7252597886.200205,
                7889465307.745459,
                8539627759.155941,
                9203038257.957611,
                9879632499.224453,
                10569327877.41126,
                11272022620.802786,
                11987595023.961475,
                12715902769.65903,
                13456782335.788815,
                14210048485.426296,
                14975493839.985449,
                15752888536.590775,
                16541979971.525274,
                17342492632.039997,
                18154128018.997314,
                18976564662.820583,
                19809458235.073444
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09346206194175447,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1761878183339585,
                1.1519916003901192,
                1.1335776528539276,
                1.1186994434102389,
                1.1062038251471338,
                1.0954213621823314,
                1.0865389285532396,
                1.0785440904513939,
                1.0712704884642419,
                1.0645946654783682,
                1.0584225452448512,
                1.052680839815535,
                1.0473113783818024,
                1.0422672427184454,
                1.0375100607247287,
                1.033008065612949,
                1.028734675033791,
                1.0246674316755202,
                1.0207872004455234,
                1.0170775511823256,
                1.0135242777667794,
                1.0101150190242882,
                1.006838956629027,
                1.0036865719827819,
                1.0006494487755173,
                0.9977201113008383,
                0.9948918910262796,
                0.9921588156906566
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.10016985371737973,
                0.1997700460278931,
                0.29812254392523657,
                0.39489837676612904,
                0.4899149037992775,
                0.5830639546891737,
                0.5779544153669068,
                0.5726436240329547,
                0.5671846961377052,
                0.5616168651724214,
                0.5559698987937214,
                0.550266882033824,
                0.5445260438355937,
                0.5387619972269104,
                0.5329866057957964,
                0.5272096044245369,
                0.521439053667874,
                0.515681678727786,
                0.509943126569827,
                0.5042281639850276,
                0.49854083219156725,
                0.4928845689451349,
                0.48726230609676424,
                0.4816765483030058,
                0.476129437159718,
                0.4706228038050273,
                0.465158212479393,
                0.45973699684978214
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9633335.578533815,
                13357929.239269596,
                17194214.28982642,
                21136511.38924671,
                25181924.95490361,
                29329047.209308483,
                33115459.841806773,
                36983317.47156237,
                40932960.92831245,
                44964934.05258163,
                49079939.29409534,
                53278806.94660236,
                57562473.213275425,
                61931964.19414284,
                66388383.96480503,
                70932905.55493256,
                75566764.02840452,
                80291251.1168427,
                85107711.02153757,
                90017537.10804853,
                95022169.29254097,
                100123091.97110195,
                105321832.38033164,
                110619959.30424407,
                116019082.06208551,
                121520849.72619806,
                127126950.52996042,
                132839111.43411589
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08142673301341329,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.49031111436996,
                116.02707745070686,
                114.93321584042994,
                114.06160407231906,
                113.33787213510624,
                112.71934127406314,
                112.23423783416308,
                111.79862088558092,
                111.40322800914562,
                111.04114509901927,
                110.70707868812576,
                110.39688900670957,
                110.10727937456352,
                109.83558318504778,
                109.57961393790062,
                109.33755722758177,
                109.10789137995106,
                108.8893280994755,
                108.68076737744576,
                108.48126274778735,
                108.28999417305008,
                108.10624663939987,
                107.92939308007936,
                107.75888062050444,
                107.59421940070828,
                107.43497341805842,
                107.28075296850551,
                107.13120836369326
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.028440599675946032,
                0.05667572560910514,
                0.08462702916931769,
                0.1122423831094654,
                0.13947967985897575,
                0.16630162637354434,
                0.16514881696815928,
                0.1639220653308469,
                0.16262487883390395,
                0.16125992571016165,
                0.15982934853856592,
                0.15833495951842322,
                0.15677836766288383,
                0.15516106478016695,
                0.15348448544376844,
                0.1517500498963503,
                0.1499591953816666,
                0.14811339936636297,
                0.1462141968936794,
                0.14426319356972198,
                0.14226207518927855,
                0.1402126147034118,
                0.13811667701994693,
                0.13597622198893156,
                0.1337933058288699,
                0.13157008118360178,
                0.12930879595747272,
                0.12701179103954413
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1416972185.752589,
                1933614480.0559378,
                2464551503.2650757,
                3009700768.301525,
                3569049225.4366117,
                4142613124.32823,
                4709842729.076024,
                5290384301.210168,
                5884257607.242834,
                6491473951.486427,
                7112033613.048084,
                7745923869.654771,
                8393117400.694585,
                9053570949.506462,
                9727224173.268599,
                10413998636.783281,
                11113796923.287476,
                11826501845.906195,
                12551975750.085636,
                13290059901.743343,
                14040573958.795443,
                14803315525.671593,
                15578059791.717813,
                16364559255.21272,
                17162543535.210634,
                17971719273.659756,
                18791770130.28202,
                19622356872.582848
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.030421292384241152,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6637355094805455,
                1.6548721916704563,
                1.6480950583500895,
                1.6426037931066242,
                1.6379834146699876,
                1.633991412933153,
                1.6305889975841164,
                1.6275349300216508,
                1.624762513083268,
                1.6222225783455455,
                1.6198778639660714,
                1.6176994755398326,
                1.615664568613914,
                1.6137547800128391,
                1.6119551355663386,
                1.610253270753456,
                1.6086388626384907,
                1.6071032079701137,
                1.6056389045714092,
                1.6042396071226284,
                1.602899837441965,
                1.6016148353060617,
                1.600380439848129,
                1.599192994312568,
                1.5980492688573404,
                1.596946397450477,
                1.5958818258811571,
                1.5948532686151857
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.2935451086818284,
                0.610946744971066,
                0.9512948321759458,
                1.3126475599988012,
                1.6953325828409278,
                2.1010795867893206,
                2.165889471738061,
                2.2306972967304843,
                2.2736460253354123,
                2.3159495477164334,
                2.357655613066243,
                2.398786113937872,
                2.439343349163413,
                2.479314226821326,
                2.5186731701657505,
                2.5573841825212096,
                2.595402353979047,
                2.632674991312622,
                2.6691424908714234,
                2.704739035916271,
                2.739393175058206,
                2.773028322382785,
                2.8055632088810682,
                2.836912307380473,
                2.866986247884418,
                2.895692236463354,
                2.922934488027814,
                2.9486146811381135
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5206256615.772356,
                7068710285.801251,
                9044239956.323483,
                11134148194.368647,
                13341062153.568645,
                15668404532.62644,
                18090171297.01829,
                20637612827.02431,
                23315253378.53616,
                26127876790.78763,
                29080492225.760662,
                32178308677.678886,
                35426714586.7409,
                38831260345.48142,
                42397642293.24156,
                46131687263.368484,
                50039337031.70411,
                54126632193.256714,
                58399695110.10633,
                62864711652.25098,
                67527911508.79946,
                72395546888.52534,
                77473869461.89413,
                82769105424.88292,
                88287428590.6419,
                94034931439.9581,
                100017594086.72636,
                106241251141.03426
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.110030173876092,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7911173344904747,
                3.62998288632807,
                3.5060241463794206,
                3.4053811678982613,
                3.320653308961146,
                3.247444138571282,
                3.183663996084459,
                3.126537000067517,
                3.0747559483660103,
                3.0273612472820304,
                2.9836287631821676,
                2.9429993392647007,
                2.905032736863595,
                2.8693765315412767,
                2.83574450945037,
                2.803901292765029,
                2.773651162983379,
                2.7448297821151733,
                2.717297957329988,
                2.6909368740996396,
                2.6656444027007584,
                2.6413322013512825,
                2.6179234188444918,
                2.595350854050313,
                2.5735554676208263,
                2.552485168097022,
                2.532093813888465,
                2.5123403866112337
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.033175668605608735,
                0.06975978282912988,
                0.10989643178071098,
                0.1537823499360247,
                0.20164364547102712,
                0.25372809274758973,
                0.2659732453019056,
                0.2787371769174792,
                0.2920474895481413,
                0.3059308506449369,
                0.32041345331871823,
                0.33552128090904837,
                0.3512802532847092,
                0.36771629615904505,
                0.38485535626279416,
                0.40272337521930035,
                0.42134622928154397,
                0.4407496386881316,
                0.4609590482869381,
                0.4819994796800911,
                0.5038953542179849,
                0.5266702855076926,
                0.5503468396140953,
                0.5749462607928965,
                0.6004881603425196,
                0.6269901659931039,
                0.6544675291829307,
                0.6829326875746377
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                344239960.4006716,
                483001470.06498104,
                634317745.0910269,
                799147916.9542842,
                978569931.4242159,
                1173771989.1581585,
                1368485300.649936,
                1578865892.4202979,
                1806121403.148673,
                2051554093.8701363,
                2316566825.37094,
                2602669607.771495,
                2911486666.1884193,
                3244763990.646798,
                3604377350.353681,
                3992340757.455818,
                4410815366.128562,
                4862118790.597452,
                5348734821.129704,
                5873323510.443568,
                6438731594.462405,
                7048003200.861628,
                7704390786.321211,
                8411366228.658628,
                9172631982.90213,
                9992132190.682547,
                10874063609.879604,
                11822886206.07203
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 4,
              "pct_change_gp": 0.16308304529447465,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2331290020938273,
                2.134327293934735,
                2.058601589926207,
                1.9969394269572955,
                1.9447061885125934,
                1.8992148007272769,
                1.861838869671086,
                1.8278272043023651,
                1.7965423756789525,
                1.767511304249531,
                1.7403737374507817,
                1.7148493817159105,
                1.6907161491282037,
                1.6677953045973406,
                1.6459410497925937,
                1.625033047022257,
                1.6049709430310695,
                1.5856702850091666,
                1.5670594257892645,
                1.549077144825087,
                1.5316707956765863,
                1.514794846558879,
                1.4984097183084688,
                1.482480850174305,
                1.4669779420985598,
                1.4518743351395484,
                1.4371465010563098,
                1.4227736189165625
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.05296011051417736,
                0.13056328771076878,
                0.22594967299456317,
                0.3343971227955144,
                0.4524540224718656,
                0.5774753957177231,
                0.6063148406662916,
                0.6303261153154921,
                0.6501899045553214,
                0.6664563606791398,
                0.6795790388479388,
                0.6899379913035507,
                0.697855996725106,
                0.7036102890791225,
                0.7074412439559472,
                0.7095589515100726,
                0.7101482856444601,
                0.7093728800791091,
                0.7073782942634261,
                0.7042945685964856,
                0.7002383118856721,
                0.6953144256168733,
                0.6896175423304061,
                0.6832332365179363,
                0.6762390523931728,
                0.6687053827666175,
                0.6606962258658753,
                0.652269841104993
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1310374.5602481342,
                2407338.26086211,
                3772114.850514234,
                5375708.083207843,
                7196264.230441206,
                9216617.651812749,
                11290052.558316987,
                13518643.238143947,
                15893974.67261449,
                18408700.906811614,
                21056326.111622177,
                23831043.82258183,
                26727615.97109728,
                29741279.91467594,
                32867675.661294755,
                36102787.97814727,
                39442899.683111474,
                42884553.48171315,
                46424520.43394717,
                50059773.63508515,
                53787466.04747987,
                57604911.67393199,
                61509569.44828336,
                65499029.35598981,
                69571000.40030935,
                73723300.10789457,
                77953845.32761164,
                82260644.12300257
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.29533331390286877,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                354.44699731986026,
                320.0955417620971,
                297.69544034308404,
                281.6174955306406,
                269.34641094208,
                259.5741036422156,
                251.9729588836522,
                245.52111013577084,
                239.95177538983197,
                235.0777905782021,
                230.76344350891034,
                226.90759430353725,
                223.43310388242827,
                220.27996296090333,
                217.40068263544984,
                214.75711652114177,
                212.3182170207658,
                210.05841758417566,
                207.95644443205882,
                205.9944291335962,
                204.1572359178688,
                202.4319448512826,
                200.8074498871895,
                199.27414275579227,
                197.82366181593818,
                196.4486906417811,
                195.1427950948789,
                193.90029047155494
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.04598809976734628,
                0.11357495116779173,
                0.1967399770898243,
                0.2913432058492188,
                0.39436126853474707,
                0.5034787278924066,
                0.5287399305282711,
                0.5497740175437206,
                0.567177701798298,
                0.5814330636464392,
                0.5929374014821159,
                0.6020235328278296,
                0.6089740528102101,
                0.6140316334172767,
                0.6174066479097169,
                0.6192829378447734,
                0.6198222592805801,
                0.6191677690830627,
                0.6174468001241749,
                0.6147731006176851,
                0.6112486631015165,
                0.6069652348620276,
                0.6020055777551316,
                0.596444528574519,
                0.5903498988835302,
                0.5837832444577554,
                0.5768005277383264,
                0.5694526917388575
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1303947.9013299493,
                2391235.3993824893,
                3743611.92841342,
                5332435.471218015,
                7136107.567887288,
                9137657.352524133,
                11204742.564022316,
                13427343.517634863,
                15796997.147398107,
                18306319.705127906,
                20948784.998010874,
                23718561.724449407,
                26610391.1838627,
                29619493.364853427,
                32741493.482295673,
                35972363.57439642,
                39308375.40630503,
                42746062.008342125,
                46282185.909415916,
                49913712.6331068,
                53637788.381566815,
                57451721.08920501,
                61352964.21550082,
                65339102.78498426,
                69407841.28645849,
                73556993.12253326,
                77784471.36119144,
                82088280.58816652
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.309363419087469,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                406.33922658949484,
                364.19827989607325,
                336.6823208387679,
                316.91851062009823,
                301.8278140551822,
                289.8066219010826,
                280.4021923586665,
                272.4256799470841,
                265.5443816710899,
                259.5252236936402,
                254.19940628108546,
                249.44125664103908,
                245.15500587749622,
                241.26620629043418,
                237.7159803863241,
                234.45705999432815,
                231.45099216337445,
                228.66612617890223,
                226.07613600041591,
                223.65891748470128,
                221.39575292309067,
                219.27066948723032,
                217.26994050170535,
                215.3816933904919,
                213.5955983128142,
                211.90261854758566,
                210.29480864019155,
                208.7651498601204
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9256169.160996797,
      "catalyzed_erp": 162.05408240815524
    },
    "1750": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.11558377854307764,
            0.28447622961982705,
            0.49185695545541264,
            0.7275135667250783,
            0.9839750624190393,
            1.2555131863745133,
            1.317935500055625,
            1.3699025233315834,
            1.4128864634340967,
            1.448077683501171,
            1.4764578616083692,
            1.4988498087441822,
            1.5159524969257103,
            1.528366385742113,
            1.536612186947275,
            1.5411450687489943,
            1.5423656141292073,
            1.5406284184541503,
            1.5362489372472594,
            1.529509014160262,
            1.520661398122089,
            1.5099334751436357,
            1.4975303820486452,
            1.483637628028596,
            1.46842332019584,
            1.4520400669304452,
            1.4346266170489614,
            1.4163092803250654
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.19883851233312044,
            0.4004503369245455,
            0.6045817163580415,
            0.8111745250001758,
            1.0202577193805915,
            1.2319113556257906,
            1.2396444483811355,
            1.2475689459545594,
            1.2557359958859506,
            1.2641912143416711,
            1.2729768768880088,
            1.2821332634986697,
            1.2916995075180113,
            1.3017141368683471,
            1.3122154137858484,
            1.3232415352577545,
            1.334830731532279,
            1.3470212854723695,
            1.3598514866406013,
            1.373359528387375,
            1.387583352581279,
            1.4025604441686736,
            1.4183275760945344,
            1.4349205039567137,
            1.4523736089645822,
            1.4707194872348293,
            1.4899884831271093,
            1.5102081641716825
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.3390707455392112,
            0.7056816834291243,
            1.0987894998259833,
            1.5161538289444436,
            1.9581541187469078,
            2.4267885855073703,
            2.5016331837904042,
            2.5764763605263914,
            2.62607274374006,
            2.6749243970725733,
            2.723086271979324,
            2.770583507888213,
            2.8174186484288795,
            2.863576484995539,
            2.9090274061147112,
            2.953729777786361,
            2.9976316806151484,
            3.0406722127369323,
            3.0827824965663813,
            3.1238864831811513,
            3.163901619755242,
            3.202739426731023,
            3.2403060189407413,
            3.2765025962359426,
            3.3112259231525427,
            3.3443688127336317,
            3.375820626447107,
            3.405467799615273
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.431609748589035,
            0.8709518129720936,
            1.314661663543856,
            1.4353584820572014,
            1.5527892982931712,
            1.6671348413211953,
            1.66667100183366,
            1.6654042332001415,
            1.6634964641157381,
            1.6610682180307,
            1.6582115660426995,
            1.6549983665397172,
            1.6514857134988854,
            1.64771965857198,
            1.6437378251545538,
            1.6395712889492202,
            1.635245959227267,
            1.6307836122986397,
            1.626202677324203,
            1.6215188426146763,
            1.616745529610836,
            1.6118942677436676,
            1.6069749941187021,
            1.6019962954214948,
            1.5969656050278207,
            1.5918893647823458,
            1.5867731588600882,
            1.5816218253495156
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 284.29690095000603,
            "pct_change": 0.3678350745893929
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9610029330608117,
            "pct_change": 0.16867113138589213
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.168852124756983,
            "pct_change": 0.24431474540741735
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.97713108857032,
            "pct_change": 0.0762320257035328
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.586301369863,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-08-02T00:00:00",
            "x3": 0.41643835616438357,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.5287671232877,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-07-12T00:00:00",
            "x3": 0.473972602739726,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.9150684931506,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-30T00:00:00",
            "x3": 0.08767123287671233,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.895890410959,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-11-22T00:00:00",
            "x3": 1.1095890410958904,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.5972602739726,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-08-06T00:00:00",
            "x3": 0.4054794520547945,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.4246575342468,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-06-04T00:00:00",
            "x3": 0.5780821917808219,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.917808219178,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-12-01T00:00:00",
            "x3": 0.08493150684931507,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.8630136986303,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-11-10T00:00:00",
            "x3": 1.1424657534246576,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 1750000000.0,
        "tech_funds": {
          "dac": 437500000.0,
          "saf": 437500000.0,
          "gh": 437500000.0,
          "ldes": 437500000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.3152460969384265,
                0.6389229086092616,
                0.9684315027139516,
                0.9767654255179438,
                0.983880410935443,
                0.9900810677744958,
                0.9955686240096222,
                1.0004830418137713,
                1.004925619509739,
                1.0089720264364108,
                1.0126802582564673,
                1.016095719718783,
                1.0192545974647391,
                1.0221861705878295,
                1.0249144371445842,
                1.0274592863476435,
                1.0298373608320364,
                1.0320627024969573,
                1.0341472440572255,
                1.0361011885406823,
                1.03793330608581,
                1.0396511687469652,
                1.0412613382456353,
                1.0427695175263785,
                1.0441806741894692,
                1.0454991418154498,
                1.0467287037852282,
                1.0478726630610784
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                5880465.874406348,
                8669921.589924145,
                11618447.203049509,
                13726868.535993092,
                15915801.319235466,
                18186236.921018373,
                20539524.691294964,
                22977285.174002312,
                25501355.823922735,
                28113755.65879529,
                30816661.550764136,
                33612391.99495495,
                36503395.86249927,
                39492244.58328387,
                42581626.75466384,
                45774344.50853425,
                49073311.18107956,
                52481549.96698813,
                56002193.331354454,
                59638483.01467007,
                63393770.50943007,
                67271517.91732274,
                71275299.11779685,
                75408801.19467339,
                79675826.07915336,
                84080292.37627201,
                88626237.3483859,
                93317819.03422986
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.0582125398685928,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.93778480374536,
                117.19065317897345,
                115.94664292301323,
                115.2648693211791,
                114.67576871876491,
                114.15722799582989,
                113.69409119828359,
                113.27553902379788,
                112.89359096188846,
                112.54219993813723,
                112.21667959400521,
                111.91332800740182,
                111.62917243470068,
                111.36179133554113,
                111.10918729667735,
                110.86969439035758,
                110.64190938402791,
                110.42463981903609,
                110.21686424399007,
                110.01770135330575,
                109.82638574920034,
                109.6422486977917,
                109.46470269794568,
                109.29322899431897,
                109.12736738782274,
                108.96670785619811,
                108.81088361354142,
                108.6595653232344
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.12706980925131348,
                0.25318176743068593,
                0.37800252694550185,
                0.5013042762588527,
                0.6229009037714601,
                0.7426267360266047,
                0.7374237274729208,
                0.7318927806862346,
                0.726048918402336,
                0.7199036591397938,
                0.7134663229625401,
                0.7067448503103476,
                0.6997463383106723,
                0.6924774050448436,
                0.6849444445856572,
                0.6771538100594012,
                0.669111947643902,
                0.6608254960322735,
                0.6523013608186098,
                0.6435467701109082,
                0.6345693156585438,
                0.6253769824597313,
                0.6159781689459592,
                0.6063816992403983,
                0.5965968285870399,
                0.5866332427670231,
                0.5765010521282795,
                0.5662107807214077
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1441360933.517764,
                1983705309.2654312,
                2541644093.4223137,
                3115095223.7257247,
                3704050245.5499744,
                4308530653.913201,
                4879684098.581128,
                5464156036.085976,
                6061964050.709697,
                6673116429.850158,
                7297609803.418687,
                7935427296.115803,
                8586537016.710478,
                9250890781.57274,
                9928423010.63307,
                10619049757.91361,
                11322667853.368279,
                12039154141.941938,
                12768364811.678343,
                13510134806.602222,
                14264277322.69519,
                15030583387.014599,
                15808821521.14055,
                16598737490.851072,
                17400054144.331566,
                18212471341.39644,
                19035665976.189384,
                19869292095.67016
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09412913670748353,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1758703957367134,
                1.15152851317071,
                1.1330311817422072,
                1.118098759067111,
                1.1055648022137088,
                1.094753551896925,
                1.0859411979016753,
                1.0780027393249088,
                1.0707754819227588,
                1.0641384392946454,
                1.0579992603841524,
                1.0522858977866458,
                1.0469410952274272,
                1.0419186232443811,
                1.0371806381755024,
                1.0326957853060115,
                1.028437808259855,
                1.0243845108976495,
                1.0205169697801484,
                1.016818928037066,
                1.0132763227517227,
                1.0098769120858822,
                1.006609977924689,
                1.0034660864098948,
                1.0004368933479375,
                0.9975149847663226,
                0.9946937452637056,
                0.991967248532872
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.11636365165060851,
                0.23202890436283194,
                0.34623016082990427,
                0.45859305653925764,
                0.5689088873577282,
                0.6770537735466994,
                0.6711023778240376,
                0.6649211913863701,
                0.6585708446059994,
                0.6520961915942892,
                0.6455313077862321,
                0.6389026468209342,
                0.6322311160341464,
                0.6255334879841505,
                0.6188233880099696,
                0.6121120026015767,
                0.6054085983952306,
                0.5987209098016824,
                0.5920554332669777,
                0.5854176540739938,
                0.5788122235250259,
                0.5722430989967026,
                0.5657136558730669,
                0.5592267778951163,
                0.5527849308383515,
                0.5463902229668961,
                0.54004445507486,
                0.5337491622884372
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9698018.403176062,
                13490765.768639024,
                17398440.104238633,
                21415269.952149797,
                25538332.869274553,
                29766229.34008632,
                33562126.17051256,
                37439495.389109366,
                41398704.38396332,
                45440317.45162358,
                49565053.32501539,
                53773755.63829609,
                58067371.80623391,
                62446937.56757489,
                66913565.45387801,
                71468436.04828462,
                76112791.27120736,
                80847929.16739526,
                85675199.82442422,
                90596002.15710801,
                95611781.36396372,
                100724026.91196065,
                105934270.94142441,
                111244087.0087333,
                116655089.10333435,
                122168930.88964255,
                127787305.13493899,
                133511943.29240707
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08228407537383035,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.45981261069021,
                115.98361615395359,
                114.88281267558745,
                114.00696194301463,
                113.28040674097754,
                112.65987291563798,
                112.18111499671168,
                111.7506103158347,
                111.35941991479716,
                111.0008497771031,
                110.66976125357134,
                110.36212656338527,
                110.07473198573425,
                109.80497364326823,
                109.5507133241824,
                109.31017438423379,
                109.08186509289992,
                108.8645211963052,
                108.65706220558151,
                108.45855766384237,
                108.26820078432333,
                108.08528761260557,
                107.90920038324188,
                107.73939409945002,
                107.57538561674697,
                107.41674469153914,
                107.26308658610333,
                107.1140659169997
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.033069198789207804,
                0.06589821295456028,
                0.0983964950497735,
                0.13050359750183943,
                0.162170623575808,
                0.1933542404383942,
                0.19201219671765987,
                0.19058425415014346,
                0.18907447010402023,
                0.18748593413169357,
                0.1858211293964749,
                0.18408215799781547,
                0.1822708878348495,
                0.18038905194553195,
                0.17843831782280017,
                0.17642033702713292,
                0.17433678142774692,
                0.17218937006605378,
                0.16997988923582524,
                0.16771020750607757,
                0.1653822868547289,
                0.1629981907222996,
                0.16056008955383022,
                0.15807026423725445,
                0.1555311077307581,
                0.15294512510537575,
                0.15031493216425626,
                0.1476432527765502
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1419854418.649875,
                1939534945.3108733,
                2473664330.3305073,
                3022160077.264019,
                3585009675.162725,
                4162230010.3418818,
                4729924857.909057,
                5310932471.815995,
                5905272374.965813,
                6512955525.046031,
                7133981777.070227,
                7768337923.298584,
                8415996107.00886,
                9076912492.350945,
                9751026119.883831,
                10438257904.832119,
                11138509751.6458,
                11851663768.768085,
                12577581574.135197,
                13316103686.268799,
                14067048998.703545,
                14830214337.415201,
                15605374102.185389,
                16392279993.65055,
                17190660828.26077,
                18000222443.601006,
                18820647696.559647,
                19651596556.706013
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.030533855366020834,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6636769093117403,
                1.654786035343561,
                1.6479929005968976,
                1.6424911247942071,
                1.6378632523392211,
                1.6338655839217164,
                1.63047674049298,
                1.6274335274321738,
                1.6246699901138335,
                1.6221374558918935,
                1.6197990063764227,
                1.6176259924949168,
                1.615595749633416,
                1.6136900493988697,
                1.6118940205910226,
                1.6101953786846646,
                1.6085838638162198,
                1.607050823119405,
                1.6055888951413877,
                1.6041917678259352,
                1.6028539904200028,
                1.601570825513022,
                1.6003381313607743,
                1.599152267351675,
                1.5980100173622767,
                1.596908527088067,
                1.5958452523987319,
                1.5948179164687746
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.3390707455392112,
                0.7056816834291243,
                1.0987894998259833,
                1.5161538289444436,
                1.9581541187469078,
                2.4267885855073703,
                2.5016331837904042,
                2.5764763605263914,
                2.62607274374006,
                2.6749243970725733,
                2.723086271979324,
                2.770583507888213,
                2.8174186484288795,
                2.863576484995539,
                2.9090274061147112,
                2.953729777786361,
                2.9976316806151484,
                3.0406722127369323,
                3.0827824965663813,
                3.1238864831811513,
                3.163901619755242,
                3.202739426731023,
                3.2403060189407413,
                3.2765025962359426,
                3.3112259231525427,
                3.3443688127336317,
                3.375820626447107,
                3.405467799615273
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5209606396.756504,
                7075822647.218379,
                9055530144.241823,
                11150046116.398382,
                13362017998.786959,
                15694892096.37264,
                18118044738.131683,
                20666921298.918995,
                23346049184.395535,
                26160214930.630928,
                29114430070.58393,
                32213905717.601303,
                35464032221.91699,
                38870361700.38329,
                42438592041.66619,
                46174551455.54025,
                50084182917.323235,
                54173528035.932945,
                58448709989.67332,
                62915915252.18822,
                67581373886.52089,
                72451338226.70369,
                77532059799.3287,
                82829764365.71672,
                88350624991.0159,
                94100733071.46964,
                100086067276.34752,
                106312460387.45078
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.11016655414073757,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.790769661239805,
                3.629466549667693,
                3.5054096312786167,
                3.4047024920476683,
                3.3199291159242583,
                3.2466855859121564,
                3.1829890634086433,
                3.1259285045492486,
                3.0742014592778144,
                3.0268514969294293,
                2.9831566678362536,
                2.9425593699712893,
                2.9046205005615886,
                2.868988483686244,
                2.8353777516870835,
                2.8035534272307525,
                2.7733201852914373,
                2.744514001357413,
                2.7169959353320166,
                2.6906473786413883,
                2.665366371030492,
                2.641064711397245,
                2.6176656662591635,
                2.5951021337274573,
                2.5733151586804195,
                2.5522527215861937,
                2.531868742629485,
                2.51212225676142
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.038699504292599156,
                0.0813703565392993,
                0.12818269436276616,
                0.17936665123948378,
                0.2351861920333233,
                0.29593037916079173,
                0.31020852419055467,
                0.32509191111818136,
                0.3406126073795943,
                0.35680162107018376,
                0.37368942452899373,
                0.3913062551905067,
                0.4096822813724896,
                0.4288476798779716,
                0.4488326513773909,
                0.46966738817122033,
                0.49138200246062996,
                0.5140064193740423,
                0.5375702365861663,
                0.5621025507703892,
                0.5876317500680065,
                0.6141852709866429,
                0.6417893175947449,
                0.6704685404790609,
                0.7002456726467843,
                0.7311411193624305,
                0.7631724988345736,
                0.7963541306737244
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                346063414.5128832,
                486952733.93016887,
                640733319.8542051,
                808401553.662972,
                991077220.3201356,
                1189994997.6823654,
                1386015725.8988085,
                1597804412.6607451,
                1826576646.9343011,
                2073643131.4318793,
                2340415712.6017385,
                2628413976.519784,
                2939272357.385185,
                3274747728.2029834,
                3636727454.4199333,
                4027237895.8428845,
                4448453342.597256,
                4902705368.418418,
                5392492579.82176,
                5920490732.939568,
                6489563181.125026,
                7102771605.772077,
                7763386970.080546,
                8474900620.560002,
                9241035443.736925,
                10065756965.617205,
                10953284258.766659,
                11908100496.223228
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.16448730666812578,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.231546547137943,
                2.13201549390001,
                2.0558677812390664,
                1.9939251010958157,
                1.9414867912512352,
                1.895834789995229,
                1.8587799789369495,
                1.8250260985188063,
                1.793952631572247,
                1.765097934934321,
                1.7381097587717724,
                1.7127135723973348,
                1.6886915403209433,
                1.665868131971196,
                1.6441000084642725,
                1.6232687503235754,
                1.6032755218542072,
                1.584037086202275,
                1.5654827816888557,
                1.5475521947604043,
                1.530193346035169,
                1.5133612598663315,
                1.4970168244191806,
                1.4811258745099751,
                1.4656584471722192,
                1.4505881725356378,
                1.4358917717151873,
                1.4215486400702915
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.06187899835545977,
                0.1521562784676731,
                0.2629425106867676,
                0.38879821548806437,
                0.5257421783519759,
                0.6707209110273503,
                0.7039848737211526,
                0.731675906857639,
                0.7545781946952573,
                0.7733259373072148,
                0.7884422482521132,
                0.8003656513131628,
                0.8094687165933037,
                0.8160715386655979,
                0.8204517249197446,
                0.8228519580570995,
                0.8234858314759604,
                0.822542428312664,
                0.8201899690439789,
                0.8165787564898768,
                0.8118435825778966,
                0.8061057169002694,
                0.7994745660487028,
                0.7920490708456982,
                0.783918892550197,
                0.7751654274683085,
                0.7658626807344234,
                0.7560780236525604
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1320135.419830633,
                2431700.568758019,
                3815142.888308667,
                5440941.448408377,
                7286863.4005930675,
                9335453.274174914,
                11418376.677532727,
                13655920.333964713,
                16039740.34920538,
                18562546.777290717,
                21217888.830787938,
                23999996.918972693,
                26903663.625577997,
                29924152.128421493,
                33057124.438488998,
                36298584.26485576,
                39644830.879814334,
                43092421.39800971,
                46638139.58896584,
                50278969.83165965,
                54012075.16564534,
                57834778.641978614,
                61744547.35894052,
                65738978.70228038,
                69815788.41087788,
                73972800.16563734,
                78207936.45854577,
                82519210.54473753
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.2965239247719389,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                353.99763883274795,
                319.56704902148914,
                297.15970453705563,
                281.0932287644115,
                268.8385852719099,
                259.0834270289386,
                251.5601246677403,
                245.16727075192958,
                239.64392293397154,
                234.80662010395505,
                230.5220991429262,
                226.6908977085873,
                223.23706029958825,
                220.10143412366034,
                217.23716381224162,
                214.60658065984478,
                212.1790033660499,
                209.92915050729823,
                207.83597324002685,
                205.88178273411125,
                204.0515881903765,
                202.3325878570412,
                200.71377290238232,
                199.18561568736845,
                197.7398219560427,
                196.3691319936582,
                195.0671596996852,
                193.82826130686493
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.053704780187617866,
                0.13231995115215395,
                0.22891444476864506,
                0.33871535123701396,
                0.4582328840670634,
                0.584792275347163,
                0.6139506263344724,
                0.6382266164739444,
                0.6583082687388393,
                0.6747517461939563,
                0.688015613356256,
                0.6984841574310194,
                0.7064837803324067,
                0.7122948470765151,
                0.7161604620275305,
                0.7182931106918947,
                0.7188797826532468,
                0.7180859901414863,
                0.7160589682032806,
                0.7129302576703853,
                0.7088178155441923,
                0.7038277582433663,
                0.6980558159999424,
                0.6915885571828977,
                0.684504427645643,
                0.6768746394621367,
                0.6687639363145381,
                0.6602312566725052
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1312595.0107249466,
                2412890.197957721,
                3781930.4143043635,
                5390598.670773891,
                7216954.101238572,
                9243764.508821407,
                11319374.108643975,
                13550016.492291832,
                15927293.013294147,
                18443870.60276268,
                21093263.78978548,
                23869674.586525805,
                26767871.968532555,
                29783099.22534237,
                32911001.418710314,
                36147567.66555728,
                39489084.561541535,
                42932098.119811244,
                46473382.31738365,
                50109912.83893759,
                53838844.959063694,
                57657494.75646175,
                61563323.03790079,
                65553921.48629991,
                69627000.649778,
                73780379.46638769,
                78011976.07907209,
                82319799.74181874
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3104941428736467,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                405.84686699897526,
                363.61589678950753,
                336.0901624949131,
                316.33795668255914,
                301.2647734875355,
                289.2621204294231,
                279.9444802053621,
                272.03365497949443,
                265.20350470661356,
                259.2251088715995,
                253.93241017042743,
                249.2016117017748,
                244.93826654724506,
                241.0688826874946,
                237.5352889125379,
                234.29074917313594,
                231.2972182850142,
                228.5233627481937,
                225.94310663610563,
                223.53454541201242,
                221.27912241593864,
                219.16099605433155,
                217.16654756407254,
                215.28399384205883,
                213.50307979900026,
                211.8148316062191,
                210.21135706875128,
                208.68568283104705
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9326516.05685782,
      "catalyzed_erp": 187.6370543224679
    },
    "2000": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.13224026726107696,
            0.32469114548002975,
            0.5606446535352286,
            0.8285705260340146,
            1.1200232778828985,
            1.4285221994353117,
            1.4990846576613643,
            1.5578202794694864,
            1.606391437650978,
            1.6461430375598043,
            1.6781847362385824,
            1.7034469799477208,
            1.7227204364355644,
            1.7366845253987806,
            1.745928569633799,
            1.7509678145902678,
            1.7522557928697329,
            1.7501940293441884,
            1.7451397738814176,
            1.7374122459376182,
            1.7272977387894057,
            1.7150538376283075,
            1.700912939851721,
            1.6850852197166977,
            1.6677611455890067,
            1.649113633213025,
            1.6292999004933317,
            1.608463075052853
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.226652354903988,
            0.4564523959945058,
            0.6891180529654009,
            0.9245854998180886,
            1.1628888283283916,
            1.4041198807997146,
            1.412924345951325,
            1.42194834807463,
            1.4312498767552313,
            1.4408807217798163,
            1.450888928508395,
            1.4613203068939342,
            1.4722193844745899,
            1.4836300138192664,
            1.4955957533686461,
            1.508160091327078,
            1.52136655449286,
            1.5352587275601888,
            1.549880198448569,
            1.5652744389316968,
            1.5814846257256434,
            1.5985534044760006,
            1.6165225971788315,
            1.6354328523041888,
            1.6553232359582943,
            1.676230761844772,
            1.6981898573915015,
            1.721231763261871
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.383781242265286,
            0.7987162293973997,
            1.2436328039038889,
            1.7159980564485138,
            2.2162425533182724,
            2.7466283041446946,
            2.831323649678867,
            2.9160184462453174,
            2.9721400291364453,
            3.027419279242017,
            3.0819181689545347,
            3.135664988138109,
            3.188662491970496,
            3.2408933707479615,
            3.292324032895344,
            3.3429072938693296,
            3.392584338779622,
            3.4412861946366666,
            3.4889348681522088,
            3.535444255021144,
            3.580720894519451,
            3.6246646222440466,
            3.667169159582845,
            3.708122668821762,
            3.747408295945928,
            3.784904718232597,
            3.820486710130603,
            3.8540257380434153
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.4888827055328784,
            0.9862582463016925,
            1.4884453834225226,
            1.6255244121557215,
            1.7589276500724715,
            1.8888483657682884,
            1.8881939277149153,
            1.8866462139523428,
            1.8843846064201144,
            1.8815428630554858,
            1.8782233205086614,
            1.874505951164215,
            1.8704543648043646,
            1.8661199161109507,
            1.8615445942363535,
            1.8567631043906696,
            1.8518043988078072,
            1.8466928234216644,
            1.8414489907211644,
            1.8360904537921743,
            1.8306322337061354,
            1.8250872369600502,
            1.8194665893964603,
            1.813779905965535,
            1.8080355106099424,
            1.80224061690022,
            1.7964014775175734,
            1.7905235089605944
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 283.6824158638873,
            "pct_change": 0.36920144867695465
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.959483873347585,
            "pct_change": 0.1699852149847131
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1680027283450607,
            "pct_change": 0.24461069750874637
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.86561591825918,
            "pct_change": 0.07770413725453736
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.531506849315,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-07-13T00:00:00",
            "x3": 0.4712328767123288,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.4657534246576,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-06-19T00:00:00",
            "x3": 0.536986301369863,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.904109589041,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-26T00:00:00",
            "x3": 0.09863013698630137,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.750684931507,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-09-30T00:00:00",
            "x3": 1.2547945205479452,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.5424657534245,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-07-17T00:00:00",
            "x3": 0.4602739726027397,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.3424657534247,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-05-05T00:00:00",
            "x3": 0.6602739726027397,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.9068493150685,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-27T00:00:00",
            "x3": 0.0958904109589041,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.717808219178,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-09-18T00:00:00",
            "x3": 1.2876712328767124,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 2000000000.0,
        "tech_funds": {
          "dac": 500000000.0,
          "saf": 500000000.0,
          "gh": 500000000.0,
          "ldes": 500000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.3564660013317825,
                0.7222591785742438,
                1.09454598035744,
                1.1038242381986298,
                1.1117599526081239,
                1.1186853882912144,
                1.1248209062489707,
                1.1303202538486794,
                1.1352949874228109,
                1.1398286126338177,
                1.1439852428661865,
                1.1478151466351865,
                1.151358437537839,
                1.1546476073451883,
                1.1577093123966078,
                1.1605656631158883,
                1.1632351739143878,
                1.1657334756049074,
                1.1680738582373247,
                1.1702676906354428,
                1.1723247488107633,
                1.1742534760178118,
                1.1760611908495682,
                1.1777542553432787,
                1.1793382119860223,
                1.1808178962478195,
                1.1821975297257903,
                1.183480797737111
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                5995627.789847725,
                8909950.870444763,
                11992922.674716074,
                14115816.488354554,
                16319368.679015484,
                18604643.772326753,
                20973045.266523477,
                23426236.171803612,
                25966088.90219688,
                28594652.447913207,
                31314130.261374805,
                34126865.075068116,
                37035328.368456714,
                40042113.0506849,
                43149928.4284462,
                46361596.83699415,
                49680051.50788499,
                53108335.37453602,
                56649600.60183928,
                60307108.684196554,
                64084230.99678986,
                67984449.71353747,
                72011359.02577329,
                76168666.61069249,
                80460195.3096777,
                84889884.9848755,
                89461794.52860604,
                94180104.00490159
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.060019936239074485,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.84777071467113,
                117.07199374210597,
                115.81547414677831,
                115.15249620774442,
                114.577511314155,
                114.0699347112947,
                113.61554370225802,
                113.2041198040557,
                112.82808611708421,
                112.48167635433077,
                112.16040530552225,
                111.86071877849409,
                111.5797548772237,
                111.31517679935465,
                111.06505297861837,
                110.82776940097197,
                110.6019642942669,
                110.38647869739485,
                110.18031850712183,
                109.98262495795954,
                109.79265139040007,
                109.60974477166637,
                109.43333085250467,
                109.26290213720954,
                109.09800805282916,
                108.93824685395955,
                108.78325890937293,
                108.63272109785382
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.1448428103862318,
                0.2885833798329897,
                0.4308458931606232,
                0.5713719942731373,
                0.7099500772447502,
                0.8463915523655873,
                0.8404464996539219,
                0.8341282786706944,
                0.8274538457368751,
                0.8204362260097915,
                0.8130859776526003,
                0.8054121114028265,
                0.7974226936290045,
                0.789125256379081,
                0.78052708472657,
                0.7716354231714404,
                0.7624576268078928,
                0.7530012735692955,
                0.7432742481809562,
                0.7332848049138033,
                0.7230416139598949,
                0.7125537947760797,
                0.7018309387497801,
                0.690883122882007,
                0.6797209157202001,
                0.6683553764658151,
                0.6567980479619228,
                0.64506094411955
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1447249199.7581391,
                1995796747.9675517,
                2560250978.5547113,
                3140530088.9467154,
                3736626742.746812,
                4348563652.4185915,
                4920660092.459655,
                5506076516.092397,
                6104829941.58201,
                6716927900.630296,
                7342366122.283887,
                7981126713.758981,
                8633176669.087116,
                9298466606.546852,
                9976929675.192371,
                10668480593.905699,
                11373014800.50507,
                12090407697.327805,
                12820513985.449316,
                13563167083.483963,
                14318178629.434475,
                15085338065.73669,
                15864412308.747673,
                16655145504.615156,
                17457258873.853596,
                18270450647.109417,
                19094396094.57528,
                19928747651.344955
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09478515765604902,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1755560310177047,
                1.151070910101674,
                1.1324919224659034,
                1.117506566774197,
                1.1049352654956537,
                1.094096031536274,
                1.0853521312708925,
                1.077468836694333,
                1.0702869871685425,
                1.06368798483594,
                1.0575811502355086,
                1.0518956402585267,
                1.046575087887934,
                1.0415739343302137,
                1.0368548511106725,
                1.0323868856876606,
                1.0281441001313936,
                1.0241045536618614,
                1.0202495299407655,
                1.0165629417787234,
                1.013030866565686,
                1.009641179452936,
                1.0063832606166956,
                1.0032477593545022,
                1.0002264022717495,
                0.9973118360261126,
                0.9944974974165269,
                0.9917775052998437
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.13241670420109586,
                0.2639990677274487,
                0.39389940306508275,
                0.5217001739570916,
                0.6471676974643477,
                0.7701629774770739,
                0.7633730214659448,
                0.7563259601036635,
                0.7490896189973036,
                0.741714250421668,
                0.7342380776424748,
                0.7266908045290287,
                0.7190959272665256,
                0.7114723087657624,
                0.7038352818397458,
                0.6961974412747813,
                0.6885692248934195,
                0.680959347816757,
                0.6733751324838397,
                0.6658227631567315,
                0.658307484895372,
                0.6508337609422382,
                0.6434053985468922,
                0.6360256506222564,
                0.6286972986239201,
                0.6214227206524006,
                0.6142039477917831,
                0.6070427112234836
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9762139.049118126,
                13622413.500886109,
                17600804.93633115,
                21691457.059503518,
                25891423.801485162,
                30199315.39841836,
                34004585.54971595,
                37891358.591029435,
                41860027.325036615,
                45911175.703007706,
                50045538.15856056,
                54263971.22911648,
                58567433.20367429,
                62956969.192740306,
                67433699.96774109,
                71998813.48669869,
                76653558.3761051,
                81399238.86481436,
                86237210.81420659,
                91168878.5887805,
                96195692.58001244,
                101319147.24444833,
                106540779.5513037,
                111862167.75968884,
                117284930.46382074,
                122810725.85815643,
                128441251.18460216,
                134178242.33173162
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08311813604222365,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.42980171727162,
                115.94101098517989,
                114.83351387022297,
                113.95359869582305,
                113.2243492501817,
                112.60191213890052,
                112.1292623244738,
                111.70369116829882,
                111.31656480735514,
                110.96139768685612,
                110.63319830799645,
                110.32804564711564,
                110.04280526773238,
                109.77493337480168,
                109.5223380964837,
                109.28327908781004,
                109.05629344613389,
                108.84014009481089,
                108.63375738596937,
                108.43623033225853,
                108.24676496382729,
                108.06466803347611,
                107.88933078846222,
                107.7202158713015,
                107.55684665435578,
                107.39879848643994,
                107.24569145544335,
                107.09718436327768
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.03766677107162536,
                0.07505854189654643,
                0.11207278866672703,
                0.14864084240176278,
                0.18470707990100896,
                0.22022217710939151,
                0.21869172147243585,
                0.2170635111323509,
                0.21534214427699705,
                0.2135311255381901,
                0.21163327423852626,
                0.20965097910848646,
                0.20758636442121317,
                0.20544140242811512,
                0.20321799185112466,
                0.20091801408527923,
                0.19854337426347235,
                0.1960960317038193,
                0.19357802266893226,
                0.19099147739306863,
                0.18833863269509266,
                0.1856218411006797,
                0.1828435771083356,
                0.1800064410689801,
                0.1771131610056463,
                0.1741665926329367,
                0.1711697177576193,
                0.16812564121695078
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1422717331.1113055,
                1945415507.3755224,
                2482715494.6759152,
                3034534803.9981027,
                3600861460.909256,
                4181712979.707961,
                4749869543.139182,
                5331339659.450507,
                5926142605.652079,
                6534288992.422518,
                7155778251.573091,
                7790596691.701774,
                8438715922.472973,
                9100091531.895855,
                9774661947.415741,
                10462347438.576756,
                11163049235.282995,
                11876648745.844284,
                12603006865.51306,
                13341963370.493956,
                14093336395.249132,
                14856921992.818506,
                15632493779.12586,
                16419802663.04011,
                17218576664.42899,
                18028520822.66185,
                18849317198.04467,
                19680624968.542103
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.030645018535758118,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.663618828296694,
                1.6547007388525241,
                1.6478918334198347,
                1.6423797140669414,
                1.6377444754658272,
                1.633741242738591,
                1.6303657572004626,
                1.6273332367555073,
                1.6245784528269602,
                1.6220532182213825,
                1.6197209512399684,
                1.6175532435475921,
                1.6155276071472913,
                1.6136259461083238,
                1.6118334904908893,
                1.6101380344803597,
                1.6085293803019902,
                1.6069989247103835,
                1.6055393463746166,
                1.6041443660214925,
                1.6028085599390132,
                1.6015272132133447,
                1.6002962029628052,
                1.5991119045040696,
                1.5979711152518805,
                1.596870992477271,
                1.5958090020016782,
                1.5947828755985163
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.383781242265286,
                0.7987162293973997,
                1.2436328039038889,
                1.7159980564485138,
                2.2162425533182724,
                2.7466283041446946,
                2.831323649678867,
                2.9160184462453174,
                2.9721400291364453,
                3.027419279242017,
                3.0819181689545347,
                3.135664988138109,
                3.188662491970496,
                3.2408933707479615,
                3.292324032895344,
                3.3429072938693296,
                3.392584338779622,
                3.4412861946366666,
                3.4889348681522088,
                3.535444255021144,
                3.580720894519451,
                3.6246646222440466,
                3.667169159582845,
                3.708122668821762,
                3.747408295945928,
                3.784904718232597,
                3.820486710130603,
                3.8540257380434153
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5212896199.646658,
                7082807349.230311,
                9066617379.786116,
                11165657959.52901,
                13382596454.31163,
                15720902353.900991,
                18145415638.78226,
                20695701120.25087,
                23376289289.298843,
                26191969324.60588,
                29147755082.97721,
                32248859755.039,
                35500675563.44575,
                38908756315.379395,
                42478801418.15822,
                46216640430.50973,
                50128217502.969315,
                54219575237.439285,
                58496837611.04713,
                62966191687.45652,
                67633867894.08972,
                72506118685.08759,
                77589195442.78893,
                82889323498.6399,
                88412675180.14041,
                94165340815.342,
                100153297651.67392,
                106382376672.30865
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.11030000504293243,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7904284671799653,
                3.628960070403023,
                3.5048070330617245,
                3.404037127921414,
                3.319219254668845,
                3.245942156204491,
                3.1823274786093974,
                3.1253319648522493,
                3.073657806561119,
                3.0263516639950994,
                2.9826937224534,
                2.9421279002748566,
                2.9042162057361502,
                2.868607893065256,
                2.8350180269316887,
                2.8032122198446583,
                2.7729955319418185,
                2.744204245610182,
                2.7166996682130593,
                2.6903633928560686,
                2.665093625251999,
                2.64080230208575,
                2.6174128051879855,
                2.594858129886254,
                2.5730794035724114,
                2.5520246771838404,
                2.5316479314319253,
                2.511908253527871
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.04414277344613083,
                0.09281047426496968,
                0.14619937113805057,
                0.20457266314318845,
                0.26823167118263236,
                0.33750615132473566,
                0.35378612482496713,
                0.37075655827158455,
                0.38845388674135906,
                0.4069133702318348,
                0.4261696766172684,
                0.4462572163826212,
                0.46721032642437205,
                0.4890633550120703,
                0.5118506767909515,
                0.5356066540703583,
                0.5603655534214951,
                0.586161422287074,
                0.6130279275986805,
                0.640998156624825,
                0.6701043790706558,
                0.7003777685992411,
                0.7318480813207159,
                0.7645432883532017,
                0.798489159232448,
                0.8337087927460203,
                0.8702220916719594,
                0.9080451779253703
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                347860273.088041,
                490845988.8984984,
                647054312.8026534,
                817518366.1280985,
                1003399163.5470606,
                1205977167.4541717,
                1403285513.4900997,
                1616460993.4673622,
                1846727015.6773741,
                2095402587.8007069,
                2363908397.6803417,
                2653773454.1609282,
                2966642237.2901354,
                3304282328.7658,
                3668592503.9899178,
                4061611271.2315764,
                4485525843.548341,
                4942681526.440539,
                5435591499.288858,
                5966946961.7037735,
                6539627607.064104,
                7156712374.700453,
                7821490419.278938,
                8537472220.809543,
                9308400741.163725,
                10138262512.846115,
                11031298522.832586,
                11992014728.38006
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.16586207379923107,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2299965994675026,
                2.1297588041448066,
                2.0532051327914793,
                1.9909942069254962,
                1.938360829078772,
                1.8925567871824267,
                1.8558101071279873,
                1.8223040726636799,
                1.7914341491865926,
                1.7627495359452257,
                1.7359055902863192,
                1.7106332718274895,
                1.6867188041465404,
                1.6639897205680019,
                1.6423050371508243,
                1.6215481734871955,
                1.6016217504907675,
                1.5824436996207127,
                1.5639443069476067,
                1.5460639356566586,
                1.5287512489063486,
                1.5119618071212764,
                1.4956569492233331,
                1.4798028917960369,
                1.4643699973845163,
                1.4493321754043471,
                1.4346663880024286,
                1.42035223970554
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.0708124719911575,
                0.17368808505210398,
                0.29973674748876283,
                0.4428193872374842,
                0.598436977935649,
                0.763135939445566,
                0.8007249302937497,
                0.8320118028959833,
                0.8578816966271047,
                0.8790508940864554,
                0.8961105092460878,
                0.9095562753169648,
                0.9198095128569399,
                0.9272323053905607,
                0.9321387528392768,
                0.9348034966826769,
                0.9354683013722498,
                0.9343472211382114,
                0.9316307174205366,
                0.9274889844124796,
                0.922074667680452,
                0.9155251110781805,
                0.9079642321487715,
                0.8995041016972136,
                0.8902462850917391,
                0.8802829897259341,
                0.869698053493466,
                0.8585678015661297
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1329912.2421671294,
                2455993.8455546247,
                3857939.924448652,
                5505719.243326328,
                7376729.060834589,
                9453230.492528984,
                11545478.943847943,
                13791824.120302642,
                16183991.249696394,
                18714744.89130094,
                21377678.139859207,
                24167057.363976754,
                27077705.06456758,
                30104909.638823573,
                33244354.602348898,
                36492062.187476896,
                39844347.765328914,
                43297782.554606155,
                46849162.77030388,
                50495483.84470501,
                54233918.69207751,
                58061799.232605614,
                61976600.56960098,
                65975927.34646948,
                70057501.90944235,
                74219153.97779247,
                78458811.58148895,
                82774493.07149121
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.297683801012406,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                353.5516383426554,
                319.0465224627159,
                296.63419307457093,
                280.58024582632555,
                268.34251305786734,
                258.6046699462784,
                251.15682882072625,
                244.82127017490475,
                239.34265095776382,
                234.54107028389078,
                230.2856250056276,
                226.4784723377861,
                223.0448012867092,
                219.92628842530704,
                217.0766925929338,
                214.45880868059987,
                212.0423112179745,
                209.80219599157064,
                207.71763301163963,
                205.7711083103228,
                203.94777232154283,
                202.23493849154747,
                200.62169279725586,
                199.09858627127426,
                197.65739043377596,
                196.29090094888764,
                194.9927786427544,
                193.75741975123148
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.06142779526991944,
                0.1510030604279258,
                0.2609079060464658,
                0.38575113879653045,
                0.5215862999472495,
                0.6653862599897457,
                0.6983597273676146,
                0.7258084765735031,
                0.7485097410238732,
                0.7670921434733489,
                0.7820742269924945,
                0.793890704630756,
                0.8029109235786246,
                0.8094522200082199,
                0.8137898167945221,
                0.8161643179075909,
                0.8167874914974831,
                0.815846808205977,
                0.8135090564608811,
                0.8099232615251387,
                0.8052230711089537,
                0.7995287265501271,
                0.7929487077029496,
                0.7855811180194839,
                0.7775148604972677,
                0.768830643487091,
                0.7596018469998658,
                0.7498952734867234
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1321249.218569901,
                2434473.498491298,
                3820033.3289129646,
                5448348.892477138,
                7297144.714992657,
                9348932.697783558,
                11432927.290433347,
                13671481.85831674,
                16056260.486344919,
                18579979.516735114,
                21236193.22216087,
                24019136.156655204,
                26923604.351016533,
                29944863.887921706,
                33078579.254645895,
                36320756.29271979,
                39667696.12737702,
                43115957.496290796,
                46662325.60089801,
                50303786.09170963,
                54037503.14406756,
                57860800.829017654,
                61771147.16534388,
                65766140.37326421,
                69843496.951278,
                74001041.27442928,
                78236696.47126654,
                82548476.38261363
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.31159759577759366,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                405.3580960016035,
                363.04182161916566,
                335.5086343636511,
                315.76912537574793,
                300.71394048756065,
                288.72999808169686,
                279.496680855411,
                271.64978230488526,
                264.8694762261454,
                258.930847635278,
                253.67048945166758,
                248.96642068557483,
                244.72547598364432,
                240.87509094886497,
                237.35778066292266,
                234.12732662870687,
                231.14608059832582,
                228.38301809286136,
                225.81230700327987,
                223.41223749243022,
                221.16441007632315,
                219.05311130449147,
                217.06482783201582,
                215.18786399745574,
                213.4120377941766,
                211.72843690377147,
                210.12922123769968,
                208.60746184650677
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9396953.760017939,
      "catalyzed_erp": 212.83493045475856
    },
    "2250": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.14909459856979082,
            0.3652152632808844,
            0.6297969333879794,
            0.9300087959886585,
            1.2564414084223767,
            1.601868560423767,
            1.6804811181243549,
            1.7459083446213244,
            1.8000003024092741,
            1.844254603685536,
            1.879907484452815,
            1.9079959323091797,
            1.929401438027614,
            1.9448816728050655,
            1.955093986298899,
            1.9606132130838487,
            1.9619454229008328,
            1.959538718002328,
            1.9537918393082783,
            1.9450611188269762,
            1.933666164138617,
            1.9198945572949704,
            1.9040057774424164,
            1.8862345049905385,
            1.8667934278059053,
            1.8458756420522895,
            1.8236567206261403,
            1.8002965061859382
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.25432010802835864,
            0.5121573639863739,
            0.7732029858097159,
            1.037388004373645,
            1.3047518644872644,
            1.57539826828692,
            1.5852659584785895,
            1.5953814722316084,
            1.6058095370511387,
            1.6166080382295447,
            1.6278307285961962,
            1.6395288955277239,
            1.6517524142392572,
            1.664550419043188,
            1.6779717236616127,
            1.6920650674239641,
            1.7068792335794072,
            1.7224630679113764,
            1.7388654148213085,
            1.7561349810969134,
            1.7743201330360863,
            1.7934686295788738,
            1.8136272919987284,
            1.8348416092909872,
            1.8571552773740807,
            1.8806096695706196,
            1.9052432354226452,
            1.9310908247138334
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.4276589038228485,
            0.8900138178741442,
            1.385768056399727,
            1.9121022535337258,
            2.4694973834869125,
            3.060474403852438,
            3.1548328812420285,
            3.2491919010336034,
            3.3117138432665914,
            3.373297800740256,
            3.434012585791132,
            3.4938895432834065,
            3.552931608302804,
            3.611119387772287,
            3.6684153672348883,
            3.724766901412395,
            3.780108397279792,
            3.8343629519762015,
            3.8874436188126404,
            3.9392544192295067,
            3.9896911827653976,
            4.038642273828669,
            4.085989248161199,
            4.1316074711740365,
            4.175366722688646,
            4.217131807106254,
            4.256763184015028,
            4.294117631079887
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.5451329593238461,
            1.0994600549327425,
            1.659008606972364,
            1.812276579983939,
            1.9614690783810642,
            2.106790029543979,
            2.105921415518664,
            2.1040738137459534,
            2.101443032746329,
            2.098175330601818,
            2.0943827684010365,
            2.0901530237828614,
            2.0855559069298755,
            2.0806478278378515,
            2.0754749437429205,
            2.0700754295107964,
            2.0644811494735538,
            2.0587189110304496,
            2.0528114196605585,
            2.046778017270295,
            2.0406352601809226,
            2.0343973770373904,
            2.0280766353369035,
            2.021683637705454,
            2.015227563567745,
            2.008716367703988,
            2.002156944746393,
            1.995555266395856
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 283.0830204406461,
            "pct_change": 0.37053426926612926
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.957994818755844,
            "pct_change": 0.17127334223851237
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.16717138172412,
            "pct_change": 0.2449003605870766
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.75984678589572,
            "pct_change": 0.07910039536953244
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.476712328767,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-06-23T00:00:00",
            "x3": 0.5260273972602739,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.4027397260274,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-05-27T00:00:00",
            "x3": 0.6,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8931506849317,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-22T00:00:00",
            "x3": 0.1095890410958904,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.608219178082,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-08-09T00:00:00",
            "x3": 1.3972602739726028,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.487671232877,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-06-27T00:00:00",
            "x3": 0.5150684931506849,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.26301369863,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-04-06T00:00:00",
            "x3": 0.7397260273972602,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8986301369864,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-24T00:00:00",
            "x3": 0.10410958904109589,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.572602739726,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-07-27T00:00:00",
            "x3": 1.4328767123287671,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 2250000000.0,
        "tech_funds": {
          "dac": 562500000.0,
          "saf": 562500000.0,
          "gh": 562500000.0,
          "ldes": 562500000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.3968028621452231,
                0.803776648044639,
                1.2178734715197284,
                1.2280499288416442,
                1.2367687567896477,
                1.244387426833917,
                1.2511438781551474,
                1.2572046027315533,
                1.2626906886879825,
                1.267692953577959,
                1.2722812389337008,
                1.2765103806716653,
                1.2804241892841537,
                1.2840581874857238,
                1.2874415441740172,
                1.290598472559754,
                1.2935492614994175,
                1.2963110498903392,
                1.298898417390813,
                1.30132384141766,
                1.3035980552020834,
                1.305730331550532,
                1.3077287100887092,
                1.3096001809511346,
                1.3113508345940994,
                1.3129859849148147,
                1.3145102712168786,
                1.3159277431978904
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6108322.62071749,
                9144741.55107963,
                12359122.664479049,
                14496091.787544785,
                16713876.450875517,
                19013608.21410286,
                21396740.744047422,
                23864976.761986937,
                26420221.627971757,
                29064552.762372747,
                31800198.965697844,
                34629526.18589195,
                37555027.6347418,
                40579316.927603886,
                43705123.38097825,
                46935288.88673829,
                50272765.962956786,
                53720616.6998573,
                57282012.398945756,
                60960233.7578518,
                64758671.49143787,
                68680827.30673164,
                72730315.16869755,
                76910862.80807705,
                81226313.43303667,
                85680627.61421174,
                90277885.31865095,
                95022288.07265829
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.06171544919445408,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.76161915694003,
                116.95948262125013,
                115.69175091415751,
                115.04607208305931,
                114.48416540752442,
                113.98679982176078,
                113.54058780950889,
                113.13585300958314,
                112.7653851555059,
                112.42367445117486,
                112.10642029862858,
                111.81020464037422,
                111.53226808601723,
                111.27035243460968,
                111.02258736366242,
                110.78740725985062,
                110.56348908901921,
                110.34970524869159,
                110.14508728241422,
                109.94879759622441,
                109.76010715677039,
                109.57837772022542,
                109.4030475346745,
                109.23361973498126,
                109.06965284606616,
                108.91075295278958,
                108.7565671987043,
                108.6067783529649
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.16252215475799858,
                0.3237961146248431,
                0.48340466351554956,
                0.641059349565508,
                0.7965233291147061,
                0.9495852770757713,
                0.9428986492794296,
                0.9357940045114203,
                0.928290186014096,
                0.920401694846813,
                0.9121403064819293,
                0.9035160876066406,
                0.8945380637821896,
                0.8852146738952579,
                0.8755540888982942,
                0.8655644409261805,
                0.8552539911865028,
                0.8446312546630017,
                0.8337050933962628,
                0.8224847861934741,
                0.8109800801097845,
                0.799201227411103,
                0.7871590106314177,
                0.774864757603606,
                0.7623303478334451,
                0.7495682112455457,
                0.7365913200858151,
                0.7234131746019162
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1453106437.1389391,
                2007823675.4260108,
                2578757653.5214863,
                3165826880.9603143,
                3769025135.3373194,
                4388376320.843425,
                4961409128.493315,
                5547763367.640629,
                6147455479.333353,
                6760492233.595614,
                7386868456.083838,
                8026565236.43973,
                8679548455.68393,
                9345767537.15714,
                10025154363.364212,
                10717622323.376842,
                11423065469.092022,
                12141357767.253752,
                12872352439.719784,
                13615881388.13162,
                14371754701.596403,
                15139760247.6215,
                15919663347.610262,
                16711206538.893082,
                17514109425.636883,
                18328068621.11949,
                19152757783.821243,
                19987827749.60887
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09543042308233332,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1752446827614922,
                1.150618692715012,
                1.1319597265636019,
                1.1169226764393052,
                1.1043149899368787,
                1.0934485460862575,
                1.0847715289909388,
                1.0769422213514024,
                1.0698048707866725,
                1.063243189423939,
                1.0571681180098778,
                1.0515099828945222,
                1.046213281975614,
                1.0412331096708307,
                1.0365326398879773,
                1.0320813126791857,
                1.0278535012686627,
                1.023827514597875,
                1.0199848390098016,
                1.0163095534878115,
                1.012787872912287,
                1.0094077871400249,
                1.0061587727685657,
                1.0030315607074574,
                1.000017947076386,
                0.9971106380864184,
                0.9943031218282092,
                0.9915895615513258
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.14833009717862297,
                0.29568340688810346,
                0.44113513545263555,
                0.5842266511422949,
                0.7247003215914165,
                0.8624026027100619,
                0.8547775373635164,
                0.8468692110143999,
                0.8387523440583462,
                0.8304823770238589,
                0.8221015294673356,
                0.8136426431111959,
                0.8051317176457216,
                0.7965896403521278,
                0.7880333995689034,
                0.7794769569510424,
                0.7709318879741364,
                0.7624078611401103,
                0.7539130022697457,
                0.7454541758526352,
                0.7370372049788391,
                0.7286670454868583,
                0.7203479252481944,
                0.7120834567543194,
                0.7038767289736456,
                0.6957303827891732,
                0.6876466735295145,
                0.6796275231979656
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9825701.853345726,
                13752884.257357134,
                17801329.438138336,
                21965103.005880736,
                26241238.30143894,
                30628356.717902634,
                34442891.64326631,
                38338962.844538145,
                42316987.467340365,
                46377568.36331024,
                50521455.11613737,
                54749516.75180362,
                59062722.11245817,
                63462125.42554788,
                67948855.49554004,
                72524107.48397157,
                77189136.57870404,
                81945253.06832512,
                86793818.47936733,
                91736242.52964789,
                96773980.71692897,
                101908532.40836902,
                107141439.32928066,
                112474284.3736794,
                117908690.67673592,
                123446320.90237902,
                129088876.70919813,
                134838098.36534032
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08392994086384566,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.40026757529742,
                115.89923626298591,
                114.78528148769057,
                113.90146674332395,
                113.16964437668231,
                112.54539736262767,
                112.0786311931337,
                111.65782394656101,
                111.27462985834086,
                110.92276101857874,
                110.59736591502985,
                110.29462537509745,
                110.01148078943537,
                109.74544594839217,
                109.49447347763544,
                109.25685794605623,
                109.03116421959106,
                108.8161735767327,
                108.61084256400949,
                108.4142711488708,
                108.22567776410666,
                108.0443795329815,
                107.86977643920748,
                107.70133853608026,
                107.5385955223098,
                107.3811281791833,
                107.22856128503525,
                107.08055771219168
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.04223361954651637,
                0.08415732788626334,
                0.12565683963835436,
                0.16665536119728555,
                0.20709060464287118,
                0.24690730266633465,
                0.245189254955096,
                0.24336169597832627,
                0.24142975580695225,
                0.239397348066665,
                0.23726762391423617,
                0.23504325551342092,
                0.2327266210470232,
                0.23031992999973658,
                0.22782531065802128,
                0.22524487278687044,
                0.22258075343767675,
                0.2198351509198717,
                0.21701035019413545,
                0.21410874187070295,
                0.21113283627825427,
                0.2080852736312851,
                0.20496883100798344,
                0.20178642665674842,
                0.19854112200113716,
                0.19523612162697043,
                0.19187477146169432,
                0.18846055531244263
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1425561111.8297138,
                1951256561.3446758,
                2491705611.5349727,
                3046825796.845928,
                3616605677.006848,
                4201063385.7372904,
                4769678178.021159,
                5351607297.358861,
                5946869772.705325,
                6555475867.414046,
                7177424591.020079,
                7812701770.277034,
                8461278483.731255,
                9123109746.286787,
                9798133375.759565,
                10486268999.882294,
                11187417178.22033,
                11901458623.459518,
                12628253512.951227,
                13367640885.614338,
                14119438122.096186,
                14883440507.964058,
                15659420880.932417,
                16447129363.916203,
                17246293186.158756,
                18056616594.89495,
                18877780860.03055,
                19709444374.18587
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.03075480926338249,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6635612595507863,
                1.654616289107126,
                1.647791838882931,
                1.6422695391663213,
                1.6376270591824102,
                1.6336183619097944,
                1.630256025258702,
                1.6272340391432731,
                1.6244878850523974,
                1.6219698512189251,
                1.6196436860594747,
                1.6174812175017586,
                1.6154601310250603,
                1.6135624608972208,
                1.61177353676955,
                1.6100812302821779,
                1.6084754047877399,
                1.606947505914124,
                1.6054902518628926,
                1.6040973956733606,
                1.6027635402947429,
                1.60148399300019,
                1.6002546495156218,
                1.5990719008743837,
                1.5979325578524373,
                1.5968337891472988,
                1.5957730704057826,
                1.594748141892437
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.4276589038228485,
                0.8900138178741442,
                1.385768056399727,
                1.9121022535337258,
                2.4694973834869125,
                3.060474403852438,
                3.1548328812420285,
                3.2491919010336034,
                3.3117138432665914,
                3.373297800740256,
                3.434012585791132,
                3.4938895432834065,
                3.552931608302804,
                3.611119387772287,
                3.6684153672348883,
                3.724766901412395,
                3.780108397279792,
                3.8343629519762015,
                3.8874436188126404,
                3.9392544192295067,
                3.9896911827653976,
                4.038642273828669,
                4.085989248161199,
                4.1316074711740365,
                4.175366722688646,
                4.217131807106254,
                4.256763184015028,
                4.294117631079887
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5216124722.440988,
                7089661646.666444,
                9077497323.69235,
                11180977631.257788,
                13402789506.618023,
                15746425193.629618,
                18172273373.39325,
                20723941132.033043,
                23405961980.69447,
                26223127685.747364,
                29180454380.067528,
                32283157289.121243,
                35536630469.82447,
                38946429385.21006,
                42518254930.14186,
                46257937984.452255,
                50171423849.328,
                54264756098.49393,
                58544059490.341034,
                63015521664.85128,
                67685373404.28653,
                72559867277.86087,
                77645254523.50244,
                82947760047.90207,
                88473555451.72691,
                94228730011.78224,
                100219259577.17653,
                106450973362.99463
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.11043062009503755,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7900938730916485,
                3.628463613998797,
                3.5042165374866587,
                3.403385271466198,
                3.318523926424992,
                3.2452140533033202,
                3.1816794297707736,
                3.124747555356749,
                3.0731251526846974,
                3.0258619005519503,
                2.982240069982292,
                2.9417050650665444,
                2.9038199801185316,
                2.868234881008724,
                2.8346654507591116,
                2.8028777809779877,
                2.7726773085765033,
                2.7439006161962856,
                2.716409253333006,
                2.690085010451638,
                2.6648262556939613,
                2.640545060606249,
                2.617164919893328,
                2.5946189240501596,
                2.5728482812487843,
                2.5518011114199344,
                2.5314314545375405,
                2.51169844898421
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.049564333723843675,
                0.1042039214752675,
                0.16414148265581194,
                0.22967329361085154,
                0.3011379307296871,
                0.378905688544814,
                0.397178054244064,
                0.41622577174186176,
                0.43608959523009044,
                0.45680899531606667,
                0.47842279820003075,
                0.5009695524076623,
                0.5244877294100446,
                0.5490158151481935,
                0.5745923241052974,
                0.6012557537109132,
                0.6290444889552277,
                0.6579966623285031,
                0.6881499712309104,
                0.7195414530327363,
                0.7522072166480476,
                0.7861821285364856,
                0.8214994503593273,
                0.8581904250306328,
                0.8962838075394984,
                0.9358053366981033,
                0.9767771438751358,
                1.0192170947994745
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                349649965.4219351,
                494723361.1444211,
                653349145.18489,
                826597062.9724225,
                1015669194.8005042,
                1221891590.6359048,
                1420481719.6449087,
                1635037728.6050286,
                1866790799.5935967,
                2117068199.063047,
                2387299408.219921,
                2679022807.985279,
                2993892870.256494,
                3333687827.5654945,
                3700317803.0397215,
                4095833386.311395,
                4522434640.898218,
                4982480525.6994,
                5478498708.1533785,
                6013195739.51978,
                6589467553.734847,
                7210410240.2992325,
                7879331028.57644,
                8599759405.554863,
                9375458271.3768,
                10210435016.571793,
                11108952381.75724,
                12075538934.406078
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.1672083571248369,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.228462031123847,
                2.1275318488591526,
                2.0505833014014487,
                1.9881129767150008,
                1.935291943792702,
                1.8893423382970667,
                1.8528946902524148,
                1.8196296420242355,
                1.7889579407240854,
                1.760439184489906,
                1.733736045396372,
                1.7085847737690003,
                1.6847755119806684,
                1.662138756154083,
                1.6405358028122563,
                1.6198518546816238,
                1.5999909465555895,
                1.5808721450668437,
                1.562426657981437,
                1.5445956044874172,
                1.527328273596001,
                1.510580748237393,
                1.4943148069596868,
                1.4784970389037217,
                1.4630981244456442,
                1.448092245835186,
                1.4334566007952592,
                1.4191709983771508
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.07994020401063608,
                0.19559388856990118,
                0.3370779875900953,
                0.4975570538463807,
                0.6720153529059592,
                0.856599408061099,
                0.8985028982801391,
                0.933375646611931,
                0.9622032959741906,
                0.9857839433133391,
                1.0047765480799853,
                1.0197339694077467,
                1.031126240379965,
                1.0393574248924788,
                1.044778125655016,
                1.0476949650572593,
                1.0483779080637605,
                1.0470660136886498,
                1.0439720201161828,
                1.0392860494149627,
                1.033178637044914,
                1.0258032364409733,
                1.0172983101347963,
                1.0077890913389944,
                0.9973890802158092,
                0.9862013241353689,
                0.974319520766604,
                0.9618289743850131
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1339901.6614905454,
                2480709.0846835678,
                3901373.2047922197,
                5571356.2005810635,
                7467687.001219528,
                9572343.882846572,
                11673944.87375337,
                13929120.249622801,
                16329663.800620545,
                18868394.22149617,
                21538948.240305614,
                24335628.0152787,
                27253285.847905073,
                30287235.274507035,
                33433181.26039821,
                36687164.52417042,
                40045516.511585124,
                43504822.53046169,
                47061891.23305542,
                50713729.10165846,
                54457518.925553784,
                58290601.49698737,
                62210459.92915224,
                66214706.12934648,
                70301069.05837405,
                74467384.48178242,
                78711585.9758863,
                83031696.996119
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.298814192146447,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                353.10010072469987,
                318.5234368090874,
                296.10817601317535,
                280.0680030999119,
                267.8479507495748,
                258.12791276706014,
                250.75473740945606,
                244.47597247611554,
                239.0417553057351,
                234.2756789885067,
                230.04916157096534,
                226.26595618133302,
                222.8523814142582,
                219.75093351546707,
                216.91597907665783,
                214.31077219220185,
                211.90534017603485,
                209.6749538785488,
                207.59900063004952,
                205.66014021532519,
                203.8436634900445,
                202.13699849977112,
                200.52932558481373,
                199.01127411613828,
                197.57468113928275,
                196.21239750782306,
                194.91813083111597,
                193.68631723934783
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.06915439455915474,
                0.1696213747109832,
                0.29271894579788416,
                0.43245174214227783,
                0.5844260555164176,
                0.745269152362668,
                0.7819782198442158,
                0.8125326980093934,
                0.8377970064350835,
                0.8584706603721971,
                0.8751309363728298,
                0.8882619629014329,
                0.8982751976476491,
                0.9055242479125866,
                0.910315860643883,
                0.9129182480265893,
                0.9135675148370723,
                0.9124727043136783,
                0.9098198191920955,
                0.9057750694120134,
                0.9004875270937029,
                0.8940913208539972,
                0.88670746730762,
                0.878445413651544,
                0.8694043475900962,
                0.8596743179169207,
                0.8493371998595363,
                0.838467531800925
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1329907.4427870694,
                2455981.9458661787,
                3857918.9868929135,
                5505687.577259325,
                7376685.154864699,
                9453172.972760746,
                11545416.888852678,
                13791757.784034744,
                16183920.852678386,
                18714670.627675634,
                21377600.182519194,
                24166975.868392754,
                27077620.17170979,
                30104821.477434255,
                33244263.290740244,
                36491967.83497453,
                39844250.47344614,
                43297682.4180105,
                46849059.87762088,
                50495378.27919312,
                54233810.532199614,
                58061688.5525167,
                61976487.43956733,
                65975811.83323129,
                70057384.07652932,
                74219033.8857999,
                78458689.2883207,
                82774368.63257429
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3126748086049885,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                404.8730371008153,
                362.4759538534553,
                334.9374782972728,
                315.2116639907769,
                300.1749063612807,
                288.2098130549419,
                279.0584567345458,
                271.27379492153545,
                264.5420791905351,
                258.64225961667256,
                253.41349148455814,
                248.735552445456,
                244.5165200549348,
                240.6847306582416,
                237.18336645076158,
                233.96671249474622,
                230.9975070661306,
                228.24502682233367,
                225.68367740770677,
                223.2919389552228,
                221.05156542165454,
                218.94696851580352,
                216.96473790252202,
                215.09326339951787,
                213.32243446968215,
                211.64339896827573,
                210.0483677974853,
                208.5304554762559
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9464922.634360997,
      "catalyzed_erp": 237.71985117255042
    },
    "2500": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.16541363397736858,
            0.40430196554493303,
            0.6963488888742887,
            1.027493731715352,
            1.3874134497322697,
            1.7681742251600838,
            1.8544139793720769,
            1.926179288007005,
            1.985497719972237,
            2.034011467431081,
            2.073076716777363,
            2.103831492668583,
            2.1272434701285996,
            2.144144613773036,
            2.1552568876056446,
            2.1612117473387,
            2.162565199017923,
            2.1598096286438397,
            2.153383234362928,
            2.143677648748274,
            2.131044172713753,
            2.115798930012743,
            2.098227171165036,
            2.078586899677804,
            2.0571119522944543,
            2.034014634854719,
            2.009487993547447,
            1.9837077840595816
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.2818424280822785,
            0.5675667038483277,
            0.8568388335726103,
            1.1495852351699352,
            1.4458509144831397,
            1.7457515027687194,
            1.7566743331193448,
            1.7678734155668159,
            1.779420114906197,
            1.7913783360580893,
            1.8038074785018008,
            1.816764255541496,
            1.8303038443285413,
            1.84448061861512,
            1.8593486069649667,
            1.8749617601496964,
            1.8913740781355513,
            1.9086396273984612,
            1.9268124672857256,
            1.9459464965374054,
            1.966095226130689,
            1.9873114812902961,
            2.00964703322548,
            2.0331521595872095,
            2.0578751315515866,
            2.0838616246894133,
            2.1111540503698016,
            2.1397908042320033
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.4706937674794608,
            0.9795539703989808,
            1.525163611992287,
            2.104422981799483,
            2.717862725248534,
            3.3682578396741625,
            3.4720898865672014,
            3.575923770964553,
            3.6447199745297985,
            3.712484505678579,
            3.7792928372100296,
            3.845179275103608,
            3.9101469043414303,
            3.974174267958076,
            4.037219987751027,
            4.099226049545087,
            4.160120201762229,
            4.219817755248412,
            4.278222974695283,
            4.335230191018501,
            4.390724724910832,
            4.444583686087815,
            4.4966766953820745,
            4.546866565057516,
            4.5950099642658015,
            4.640958090592333,
            4.684557364158758,
            4.725650157314707
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.6003854761366437,
            1.210612928907307,
            1.8264404677746442,
            1.9957097829559367,
            2.1605131674716738,
            2.321063606225973,
            2.319959153658087,
            2.3177942822112154,
            2.3147802913116933,
            2.3110752666153,
            2.306800495964277,
            2.302050984964339,
            2.2969024522414943,
            2.2914161331526266,
            2.285642169110826,
            2.2796220556016737,
            2.27339044689517,
            2.2669765106091946,
            2.260404960683792,
            2.253696856576261,
            2.24687022941363,
            2.2399405784085364,
            2.2329212684232886,
            2.2258238516044515,
            2.2186583297701734,
            2.211433370204574,
            2.2041564844531116,
            2.1968341775461973
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 282.4981321684314,
            "pct_change": 0.37183483163505626
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.956534793222006,
            "pct_change": 0.1725363575045818
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1663575148022076,
            "pct_change": 0.2451839332774733
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.6593127942192,
            "pct_change": 0.08042754440228436
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.4246575342468,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-06-04T00:00:00",
            "x3": 0.5780821917808219,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.3397260273973,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-05-04T00:00:00",
            "x3": 0.663013698630137,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.882191780822,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-18T00:00:00",
            "x3": 0.12054794520547946,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.468493150685,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-06-19T00:00:00",
            "x3": 1.536986301369863,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.435616438356,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-06-08T00:00:00",
            "x3": 0.5671232876712329,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.186301369863,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-03-09T00:00:00",
            "x3": 0.8164383561643835,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8876712328765,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-20T00:00:00",
            "x3": 0.11506849315068493,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.4301369863015,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-06-05T00:00:00",
            "x3": 1.5753424657534247,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 2500000000.0,
        "tech_funds": {
          "dac": 625000000.0,
          "saf": 625000000.0,
          "gh": 625000000.0,
          "ldes": 625000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.43628050847669325,
                0.8835281057906041,
                1.3384982500601759,
                1.3495304163656885,
                1.3589975025543466,
                1.3672800407699734,
                1.374632171319841,
                1.3812322014218534,
                1.3872100979160569,
                1.3926635159533907,
                1.3976676711894473,
                1.4022816965267046,
                1.4065528886949694,
                1.4105196344400632,
                1.4142134810069191,
                1.4176606350916015,
                1.4208830699555512,
                1.4238993576780368,
                1.4267253046624524,
                1.429374443745815,
                1.4318584200930078,
                1.434187297263565,
                1.4363698024835634,
                1.4384135250533485,
                1.4403250782537547,
                1.4421102324830777,
                1.4437740255709934,
                1.4453208547781633
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6218616.941763095,
                9374445.676682368,
                12717297.40922135,
                14867963.567849088,
                17099610.803471822,
                19413432.352514386,
                21810928.52439883,
                24293839.283862244,
                26864101.116552822,
                29523818.466358133,
                32275244.34929731,
                35120766.98288754,
                38062900.49531312,
                41104278.484150186,
                44247649.617430806,
                47495874.732567504,
                50851925.05687958,
                54318881.2840731,
                57899933.31548435,
                61598380.526072234,
                65417632.45097764,
                69361209.81397638,
                73432745.83758143,
                77635987.78804164,
                81974798.7184767,
                86453159.38086404,
                91075170.28323647,
                95845053.87273832
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.06330994637080564,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.67908189757337,
                116.85262781447686,
                115.5748134897877,
                114.94510765474787,
                114.39535119498616,
                113.90751802865765,
                113.46897126424835,
                113.07052554345125,
                112.70530476240471,
                112.36803413342392,
                112.05458296443781,
                111.76165896839252,
                111.48659777160552,
                111.2272142443686,
                110.98169514561002,
                110.74852007656855,
                110.52640226526573,
                110.31424351793143,
                110.11109947040406,
                109.91615244730552,
                109.7286900214005,
                109.5480878997583,
                109.37379613347133,
                109.2053279082616,
                109.04225035945086,
                108.88417698954365,
                108.73076136546499,
                108.5816918457509
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.1801083015846359,
                0.35882098088943076,
                0.5356804285328526,
                0.710368530567316,
                0.8826234569033512,
                1.0522113256083467,
                1.0447836400088728,
                1.036893463038504,
                1.0285614800816087,
                1.019803638533236,
                1.0106329111343968,
                1.0010604066702349,
                0.991096100175511,
                0.9807493304676871,
                0.9700291494040398,
                0.95894457310648,
                0.9475047661474153,
                0.9357191783994541,
                0.9235976474100319,
                0.911150474887895,
                0.8983884831513316,
                0.8853230555996324,
                0.8719661640711756,
                0.8583303851472733,
                0.8444289069076962,
                0.8302755272626375,
                0.8158846447284063,
                0.8012712423259416
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1458932797.800933,
                2019786436.293445,
                2597164678.3677845,
                3190986394.1758313,
                3801246470.240744,
                4427969976.886681,
                5001932584.308523,
                5589218027.845742,
                6189842160.751126,
                6803810985.78614,
                7431118422.954281,
                8071744544.411346,
                8725654120.016727,
                9392795381.411259,
                10073098948.933449,
                10766476887.208769,
                11472821868.444202,
                12192006430.806799,
                12923882324.676653,
                13668279943.137674,
                14425007835.451721,
                15193852303.847557,
                15974577084.991184,
                16766923118.146074,
                17570608402.383167,
                18385327945.32996,
                19210753805.903015,
                20046535233.281822
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09606521993362717,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1749363101534678,
                1.1501717648526897,
                1.1314344497291153,
                1.1163469038461888,
                1.1037037579139262,
                1.0928108493734552,
                1.084199197774782,
                1.076422736884495,
                1.0693290030852436,
                1.062803943347927,
                1.056760069336856,
                1.0511288433666803,
                1.0458556047981378,
                1.0408960844121706,
                1.0362139461223128,
                1.031779013302791,
                1.0275659632652048,
                1.02355334920317,
                1.019722855849243,
                1.016058724949964,
                1.012547306136873,
                1.0091767017490394,
                1.0059364829840811,
                1.0028174608589102,
                0.9998114997546886,
                0.9969113643852392,
                0.9941105932459979,
                0.9914033932259165
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.16410496765995047,
                0.327084823116703,
                0.4879422177144684,
                0.6461793665902481,
                0.8015156649173272,
                0.9537835654559996,
                0.9453269823382459,
                0.9365620807893621,
                0.9275701933956364,
                0.9184117506619093,
                0.9091328247748297,
                0.8997692884376342,
                0.8903495635465246,
                0.8808964987125634,
                0.871428688103907,
                0.8619614205100723,
                0.8525073769396185,
                0.8430771529311576,
                0.8336796560213394,
                0.8243224128304462,
                0.8150118093206222,
                0.8057532811449715,
                0.7965514659397253,
                0.787410326551103,
                0.778333251516419,
                0.7693231377214965,
                0.7603824588821182,
                0.7515133227680338
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9888711.357691437,
                13882189.984986888,
                18000034.240205348,
                22236237.894803025,
                26587816.547938947,
                31053404.07399951,
                34877097.471116684,
                38782363.204190746,
                42769641.7564711,
                46839554.16903233,
                50992864.654645,
                55230454.33408543,
                59553302.296820104,
                63962471.643817104,
                68459099.01448436,
                73044386.6093309,
                77719596.03831717,
                82486043.52980724,
                87345096.1704767,
                92298168.9381482,
                97346722.35279402,
                102492260.6154619,
                107736330.13672824,
                113080518.37942174,
                118526452.9574049,
                124075800.94491635,
                129730268.36057648,
                135491599.7974814
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08472045245362314,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.3711995581432,
                115.8582673084332,
                114.7380793654061,
                113.8505209439967,
                113.11623985256368,
                112.49027051452815,
                112.02917556727928,
                111.61297113605008,
                111.23358380254344,
                110.88491322658089,
                110.56224118176878,
                110.26184574123313,
                109.98074086751551,
                109.71649557900581,
                109.46710525531068,
                109.23089806539315,
                109.00646563738074,
                108.79261082256049,
                108.58830774560244,
                108.39267083750069,
                108.20493053781843,
                108.02441401812133,
                107.85052973402038,
                107.68275493053338,
                107.5206254499444,
                107.36372735243276,
                107.21168997688177,
                107.06418015549674
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.0467700435720576,
                0.09319517858571891,
                0.13914956558871702,
                0.18454838106659174,
                0.2293227331830459,
                0.2734114587582736,
                0.27150663618789805,
                0.2694806436495932,
                0.2673391343963315,
                0.26508642510927033,
                0.2627259945535221,
                0.2602607951949231,
                0.25769345670951027,
                0.2550264238691774,
                0.2522620528852999,
                0.2494026804484929,
                0.24645067419915043,
                0.24340847016351563,
                0.240278600741149,
                0.2370637156390602,
                0.23376659737252017,
                0.2303901724584192,
                0.22693751908999388,
                0.2234118718571116,
                0.21981662392944368,
                0.21615532700423182,
                0.21243168925807515,
                0.2086495714833051
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1428385947.2156558,
                1957058497.312784,
                2500635288.2046413,
                3059033893.0893784,
                3632243403.418777,
                4220282563.880254,
                4789352137.343805,
                5371736799.720137,
                5967455329.863472,
                6576517643.550873,
                7198922329.000361,
                7834654732.951254,
                8483685405.324809,
                9145968790.942524,
                9821442101.453917,
                10510024326.621273,
                11211615359.844637,
                11926095222.656637,
                12653323379.24722,
                13393138136.233744,
                14145356125.655657,
                14909771871.01668,
                15686157437.41314,
                16474262167.559242,
                17273812505.96883,
                18084511913.757774,
                18906040876.54586,
                19738057007.799313
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.03086325417851739,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6635041963092378,
                1.654532673285889,
                1.647692899452001,
                1.6421605788493259,
                1.6375109792347868,
                1.633496914659982,
                1.6301475227624478,
                1.6271359161838261,
                1.6243982709814009,
                1.6218873410754848,
                1.6195671986016136,
                1.6174099033923681,
                1.6153933113411285,
                1.6134995847052398,
                1.6117141510967803,
                1.6100249583832955,
                1.608421930104574,
                1.6068965600302842,
                1.6054416053174516,
                1.6040508508570732,
                1.602718925887413,
                1.6014411595649998,
                1.6002134659733924,
                1.5990322516550757,
                1.5978943405736068,
                1.5967969127067012,
                1.5957374534025042,
                1.5947137113108785
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.4706937674794608,
                0.9795539703989808,
                1.525163611992287,
                2.104422981799483,
                2.717862725248534,
                3.3682578396741625,
                3.4720898865672014,
                3.575923770964553,
                3.6447199745297985,
                3.712484505678579,
                3.7792928372100296,
                3.845179275103608,
                3.9101469043414303,
                3.974174267958076,
                4.037219987751027,
                4.099226049545087,
                4.160120201762229,
                4.219817755248412,
                4.278222974695283,
                4.335230191018501,
                4.390724724910832,
                4.444583686087815,
                4.4966766953820745,
                4.546866565057516,
                4.5950099642658015,
                4.640958090592333,
                4.684557364158758,
                4.725650157314707
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5219291232.079811,
                7096384002.077138,
                9088167553.625023,
                11196001738.17329,
                13422592699.844395,
                15771455000.630259,
                18198612048.216785,
                20751635150.640602,
                23435060773.81308,
                26253683216.634552,
                29212520840.03023,
                32316790861.629696,
                35571889134.23006,
                38983372742.03729,
                42556944036.18212,
                46298435189.61311,
                50213792629.49703,
                54309060880.153786,
                58590365463.65621,
                63063894582.6294,
                67735879364.71429,
                72612572489.29881,
                77700225049.94017,
                83005061533.95657,
                88533252826.3319,
                94290887169.97993,
                100283939039.69656,
                106518235913.59254
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.11055848886569782,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7897659407610504,
                3.62797725843319,
                3.5036382263154606,
                3.402747003891996,
                3.3178432100999204,
                3.2445013530395976,
                3.1810449909688345,
                3.124175347592396,
                3.072603566349681,
                3.0253822724379695,
                2.981795773482362,
                2.941290924766086,
                2.9034318816483466,
                2.86786950313911,
                2.8343200766322245,
                2.802550162081987,
                2.772365564771777,
                2.743603160942055,
                2.716124736881932,
                2.6898122760849823,
                2.66456430557336,
                2.6402930288201953,
                2.6169220509574345,
                2.5943845555912675,
                2.572621829934033,
                2.5515820614280726,
                2.5312193480404876,
                2.5114928782319104
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.05496408292558499,
                0.1155505443731781,
                0.18200883945104057,
                0.25466832353602753,
                0.3339047243967425,
                0.42012871840209903,
                0.4403840569225742,
                0.46149930887871865,
                0.4835195004282568,
                0.5064882724155828,
                0.5304485728138819,
                0.555443053676338,
                0.5815142874435202,
                0.6087048642782555,
                0.637057404675627,
                0.6666145065947237,
                0.6974186377889855,
                0.7295119788354912,
                0.7629362191345449,
                0.7977323060104502,
                0.8339401456068373,
                0.8715982532322446,
                0.9107433500643106,
                0.9514099025828249,
                0.9936296007144465,
                1.0374307704225438,
                1.0828377163833203,
                1.1298699904227563
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                351432457.77776057,
                498584798.32802886,
                659617750.5286819,
                835637564.9470158,
                1027887222.248823,
                1237738162.5068452,
                1437604243.098785,
                1653534518.937492,
                1886767900.7905147,
                2138639867.9942832,
                2410588647.321093,
                2704161941.2686477,
                3021024159.758083,
                3362964128.463932,
                3731903256.181126,
                4129904146.990626,
                4559179642.603128,
                5022102277.182572,
                5521214121.6836605,
                6059236987.493765,
                6639082949.995743,
                7263865141.509921,
                7936908749.813546,
                8661762142.91729,
                9442208022.819717,
                10282274490.369635,
                11186245879.95114,
                12158673196.28861
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.1685271185613837,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2269426599658266,
                2.125334062941492,
                2.048001332209128,
                1.9852800906940478,
                1.9322784739456813,
                1.8861894567839812,
                1.8500321338269372,
                1.8170014894913147,
                1.7865228925500993,
                1.7581659207153084,
                1.7316002834077748,
                1.7065673318314603,
                1.6828609934321301,
                1.6603146305569298,
                1.6387917489192845,
                1.6181792807672546,
                1.5983826337608258,
                1.579321977856755,
                1.5609294174479977,
                1.5431468077529975,
                1.5259240475473064,
                1.509217729176524,
                1.4929900600661339,
                1.4772079930134947,
                1.4618425187978483,
                1.446868086259632,
                1.4322621234147697,
                1.4180046393408234
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.08857737690632347,
                0.21623936613007536,
                0.3721892254523851,
                0.5489487676143924,
                0.7410242234327622,
                0.9441915696233408,
                0.9900851960720052,
                1.0282731133220722,
                1.0598337360013823,
                1.0856407689678933,
                1.1064159300878391,
                1.1227649626257463,
                1.1352030247567217,
                1.1441730904904708,
                1.1500596114232038,
                1.1531988775078736,
                1.1538870231666813,
                1.1523863180351483,
                1.148930183850529,
                1.1437272493060462,
                1.1369646669739624,
                1.1288108562038175,
                1.1194177937676655,
                1.1089229440285848,
                1.0974508986715572,
                1.0851147800344665,
                1.0720174504233453,
                1.058252560651906
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1349354.211343523,
                2504002.360101448,
                3942212.668966027,
                5632980.9633750655,
                7552996.098268103,
                9683974.656710465,
                11794270.600405732,
                14057657.747654518,
                16465992.953316718,
                19012144.77008209,
                21689790.154867366,
                24493264.293432306,
                27417447.009795472,
                30457675.07375071,
                33609673.28636832,
                36869499.470701426,
                40233499.95272225,
                43698273.08805455,
                47260639.051426016,
                50917614.56595655,
                54666391.57570903,
                58504319.10028878,
                62428887.68269138,
                66437715.96969873,
                70528539.06053938,
                74699198.33296692,
                78947632.51246107,
                83271869.794246
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.2999162779625153,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                352.6766407425816,
                318.03630171683056,
                295.6201251901422,
                279.59380763132646,
                267.39081401750616,
                257.6877062671258,
                250.3830476629121,
                244.15648941702943,
                238.76314546302194,
                234.02978984405405,
                229.829958345556,
                226.0688627309637,
                222.67385559196936,
                219.58818446889617,
                216.76677364417336,
                214.1732990141788,
                211.77811223219206,
                209.55673723509048,
                207.4887614520874,
                205.5570047547976,
                203.7468874517783,
                202.04594329225577,
                200.4434396973539,
                198.93007836281342,
                197.49775685438982,
                196.1393770188268,
                194.84868970678895,
                193.6201679345051
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.0768362570710451,
                0.18806259941485767,
                0.3241596634219036,
                0.4785449641009598,
                0.6463892262995073,
                0.823982655536743,
                0.8643287833000717,
                0.8979061746849328,
                0.9256639839708546,
                0.9483706984631876,
                0.9666607866895242,
                0.9810665300428364,
                0.992040445371878,
                0.9999715232825651,
                1.005197276182441,
                1.0080128698308262,
                1.0086781758512418,
                1.0074233106086914,
                1.0044530505123987,
                0.9999503994422277,
                0.9940795057397902,
                0.9869880738089257,
                0.9788093773973705,
                0.9696639556492188,
                0.9596610536228971,
                0.9488998548202524,
                0.9374705431241015,
                0.9254552234076755
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1338515.536139095,
                2477285.8139262036,
                3895363.6062333803,
                5562280.523282176,
                7455116.043334044,
                9555887.295833612,
                11656200.778130513,
                13910160.391394736,
                16309550.53222814,
                18847182.474178683,
                21516686.96091066,
                24312361.2400144,
                27229053.522633214,
                30262073.893491574,
                33407124.372061517,
                36660243.137474984,
                40017759.42684558,
                43476256.611783795,
                47032541.63504927,
                50683619.45987841,
                54426671.51783342,
                58259037.38117805,
                62178199.06154926,
                66181767.467171855,
                70267470.64899197,
                74433143.5407931,
                78676718.95583329,
                82996219.64722447
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3137267587806419,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                404.3946358979993,
                361.92146694434143,
                334.3797344449203,
                314.6684364528118,
                299.650371512308,
                287.7041232986628,
                278.6319988914194,
                270.90759652853,
                264.2229876683881,
                258.36083212343397,
                253.16274933834546,
                248.51021095650506,
                244.31249341160841,
                240.49880291951754,
                237.01296659479587,
                233.80975675045346,
                230.8522859703763,
                228.1101227412018,
                225.5579032614121,
                223.17429198912507,
                220.94119184103508,
                218.8431360887209,
                216.86681449080538,
                215.00069995794124,
                213.23475145327103,
                211.5601753678077,
                209.96923222230882,
                208.45507884691048
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9538990.378394619,
      "catalyzed_erp": 262.0822435949183
    },
    "2750": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.1823273465071543,
            0.4446613561804406,
            0.7649183406279815,
            1.1277925321411715,
            1.5220339165605359,
            1.9389896540280231,
            2.0329653112360533,
            2.1111569374027623,
            2.1757718212982944,
            2.2285986983882653,
            2.271115443533203,
            2.304562658745046,
            2.3299955713313403,
            2.3483216579521207,
            2.3603285857704144,
            2.3667054072277134,
            2.368058941484235,
            2.36492664814988,
            2.3577868959507726,
            2.3470672635254815,
            2.3331513304328184,
            2.316384293646097,
            2.2970776584831887,
            2.2755131917459757,
            2.251946280344945,
            2.226608805920878,
            2.199711622258995,
            2.171446703499581
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.309219991847749,
            0.6226819102642231,
            0.9400279572056336,
            1.261180442193206,
            1.5861901307007915,
            1.9151846481649368,
            1.9271545976038569,
            1.939429357825433,
            1.9520868335991186,
            1.9651968757338145,
            1.978824470985042,
            1.9930317081097046,
            2.007879021211421,
            2.023425981763643,
            2.039731793208028,
            2.0568555784815037,
            2.0748565149005147,
            2.093793849583525,
            2.1137268156287554,
            2.134714461017469,
            2.1568153968745776,
            2.180087468097631,
            2.2045873469220463,
            2.230370048269524,
            2.257488364552237,
            2.2859922168155835,
            2.3159279186365604,
            2.3473373489923626
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.512918623673504,
            1.0674051530497497,
            1.6619262851940024,
            2.293107824848503,
            2.96152937562677,
            3.670215255949278,
            3.783338766402565,
            3.89646559995706,
            3.97141493604542,
            4.045240796978505,
            4.118025144586003,
            4.189805156473104,
            4.2605840392464,
            4.330338291202266,
            4.399022727547988,
            4.466574053759038,
            4.532913474831061,
            4.597948653494439,
            4.6615752243193,
            4.723678004407482,
            4.784131998767939,
            4.842803270563725,
            4.89954972754334,
            4.954221863101709,
            5.006663481302134,
            5.05671242862959,
            5.104201350408313,
            5.1489584860523765
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.6546649385790261,
            1.3197712263967234,
            1.9908274376435364,
            2.1759157163905978,
            2.356156079698007,
            2.5317692017425237,
            2.5304089885958696,
            2.527910889575133,
            2.524500839566196,
            2.5203481339922487,
            2.5155828285366506,
            2.5103069077320566,
            2.504601727741434,
            2.4985331353730484,
            2.492155084772744,
            2.4855122528711564,
            2.4786419695519357,
            2.471575667849293,
            2.46433999081704,
            2.456957648513576,
            2.44944809008088,
            2.441828036722956,
            2.4341119090017242,
            2.4263121725925747,
            2.418439620545259,
            2.41050360555357,
            2.4025122324720365,
            2.394472519020939
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 281.9271994097096,
            "pct_change": 0.3731043624094889
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9551028655885226,
            "pct_change": 0.17377506629362735
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1655605812773735,
            "pct_change": 0.2454616059259371
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.56356773330086,
            "pct_change": 0.08169147476921362
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.3698630136987,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-05-15T00:00:00",
            "x3": 0.6328767123287671,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.276712328767,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-04-11T00:00:00",
            "x3": 0.726027397260274,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8739726027397,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-15T00:00:00",
            "x3": 0.12876712328767123,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.3315068493152,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-04-30T00:00:00",
            "x3": 1.673972602739726,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.380821917808,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-05-19T00:00:00",
            "x3": 0.6219178082191781,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.1068493150683,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-02-08T00:00:00",
            "x3": 0.8958904109589041,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.876712328767,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-16T00:00:00",
            "x3": 0.12602739726027398,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.290410958904,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-04-15T00:00:00",
            "x3": 1.715068493150685,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 2750000000.0,
        "tech_funds": {
          "dac": 687500000.0,
          "saf": 687500000.0,
          "gh": 687500000.0,
          "ldes": 687500000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.47492244314411436,
                0.9615649862157823,
                1.4565019395924388,
                1.4683505664068388,
                1.4785335332577492,
                1.487452542794335,
                1.4953767115383843,
                1.5024953287123513,
                1.5089466505664317,
                1.5148347395680208,
                1.5202398628715954,
                1.5252252029871007,
                1.5298413495188967,
                1.5341293995982264,
                1.5381231549556624,
                1.5418507146701015,
                1.5453356529678577,
                1.5485979055680668,
                1.5516544471678713,
                1.5545198165143792,
                1.5572065284948375,
                1.559725401205762,
                1.5620858182521917,
                1.5642959410552573,
                1.5663628821979396,
                1.5682928480546254,
                1.5700912570261405,
                1.5717628381876727
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6326576.416151684,
                9599211.391181663,
                13067689.276365802,
                15231691.616955366,
                17476847.378444538,
                19804406.761332896,
                22215913.578618247,
                24713142.81896126,
                27298060.441184483,
                29972796.625318784,
                32739627.558620505,
                35600962.85019235,
                38559336.782748364,
                41617402.2565276,
                44777926.67043386,
                48043789.22904013,
                51417979.32076637,
                54903595.715976164,
                58503846.40361979,
                62222048.933236204,
                66061631.16296243,
                70026132.33834735,
                74119204.444254,
                78344613.78496206,
                82706242.75709982,
                87208091.78716718,
                91854281.41080086,
                96649054.47503473
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.064812861784656,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.59993151576663,
                116.75099041486995,
                115.4640814905068,
                114.84916819209282,
                114.31072863355183,
                113.83181432936277,
                113.40046571939239,
                113.00794371702135,
                112.64767774634925,
                112.31460895984706,
                112.00476344531721,
                111.71496539158655,
                111.44263869782633,
                111.18566630743821,
                110.94228828671055,
                110.71102655420685,
                110.49062834298961,
                110.28002308614897,
                110.07828908830798,
                109.88462744308595,
                109.69834139180828,
                109.51881982089934,
                109.34552394427327,
                109.17797646326312,
                109.01575267292819,
                108.85847311149332,
                108.70579744362486,
                108.55741933803725
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.19760171830371517,
                0.39365899847465646,
                0.5876747910054252,
                0.7793017391562365,
                0.9682532725506191,
                1.1542731286125774,
                1.1461049490138475,
                1.1374301711613235,
                1.1282712795895196,
                1.1186456395765834,
                1.1085674018094143,
                1.0980487037931297,
                1.0871004606319672,
                1.075732904377825,
                1.0639559629569784,
                1.051779532919367,
                1.0392136795063498,
                1.026268785343218,
                1.012955661681465,
                0.999285631488437,
                0.9852705907276517,
                0.9709230522288161,
                0.9562561752596412,
                0.9412837830311833,
                0.9260203697739351,
                0.9104810986077738,
                0.8946817911473389,
                0.8786389095865301
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1464728436.6080513,
                2031685378.8994987,
                2615472617.4594555,
                3216009427.8838577,
                3833291799.772332,
                4467345944.110102,
                5042231842.905978,
                5630441938.775542,
                6231991487.188691,
                6846885718.436374,
                7475117644.856658,
                8116666321.378218,
                8771495408.665823,
                9439551949.978233,
                10120765307.923101,
                10815046228.075218,
                11522286009.197279,
                12242355767.895922,
                12975105790.801252,
                13720364970.83116,
                14477940326.418316,
                15247616604.11854,
                16029155966.022984,
                16822297764.0154,
                17626758403.24985,
                18442231297.3403,
                19268386917.69764,
                20104872939.254723
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09668982436891345,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1746308729790187,
                1.1497300326037152,
                1.130915951665992,
                1.1157790704229784,
                1.1031013589206322,
                1.0921827036699079,
                1.08363495045685,
                1.0759102314972362,
                1.0688592579646263,
                1.0623701397675727,
                1.0563569121905028,
                1.0507521412979135,
                1.04550198531262,
                1.0405627951141556,
                1.0358987126554156,
                1.0314799356564959,
                1.027281438666847,
                1.0232820138252552,
                1.0194635400855412,
                1.015810418642981,
                1.0123091312153354,
                1.008947890459468,
                1.0057163603987962,
                1.0026054306908505,
                0.9996070327563308,
                0.9967139887865323,
                0.993919886815728,
                0.9912189766364202
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.1797424954349117,
                0.35820624018094094,
                0.5343254980510977,
                0.7075651499837589,
                0.877622546440258,
                1.044316658948189,
                1.0350322770574856,
                1.0254155608627817,
                1.0155541889997644,
                1.005513394424228,
                0.9953429656650553,
                0.9850817047449557,
                0.974760378222537,
                0.9644037357748222,
                0.9540319298170815,
                0.9436615382010549,
                0.9333063165840779,
                0.9229777622812259,
                0.9126855436491685,
                0.9024378319991967,
                0.8922415615860423,
                0.8821026355171939,
                0.8720260907495327,
                0.8620162315373175,
                0.8520767383473196,
                0.8422107574989443,
                0.8324209754458961,
                0.8227096808332665
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9951172.274617262,
                14010342.723344266,
                18196939.92396847,
                22504891.61632033,
                26931198.330824204,
                31474507.670164727,
                35307255.40051051,
                39221614.00664362,
                43218046.36522549,
                47297191.03605513,
                51459826.36755924,
                55706845.20109761,
                60039236.581910975,
                64458072.25178709,
                68964496.49520187,
                73559718.39354317,
                78245005.84475362,
                83021680.90120874,
                87891116.10818529,
                92854731.61311077,
                97913992.8765749,
                103070408.8589381,
                108325530.58708735,
                113680950.02824037,
                119138299.2141866,
                124699249.57167141,
                130365511.42394009,
                136138833.6355678
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08549057519973048,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.34258727691781,
                115.81808040068417,
                114.69187301125174,
                113.8007184422614,
                113.06408621714681,
                112.43647677473636,
                111.98085182376366,
                111.56909707679327,
                111.19339684294088,
                110.84782895595306,
                110.5278022009288,
                110.22968756943422,
                109.95056852804403,
                109.6880670957512,
                109.44021975484577,
                109.20538702760577,
                108.98218634757202,
                108.76944139383984,
                108.56614328152472,
                108.37142043605198,
                108.1845149252711,
                108.00476366068685,
                107.83158331632525,
                107.66445811883744,
                107.50292987863682,
                107.34658978725275,
                107.19507161923308,
                107.04804606031686
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.051276338884403,
                0.10217269397993853,
                0.1525518723334902,
                0.20232111323122515,
                0.25140498080378787,
                0.29973646280105226,
                0.2976456798917752,
                0.2954221647728616,
                0.2930720854016024,
                0.2906001557209042,
                0.28801017797411765,
                0.285305381862588,
                0.28248864618606156,
                0.27956264908993794,
                0.27652997309736915,
                0.27339318039729477,
                0.27015486790455584,
                0.2668177081011792,
                0.26338447957616584,
                0.2598580898635988,
                0.25624159235152016,
                0.25253819848187836,
                0.24875128610579633,
                0.24488440461050678,
                0.24094127726672393,
                0.23692580113427164,
                0.23284204477983328,
                0.22869424400970517
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1431192021.4244156,
                1962821700.4462695,
                2509505124.1669064,
                3071159919.1241584,
                3647775705.970363,
                4239371832.014629,
                4808892777.72959,
                5391729561.9583,
                5987900711.520858,
                6597415794.428713,
                7220272978.573453,
                7856457132.521385,
                8505938280.058777,
                9168670298.933851,
                9844589798.071283,
                10533615133.076,
                11235645535.322659,
                11950560339.623102,
                12678218301.702875,
                13418457000.815512,
                14171092325.549337,
                14935918042.700079,
                15712705450.280476,
                16501203116.497717,
                17301136706.96879,
                18112208902.641304,
                18934099410.89625,
                19766465072.166878
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.030970379196224657,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.663447631924615,
                1.6544498788291366,
                1.6475949979831952,
                1.6420528123728153,
                1.6373962119626233,
                1.6333768748896593,
                1.630040228332282,
                1.6270388498893356,
                1.6243095951564408,
                1.621805674279638,
                1.6194914768898863,
                1.61733929047938,
                1.6153271383695431,
                1.613437308651108,
                1.6116553253044161,
                1.6099692112247432,
                1.608368949219425,
                1.6068460804840563,
                1.6053934005661687,
                1.6040047257570476,
                1.6026747112193152,
                1.601398707695257,
                1.600172647380952,
                1.5989929521244561,
                1.5978564589065605,
                1.596760358841609,
                1.5957021468573012,
                1.5946795798849098
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.512918623673504,
                1.0674051530497497,
                1.6619262851940024,
                2.293107824848503,
                2.96152937562677,
                3.670215255949278,
                3.783338766402565,
                3.89646559995706,
                3.97141493604542,
                4.045240796978505,
                4.118025144586003,
                4.189805156473104,
                4.2605840392464,
                4.330338291202266,
                4.399022727547988,
                4.466574053759038,
                4.532913474831061,
                4.597948653494439,
                4.6615752243193,
                4.723678004407482,
                4.784131998767939,
                4.842803270563725,
                4.89954972754334,
                4.954221863101709,
                5.006663481302134,
                5.05671242862959,
                5.104201350408313,
                5.1489584860523765
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5222398141.287256,
                7102979555.651325,
                9098636245.851244,
                11210741809.51625,
                13442021247.023949,
                15796011019.480469,
                18224451928.49536,
                20778804497.11026,
                23463608083.323338,
                26283659465.669968,
                29243979187.062515,
                32349786416.351257,
                35606478765.39065,
                39019614906.28791,
                42594898616.431404,
                46338163335.03634,
                50255356591.58684,
                54352523840.64457,
                58635791351.114784,
                63111347875.17122,
                67785424876.790504,
                72664275140.87537,
                77754149616.70084,
                83061272377.43697,
                88591813603.15164,
                94351860519.59894,
                100347386250.38858,
                106584216566.61256
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.11068369718584803,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.789444406617549,
                3.62750059961632,
                3.503071608489694,
                3.4021217745344976,
                3.3171765109940137,
                3.2438034262485544,
                3.1804236084482307,
                3.1236148466633433,
                3.0720925998123407,
                3.024912370493795,
                2.9813604559314792,
                2.940885129523147,
                2.903051583746847,
                2.86751145303369,
                2.8339816157590114,
                2.8022290899222426,
                2.7720600411298064,
                2.743311632843285,
                2.715845883028735,
                2.689544964057553,
                2.664307558430782,
                2.6400459987353098,
                2.6166839981842154,
                2.5941548315234972,
                2.5723998633375165,
                2.5513673471593794,
                2.531011437732081,
                2.5112913725457173
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.06034193465963085,
                0.1268502178096281,
                0.19980129386671824,
                0.2795575898057444,
                0.36653187734638454,
                0.4611750567513072,
                0.4834039686982342,
                0.5065770218912478,
                0.5307434686079966,
                0.5559510804363271,
                0.5822468912015101,
                0.6096776224539868,
                0.6382899143933921,
                0.6681304282958801,
                0.6992458571536806,
                0.7316828651648416,
                0.7654879674896093,
                0.800707356139128,
                0.8373866743711246,
                0.875570739665433,
                0.9153032137954059,
                0.9566262173869367,
                0.9995798855566087,
                1.0442018606278338,
                1.0905267175115778,
                1.1385853170735383,
                1.1884040827093882,
                1.2400041953961272
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                353207721.63700455,
                502430257.8594014,
                665860077.0297936,
                844639813.0552458,
                1040053180.6990204,
                1253516812.2797387,
                1454653018.7941647,
                1671951304.0836244,
                1906658262.9428465,
                2160117542.0130763,
                2433776066.074982,
                2729190808.907291,
                3048036064.816679,
                3392111195.1134505,
                3763348832.3935328,
                4163823528.4785223,
                4595760831.233187,
                5061546772.209074,
                5563737741.6284485,
                6105070719.821028,
                6688473824.892798,
                7317077125.081043,
                7994223650.811278,
                8723480525.760422,
                9508650118.063784,
                10353781091.988483,
                11263179216.741568,
                12241417762.410673
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.16981927450075399,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.225438303222774,
                2.123164892494493,
                2.045458298080117,
                1.9824942750270715,
                1.9293188230392808,
                1.8830962406762741,
                1.8472209062157585,
                1.81441834640191,
                1.784127929510705,
                1.755928816101526,
                1.7294974896774906,
                1.704580221672251,
                1.6809745970570096,
                1.658516752104748,
                1.6370723334810895,
                1.6165299515464593,
                1.5967963474451596,
                1.577792763836892,
                1.559452177612587,
                1.5417171607775682,
                1.5245382063560085,
                1.5078724034738311,
                1.4916823780506394,
                1.4759354379359335,
                1.4606028771266348,
                1.4456594050104592,
                1.4310826747795709,
                1.4168528911786895
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.0976602187215758,
                0.23786754730099346,
                0.4088901083766822,
                0.6025899014843604,
                0.8129813096944284,
                1.0354584782878382,
                1.0854558118873983,
                1.1270520870221692,
                1.1614209610952912,
                1.189513848327049,
                1.2121170289411194,
                1.2298906921328134,
                1.243396483434459,
                1.2531174831503924,
                1.2594730481824317,
                1.2628300737205833,
                1.2635116997305866,
                1.2618041547952732,
                1.257962216249314,
                1.2522136247823792,
                1.244762696764151,
                1.2357933122457259,
                1.225471410961284,
                1.2139470960120913,
                1.20135642138783,
                1.187822922085109,
                1.1734589329099205,
                1.1583667321593774
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1359294.5026928985,
                2528404.371612362,
                3984901.120999215,
                5697303.047771176,
                7641949.7937638415,
                9800288.667689612,
                11919573.622123798,
                14191452.701069262,
                16607847.279877543,
                19161676.983243708,
                21846660.019739036,
                24657165.472727157,
                27588101.435678907,
                30634828.558481287,
                33793092.042096846,
                37058967.309715115,
                40428816.00588282,
                43899249.92402647,
                47467101.110222295,
                51129396.84208699,
                54883338.501794145,
                58726283.59333944,
                62655730.32362586,
                66669304.29293413,
                70764746.93524286,
                74939905.4211905,
                79192723.79220137,
                83521235.13767497
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.300991173456446,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                352.2352629153185,
                317.5319431237793,
                295.1166051978774,
                279.105639105871,
                266.9208862046483,
                257.23564641787334,
                250.0009258452746,
                243.8277467346495,
                238.4762510110385,
                233.77643456158864,
                229.60398257590788,
                225.86558984194764,
                222.48966179947394,
                219.42021196104065,
                216.61273383028683,
                214.0313342493457,
                211.64669653014278,
                209.43460360826995,
                207.37484782891505,
                205.45041310742846,
                203.6468524806167,
                201.95180813213852,
                200.3546368560496,
                198.84611460897023,
                197.4182011219623,
                196.06385064052677,
                194.776858463864,
                193.55173553619127
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.08466712778557849,
                0.20679380887944715,
                0.35602823225129926,
                0.525202630656811,
                0.7090526068661076,
                0.9035311757401848,
                0.9475094993486551,
                0.9841048503805933,
                1.014350860203003,
                1.0390848500612162,
                1.0589984145920837,
                1.0746719666122326,
                1.0865990878968812,
                1.0952041748017283,
                1.100855537587983,
                1.10387533350713,
                1.1045472417536486,
                1.103122493354607,
                1.0998246797014586,
                1.0948536387431023,
                1.0883886336686674,
                1.080590981400371,
                1.0716062475219046,
                1.0615660957338846,
                1.0505898589571145,
                1.0387858838357693,
                1.0262526893490749,
                1.0130799713402037
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1347290.6041796622,
                2498924.6812800895,
                3933317.7790117785,
                5619566.490569146,
                7534433.233680357,
                9659691.244010765,
                11768101.448270116,
                14029707.449215608,
                16436352.47936507,
                18980894.31664801,
                21657001.220630925,
                24459001.234153993,
                27381768.22711191,
                30420633.941744648,
                33571318.885827884,
                36829877.179538965,
                40192651.92691333,
                43656238.65794397,
                47217455.051690996,
                50873315.61290816,
                54621010.30231453,
                58457886.35689861,
                62381432.70968419,
                66389266.546932705,
                70479121.63749532,
                74648838.14269961,
                78896353.67188017,
                83219695.39278308
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.31475437385701105,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                403.9108483783162,
                361.36425448929896,
                333.8211144521391,
                314.1254615589986,
                299.12679208892206,
                287.19984079822325,
                278.20629155152784,
                270.54174182728184,
                263.9039807658169,
                258.079321179301,
                252.9118136142228,
                248.28460365999567,
                244.10815406010605,
                240.31253279584692,
                236.84220654736092,
                233.65243126930517,
                230.70669137761692,
                227.9748454642012,
                225.4317590660872,
                223.05628010034872,
                220.83045982106316,
                218.73895261215287,
                216.76854799852882,
                214.90780174502015,
                213.1467421408195,
                211.47663398583575,
                209.8897873274791,
                208.3794012410737
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9606547.170749923,
      "catalyzed_erp": 286.26310276945475
    },
    "3000": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.19898796746791048,
            0.48427415893589276,
            0.8320780431650107,
            1.2258951640768476,
            1.6535811638315425,
            2.1057884891344814,
            2.2072245639660117,
            2.291611593586701,
            2.3613298982500286,
            2.4183094545001556,
            2.4641455260879344,
            2.500177740160651,
            2.5275458957912234,
            2.547230448350822,
            2.56008259132562,
            2.566847086196596,
            2.5681799152647713,
            2.5646621599707036,
            2.5568110748067676,
            2.5450890418286285,
            2.529910898510726,
            2.511649999978287,
            2.490643283306896,
            2.4671955362214755,
            2.4415830245671497,
            2.4140565976489086,
            2.3848443648016335,
            2.354154016765467
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.33645638473845674,
            0.6775102614894666,
            1.0227813435381818,
            1.3721883075888357,
            1.725787864225051,
            2.0837196903292785,
            2.096728678344429,
            2.1100711475189966,
            2.1238314487201873,
            2.1380853071755466,
            2.1529032391179435,
            2.1683526594368,
            2.1844992150906855,
            2.2014076342733526,
            2.219142255951463,
            2.2377673365006574,
            2.2573471917316565,
            2.277946209856636,
            2.299628757033463,
            2.322458988296994,
            2.3465005709345537,
            2.3718163234926446,
            2.3984677709601674,
            2.426514614830629,
            2.456014115484753,
            2.4870203834916067,
            2.5195835759196994,
            2.553748993556631
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.5543593668064932,
            1.1536214669272864,
            1.7961405116700226,
            2.478273476301933,
            3.2006482261230493,
            3.966533831230756,
            4.088772621723444,
            4.211016398987225,
            4.292001690048959,
            4.3717735243345714,
            4.450420188013433,
            4.527981644618827,
            4.604461195697909,
            4.679833313877755,
            4.754049063523078,
            4.827039955795786,
            4.898720763987898,
            4.968991636683242,
            5.037739731948204,
            5.104840524267803,
            5.170158890075568,
            5.233550047568169,
            5.294860406205615,
            5.353928367357718,
            5.410585107757973,
            5.464655370345008,
            5.515958281842715,
            5.564308212374183
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.7079914120546437,
            1.4269793578477614,
            2.152240404011204,
            2.352970023070026,
            2.5484775671254165,
            2.7389902249401494,
            2.737355854192141,
            2.7345098155100627,
            2.7306918939591243,
            2.726082025118361,
            2.7208186078104992,
            2.715010281354126,
            2.708743787216344,
            2.702089384024408,
            2.695104678323302,
            2.6878373980135457,
            2.6803274413333105,
            2.6726084175739295,
            2.664708824218241,
            2.6566529589174124,
            2.6484616351941224,
            2.6401527504428284,
            2.6317417415264464,
            2.623241953644703,
            2.6146649416188366,
            2.6060207179215724,
            2.597317958351723,
            2.5885641738129594
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 281.36969932110725,
            "pct_change": 0.37434402418824914
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9536981469288377,
            "pct_change": 0.17499023758385182
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1647800574079663,
            "pct_change": 0.24573356101786603
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.47221960423092,
            "pct_change": 0.08289736124718179
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.317808219178,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-04-26T00:00:00",
            "x3": 0.684931506849315,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.213698630137,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-03-19T00:00:00",
            "x3": 0.7890410958904109,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8630136986303,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-11T00:00:00",
            "x3": 0.13972602739726028,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.2,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-03-13T00:00:00",
            "x3": 1.8054794520547945,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.328767123288,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-04-30T00:00:00",
            "x3": 0.673972602739726,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.0301369863014,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-01-11T00:00:00",
            "x3": 0.9726027397260274,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8657534246577,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-12T00:00:00",
            "x3": 0.136986301369863,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.1534246575343,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-02-25T00:00:00",
            "x3": 1.8520547945205479,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 3000000000.0,
        "tech_funds": {
          "dac": 750000000.0,
          "saf": 750000000.0,
          "gh": 750000000.0,
          "ldes": 750000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.5127475163811596,
                1.0379287604863314,
                1.571950596613646,
                1.5845792452377596,
                1.5954478715337201,
                1.604977673873035,
                1.6134516494745565,
                1.6210693187049972,
                1.6279766929017918,
                1.6342838497039849,
                1.6400758124606512,
                1.645419585990974,
                1.650368874543417,
                1.6549673436065622,
                1.6592509346248128,
                1.6632495451686629,
                1.6669882727826908,
                1.6704883520147298,
                1.6737678712904096,
                1.6768423289927312,
                1.6797250702212205,
                1.682427633689422,
                1.6849600301038226,
                1.687330967628919,
                1.6895480360656596,
                1.6916178584862975,
                1.693546216985043,
                1.695338157647509
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6432253.708462148,
                9819158.141899709,
                13410494.409441363,
                15587486.745869739,
                17845810.31184786,
                20186768.099175096,
                22611944.612864792,
                25123147.857465547,
                27722371.756540373,
                30411771.041488178,
                33193644.102654267,
                36070421.1213357,
                39044655.8195564,
                42119019.75897123,
                45296298.48107175,
                48579389.00730213,
                51971298.36399318,
                55475142.89398979,
                59094148.182584,
                62831649.470833294,
                66691092.46135753,
                70676034.44461346,
                74790145.69026813,
                79037211.06050941,
                83421131.81121238,
                87945927.55369419,
                92615738.35494593,
                97434826.958157
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.0662324324539593,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.52396774637855,
                116.65418815289082,
                115.35905350224645,
                114.75787597551704,
                114.23000114449837,
                113.75944825510749,
                113.3348711607544,
                112.94793768582875,
                112.59235739848903,
                112.26327038236714,
                111.9568477343803,
                111.67002174606044,
                111.40029848975207,
                111.14562444530024,
                110.90428955000439,
                110.67485539436602,
                110.45610115036094,
                110.24698224315482,
                110.04659833742106,
                109.85416823687025,
                109.66900998628363,
                109.49052493837928,
                109.31818487735303,
                109.15152152405615,
                108.99011791505427,
                108.83360126943204,
                108.6816370467028,
                108.5339239657805
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.2150057729899072,
                0.4283169581701625,
                0.6393979624711276,
                0.8478725871580834,
                1.0534297586141637,
                1.2557910038142392,
                1.246882815632069,
                1.2374242760176957,
                1.2274396276538542,
                1.216947626541459,
                1.2059635828676554,
                1.1945006500996858,
                1.182570674465001,
                1.1701847749019896,
                1.1573537508480505,
                1.1440883759535698,
                1.1303996136307648,
                1.1162987773010529,
                1.1017976502842874,
                1.0869085753250927,
                1.0716445205728553,
                1.0560191267467658,
                1.0400467388349144,
                1.0237424247315665,
                1.0071219825750914,
                0.9902019381075283,
                0.972999533069036,
                0.9555327054348152
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1470494469.4168093,
                2043522822.641899,
                2633685066.4209704,
                3240900923.232147,
                3865167479.3310323,
                4506512061.196827,
                5082314954.2241,
                5671443361.530044,
                6273911931.272065,
                6889725116.2915,
                7518875019.33505,
                8161339678.355631,
                8817081646.716795,
                9486046782.491188,
                10168163194.83165,
                10863340315.46804,
                11571468075.749336,
                12292416177.501396,
                13026033451.075968,
                13772147297.343493,
                14530563212.635202,
                15301064397.137506,
                16083411448.214596,
                16877342140.731834,
                17682571296.769653,
                18498790747.22057,
                19325669387.695698,
                20162853330.96325
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09730450228454612,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1743282814254579,
                1.1492933318748848,
                1.1304040111650349,
                1.1152189102932033,
                1.1025074909926234,
                1.0915637769225506,
                1.0830785136477075,
                1.075404474098193,
                1.0683954359767216,
                1.0619416036312097,
                1.0559584908050432,
                1.0503797364933782,
                1.0451522961241642,
                1.0402331250686885,
                1.0355868318232695,
                1.031183979821732,
                1.0269998342585573,
                1.0230134211056643,
                1.0192068095202012,
                1.0155645569493905,
                1.012073274625486,
                1.008721283433379,
                1.0054983385085179,
                1.0023954067317875,
                0.9994044853822952,
                0.9965184531374645,
                0.9937309467328824,
                0.9910362581527404
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.19524389567348407,
                0.3890505973614301,
                0.5802898073975582,
                0.7683907778322663,
                0.9530296955916965,
                1.1340125510671146,
                1.1239042047175845,
                1.1134404968050655,
                1.1027152010573325,
                1.0917981754143762,
                1.0807427953498479,
                1.069590695363152,
                1.058374912672927,
                1.0471220404178454,
                1.0358537436984896,
                1.0245878528448826,
                1.01333916855062,
                1.0021200655592,
                0.9909409529278311,
                0.9798106299246814,
                0.9687365649729018,
                0.9577251167534063,
                0.9467817114226238,
                0.9359109860157837,
                0.9251169055531772,
                0.914402859435275,
                0.90377174136668,
                0.8932260161654504
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10013089.457828349,
                14137354.575905379,
                18392066.997645594,
                22771093.827640086,
                27271423.03589786,
                31891717.126652732,
                35733417.13943153,
                39656768.86742097,
                43662256.692847535,
                47750536.0608155,
                51922398.98765789,
                56178749.67939051,
                60520586.85889092,
                64948990.68615011,
                69465112.90860243,
                74070169.33652265,
                78765434.02660614,
                83552234.74345969,
                88431949.39272188,
                93406003.20380487,
                98475866.49869798,
                103643052.92455468,
                108909118.05742492,
                114275658.30609556,
                119744310.06096052,
                125316749.0447804,
                130994689.83122413,
                136779885.50399345
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08624115978583649,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.31442058362231,
                115.77865273431097,
                114.64662950704898,
                113.75201852111552,
                113.01313662396802,
                112.38396434208373,
                111.93361858969641,
                111.52616784607763,
                111.15404056261873,
                110.81148397445861,
                110.49402799685394,
                110.19813246990888,
                109.9209474704514,
                109.66014591169491,
                109.41380381361056,
                109.18031286781667,
                108.95831540306051,
                108.7466552163933,
                108.54433985258908,
                108.35051128328507,
                108.16442284255594,
                107.98542088654278,
                107.81293006469366,
                107.64644138371165,
                107.48550245365405,
                107.32970945559745,
                107.17870047963815,
                107.03214996269283
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.05575279764013999,
                0.11109046648628632,
                0.16586465405780582,
                0.21997475320267212,
                0.2733388429997752,
                0.3258841083579941,
                0.32360817687195226,
                0.3211880460258366,
                0.31863039022183615,
                0.31594031501021935,
                0.3131219420733743,
                0.3101787753470742,
                0.3071139404281168,
                0.30393034695612176,
                0.3006308021740267,
                0.2972180923634452,
                0.2936950424121184,
                0.2900645600092011,
                0.28632966869331355,
                0.2824935325666146,
                0.27855947458411656,
                0.2745309897437729,
                0.27041175410854623,
                0.26620563032645156,
                0.2619166701356393,
                0.2575491142201995,
                0.2531073896906482,
                0.2485961054087567
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1433979516.3821712,
                1968546551.0529702,
                2518315711.2055197,
                3083204690.6267166,
                3663203636.5689287,
                4258332490.721612,
                4828301437.921978,
                5411586961.040507,
                6008207333.03734,
                6618171774.028613,
                7241478032.599891,
                7878110500.996016,
                8528038679.354918,
                9191215881.34726,
                9867578116.593021,
                10557043110.324966,
                11259509435.99903,
                11974855746.100714,
                12702940092.545984,
                13443599332.116173,
                14196648615.055685,
                14961880956.754965,
                15739066893.621794,
                16527954224.991276,
                17328267843.35477,
                18139709655.37849,
                18961958596.185696,
                19794670739.229618
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.03107620954161723,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6633915598644053,
                1.6543678934322936,
                1.6474981177119796,
                1.6419462194785424,
                1.6372827342808571,
                1.6332582171533505,
                1.629934121098506,
                1.6269428226833658,
                1.6242218424612904,
                1.621724837609446,
                1.6194165091978074,
                1.6172693682419343,
                1.6152616025789337,
                1.6133756240281485,
                1.6115970513823954,
                1.60991398139185,
                1.6083164552318314,
                1.6067960608232246,
                1.6053456315508559,
                1.603959014664088,
                1.6026308908924911,
                1.6013566322723192,
                1.6001321888717763,
                1.5989539976447809,
                1.5978189084221823,
                1.59672412331403,
                1.5956671467080166,
                1.5946457437147887
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.5543593668064932,
                1.1536214669272864,
                1.7961405116700226,
                2.478273476301933,
                3.2006482261230493,
                3.966533831230756,
                4.088772621723444,
                4.211016398987225,
                4.292001690048959,
                4.3717735243345714,
                4.450420188013433,
                4.527981644618827,
                4.604461195697909,
                4.679833313877755,
                4.754049063523078,
                4.827039955795786,
                4.898720763987898,
                4.968991636683242,
                5.037739731948204,
                5.104840524267803,
                5.170158890075568,
                5.233550047568169,
                5.294860406205615,
                5.353928367357718,
                5.410585107757973,
                5.464655370345008,
                5.515958281842715,
                5.564308212374183
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5225447355.376052,
                7109452369.106908,
                9108909863.619465,
                11225206961.398567,
                13461087179.359053,
                15820108472.061771,
                18249809045.471096,
                20805466039.748184,
                23491621644.502716,
                26313075066.845272,
                29274848987.19749,
                32382164486.08827,
                35640420898.86184,
                39055178453.38957,
                42632142324.33394,
                46377147191.24898,
                50296141663.0919,
                54395172105.02488,
                58680365516.5363,
                63157911186.73458,
                67834040907.240486,
                72715007564.02509,
                77807061962.23166,
                83116427766.00502,
                88649274460.98645,
                94411688272.03392,
                100409640994.02528,
                106648956720.09834
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.11080632734191526,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7891290605314425,
                3.6270333136041004,
                3.5025162889706727,
                3.4015091393027084,
                3.3165233485829035,
                3.2431197637604483,
                3.179814834831932,
                3.1230656533032417,
                3.07159189226633,
                3.024451865327894,
                2.980933814062806,
                2.940487398128816,
                2.9026788240739125,
                2.8671604846772656,
                2.833649836389909,
                2.8019143453262316,
                2.7717605296547396,
                2.743025833907769,
                2.71557250279348,
                2.689282893557146,
                2.66405584089713,
                2.6398038037998512,
                2.61645060129576,
                2.593929597368162,
                2.572182232363348,
                2.551156824533791,
                2.530807584223378,
                2.511093796939452
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.0656978141084096,
                0.1381028368330177,
                0.21751872700924846,
                0.30434096722808024,
                0.39901926261111226,
                0.502044578157045,
                0.526237685840408,
                0.5514588254754642,
                0.5777614308444972,
                0.6051973656238685,
                0.6338177141769135,
                0.6636732339900401,
                0.6948146001975679,
                0.7272925124152414,
                0.7611577029293858,
                0.7964608681836427,
                0.8332525356887731,
                0.871582872546382,
                0.9115014380558617,
                0.953056880405287,
                0.9962965757775818,
                1.041266207002106,
                1.0880092780167068,
                1.136566559772611,
                1.1869754627740225,
                1.239269331163879,
                1.2934766531600155,
                1.349620182713059
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                354975732.3018121,
                506259704.0215797,
                672076082.9755954,
                853603762.0160098,
                1052167022.8077261,
                1269227491.7356107,
                1471628005.6228092,
                1690288049.1919856,
                1926461857.02012,
                2181501197.7782836,
                2456861646.9400373,
                2754109399.478983,
                3074928580.6448274,
                3421129030.0714803,
                3794654542.4924655,
                4197591550.98119,
                4632178237.760017,
                5100814054.163318,
                5606069625.741383,
                6150697010.934201,
                6737640272.252949,
                7370046307.415892,
                8051275874.269318,
                8784914727.420937,
                9574784766.139307,
                10424955072.034975,
                11339752691.146784,
                12323772988.13525
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.17108569858045042,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.223948778699729,
                2.1210237960353697,
                2.0429533002264457,
                1.9797543015133274,
                1.9264114580827971,
                1.8800608698364434,
                1.8444595371705497,
                1.811878991850994,
                1.7817720147232246,
                1.7537269735590915,
                1.727426875912233,
                1.7026227414295627,
                1.679115690882271,
                1.6567445462112338,
                1.635377029671122,
                1.6149033804163635,
                1.59523163524359,
                1.5762840800637206,
                1.5579945410325098,
                1.5403062883944312,
                1.5231703944538348,
                1.506544432917299,
                1.490391438165311,
                1.4746790647792327,
                1.459378903020872,
                1.4444659169738134,
                1.4299179800493858,
                1.4157154884340863
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.1066295545903995,
                0.25914651139395134,
                0.44491999360399076,
                0.6551757857682812,
                0.8834529510949501,
                1.1247760816539296,
                1.1787372937561806,
                1.223624619746594,
                1.2607035579728567,
                1.2910005404301235,
                1.3153642308296944,
                1.33450746688725,
                1.3490368753753905,
                1.3594743642267684,
                1.3662730980834856,
                1.3698296264308434,
                1.3704932642819239,
                1.3685734689087203,
                1.3643457274053103,
                1.3580563183595438,
                1.3499262093605338,
                1.3401542818413605,
                1.3289200255050047,
                1.3163858098027788,
                1.3026988144183487,
                1.2879926821169918,
                1.272388943483993,
                1.2559982527723277
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1369110.5727652381,
                2552412.378683385,
                4026809.105362136,
                5760359.762707942,
                7729067.175192081,
                9914118.410053134,
                12042131.827793755,
                14322259.056234153,
                16746483.470661758,
                19307773.83308724,
                21999888.082512826,
                24817227.979058962,
                27754728.888549127,
                30807774.51418823,
                33972129.76219268,
                37243887.06548184,
                40619422.88020877,
                44095361.998307556,
                47668547.95344054,
                51336018.24001079,
                55094983.37914712,
                58942809.09263794,
                62877001.012406304,
                66895191.4770995,
                70995128.06081274,
                75174663.55023263,
                79431747.14142968,
                83764416.67031682
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3020399333496541,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                351.80329372799935,
                317.0415583073001,
                294.6287266754342,
                278.6336336774677,
                266.4671588527334,
                256.79960900055005,
                249.63194163570043,
                243.5100252332173,
                238.19877309518924,
                233.5312461242439,
                229.38517850320204,
                225.6686812175347,
                222.31116660146088,
                219.25738164400875,
                216.4633655289306,
                213.89363862671155,
                211.51920277995768,
                209.31608986399752,
                207.26428931861187,
                205.34694294071738,
                203.54973158238028,
                201.86040190220518,
                200.26839684466415,
                198.76456398089445,
                197.34092301606572,
                195.99047876629723,
                194.70706944336092,
                193.48524262056918
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.092358412877511,
                0.2251276475419414,
                0.3871580495610199,
                0.5707193783085663,
                0.7701282127365924,
                0.9810124074805517,
                1.0284872702098309,
                1.0679869738401073,
                1.1006263402771717,
                1.1273089140700319,
                1.1487812952582397,
                1.1656702732734008,
                1.1785090204158328,
                1.1877560841240538,
                1.1938094932421346,
                1.1970174597657528,
                1.1976866509828474,
                1.1960886910619832,
                1.1924653474014573,
                1.1870327234690845,
                1.1799846891501922,
                1.1714957181369265,
                1.1617232578018915,
                1.150809726418697,
                1.1388842101488013,
                1.1260639155319165,
                1.1124554213176405,
                1.0981557639931394
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1355909.256226898,
                2520104.4936921815,
                3970392.1286831643,
                5675451.645343173,
                7611740.672464186,
                9760797.559292864,
                11877038.55841654,
                14146041.731421972,
                16559706.68157651,
                19110935.792448852,
                21793433.34178301,
                24601556.96215757,
                27530205.194994044,
                30574730.61943594,
                33730871.54053241,
                36994697.43093273,
                40362564.75521893,
                43831080.76219611,
                47397073.48258071,
                51057566.62363192,
                54809758.37448224,
                58651003.36846956,
                62578797.219219655,
                66590763.17393176,
                70684640.52273022,
                74858274.47564667,
                79109607.2748048,
                83436670.35296236
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3157585347437333,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                403.43945595463896,
                360.82460128164837,
                333.28183165033,
                313.6023084504785,
                298.62298610860626,
                286.71505365626166,
                277.7966318385729,
                270.18939527688207,
                263.596549616175,
                257.8078760592595,
                252.66973770801303,
                248.06687497450764,
                243.91088235248648,
                240.13265090555248,
                236.67725878572813,
                233.50042475417283,
                230.56598931953883,
                227.84408909345962,
                225.3098095354591,
                222.94217458889153,
                220.7233779085956,
                218.63819019821608,
                216.67349684206093,
                214.81793324583225,
                213.06159436026712,
                211.3958011680677,
                209.81291137050707,
                208.3061647023791
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9676553.14684194,
      "catalyzed_erp": 310.02775001334913
    },
    "3250": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.21560289884349915,
            0.5236442459939135,
            0.8986927816752852,
            1.3230747083222898,
            1.7837714164288645,
            2.2707553073181685,
            2.3794807242301865,
            2.4699190630098125,
            2.544619445480243,
            2.6056497794276208,
            2.654720064229065,
            2.6932667821127194,
            2.722512499340472,
            2.743509128039512,
            2.75717008927029,
            2.7642947340356714,
            2.765587234502621,
            2.761671441588777,
            2.7531027442932143,
            2.7403776623525875,
            2.7239416984714433,
            2.704195835982823,
            2.681501968249181,
            2.656187475979378,
            2.628549117766721,
            2.5988563611869333,
            2.5673542544566885,
            2.5342659173407025
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.36354363339417206,
            0.7320360109573042,
            1.1050756133385211,
            1.482577951406054,
            1.8646058698198176,
            2.251311161246748,
            2.2653515410443683,
            2.279754195225153,
            2.294609829008038,
            2.3099999718226027,
            2.3260006123354304,
            2.342684442693185,
            2.3601222788589067,
            2.378383964860993,
            2.397538935861696,
            2.417656542906186,
            2.4388062013576937,
            2.4610574008512405,
            2.484479599771614,
            2.5091420178595505,
            2.5351133344180568,
            2.5624612954562327,
            2.591252230287039,
            2.6215504761375468,
            2.6534177079748327,
            2.686912169854426,
            2.7220878035753655,
            2.7589932702129456
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.5950427511416637,
            1.2382587868149981,
            1.927893474126638,
            2.660040406442786,
            3.435375032834775,
            4.257406760100711,
            4.388590744209393,
            4.5197815452922425,
            4.606689678923848,
            4.692296130086817,
            4.77669535130624,
            4.859930010072405,
            4.942003477679278,
            5.022888219951943,
            5.102531604613669,
            5.1808600318987725,
            5.257781951516524,
            5.333190127493782,
            5.406963389920343,
            5.478968036144427,
            5.549058994843754,
            5.61708083403868,
            5.68286867242891,
            5.7462490385023015,
            5.8070407113338725,
            5.8650555694332205,
            5.920099468372509,
            5.971973163605118
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.7604007760491186,
            1.5323131527985578,
            2.3107973150827394,
            2.52699476705717,
            2.7376032184550687,
            2.9428553697333877,
            2.940930112483845,
            2.9377228173519923,
            2.933486399815336,
            2.9284109114776413,
            2.922642702126148,
            2.9162967658129517,
            2.909464995232643,
            2.902221875125691,
            2.89462851502712,
            2.886735572133415,
            2.878585412714318,
            2.870213738986714,
            2.8616508328840524,
            2.8529225205451754,
            2.8440509295901206,
            2.8350550905849263,
            2.8259514196991646,
            2.8167541096858644,
            2.807475449440204,
            2.7981260870951137,
            2.788715248392438,
            2.7792509190392627
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 280.8251359426213,
            "pct_change": 0.3755549197920759
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9523197880676422,
            "pct_change": 0.17618260596609212
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1640154408584626,
            "pct_change": 0.2459999735801988
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.38492217949208,
            "pct_change": 0.08404977438498566
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.2657534246578,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-04-07T00:00:00",
            "x3": 0.736986301369863,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.1534246575343,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-02-25T00:00:00",
            "x3": 0.8493150684931506,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8520547945204,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-07T00:00:00",
            "x3": 0.1506849315068493,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.071232876712,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-01-26T00:00:00",
            "x3": 1.9342465753424658,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.276712328767,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-04-11T00:00:00",
            "x3": 0.726027397260274,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.958904109589,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-12-15T00:00:00",
            "x3": 1.0465753424657533,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.857534246575,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-09T00:00:00",
            "x3": 0.14520547945205478,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2048.019178082192,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-01-07T00:00:00",
            "x3": 1.9863013698630136,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 3250000000.0,
        "tech_funds": {
          "dac": 812500000.0,
          "saf": 812500000.0,
          "gh": 812500000.0,
          "ldes": 812500000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.5497910436731581,
                1.1126936624387849,
                1.6849593764976973,
                1.6983344647821959,
                1.7098607754001371,
                1.7199775199974414,
                1.7289805986641276,
                1.7370790882761198,
                1.7444262725800226,
                1.7511378885382127,
                1.7573034466977968,
                1.7629935664839171,
                1.7682649034680975,
                1.7731635610296101,
                1.7777275146770373,
                1.7819883738802207,
                1.7859726877879507,
                1.789702929817168,
                1.793198251583018,
                1.7964750681937829,
                1.799547518290886,
                1.802427829691856,
                1.8051266129890895,
                1.807653099473347,
                1.8100153356131228,
                1.8122203432198796,
                1.8142742523591475,
                1.8161824123321564
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6535747.484594335,
                10034499.740839737,
                13746054.742722435,
                15935710.208525062,
                18206879.118744377,
                20560913.565865625,
                22999436.234984424,
                25524286.336855385,
                28137484.389932938,
                30841208.593729705,
                33637778.65112706,
                36529644.557862036,
                39519378.81075373,
                42609671.03468119,
                45803324.363053,
                49103253.11759037,
                52512481.47019954,
                56034142.86082154,
                59671480.00711815,
                63427845.38484122,
                67306702.08808115,
                71311625.0003541,
                75446302.22331509,
                79714536.72153835,
                84120248.15048213,
                88667474.8412746,
                93360375.92089689,
                98203233.55010295
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.06757588860881976,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.45097349458106,
                116.56183589539205,
                115.25923934239133,
                114.67085351229281,
                114.15286656520499,
                113.69017060047906,
                113.27197712823474,
                112.89032626927361,
                112.53918526320255,
                112.21387798216047,
                111.91071000306721,
                111.6267141995433,
                111.3594733189821,
                111.1069932756381,
                110.86761076210408,
                110.63992463636032,
                110.42274412719881,
                110.21504915972713,
                110.01595956198452,
                109.82471087794075,
                109.64063516231414,
                109.46314557916169,
                109.29172393801862,
                109.12591052247943,
                108.96529572511577,
                108.80951311845423,
                108.65823367712414,
                108.51116092993131
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.2323122676477601,
                0.46277861437495443,
                0.6908257726915741,
                0.9160491039176527,
                1.1381132755316652,
                1.3567177830187478,
                1.3470704848725872,
                1.3368294512866779,
                1.3260206428381152,
                1.3146641794128018,
                1.302776512332192,
                1.2903717981966323,
                1.2774628052358539,
                1.2640615326797284,
                1.2501796466106205,
                1.2358287940937942,
                1.221020833814547,
                1.2057680075900512,
                1.1900830686919435,
                1.173979377642679,
                1.1574709727638586,
                1.1405726205338256,
                1.1232998493284454,
                1.1056689691224413,
                1.0876970790323728,
                1.0694020641171431,
                1.0508025825245992,
                1.0319180438474538
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1476228180.198633,
                2055293218.8273044,
                2651793514.574887,
                3265649274.7435513,
                3896858674.564865,
                4545450130.415201,
                5122163322.061536,
                5712203300.539009,
                6315584098.000524,
                6932309385.58994,
                7562370355.218889,
                8205744028.778586,
                8862391854.883507,
                9532258510.279175,
                10215270855.611156,
                10911337014.651873,
                11620345558.059454,
                12342164780.360817,
                13076642063.811813,
                13823603326.071663,
                14582852550.820343,
                15354171401.90312,
                16137318922.529587,
                16932031321.629505,
                17738021849.76836,
                18554980767.11576,
                19382575405.883125,
                20220450329.434906
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09790950980725013,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1740286470586674,
                1.1488617901692146,
                1.129898749883253,
                1.1146665337055548,
                1.1019222518034868,
                1.0909541543819083,
                1.0825299879800483,
                1.0749055728194123,
                1.067937648280327,
                1.0615184465253007,
                1.0555649156677633,
                1.0500117374478801,
                1.0448066432253293,
                1.0399071774926123,
                1.0352784039407017,
                1.0308912431770176,
                1.026721244505498,
                1.022747662654184,
                1.018952752986302,
                1.0153212260156015,
                1.011839819922421,
                1.0084969617313089,
                1.0052824959748672,
                1.002187465337394,
                0.9992039317716627,
                0.9963248294457545,
                0.9935438429546892,
                0.9908553057580786
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.21060973237596045,
                0.41961949035977303,
                0.6258379385850419,
                0.828660302274974,
                1.0277424430549316,
                1.222877849735946,
                1.2119495138197178,
                1.2006437290758722,
                1.1890601272353136,
                1.1772730229394284,
                1.1653392554283513,
                1.1533031993290346,
                1.1412000917645455,
                1.129058314096081,
                1.1169010003500828,
                1.1047471982531945,
                1.0926127249263673,
                1.0805108091695463,
                1.0684525813010346,
                1.0564474523513927,
                1.0445034112992349,
                1.0326272608930702,
                1.0208248067100751,
                1.009101010212517,
                0.9974601138270812,
                0.985905743875234,
                0.9744409960332905,
                0.9630685067071064
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10074465.16013649,
                14263232.113484532,
                18585427.31735659,
                23034862.262944102,
                27608514.714263525,
                32305063.179426994,
                36155615.04844251,
                40087861.602529235,
                44102307.892804064,
                48199625.65017202,
                52380620.114485055,
                56646206.5171163,
                60997392.99261799,
                65435267.9057058,
                69960990.29167384,
                74575782.54317862,
                79280924.75081128,
                84077750.28317276,
                88967642.3109401,
                93952031.06032513,
                99032391.6375608,
                104210242.30584304,
                109487143.12477347,
                114864694.8832388,
                120344538.27211602,
                125928353.25476837,
                131617858.60206692,
                137414811.56538585
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08697300728617165,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.28669079570419,
                115.73996408230981,
                114.60231936830613,
                113.70438456088425,
                112.96334885222718,
                112.33268647385158,
                111.88743862395913,
                111.4841529982342,
                111.11548953955598,
                110.77585667750311,
                110.46089993389158,
                110.16716416227725,
                109.89186331505307,
                109.63271920404968,
                109.38784590092105,
                109.15566514019268,
                108.93484327831027,
                108.72424355264575,
                108.5228894015204,
                108.32993591371985,
                108.14464734253767,
                107.96637920541316,
                107.79456389382959,
                107.62869900074624,
                107.46833777374373,
                107.31308124688815,
                107.16257171006274,
                107.01648725264543
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.060199708459297195,
                0.11994908106164708,
                0.17908879348952964,
                0.23751048102431516,
                0.29512579578992915,
                0.3518561655175575,
                0.3493958943987845,
                0.3467800505217796,
                0.34401580668556037,
                0.3411086545261041,
                0.3380630312174604,
                0.334882711988039,
                0.33157106695047284,
                0.32813123538911565,
                0.3245662477008014,
                0.3208791128697998,
                0.3170728824703049,
                0.31315069816123003,
                0.3091158272027961,
                0.3049716890135058,
                0.30072187481915635,
                0.2963701618141844,
                0.2919205228354507,
                0.28737713226468914,
                0.28274436868472774,
                0.2780268146767692,
                0.2732292540603417,
                0.2683566668077041
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1436748611.8127553,
                1974233424.65177,
                2527067633.5224977,
                3095169012.7204943,
                3678528233.4226737,
                4277165823.560282,
                4847579439.07102,
                5431310355.77349,
                6028376591.045322,
                6638787017.0345955,
                7262538964.069531,
                7899616349.934392,
                8549988153.601477,
                9213607127.645807,
                9890408685.78132,
                10580309926.626085,
                11283208769.790936,
                11998983189.791834,
                12727490539.348185,
                13468566957.615208,
                14222026861.55158,
                14987662520.392927,
                15765243714.365484,
                16554517479.509172,
                17355207940.90033,
                18167016236.74762,
                18989620535.832428,
                19822676150.613735
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.031180769773452424,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6633359737086402,
                1.6542867050393784,
                1.6474022422424792,
                1.6418407803786914,
                1.6371705236618077,
                1.6331409166386033,
                1.6298291806856162,
                1.626847817388883,
                1.6241349981114408,
                1.6216448181245835,
                1.6193422840422904,
                1.617200126372694,
                1.6151966946275953,
                1.6133145222999536,
                1.611539321474903,
                1.609859261610777,
                1.608264441370793,
                1.6067464947152976,
                1.6052982923246228,
                1.6039137119729485,
                1.6025874596064684,
                1.6013149282693042,
                1.6000920856659968,
                1.5989153836603853,
                1.5977816847693098,
                1.5966882019601831,
                1.5956324489632971,
                1.5946121989684594
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.5950427511416637,
                1.2382587868149981,
                1.927893474126638,
                2.660040406442786,
                3.435375032834775,
                4.257406760100711,
                4.388590744209393,
                4.5197815452922425,
                4.606689678923848,
                4.692296130086817,
                4.77669535130624,
                4.859930010072405,
                4.942003477679278,
                5.022888219951943,
                5.102531604613669,
                5.1808600318987725,
                5.257781951516524,
                5.333190127493782,
                5.406963389920343,
                5.478968036144427,
                5.549058994843754,
                5.61708083403868,
                5.68286867242891,
                5.7462490385023015,
                5.8070407113338725,
                5.8650555694332205,
                5.920099468372509,
                5.971973163605118
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5228440842.927843,
                7115806637.323762,
                9118995080.452135,
                11239406604.967312,
                13479802915.925941,
                15843763069.496214,
                18274699944.33779,
                20831637186.471764,
                23519119758.878147,
                26341949248.048386,
                29305150429.048214,
                32413946255.970097,
                35673737753.36114,
                39090086673.87657,
                42668699561.514465,
                46415412311.17823,
                50336174589.28572,
                54437033652.68037,
                58724117215.790726,
                63203615092.52861,
                67881759393.81683,
                72764803102.45374,
                77858996879.6507,
                83170563985.53056,
                88705673221.4954,
                94470409827.2721,
                100470744290.75128,
                106712499055.246
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 29,
              "pct_change_gp": 0.11092645825715357,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.788819686642502,
                3.6265750687695153,
                3.5019718642461046,
                3.4009086453030397,
                3.315883233425876,
                3.242449847487627,
                3.1792182144006653,
                3.1225273604333545,
                3.071101075571039,
                3.024000420642884,
                2.980515538087048,
                2.9400974431954157,
                2.9023133344197216,
                2.8668163464639353,
                2.83332450143755,
                2.80160570401385,
                2.7714668174536006,
                2.7427455614393703,
                2.715304402670365,
                2.6890258794104267,
                2.663808975395159,
                2.639566273396505,
                2.616221696081911,
                2.593708694839422,
                2.571968784226223,
                2.5509503458929657,
                2.5306076446523766,
                2.510900013053774
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.07103165728711473,
                0.1493083155207027,
                0.2351610471574174,
                0.3290183664640863,
                0.4313667984982231,
                0.5427372127104424,
                0.5688851617729964,
                0.5961446934166952,
                0.6245733794843625,
                0.6542271378836969,
                0.6851610687857781,
                0.7174299325085135,
                0.7510884066725801,
                0.7861911967921488,
                0.8227930415502742,
                0.860948635942592,
                0.9007124850728416,
                0.9421386950999595,
                0.9852807038768747,
                1.0301909512033656,
                1.076920486835042,
                1.1255185131082228,
                1.1760318581231428,
                1.2285043747504163,
                1.282976260257732,
                1.3394832910605134,
                1.3980559669904242,
                1.4587185595577876
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                356736468.6504463,
                510073107.5740074,
                678265736.1864744,
                862529379.5163941,
                1064228718.1134909,
                1284870174.00091,
                1488529185.1263297,
                1708544743.5525756,
                1946178679.798753,
                2202790839.590897,
                2479845402.0233226,
                2778917733.3928366,
                3101701736.6528873,
                3450017672.6528635,
                3825820436.814448,
                4231208277.20546,
                4668431938.865096,
                5139904215.59241,
                5648209884.65057,
                6196115992.127209,
                6786582447.043236,
                7422772870.646334,
                8108065633.532364,
                8846064997.143827,
                9640612257.614365,
                10495796768.668673,
                11415966696.158424,
                12405739329.643854
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.1723272242487354,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.222473904976716,
                2.1189102442690277,
                2.0404854671806087,
                1.977058985543157,
                1.923554906379738,
                1.8770816014446146,
                1.8417466146946202,
                1.8093822504160597,
                1.779454147866358,
                1.751559526106485,
                1.7253876791205425,
                1.7006942108747871,
                1.6772836617079496,
                1.6549974547921964,
                1.6337053253327767,
                1.6132990939449547,
                1.5936880567200615,
                1.574795514482282,
                1.5565561202733844,
                1.5389138246936727,
                1.5218202648824144,
                1.5052334873438467,
                1.4891169252213978,
                1.4734385717803926,
                1.4581703068179386,
                1.4432873434464706,
                1.4287677704928619,
                1.4145921714886125
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.1155697594591038,
                0.2802821266989871,
                0.48063304333485135,
                0.7072285579800932,
                0.9531437284098108,
                1.2130418700482621,
                1.2708705221731895,
                1.3189676773740895,
                1.3586882985115976,
                1.3911319735780043,
                1.4172082557465404,
                1.4376814082638898,
                1.4532020213456123,
                1.464329971644144,
                1.4715514981477325,
                1.4752921722119439,
                1.4759269338071888,
                1.4737879870243158,
                1.4691711038203565,
                1.4623407241105668,
                1.45353413132512,
                1.4429649083596134,
                1.430825825759628,
                1.4172912770467567,
                1.402519348947477,
                1.3866535941267424,
                1.36982455961645,
                1.3521511127444183
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1378894.7617697204,
                2576258.652381086,
                4068348.564052706,
                5822777.213002254,
                7815219.248681657,
                10026607.679501401,
                12163181.39660364,
                14451400.101896532,
                16883307.361358326,
                19451919.69743594,
                22151033.705411136,
                24975082.966303278,
                27919029.423902333,
                30978279.26246103,
                34148616.61533821,
                37426150.52092082,
                40807271.90150637,
                44288618.249525346,
                47867044.32696925,
                51539597.6177664,
                55303497.58141466,
                59156117.97934602,
                63094971.709380075,
                67117697.70565955,
                71222049.55350327,
                75405885.53885032,
                79667159.90615213,
                84003915.14078522
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.30306355622668196,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                351.3765154669434,
                316.5600814747797,
                294.15128495905446,
                278.1726535387014,
                266.0246255158218,
                256.37473588727835,
                249.27202209911997,
                243.19984334648123,
                237.9276889184822,
                233.29156629960602,
                229.17118342719886,
                225.47601777833012,
                222.13645463831665,
                219.09795062665154,
                216.31707343858204,
                213.75874434400754,
                211.39427424947817,
                209.19993675382412,
                207.15591280775809,
                205.24549771087575,
                203.4544966545177,
                201.77075798243808,
                200.1838085278899,
                198.68456562616439,
                197.265107429855,
                195.91848805626324,
                194.6385875666031,
                193.419989266995
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.10003313938439533,
                0.24336211929492638,
                0.4180597383404338,
                0.6158461503421966,
                0.8306276880190538,
                1.0577134372699064,
                1.108610202056997,
                1.150951385635723,
                1.1859311469686453,
                1.2145178058496164,
                1.2375118084825245,
                1.2555853738488296,
                1.26931047799486,
                1.279179156395368,
                1.2856185911225577,
                1.2890025618237275,
                1.289660300695432,
                1.2878834545644613,
                1.2839316404728578,
                1.2780369382420205,
                1.2704075671463235,
                1.2612309276232092,
                1.2506761424895532,
                1.2388961989326215,
                1.2260297688192439,
                1.2122027670601911,
                1.1975296948402385,
                1.182114804596284
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1364509.3531670305,
                2541169.514417385,
                4007194.786542887,
                5730857.990698309,
                7688318.8646090515,
                9860885.778415041,
                11984825.67775622,
                14261103.259390676,
                16681673.042865627,
                19239480.913520794,
                21928266.308144934,
                24742415.765927278,
                27676851.94657289,
                30726947.810908083,
                33888459.08147523,
                37157470.26268497,
                40530350.9059173,
                44003719.74288634,
                47574414.9509332,
                51239469.26024944,
                54996088.93001091,
                58841635.84935204,
                62773612.18704269,
                66789647.13864617,
                70887485.4140589,
                75064977.18009165,
                79320069.22806238,
                83650797.17943121
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3167400786851325,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                402.97275098512483,
                360.29339789669825,
                332.752614210917,
                313.0898778570467,
                298.1301217472086,
                286.24121557915475,
                277.39583659131966,
                269.84440460273646,
                263.29534428794517,
                257.5417860938735,
                252.43233027979588,
                247.85326243456336,
                243.7172749972899,
                239.9560584249544,
                236.51528532389378,
                233.35112476877106,
                230.4277640127858,
                227.7156105728999,
                225.1899643342997,
                222.83002096156773,
                220.61811309709955,
                218.53912502793057,
                216.5800357817742,
                214.72955859392894,
                212.9778535835771,
                211.31629667573756,
                209.73729218747874,
                208.23411963854468
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9746656.728222033,
      "catalyzed_erp": 333.44767243001684
    },
    "3500": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.23228850436862558,
            0.5630534057143559,
            0.965245239467595,
            1.4200408046172641,
            1.9135604234533736,
            2.4351058071134073,
            2.5510068810913973,
            2.6474001289000304,
            2.7270005990376918,
            2.792011918670023,
            2.8442570767387583,
            2.885268002387801,
            2.9163488725556155,
            2.9386220917539143,
            2.9530624939431394,
            2.9605233238039412,
            2.9617563431977523,
            2.957427650746033,
            2.9481303130579795,
            2.934394584926757,
            2.9166962776653165,
            2.89546368540526,
            2.871083374093077,
            2.843905063000106,
            2.814245774543357,
            2.782393388015045,
            2.748609703680266,
            2.7131331010062913
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.3904911360585163,
            0.7862779974026761,
            1.1869389963790804,
            1.5923869089889147,
            2.0026908823688703,
            2.418014874383151,
            2.433078693127852,
            2.4485336704447143,
            2.4644767778702947,
            2.4809952813708454,
            2.4981705868555593,
            2.5160806162728706,
            2.534801311892227,
            2.554407593674904,
            2.574973954535958,
            2.596574802247881,
            2.6192846135927073,
            2.643177940765558,
            2.6683292943716967,
            2.694812917373556,
            2.7227024578512102,
            2.7520705440533186,
            2.78298826221968,
            2.8155245355777176,
            2.8497454014823553,
            2.8857131827153166,
            2.9234855484167834,
            2.9631144599008747
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.6349839384823645,
            1.321348857314555,
            2.057234779996227,
            2.8384772351201066,
            3.665798583225725,
            4.542944239085372,
            4.682906865974846,
            4.822878295332961,
            4.915598526659877,
            5.006930566147643,
            5.096974879494866,
            5.1857767589892525,
            5.273339621879548,
            5.359633946363105,
            5.444603457168305,
            5.528169525736739,
            5.610234384519246,
            5.690683540256395,
            5.769387640839569,
            5.846203968848911,
            5.9209776825603635,
            5.9935428908243304,
            6.063723625050954,
            6.131334755670975,
            6.19618288921718,
            6.258067274108941,
            6.316780737247653,
            6.372110668903803
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.8119057198385689,
            1.6358011110603035,
            2.4665440587702663,
            2.698041742612566,
            2.9235902265044444,
            3.1434268515327495,
            3.1411950131174624,
            3.137613960344245,
            3.1329490740029087,
            3.1274000353046962,
            3.1211207798844014,
            3.1142323762503894,
            3.106831649083547,
            3.098997134993842,
            3.090793305994655,
            3.082273633877987,
            3.073482858358914,
            3.0644586957542996,
            3.055233146308913,
            3.0458335086387756,
            3.036283176805602,
            3.026602273733447,
            3.0168081597561223,
            3.0069158448398348,
            2.9969383255897872,
            2.986886862908825,
            2.9767712124752803,
            2.9665998173330212
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 280.29303844061974,
            "pct_change": 0.37673809617050263
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9509669772788905,
            "pct_change": 0.17735287363518232
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.163266249615556,
            "pct_change": 0.24626101155907804
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.30136814338385,
            "pct_change": 0.08515277105660252
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.213698630137,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-03-19T00:00:00",
            "x3": 0.7890410958904109,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.0931506849315,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-02-03T00:00:00",
            "x3": 0.9095890410958904,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.841095890411,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-03T00:00:00",
            "x3": 0.16164383561643836,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.9424657534246,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-12-10T00:00:00",
            "x3": 2.063013698630137,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.2246575342465,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-03-23T00:00:00",
            "x3": 0.7780821917808219,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.882191780822,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-11-17T00:00:00",
            "x3": 1.1232876712328768,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8465753424657,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-05T00:00:00",
            "x3": 0.15616438356164383,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.890410958904,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-11-21T00:00:00",
            "x3": 2.115068493150685,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 3500000000.0,
        "tech_funds": {
          "dac": 875000000.0,
          "saf": 875000000.0,
          "gh": 875000000.0,
          "ldes": 875000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.5860624109496256,
                1.185881189745499,
                1.795563351753223,
                1.8096533772873502,
                1.8218109997322973,
                1.8324921150446767,
                1.842004642159797,
                1.8505666003221466,
                1.858338103768926,
                1.86544022143043,
                1.8719667025724434,
                1.8779915886851977,
                1.8835743347903346,
                1.8887633605159866,
                1.8935985766955825,
                1.8981132235984692,
                1.9023352346829028,
                1.906288265883357,
                1.9099924844347564,
                1.9134651817289647,
                1.916721255353093,
                1.9197735924632504,
                1.922633377809281,
                1.9253103434799677,
                1.927812973149339,
                1.9301486703720765,
                1.9323238982945248,
                1.9343442963634758
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6637083.9673620025,
                10245298.106661938,
                14074474.418047044,
                16276475.734072711,
                18560176.10330402,
                20926973.40935175,
                23378526.24021379,
                25916703.356384005,
                28543550.606315855,
                31261268.649353154,
                34072197.66594196,
                36978806.75112429,
                39983686.54571123,
                43089544.16706086,
                46299199.81278379,
                49615584.60797032,
                53041739.395043805,
                56580814.25114292,
                60236068.57647611,
                64010871.637814716,
                67908703.48011217,
                71933156.1399523,
                76087935.10962385,
                80376861.01175314,
                84803871.45272785,
                89373023.02939206,
                94088493.46822861,
                98954583.87985998
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.0688496079411735,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.38079741941196,
                116.47364751561226,
                115.16426775091267,
                114.58781902000095,
                114.07910267204896,
                113.62380076671067,
                113.21163326872747,
                112.83498180038252,
                112.48805114394557,
                112.16633531862477,
                111.86626484915385,
                111.58496635343096,
                111.32009423609036,
                111.06971008960286,
                110.8321945003104,
                110.60618137944401,
                110.3905082752184,
                110.18417823223761,
                109.98633013256884,
                109.79621535973915,
                109.61317924019569,
                109.43664613907517,
                109.26610738275915,
                109.1011113907853,
                108.94125555107385,
                108.78617948291402,
                108.6355594137478,
                108.48910345670309
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.24953036923491367,
                0.49706229621156434,
                0.7419856460581977,
                0.9838677154185943,
                1.2223491402377433,
                1.4571075501305766,
                1.4467217126114724,
                1.4356990969189651,
                1.4240673436779951,
                1.4118479114750973,
                1.399058375603658,
                1.385713883886174,
                1.371828118174442,
                1.3574139520206958,
                1.3424839138446962,
                1.3270505209547236,
                1.3111265249037627,
                1.2947250939913948,
                1.2778599498132572,
                1.2605454691700122,
                1.2427967590597697,
                1.2246297101264407,
                1.2060610323646888,
                1.1871082758153753,
                1.167789838259171,
                1.1481249614124156,
                1.128133716786966,
                1.1078369821327507
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1481932606.003257,
                2067002827.7768133,
                2669807618.431291,
                3290267705.1975293,
                3928382344.4743547,
                4584181017.61314,
                5161798327.132375,
                5752743651.832646,
                6357030399.369289,
                6974661454.875817,
                7605627097.946147,
                8249903334.962412,
                8907450511.889294,
                9578212127.479715,
                10262113798.236649,
                10959062345.230648,
                11668944984.477797,
                12391628609.977072,
                13126959163.327793,
                13874761087.042706,
                14634836860.804401,
                15406966621.327494,
                16190907867.403393,
                16986395252.258131,
                17793140465.638382,
                18610832208.118496,
                19439136260.034554,
                20277695647.230957
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.0985050937598695,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.173731780864776,
                1.1484351028579607,
                1.1293997843972905,
                1.1141214995617736,
                1.1013451556440552,
                1.0903533143121176,
                1.0819889269276097,
                1.0744131374980455,
                1.0674855483817194,
                1.0611003567152373,
                1.0551759033340689,
                1.0496478841754788,
                1.0444647863934782,
                1.0395847290419131,
                1.0349732202504993,
                1.030601529703026,
                1.0264454846144406,
                1.0224845636527877,
                1.0187012045950052,
                1.0150802679989783,
                1.0116086165579825,
                1.0082747814546713,
                1.0050686949916898,
                1.00198147430956,
                0.9990052449107719,
                0.9961329955097568,
                0.9933584577624421,
                0.9906760059245551
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.22584330888894327,
                0.44991992131480435,
                0.6709807070170434,
                0.888388365325216,
                1.1017792267721473,
                1.3109347364880728,
                1.2991903709576653,
                1.2870473600220986,
                1.2746109702339827,
                1.2619598138742663,
                1.249154077311958,
                1.2362407875651917,
                1.2232573142932124,
                1.2102337744778555,
                1.1971947292990723,
                1.1841604102795176,
                1.171147623676011,
                1.1581704298709428,
                1.1452406618741564,
                1.1323683269098106,
                1.1195619214525088,
                1.1068286812701964,
                1.094174781946841,
                1.0816055013598673,
                1.0691253524404485,
                1.0567381925367485,
                1.0444473141807553,
                1.0322555209695456
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10135312.576062847,
                14388004.169733068,
                18777066.796510898,
                23296261.00269378,
                27942556.554967,
                32714649.00315695,
                36573955.42157894,
                40515001.475929774,
                44538312.10040999,
                48644574.74653724,
                52834607.457373604,
                57109336.167827226,
                61469778.171229005,
                65917029.8335715,
                70452257.31079702,
                75076689.4377777,
                79791612.2193428,
                84598364.52419594,
                89498334.69642627,
                94492957.87705743,
                99583713.88221845,
                104772125.52289905,
                110059757.27890077,
                115448214.25980654,
                120939141.40076452,
                126534222.85211228,
                132235181.53038403,
                138043778.80477208
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08768687288079824,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.25938458801875,
                115.70198824904242,
                114.55890672545935,
                113.65777345524758,
                112.91467421802774,
                112.28259004137736,
                111.84226854320187,
                111.4430162370251,
                111.077712800503,
                110.74092019835574,
                110.42839438562693,
                110.13676162658135,
                109.86329717349379,
                109.60576985300074,
                109.36233038291238,
                109.13142947336823,
                108.91175668527468,
                108.70219405237744,
                108.50178039644972,
                108.30968351472298,
                108.12517824883143,
                107.947629007091,
                107.77647570008251,
                107.61122232175693,
                107.4514276021226,
                107.29669729750718,
                107.146677786693,
                107.00105071690471
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.06461735646824195,
                0.1287491153106778,
                0.192225162075029,
                0.25492946151358736,
                0.31676729602376713,
                0.37765438126739276,
                0.37501057658510634,
                0.3721999181899709,
                0.3692300694312163,
                0.3661069026414812,
                0.36283516662372944,
                0.3594189050189191,
                0.3558617302155388,
                0.35216700932456724,
                0.3483379943529605,
                0.3443779156154211,
                0.34029005010094676,
                0.33607777221246987,
                0.3317445917121526,
                0.32729418209372246,
                0.32273040156614546,
                0.3180573081692618,
                0.3132791700844519,
                0.3084004719110795,
                0.303425917462468,
                0.2983604295013571,
                0.2932091467336182,
                0.28797741831041884
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1439499485.2646391,
                1979882692.0417297,
                2535761467.8533525,
                3107053680.1401243,
                3693750521.2566743,
                4295873097.338358,
                4866728085.015002,
                5450901087.095769,
                6048409863.752417,
                6659262939.146726,
                7283457226.425075,
                7920976170.780488,
                8571788232.497903,
                9235845606.024588,
                9913083112.545588,
                10603417227.794117,
                11306745221.576742,
                12022944394.75934,
                12751871405.436417,
                13493361679.938114,
                14247228906.94711,
                15013264614.743078,
                15791237832.739569,
                16580894839.20096,
                17381958997.439335,
                18194130682.96747,
                19017087304.076412,
                19850483418.15042
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.031284083806745,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.663280867147562,
                1.6542063018366873,
                1.6473073555371756,
                1.641736475741936,
                1.6370595581179617,
                1.633024949145812,
                1.6297253871973452,
                1.6267538172166827,
                1.6240490476448397,
                1.6215656031587204,
                1.619268790177245,
                1.6171315547723608,
                1.6151324053587195,
                1.613253995096182,
                1.6114821278766274,
                1.6098050447451506,
                1.6082129009917199,
                1.6066973759447152,
                1.6052513770493129,
                1.6038688121799565,
                1.6025444121560535,
                1.601273590749032,
                1.6000523330684737,
                1.5988771056958708,
                1.5977447836730185,
                1.596652590688877,
                1.5955980497010538,
                1.5945789418800873
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.6349839384823645,
                1.321348857314555,
                2.057234779996227,
                2.8384772351201066,
                3.665798583225725,
                4.542944239085372,
                4.682906865974846,
                4.822878295332961,
                4.915598526659877,
                5.006930566147643,
                5.096974879494866,
                5.1857767589892525,
                5.273339621879548,
                5.359633946363105,
                5.444603457168305,
                5.528169525736739,
                5.610234384519246,
                5.690683540256395,
                5.769387640839569,
                5.846203968848911,
                5.9209776825603635,
                5.9935428908243304,
                6.063723625050954,
                6.131334755670975,
                6.19618288921718,
                6.258067274108941,
                6.316780737247653,
                6.372110668903803
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5231379719.549164,
                7122044743.572762,
                9128895693.62103,
                11253346100.807756,
                13498175536.1145,
                15866983773.27268,
                18299134067.135365,
                20857327876.01882,
                23546112879.45133,
                26370292995.280304,
                29334895051.352306,
                32445143838.090023,
                35706442035.74589,
                39124352891.49216,
                42704584291.370476,
                46452973321.22815,
                50375470683.453926,
                54478124508.11342,
                58767063209.32718,
                63248477114.00445,
                67928598644.27859,
                72813680875.70386,
                77909974325.84172,
                83223701855.7836,
                88761031592.76424,
                94528047806.91815,
                100530719700.54771,
                106774868094.797
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 30,
              "pct_change_gp": 0.11104416566194009,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.788516157952311,
                3.6261256658143615,
                3.5014380885936394,
                3.400320014160209,
                3.315255862526055,
                3.2417933548300613,
                3.178633465182577,
                3.121999717485066,
                3.0706199241067824,
                3.023557831089122,
                2.980105439432673,
                2.9397150902594644,
                2.9019549523449073,
                2.866478886323153,
                2.8330054678652807,
                2.8013030308914137,
                2.7711787764737266,
                2.742470693673016,
                2.7150414665467464,
                2.6887738106173362,
                2.6635668555751684,
                2.6393333054275367,
                2.615997184351466,
                2.593492029351454,
                2.5717594276818567,
                2.5507478231019642,
                2.530411533788297,
                2.5107099383800544
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.07634341035536067,
                0.16046658588043394,
                0.2527281882458536,
                0.35358973205673283,
                0.46357444610736004,
                0.583252942985182,
                0.6113464039312735,
                0.6406346553357783,
                0.6711793647610833,
                0.703040467254267,
                0.7362770446281717,
                0.7709478273677773,
                0.8071114635022464,
                0.8448266323296411,
                0.8841520463383008,
                0.9251463656777366,
                0.967868038587998,
                1.0123750745616933,
                1.0587247528462869,
                1.1069732661098213,
                1.1571752972252953,
                1.209383525757616,
                1.2636480597705397,
                1.3200157878512628,
                1.3785296457607161,
                1.439227791801544,
                1.5021426848961992,
                1.5673000594577053
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                358489912.90987813,
                513870445.37756604,
                684429013.4838966,
                871416645.4977517,
                1076238252.1110022,
                1300444852.3767917,
                1505356560.2516766,
                1726721399.2670805,
                1965808752.4367163,
                2223986497.863148,
                2502727371.4340496,
                2803615861.1169467,
                3128355594.539971,
                3478777196.872658,
                3856846602.9995246,
                4264673809.9681654,
                4704522054.361927,
                5178817395.426351,
                5690158678.860892,
                6241327848.322599,
                6835300561.884835,
                7475257058.8738785,
                8164593208.537171,
                8906931655.712816,
                9706132959.884378,
                10566306602.52487,
                11491821713.269829,
                12487317338.040718
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.17354464714211193,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2210135015859187,
                2.1168237198565474,
                2.038053953811106,
                1.974407184164851,
                1.9207477525133059,
                1.8741567657920613,
                1.8390807821034678,
                1.8069269900142082,
                1.7771733635632767,
                1.7494256356149036,
                1.7233791606147661,
                1.698793970602709,
                1.675477914438464,
                1.6532749357049357,
                1.6320567225030453,
                1.611716631461535,
                1.592165183011607,
                1.573326665614049,
                1.5551365376329833,
                1.5375394127759285,
                1.5204874790721554,
                1.5039392444394524,
                1.4878585314075288,
                1.4722136641390002,
                1.456976805450928,
                1.4421234119946902,
                1.4276317833565983,
                1.4134826864400754
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.12452683311901637,
                0.30138680914016075,
                0.5162227908080377,
                0.7590336450397898,
                1.0224388749805535,
                1.3007466028401808,
                1.3623700131327294,
                1.4136155263159869,
                1.4559257581732028,
                1.4904720472026647,
                1.518223718172602,
                1.5399955521470863,
                1.5564813527390913,
                1.5682783364465305,
                1.5759052827597717,
                1.579816326897694,
                1.5804116372365207,
                1.5780458186963355,
                1.573034624226522,
                1.5656603864953507,
                1.556176466358245,
                1.544810935470057,
                1.5317696547498219,
                1.5172388707096205,
                1.5013874229205657,
                1.4843686346708977,
                1.4663219433161814,
                1.447374314846467
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1388697.4120485866,
                2600070.02605042,
                4109744.6043450716,
                5884897.659426822,
                7900882.241559361,
                10138381.918407049,
                12283398.327206265,
                14579599.49561241,
                17019087.76429866,
                19594926.347063422,
                22300949.667631835,
                25131622.472911105,
                28081932.75668551,
                31147308.751785215,
                34323553.45945966,
                37606792.22142665,
                40993430.169233605,
                44480117.27524374,
                48063719.33874866,
                51741293.66644866,
                55510068.509611025,
                59367425.53959548,
                63310884.80466418,
                67338091.73113994,
                71446805.82265338,
                75634890.78045829,
                79900305.8212356,
                84241098.01046768
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3040629883294412,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                350.95266447589387,
                316.08476772207905,
                293.6814411268634,
                277.71988404706076,
                265.5905353765122,
                255.9583520965476,
                248.91892812225652,
                242.89528926069886,
                237.6613400091477,
                233.05593758890197,
                228.9607025466122,
                225.28643869792975,
                221.96447701849752,
                218.94096471399658,
                216.17298435336238,
                213.6258481258852,
                211.2711685579287,
                209.0854553242247,
                207.0490765363182,
                205.14547759980755,
                203.36058536022145,
                201.68234770002547,
                200.10037360782582,
                198.60564877005658,
                197.19030862499898,
                195.84745562841618,
                194.57101088731903,
                193.35559276522432
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.10776167124960921,
                0.2616665965741951,
                0.44902244865955737,
                0.6610071595774745,
                0.8911215484728202,
                1.1343592042732265,
                1.1886368679586679,
                1.2337846025840438,
                1.271074840864489,
                1.3015398714673585,
                1.3260333585661566,
                1.3452724502407147,
                1.359867519816524,
                1.3703437553073838,
                1.3771572111833676,
                1.3807069969062475,
                1.3813447059612318,
                1.3793818320496976,
                1.3750956888314574,
                1.3687341984314063,
                1.3605198113070718,
                1.3506527499352028,
                1.3393137193432554,
                1.3266661922904852,
                1.3128583516227912,
                1.2980247533441474,
                1.282287760364085,
                1.2657587861598245
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1373169.7429727542,
                2562315.4076506854,
                4044070.1185738747,
                5786306.3722511865,
                7764889.94969372,
                9960901.884374397,
                12092483.293228302,
                14375982.836100392,
                16803409.049726527,
                19367750.654510252,
                22062781.738649383,
                24882917.352569647,
                27823103.960244246,
                30878734.650513012,
                34045582.355423056,
                37319746.43836426,
                40697609.392302044,
                44175801.30854609,
                47751170.40536694,
                51420758.34453157,
                55181779.37569357,
                59031602.57400751,
                62967736.60180846,
                66987816.54836435,
                71089592.49456753,
                75270919.52026792,
                79529748.92657551,
                83864120.48800837
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.31769980200687953,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                402.5064292172261,
                359.76559441803715,
                332.22833558747186,
                312.58314498394583,
                297.64332545195725,
                285.7736127997562,
                276.9999401996838,
                269.50337048670974,
                262.9974065892448,
                257.27844484554356,
                252.19727087444863,
                247.64168192599047,
                243.52544594305724,
                239.78103730129612,
                236.3547121152547,
                233.20308185565264,
                230.29067467960033,
                227.58816462475022,
                225.07106265843294,
                222.71873358070187,
                220.51364699615974,
                218.44079917038079,
                216.48726148934048,
                214.64182397881532,
                212.89471107874522,
                211.23735295925528,
                209.66219997540284,
                208.1625709496529
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9815903.053302022,
      "catalyzed_erp": 356.5642387658482
    },
    "3750": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.24892701422839308,
            0.6022285450435731,
            1.0312791604641314,
            1.516133284880425,
            2.042068777521731,
            2.59773029014125,
            2.7206479547313407,
            2.8228620913622335,
            2.9072497737575236,
            2.976147288992662,
            3.0314881587205287,
            3.074897746692666,
            3.1077601651101534,
            3.131266896531768,
            3.146452979877701,
            3.15422451152301,
            3.1553799367300273,
            3.1506268076384316,
            3.1405951682278372,
            3.125848387649718,
            3.1068920329430822,
            3.084181214653908,
            3.05812672762551,
            3.029100230256553,
            2.997438648234527,
            2.963447946385548,
            2.9274063813142517,
            2.8895673235799224
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.41729673001596096,
            0.8402320316455975,
            1.2683653524223018,
            1.7016071486842574,
            2.1400330344661587,
            2.583819182192149,
            2.599898674082385,
            2.6163982973711137,
            2.633421204298039,
            2.6510603312518404,
            2.669402447224187,
            2.6885306572284158,
            2.708525987655443,
            2.7294683948833702,
            2.751437391428814,
            2.7745124040948057,
            2.7987729331507616,
            2.8242985546772035,
            2.8511687916741932,
            2.879462869023721,
            2.9092593605387584,
            2.940635731699455,
            2.973667778514973,
            3.0084289607556203,
            3.0449896262827867,
            3.0834161222101155,
            3.1237697880517326,
            3.1661058258007984
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.6742065463417384,
            1.4029410020816182,
            2.184241390414564,
            3.0136903089456855,
            3.892056373684773,
            4.8233168150286225,
            4.971896917135469,
            5.120487952326101,
            5.2189131259863535,
            5.315865257201654,
            5.41145067761109,
            5.505717228417652,
            5.598668350365541,
            5.690272553019719,
            5.780469970392843,
            5.869177024944901,
            5.956289835008355,
            6.0416867734480935,
            6.125230447249451,
            6.206769281539499,
            6.286138836001009,
            6.363162945323784,
            6.437654750682091,
            6.509417672482132,
            6.57824636268624,
            6.643927666519032,
            6.706241616971319,
            6.764962480646099
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.8625312384565496,
            1.7374963208654814,
            2.619563528449936,
            2.866195945301626,
            3.1065252734132094,
            3.3407927460498863,
            3.3382400169313455,
            3.334273876698899,
            3.3291715569118097,
            3.323141917392606,
            3.3163461399293173,
            3.3089111060559495,
            3.300938367506027,
            3.292510349483047,
            3.2836947546305693,
            3.2745477615431557,
            3.2651163943552444,
            3.255440309218515,
            3.245553162260209,
            3.2354836717402646,
            3.2252564531388255,
            3.2148926831089772,
            3.2044106329475897,
            3.1938261010876197,
            3.1831527669274653,
            3.172402482430678,
            3.1615855142728693,
            3.1507107462928223
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 279.7729594895653,
            "pct_change": 0.3778945480002704
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9496389381475231,
            "pct_change": 0.1785017122396999
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1625320209695698,
            "pct_change": 0.24651683617475273
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.22128347078564,
            "pct_change": 0.08620996866712781
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.1643835616437,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-03-01T00:00:00",
            "x3": 0.8383561643835616,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2049.0328767123287,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-01-12T00:00:00",
            "x3": 0.9698630136986301,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.832876712329,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-31T00:00:00",
            "x3": 0.16986301369863013,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.8164383561643,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-10-25T00:00:00",
            "x3": 2.1890410958904107,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.1726027397262,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-03-04T00:00:00",
            "x3": 0.8301369863013699,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.8109589041096,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-10-22T00:00:00",
            "x3": 1.1945205479452055,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8383561643836,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-11-02T00:00:00",
            "x3": 0.1643835616438356,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.7643835616439,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-10-06T00:00:00",
            "x3": 2.241095890410959,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 3750000000.0,
        "tech_funds": {
          "dac": 937500000.0,
          "saf": 937500000.0,
          "gh": 937500000.0,
          "ldes": 937500000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.6215873527406529,
                1.257545508506168,
                1.9038466684401507,
                1.9186223271959164,
                1.9313866294273263,
                1.9426109666806561,
                1.9526144800024852,
                1.9616235741537915,
                1.969804791148994,
                1.977284232316343,
                1.9841596574441844,
                1.9905083527204521,
                1.9963924323424052,
                2.00186251957626,
                2.006960368978318,
                2.0117207762583975,
                2.0161729965483155,
                2.020341815774595,
                2.024248372400182,
                2.027910796318465,
                2.0313447117087065,
                2.034563637211264,
                2.0375793076371473,
                2.0404019349448483,
                2.043040421773029,
                2.0455025374712803,
                2.047795064294644,
                2.0499239195754164
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6736335.054977933,
                10451709.251270253,
                14396003.29282226,
                16610047.637066469,
                18905979.24737282,
                21285238.837740738,
                23749518.84240572,
                26300716.063009184,
                28940900.51742451,
                31672294.39349241,
                34497257.57334659,
                37418277.58327815,
                40437962.61607223,
                43559036.743763745,
                46784336.72921151,
                50116810.029700704,
                53559513.70664006,
                57115614.03638173,
                60788386.67259971,
                64581217.24930966,
                68497602.34103078,
                72541150.71633899,
                76715584.8354787,
                81024742.55335273,
                85472578.99716525,
                90063168.59398413,
                94800707.2280391,
                99689514.51104437
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.07005924170758768,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.31326831172844,
                116.389324740049,
                115.07376394773823,
                114.50848121257229,
                114.00847482738665,
                113.56014438720342,
                113.15367493231912,
                112.78176223553747,
                112.43883063449405,
                112.12053204225077,
                111.8234133312997,
                111.54468867101446,
                111.2820795635586,
                111.03369985625291,
                110.79797141434554,
                110.57356117422628,
                110.3593334088222,
                110.15431301272912,
                109.95765690007947,
                109.76863146287698,
                109.58659461715561,
                109.41098136446358,
                109.24129207693947,
                109.07708291420393,
                108.91795792465554,
                108.7635624893111,
                108.61357784440764,
                108.46771647736728
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.2666576776934869,
                0.5311632952386632,
                0.7928706238375267,
                1.0513192636234532,
                1.306126045801478,
                1.5569469011741592,
                1.5458232585314318,
                1.5340201376146658,
                1.521566822421876,
                1.5084860857502533,
                1.4947966101068064,
                1.4805145228558645,
                1.4656544112011085,
                1.4502300170805926,
                1.4342547269122592,
                1.417741925005396,
                1.4007052532669777,
                1.3831588044301881,
                1.365117266629058,
                1.346596031262851,
                1.3276112723128977,
                1.308180002783965,
                1.2883201122920156,
                1.2680503886905181,
                1.24739052585947,
                1.2263611192523356,
                1.20498365043043,
                1.183280461563425
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1487606951.6818314,
                2078650041.3199224,
                2687724927.7232637,
                3314752890.189542,
                3959734257.6558867,
                4622699551.4680395,
                5201214703.269443,
                5793059054.035897,
                6398245379.013744,
                7016775773.452586,
                7648639603.50381,
                8293811860.902453,
                8952251791.342281,
                9623901719.178858,
                10308686021.385586,
                11006510221.826548,
                11717260188.158094,
                12440801420.85072,
                13176978428.510426,
                13925614186.787556,
                14686509680.235685,
                15459443527.858488,
                16244171693.968828,
                17040427286.517336,
                17847920445.316868,
                18666338322.654472,
                19495345158.68603,
                20334582453.779953
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09909149209846409,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.173437695194278,
                1.1480132567852264,
                1.1289070738880822,
                1.1135837424861021,
                1.100776115905898,
                1.0897611516170473,
                1.0814552575365735,
                1.0739271168756985,
                1.0670391001591053,
                1.0606873089260533,
                1.0547914364732893,
                1.0492881652690136,
                1.0441267187034045,
                1.0392657762232158,
                1.0346712799120865,
                1.0303148406247762,
                1.0261725574271319,
                1.0222241282125435,
                1.0184521694548296,
                1.0148416887906264,
                1.011379671034551,
                1.0080547495794512,
                1.0048569428971617,
                1.0017774412579201,
                0.998808432606408,
                0.995942959272258,
                0.9931747991843907,
                0.9904983667239637
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.24094388571589662,
                0.47995081235931353,
                0.7157168600097854,
                0.9475736181057098,
                1.175138643985883,
                1.39818177936923,
                1.3856255369288604,
                1.3726503025451071,
                1.359366765762816,
                1.3458576850762627,
                1.3321864824851328,
                1.3184027533354974,
                1.3045459351636217,
                1.2906478299067872,
                1.2767343856522515,
                1.2628269852847582,
                1.2489433978069286,
                1.2350984934439198,
                1.221304789860027,
                1.2075728754217998,
                1.193911741430119,
                1.1803290458977131,
                1.1668313253104423,
                1.1534241661427715,
                1.1401123451544362,
                1.1268999449593977,
                1.113790449978225,
                1.100786826717406
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10195628.751878979,
                14511666.306165561,
                18966980.117201477,
                23555284.143517014,
                28273542.228938427,
                33120467.937360268,
                36988432.31658482,
                40938183.11341114,
                44970264.40125143,
                49085378.81488486,
                53284356.79943433,
                57568134.68511525,
                61937738.68104113,
                66394272.958406515,
                70938910.63218983,
                75572886.84363942,
                80297493.39555956,
                85114074.55556542,
                90024023.75170621,
                95028780.95960057,
                100129830.63238283,
                105328700.06179808,
                110626958.08550985,
                116026214.07524973,
                121528117.15495399,
                127134355.60893062,
                132846656.44837682,
                138666785.11091667
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08838346923235531,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.23249618092075,
                115.66471001229228,
                114.516368673998,
                113.61215636798687,
                112.86707923170395,
                112.2336378028752,
                111.79807892732882,
                111.40273373891655,
                111.04069065527113,
                110.70665798131472,
                110.39649722719231,
                110.10691265962429,
                109.83523838719881,
                109.57928845970117,
                109.33724890135936,
                109.10759837856955,
                108.88904886902958,
                108.6805005857358,
                108.48100724434518,
                108.28974895774971,
                108.10601083753791,
                107.92916592239222,
                107.75866142699682,
                107.5940075674551,
                107.43476840634881,
                107.28055429584907,
                107.1310155963187,
                106.98583742119104
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.06900602334191604,
                0.13749113959052922,
                0.20527462014684708,
                0.2722328444938612,
                0.33826478167857826,
                0.4032804798547626,
                0.40045394475016904,
                0.39744936614031207,
                0.3942748902751704,
                0.39093676492050683,
                0.38744004673072785,
                0.38378904493656546,
                0.3799876120036651,
                0.37603934108169806,
                0.37194770426569423,
                0.36771615184694806,
                0.3633481849701717,
                0.35884740956749933,
                0.3542175766950236,
                0.3494626126884849,
                0.34458664146141077,
                0.3395940005559379,
                0.3344892520787227,
                0.32927718933893235,
                0.3239628397771158,
                0.31855146463268513,
                0.31304855568741696,
                0.3074598293513959
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1442232312.1371922,
                1985494719.3688424,
                2544397783.5782065,
                3118859477.389708,
                3708871511.5210705,
                4314455562.373172,
                4885748662.551937,
                5470360478.359363,
                6068308511.233233,
                6679600937.382984,
                7304234253.874048,
                7942191435.185179,
                8593440425.38732,
                9257932863.753645,
                9935602982.295918,
                10626366637.564846,
                11330120453.570988,
                12046741061.812613,
                12776084430.290028,
                13517985277.264763,
                14272256568.105326,
                15038689095.283337,
                15817051142.71463,
                16607088236.351112,
                17408522983.332355,
                18221055002.175568,
                19044360946.46956,
                19878094624.378036
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.0313861749344153,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.663226233979359,
                1.6541266722466932,
                1.6472134419069795,
                1.6416332866800278,
                1.636949816185433,
                1.6329102910688567,
                1.6296227212022854,
                1.6266608057542498,
                1.6239639769129646,
                1.6214871803121762,
                1.6191960165873938,
                1.6170636435443784,
                1.6150687257957876,
                1.613194034208496,
                1.6114254630291422,
                1.6097513237928054,
                1.6081618275734757,
                1.606648698410151,
                1.6052048799930174,
                1.6038243098807188,
                1.6025017434291962,
                1.6012326148620288,
                1.600012926466922,
                1.598839159354343,
                1.5977082009329642,
                1.5966172854799379,
                1.5955639450669683,
                1.5945459687486387
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.6742065463417384,
                1.4029410020816182,
                2.184241390414564,
                3.0136903089456855,
                3.892056373684773,
                4.8233168150286225,
                4.971896917135469,
                5.120487952326101,
                5.2189131259863535,
                5.315865257201654,
                5.41145067761109,
                5.505717228417652,
                5.598668350365541,
                5.690272553019719,
                5.780469970392843,
                5.869177024944901,
                5.956289835008355,
                6.0416867734480935,
                6.125230447249451,
                6.206769281539499,
                6.286138836001009,
                6.363162945323784,
                6.437654750682091,
                6.509417672482132,
                6.57824636268624,
                6.643927666519032,
                6.706241616971319,
                6.764962480646099
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5234265723.019299,
                7128170390.898289,
                9138617594.225113,
                11267033756.724583,
                13516216003.080257,
                15889784452.735373,
                18323126019.580715,
                20882553475.77548,
                23572617162.547043,
                26398123282.721497,
                29364100676.487522,
                32475775934.625347,
                35738553360.72664,
                39157997667.23592,
                42739818055.86994,
                46489852779.84376,
                50414053556.762505,
                54518469372.09337,
                58809229324.93708,
                63292524243.82153,
                67974586854.3088,
                72861670320.82025,
                77960025017.59409,
                83275873411.57669,
                88815382965.642,
                94584636995.43195,
                100589603438.43456,
                106836101520.45657
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 30,
              "pct_change_gp": 0.11115952225380794,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7882182838067133,
                3.6256848117973575,
                3.500914605560354,
                3.3997428457996364,
                3.3146408035313613,
                3.2411498281414057,
                3.17806018461467,
                3.1214823648592676,
                3.0701481126707906,
                3.023123799594168,
                2.9797032444452136,
                2.939340085457056,
                2.901603440928278,
                2.8661478820025534,
                2.832692526247214,
                2.801006127847646,
                2.770896218663775,
                2.742201051565674,
                2.7147835234986455,
                2.688526523615015,
                2.663329324584816,
                2.639104749189064,
                2.6157769210606188,
                2.5932794610939265,
                2.5715540277789786,
                2.5505491257385215,
                2.5302191254457704,
                2.510523450710351
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.08163302898055803,
                0.17157759681640497,
                0.2702201084379281,
                0.37805504056694295,
                0.4956422069861023,
                0.623591801163227,
                0.6536214708007843,
                0.6849287936161357,
                0.7175794916009932,
                0.7516374805810802,
                0.7871657903866526,
                0.824227089435986,
                0.8628839644506695,
                0.9031990367210797,
                0.9452349602508606,
                0.9890543272424617,
                1.0347194949136125,
                1.0822923406795162,
                1.1318339483501116,
                1.183404225072385,
                1.2370614467644498,
                1.2928617283595523,
                1.350858414144235,
                1.41110138272617,
                1.4736362606462008,
                1.5385035383250945,
                1.6057375819338853,
                1.6753655348859775
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                360236050.44561297,
                517651700.04356194,
                690565900.189931,
                880265551.482648,
                1088195625.3775852,
                1315951539.2341683,
                1522110154.1764245,
                1744818049.9936843,
                1985352119.1281712,
                2245088227.673601,
                2525507621.729694,
                2828203861.505798,
                3154890246.492458,
                3507407709.504993,
                3887733163.8990154,
                4297988289.940574,
                4740448744.764077,
                5217553776.355677,
                5731916215.926249,
                6286332815.02231,
                6883794883.765087,
                7527499174.624464,
                8220858941.990258,
                8967515091.329727,
                9771347312.730375,
                10636485071.927382,
                11567318307.317236,
                12568507653.794554
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.17473872729152082,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2195673891676524,
                2.1147637171782985,
                2.035657940375383,
                1.9717977942524836,
                1.9179886355135323,
                1.8712847623526647,
                1.8364607352644868,
                1.804512119880212,
                1.774928729849449,
                1.7473244916141453,
                1.7214006050581736,
                1.6969213812556672,
                1.6736978714398822,
                1.6515764622076812,
                1.6304307369518276,
                1.610155544659455,
                1.5906625964821737,
                1.5718771422524145,
                1.5537354248710826,
                1.5361827045108554,
                1.5191717066247745,
                1.5026613895420677,
                1.4866159561100298,
                1.4710040538525029,
                1.4557981222968512,
                1.4409738563136694,
                1.4265097617344258,
                1.4123867849804002
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.13346718299479107,
                0.3223846506759888,
                0.5515644070191312,
                0.8104123176574778,
                1.0911020694463502,
                1.3875937113740135,
                1.4529283741049621,
                1.5072518240564385,
                1.552092281972897,
                1.5886912330010392,
                1.6180764870834778,
                1.6411122906542521,
                1.6585347854129329,
                1.6709777894640276,
                1.67899199284205,
                1.683059551636133,
                1.6836053906890975,
                1.68100610243626,
                1.6755970565889353,
                1.6676781558983502,
                1.6575185513323856,
                1.645360546496109,
                1.6314228623040736,
                1.6159033908906837,
                1.5989815375599752,
                1.5808202269807534,
                1.5615676334213775,
                1.541358682219388
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1398481.759756999,
                2623760.856390952,
                4150852.031959259,
                5946506.784328814,
                7985764.012197425,
                10249063.168420298,
                12402378.750842694,
                14706428.755162785,
                17153372.73427111,
                19736319.404039398,
                22449140.088889204,
                25286329.962563857,
                28242902.469414756,
                31314307.39703181,
                34496366.20216927,
                37785220.181351274,
                41177288.377689354,
                44669232.986809544,
                48257930.62212317,
                51940448.217562266,
                55714022.64365931,
                59576043.32856681,
                63524037.33427268,
                67555656.45658292,
                71668666.00679645,
                75860935.00126587,
                80130427.53775942,
                84475195.17866069
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.30503912704156966,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                350.53327201929164,
                315.61716326214406,
                293.2206189435045,
                277.27663145112194,
                265.1660971978935,
                255.5515867789039,
                248.57364144937043,
                242.59722585014242,
                237.4004921294001,
                232.82504539777986,
                228.75435397782957,
                225.1005051288988,
                221.79574619374938,
                218.78689434866288,
                216.03153218597498,
                213.4953518872388,
                211.15025940611662,
                208.97299424788798,
                206.94410685259373,
                205.04718894249226,
                203.26828600972223,
                201.59544306204552,
                200.01834928733211,
                198.5280571231651,
                197.11675797374517,
                195.7776015362128,
                194.50454904484985,
                193.2922531464201
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.11545983123360201,
                0.2798438943675843,
                0.47971475344500025,
                0.7057209672229471,
                0.9509667080753806,
                1.2101365787672365,
                1.2677195806263784,
                1.315610267305795,
                1.3551574917846265,
                1.3874560559916227,
                1.413411671637051,
                1.4337854560384138,
                1.4492253796972205,
                1.4602891070677404,
                1.467460987035651,
                1.4711649598868766,
                1.4717745460409297,
                1.4696207052021717,
                1.4649981116389017,
                1.4581702317513678,
                1.4493734816106965,
                1.438820668157799,
                1.4267038653214366,
                1.4131968393658692,
                1.3984571106745518,
                1.382627719404795,
                1.365838747892874,
                1.3482086413605343
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1381796.0988514398,
                2583314.379266009,
                4080623.4085820555,
                5841205.682724006,
                7840639.931150349,
                10059784.813123347,
                12198871.035056755,
                14489465.060662678,
                16923628.007808026,
                19494390.33672836,
                22195559.930951443,
                25021579.66885152,
                27967419.263425965,
                31028491.473279335,
                34200586.0566166,
                37479816.91128756,
                40862579.19422367,
                44345514.11660275,
                47925479.72887897,
                51599526.4412537,
                55364876.33208442,
                59218905.5187606,
                63159129.028663516,
                67183187.72929808,
                71288836.96831828,
                75473936.64410746,
                79736442.48153602,
                84074398.32956897
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.31863846265460616,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                402.0455353303741,
                359.2467525711882,
                331.7144278008419,
                312.0873013879621,
                297.16754547216647,
                285.3169712513138,
                276.6129649410432,
                269.1697717051123,
                262.70578524235134,
                257.02055382452545,
                251.96697585647328,
                247.43431219871994,
                243.33737341988763,
                239.60939467516167,
                236.19719890027287,
                233.0578276688175,
                230.15614081396734,
                227.46307185088364,
                224.95433737277347,
                222.6094670314174,
                220.4110639896277,
                218.34423375683522,
                216.39613785829178,
                214.55564129321826,
                212.81303132190882,
                211.15979111105244,
                209.58841600730324,
                208.09226325306312
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9885231.850967031,
      "catalyzed_erp": 379.3537730360015
    },
    "4000": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.26554274272646583,
            0.6412329955295565,
            1.0969072858861102,
            1.611521837447475,
            2.1695286287174556,
            2.758927364599762,
            2.888719267623905,
            2.996634201960532,
            3.0857077748520276,
            3.1584062003100373,
            3.2167713418885264,
            3.2625202065434795,
            3.2971153524406347,
            3.3218160847115143,
            3.3377165762857524,
            3.3457748525172217,
            3.3468352402001864,
            3.341646042995457,
            3.3308736638318104,
            3.315114038373868,
            3.2949020017475363,
            3.2707190450767296,
            3.242999801160103,
            3.212137515746683,
            3.1784887003966076,
            3.142377118346852,
            3.10409722232396,
            3.063917137767258
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.4439611557753778,
            0.8938996998467716,
            1.3493571561724935,
            1.8102420571757163,
            2.276636640483455,
            2.7487293388273586,
            2.765816801723097,
            2.7833534483657005,
            2.8014485286684323,
            2.8202005850265897,
            2.839701696536732,
            2.8600401053899622,
            2.8813018805674697,
            2.903571975861576,
            2.926934885677355,
            2.951475018528252,
            2.9772768605960414,
            3.0044249735114468,
            3.0330038531833923,
            3.0630976654655973,
            3.0947898672316834,
            3.1281627165876884,
            3.163296672593193,
            3.2002696825854056,
            3.239156353584472,
            3.2800270032315195,
            3.32294658510468,
            3.367973483046958
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.7127272314171569,
            1.483070052846998,
            2.3089676973746402,
            3.1857548288344764,
            4.114245671482599,
            5.098645174344417,
            5.255685426767366,
            5.4127388781565395,
            5.5167644069574635,
            5.619233659379108,
            5.720258690506287,
            5.81988981731734,
            5.9181304828235035,
            6.0149472470835255,
            6.110276704900316,
            6.204030408048768,
            6.296098461795143,
            6.386352225900264,
            6.47464640465389,
            6.560820719548319,
            6.644701299634252,
            6.726101886262405,
            6.804824922935485,
            6.880662583289144,
            6.953397777675825,
            7.022805169773088,
            7.088652227961472,
            7.150700331072511
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.9123002436694878,
            1.8374472707941067,
            2.7699312549003117,
            3.031537270145483,
            3.286492215342757,
            3.535040568439869,
            3.5321537209235814,
            3.5277920522306907,
            3.5222440758278006,
            3.5157274111217798,
            3.5084101695386076,
            3.50042480146573,
            3.491877393765468,
            3.4828541070902768,
            3.473425750808041,
            3.4636511088908084,
            3.45357940595714,
            3.443252167976676,
            3.4327046480101107,
            3.421966934000962,
            3.4110648202261933,
            3.400020500583808,
            3.3888531258278483,
            3.3775792556520807,
            3.366213228618858,
            3.3547674671676893,
            3.343252731041489,
            3.331678329152262
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 279.26447378000114,
            "pct_change": 0.3790252210029829
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9483349275805587,
            "pct_change": 0.17962976460249822
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1618123105564266,
            "pct_change": 0.24676760225538164
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.14442278572075,
            "pct_change": 0.08722460642436256
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.1123287671235,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-02-10T00:00:00",
            "x3": 0.8904109589041096,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.9780821917807,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-12-22T00:00:00",
            "x3": 1.0273972602739727,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.821917808219,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-27T00:00:00",
            "x3": 0.18082191780821918,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.6931506849314,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-09-10T00:00:00",
            "x3": 2.3123287671232875,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.1205479452055,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-02-13T00:00:00",
            "x3": 0.8821917808219178,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.73698630137,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-09-25T00:00:00",
            "x3": 1.2684931506849315,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8273972602738,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-29T00:00:00",
            "x3": 0.17534246575342466,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.641095890411,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-08-22T00:00:00",
            "x3": 2.3643835616438356,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 4000000000.0,
        "tech_funds": {
          "dac": 1000000000.0,
          "saf": 1000000000.0,
          "gh": 1000000000.0,
          "ldes": 1000000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.6563868142116189,
                1.3277308359013007,
                2.00987816667716,
                2.0253120692427333,
                2.0386599379021497,
                2.050407590951432,
                2.060884670028722,
                2.0703254581415074,
                2.0789025564674386,
                2.086746823397181,
                2.0939598187857795,
                2.1006219094393694,
                2.1067977386397825,
                2.112540028609396,
                2.117892292312792,
                2.122890810584731,
                2.1275661016937204,
                2.1319440324099457,
                2.1360466708326773,
                2.1398929499214567,
                2.143499190070611,
                2.1468795152370395,
                2.1500461876361947,
                2.1530098794041947,
                2.1557798949764315,
                2.1583643544847555,
                2.1607703461327596,
                2.163004053585212
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6833559.264939737,
                10653860.531198164,
                14710845.776060833,
                16936642.509410013,
                19244516.634268805,
                21635949.031316195,
                24112664.113391496,
                26676585.342039574,
                29329805.83407174,
                32074568.441054735,
                34913252.022069626,
                37848361.90731349,
                40882523.28301989,
                44018476.66648731,
                47259074.91159387,
                50607281.35870932,
                54066168.85681302,
                57638919.46217579,
                61328824.67050182,
                65139286.07616757,
                69073816.37831908,
                73136040.67244597,
                77329697.97984074,
                81658642.97755937,
                86126847.89912987,
                90738404.58201249,
                95497526.64218816,
                100408551.75959389
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.07120981859287197,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.24823579405025,
                116.30860680905617,
                114.98740173641195,
                114.43258576833726,
                113.940777770044,
                113.49903123292816,
                113.09795782648872,
                112.73054306186653,
                112.39141468284652,
                112.07637144034463,
                111.78206876157941,
                111.50580273484118,
                111.24535779907849,
                110.99889692361745,
                110.76488085352075,
                110.54200768529044,
                110.32916694700688,
                110.12540421117372,
                109.92989347882671,
                109.741915380611,
                109.56083978936061,
                109.38611181852328,
                109.21724044786649,
                109.05378920850914,
                108.89536849719897,
                108.74162919073717,
                108.59225730627092,
                108.4469695092031
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.28369469067839304,
                0.5650826695676586,
                0.8434823502955118,
                1.1184059933264257,
                1.3894468474054027,
                1.6562393093603764,
                1.644378634823446,
                1.6317961188337102,
                1.6185226534819568,
                1.6045823021649472,
                1.5899948384196332,
                1.5747773578493787,
                1.5589453449971205,
                1.5425134044164732,
                1.5254957762994328,
                1.5079067087813074,
                1.4897607316516868,
                1.4710728600468883,
                1.4518587468538284,
                1.432134796383343,
                1.4119182478877226,
                1.391227234902778,
                1.3700808246400906,
                1.3484990404807045,
                1.3265028698064516,
                1.3041142588536458,
                1.2813560958868078,
                1.258252183726433
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1493251382.109461,
                2090235220.8551292,
                2705546021.419726,
                3339105644.588763,
                3990915482.6071334,
                4661007071.962432,
                5240413847.401741,
                5833150960.923425,
                6439230547.909339,
                7058653910.146222,
                7691409499.414558,
                8337471293.797516,
                8996797441.187218,
                9669329095.196957,
                10354989397.920586,
                11053682581.530153,
                11765293171.60892,
                12489685282.090025,
                13226701996.229887,
                13976164831.070793,
                14737873284.866375,
                15511604468.280624,
                16297112821.03019,
                17094129916.152746,
                17902364354.337955,
                18721501750.810875,
                19551204817.151512,
                20391113540.197506
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09966893432493963,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1731463527890633,
                1.1475961683915297,
                1.1284204961292414,
                1.1130531090243239,
                1.1002149534807009,
                1.089177465581301,
                1.0809288197608697,
                1.0734473797894755,
                1.0665981936987763,
                1.060279209339104,
                1.0544114337596464,
                1.0489325093033952,
                1.0437923767181372,
                1.0389502621456872,
                1.0343725314728758,
                1.0300311290624629,
                1.0259024199504474,
                1.0219663166759057,
                1.0182056107959105,
                1.0146054541404612,
                1.0111529513167141,
                1.0078368360296672,
                1.0046472113594986,
                1.0015753394120657,
                0.9986134694932142,
                0.9957546966384558,
                0.9929928442799145,
                0.9903223662686447
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.25591342945786894,
                0.5097164348928062,
                0.7600530882231515,
                1.0062252009027495,
                1.2478322774406072,
                1.4846329774884366,
                1.4712690508948596,
                1.4574665940891836,
                1.4433415193603618,
                1.428980587724599,
                1.4144503507528283,
                1.3998028920263605,
                1.3850796551256856,
                1.3703140784808807,
                1.3555334584952488,
                1.3407602983060776,
                1.3260133042634195,
                1.3113081355667302,
                1.2966579771774331,
                1.2820739840795057,
                1.2675656301555824,
                1.2531409853467685,
                1.2388069381916536,
                1.2245693762478858,
                1.210433333642427,
                1.1964031126829335,
                1.1824823849087291,
                1.16867427556705
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10255421.542748818,
                14634236.111676736,
                19155195.68256248,
                23811971.68780655,
                28601523.995645363,
                33522585.097105805,
                37399113.05456417,
                41357475.909713045,
                45398236.173568346,
                49522111.15542649,
                53729943.32142158,
                58022679.10343181,
                62401353.39423971,
                66867077.98249359,
                71421032.78711267,
                76064459.12508875,
                80798654.48517859,
                85624968.43623309,
                90544799.40377332,
                95559592.12033404,
                100670835.60537022,
                105880061.56628825,
                111188843.13801186,
                116598793.8974437,
                122111567.10326052,
                127728855.1220635,
                133452389.00995085,
                139283938.22475794
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.0890634695604307,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.20601519176194,
                115.62810816828767,
                114.47467584185067,
                113.5674978269178,
                112.820523731134,
                112.18578586794284,
                111.75483404354242,
                111.36327571665917,
                111.00439778649688,
                110.67304815759299,
                110.36518930929394,
                110.07760029720522,
                109.80767177633348,
                109.55326132217257,
                109.31258899357506,
                109.08416044383613,
                108.86670931723586,
                108.65915341741791,
                108.46056088638387,
                108.27012377714001,
                108.08713716649419,
                107.91098247391331,
                107.74111401202553,
                107.57704804745518,
                107.41835383143334,
                107.26464619047304,
                107.11557936330499,
                106.9708418413849
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.07336598734960034,
                0.14617571711792088,
                0.21823801709677298,
                0.289421765032376,
                0.3596196721632379,
                0.4287361631552958,
                0.4257276977914777,
                0.4225300890380863,
                0.4191519585880229,
                0.41559992449733885,
                0.41187934757731043,
                0.4079947998806599,
                0.40395037179280796,
                0.39974988074285905,
                0.39539701741423516,
                0.39089545073639026,
                0.38624890476529494,
                0.3814612157589976,
                0.3765363748678208,
                0.3714785600465392,
                0.3662921596420581,
                0.36098178936548814,
                0.35555230383759523,
                0.35000880357898706,
                0.34435663806439787,
                0.33860140531592864,
                0.3327489483922596,
                0.32680534905721104
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1444947265.7087443,
                1991069868.195673,
                2552977142.8368983,
                3130587178.9062605,
                3723892202.605686,
                4332914452.760351,
                4904642441.718805,
                5489689835.619308,
                6088073875.729072,
                6699802390.3890915,
                7324871461.708815,
                7963263595.336521,
                8614946221.597181,
                9279870427.529106,
                9957969859.304853,
                10649159757.967594,
                11353336105.707808,
                12070374868.901981,
                12800131329.946373,
                13542439503.746283,
                14297111637.270536,
                15063937792.280188,
                15842685512.455156,
                16633099576.842115,
                17434901841.94157,
                18247791174.91497,
                19071443480.374386,
                19905511823.052742
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.03148706584810956,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6631720681079145,
                1.6540478049220728,
                1.6471204860015756,
                1.6415311947347988,
                1.636841276907971,
                1.632796919376415,
                1.6295211637199698,
                1.6265687669549456,
                1.6238797720721434,
                1.62140953744476,
                1.6191239524822412,
                1.6169963829897547,
                1.6150056471380576,
                1.6131346315865835,
                1.6113693195173526,
                1.6096980918825854,
                1.608111214715473,
                1.606600456121852,
                1.6051587955276314,
                1.6037801997678554,
                1.6024594484048846,
                1.6011919958445606,
                1.5999738613300714,
                1.5988015403156755,
                1.5976719324217385,
                1.5965822823826539,
                1.5955301312730203,
                1.5945132759364775
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.7127272314171569,
                1.483070052846998,
                2.3089676973746402,
                3.1857548288344764,
                4.114245671482599,
                5.098645174344417,
                5.255685426767366,
                5.4127388781565395,
                5.5167644069574635,
                5.619233659379108,
                5.720258690506287,
                5.81988981731734,
                5.9181304828235035,
                6.0149472470835255,
                6.110276704900316,
                6.204030408048768,
                6.296098461795143,
                6.386352225900264,
                6.47464640465389,
                6.560820719548319,
                6.644701299634252,
                6.726101886262405,
                6.804824922935485,
                6.880662583289144,
                6.953397777675825,
                7.022805169773088,
                7.088652227961472,
                7.150700331072511
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5237100078.939616,
                7134186194.342437,
                9148164945.776085,
                11280475447.417608,
                13533932072.342754,
                15912174922.44062,
                18346686140.07725,
                20907324865.783604,
                23598644049.115517,
                26425452132.726513,
                29392779929.763634,
                32505855796.32289,
                35770085627.80353,
                39191035573.43173,
                42774416124.91333,
                46526066679.869965,
                50451939950.916824,
                54558085761.58165,
                58850633881.64129,
                63335775630.194695,
                68019744028.69948,
                72908792326.71477,
                78009170755.54515,
                83327101392.83638,
                88868751046.75314,
                94640202093.26764,
                100647421225.41914,
                106896226099.95981
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 30,
              "pct_change_gp": 0.11127259784796496,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7879259270510217,
                3.625252293587562,
                3.5004011539746323,
                3.399176845624757,
                3.314037736857235,
                3.240518928085138,
                3.177498075216722,
                3.120975037564575,
                3.0696854021749314,
                3.0226981081794615,
                2.979308752665385,
                2.938972243094415,
                2.9012586270864595,
                2.865823171313213,
                2.8323855239020683,
                2.8007148505734074,
                2.770619007146328,
                2.741936504884854,
                2.7145304492742817,
                2.688283899567119,
                2.663096268518635,
                2.6388804952884066,
                2.6155608009666067,
                2.5930708886573663,
                2.571352486663766,
                2.550354159259951,
                2.5300303281770167,
                2.510340461499867
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.08690047774738445,
                0.1826413131611922,
                0.28763678878020876,
                0.40241429881691465,
                0.5275701209148146,
                0.6637538663116862,
                0.695710469108173,
                0.7290272404939039,
                0.7637739165984527,
                0.8000183583643039,
                0.8378275105397883,
                0.8772679476599236,
                0.9184061637775415,
                0.9613086907022439,
                1.0060420919636872,
                1.0526728590105543,
                1.1012672241790598,
                1.1518908977055609,
                1.204608731461743,
                1.259484309035715,
                1.316579459701903,
                1.3759536923194222,
                1.4376635441155072,
                1.501761838525714,
                1.5682968457136224,
                1.6373113390619454,
                1.708841540825613,
                1.7829159502633136
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                361974869.56674224,
                521416859.604099,
                696676389.6549165,
                889076099.9383473,
                1100100852.7464018,
                1331390264.9674513,
                1538790009.1963,
                1762834749.7583528,
                2004808845.8293803,
                2266096107.3988466,
                2548186244.4445643,
                2852681840.216516,
                3181305813.4783015,
                3535909348.245253,
                3918480275.594745,
                4331151893.513111,
                4776212208.983049,
                5256113582.349262,
                5773482747.773108,
                6331131175.421758,
                6932065730.925822,
                7579499575.493563,
                8276863235.751042,
                9027815755.715576,
                9836255824.116549,
                10706332748.359552,
                11642457121.5997,
                12649311001.481054
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 5,
              "pct_change_gp": 0.17591019117151846,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.218135389607696,
                2.1127297420936535,
                2.033296631609475,
                1.9692297507692527,
                1.9152762461938129,
                1.8684640561112986,
                1.8338852200035785,
                1.802136588658089,
                1.7727193467205526,
                1.745255310156686,
                1.7194513195553778,
                1.6950758227805816,
                1.6719429719225332,
                1.6499015224388316,
                1.6288268977368607,
                1.6086153972112838,
                1.589179890386392,
                1.5704465631661702,
                1.5523524229457397,
                1.5348433603007758,
                1.5178726250999757,
                1.5013996154478437,
                1.4853889057358587,
                1.4698094595535232,
                1.4546339870264073,
                1.439838416088151,
                1.4254014544375062,
                1.4113042242740774
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.14240043629681168,
                0.34330153560506005,
                0.5867046860001528,
                0.8614356396796676,
                1.159231099664356,
                1.4737094869188918,
                1.5426793617953976,
                1.6000165528100874,
                1.6473330230647931,
                1.6859389473520585,
                1.7169194536037569,
                1.741187299837961,
                1.7595201733388588,
                1.772587826126401,
                1.78097228954064,
                1.7851832502986393,
                1.7856699638626643,
                1.782830637529175,
                1.777019929604765,
                1.7685550222819557,
                1.7577205980251618,
                1.7447729613163478,
                1.7299434858151819,
                1.7134415228482394,
                1.6954568752630894,
                1.6761619169846427,
                1.6557134213403257,
                1.6342541478840087
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1408258.3409532078,
                2647360.3473772216,
                4191725.2751759975,
                6007689.801696347,
                8069985.446434804,
                10358812.379928216,
                12520298.40421745,
                14832077.484815666,
                17286364.960170813,
                19876313.963191994,
                22595831.873279586,
                25439443.617884036,
                28402187.546432555,
                31479534.552269623,
                34667324.173000075,
                37961713.34357135,
                41359134.74863459,
                44856262.57443166,
                48449984.046345636,
                52137375.54854886,
                55915682.4148005,
                59782301.69161462,
                63734767.33118958,
                67770737.38802902,
                71887982.88125287,
                76084378.05192311,
                80357891.79673125,
                84706580.09999013
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3059928240924126,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                350.11781485295796,
                315.1565151161721,
                292.7679740548717,
                276.84201966646157,
                264.7504298347708,
                255.1535661530632,
                248.2354443286734,
                242.3050495208146,
                237.14462815848754,
                232.59843996791292,
                228.5517416591953,
                224.91786464344636,
                221.629945774898,
                218.63545332501047,
                215.89245631770558,
                213.36701692990002,
                211.03132705092992,
                208.8623503083614,
                206.84081505620108,
                204.95045587343384,
                203.17743415410524,
                201.5098898299421,
                199.93759050480472,
                198.45165390952954,
                197.04432621228423,
                195.70880335293228,
                194.43908585731555,
                193.2298599514271
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.12314230642965412,
                0.29793145992449643,
                0.5102025998859573,
                0.7500861977678073,
                1.0102975290530998,
                1.2852178776808703,
                1.3460399058285073,
                1.3966176491504445,
                1.4383747517872345,
                1.4724672529579785,
                1.4998518882847696,
                1.5213329067055184,
                1.5375951791017757,
                1.5492282585851136,
                1.5567442867451124,
                1.5605916022185822,
                1.5611652763375223,
                1.5588154054662822,
                1.553853734227045,
                1.546559016091912,
                1.5371814037223746,
                1.525946083760382,
                1.5130563153449208,
                1.4986959928984436,
                1.4830318251335182,
                1.4662152013622096,
                1.4483838009836343,
                1.4296629898832491
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1390404.8787688294,
                2604209.6894411277,
                4116933.1970040705,
                5895677.012579839,
                7915738.880157211,
                10157759.423342802,
                12304233.15853781,
                14601812.42659648,
                17042609.65483733,
                19619696.075448494,
                22326912.6122394,
                25158729.37001113,
                28110138.822851483,
                31176572.99710717,
                34353838.14136118,
                37638062.404094726,
                41025653.36373649,
                44513263.14223502,
                48097759.44038102,
                51776201.257456414,
                55545818.35991739,
                59403993.782549426,
                63348248.80630709,
                67376229.9768743,
                71485697.81842388,
                75674516.96612231,
                79940647.49424067,
                84282137.25829753
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3195567825417329,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                401.58910803479256,
                358.7356142659357,
                331.20953897436584,
                311.6009766591728,
                296.70142319614564,
                284.86995667095056,
                276.2338046750778,
                268.8426710879125,
                262.4196723987602,
                256.7674068084567,
                251.74082062084793,
                247.23059539890204,
                243.15255501573634,
                239.44067479732053,
                236.04232965788273,
                232.9149803536267,
                230.0238102069497,
                227.34000596966106,
                224.8394850319624,
                222.50193811061823,
                220.310098920992,
                218.24917980434407,
                216.30643047009326,
                214.470789291578,
                212.73260503010945,
                211.08341275210873,
                209.5157518774207,
                208.02301729425594
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 9954423.191839367,
      "catalyzed_erp": 401.8314193512688
    },
    "4250": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.2821413611806929,
            0.6800854861744999,
            1.1621664757981245,
            1.7062650703854336,
            2.2960229637005667,
            2.91880630902988,
            3.0553381934218535,
            3.1688406050715012,
            3.262504401152474,
            3.3389231520915494,
            3.4002450025610145,
            3.448276917722124,
            3.4845585009465365,
            3.5104156991461926,
            3.5270008121207614,
            3.5353229265627846,
            3.536271496869738,
            3.5306349183073333,
            3.5191153713987386,
            3.5023408433588825,
            3.4808749791322127,
            3.455225240834385,
            3.4258497318517516,
            3.3931629547242834,
            3.357540708618569,
            3.3193242854771086,
            3.278824089683653,
            3.236322779554156
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.47048516316210565,
            0.9472826013088774,
            1.4299168982937427,
            1.9182950399355452,
            2.4125060367362954,
            2.9127506239848984,
            2.930838419278462,
            2.949404521528162,
            2.968564197750024,
            2.988421533560455,
            3.0090738663126793,
            3.0306145303222185,
            3.0531345962543552,
            3.07672397682048,
            3.101472111025669,
            3.1274683521534756,
            3.154802135069689,
            3.183562968958533,
            3.213840283494589,
            3.2457231449052535,
            3.2792998508281093,
            3.314657407790059,
            3.351880891620488,
            3.391052688728636,
            3.4322516144760806,
            3.475551903817707,
            3.521022068738658,
            3.568723616826437
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.7505651041668534,
            1.5617759352817242,
            2.431476012416627,
            3.3547569121231704,
            4.332477831921225,
            5.369067452576409,
            5.534414902201532,
            5.699777942854849,
            5.8093021568200545,
            5.9171884300959166,
            6.023554403691086,
            6.128452800013277,
            6.231887044310408,
            6.3338217661877145,
            6.434190071711762,
            6.532898718430328,
            6.62983189707128,
            6.724854071967837,
            6.81781217932461,
            6.908537386760701,
            6.996846556183758,
            7.082543511582507,
            7.165420186171596,
            7.245257704596129,
            7.3218274427796795,
            7.394892098469106,
            7.464206798493744,
            7.529520263354978
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            0.9612343981766844,
            1.9356996777155748,
            2.9177183537293545,
            3.19414035882646,
            3.463568872458495,
            3.7262510568873894,
            3.7230178914554797,
            3.7182511061744767,
            3.712249968503606,
            3.705240467237288,
            3.6973973478935265,
            3.6888584010255956,
            3.679734068627072,
            3.6701141028285678,
            3.660072303084685,
            3.649669963083411,
            3.6389584287343144,
            3.62798102960405,
            3.61677455995891,
            3.6053704301107254,
            3.593795572780227,
            3.582073164463017,
            3.5702232055484284,
            3.5582629911448516,
            3.54620749653298,
            3.5340696951171457,
            3.521860822635757,
            3.509590598201731
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 278.76717664140494,
            "pct_change": 0.38013101500733915
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9470542339554808,
            "pct_change": 0.18073764632246994
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1611066914564314,
            "pct_change": 0.24701345855104082
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 69.07056550125235,
            "pct_change": 0.0881995962960402
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.06301369863,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-01-23T00:00:00",
            "x3": 0.9397260273972603,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.9150684931506,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-11-29T00:00:00",
            "x3": 1.0904109589041096,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.813698630137,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-24T00:00:00",
            "x3": 0.18904109589041096,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.572602739726,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-07-28T00:00:00",
            "x3": 2.4328767123287673,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.071232876712,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-01-26T00:00:00",
            "x3": 0.9315068493150684,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.66301369863,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-08-29T00:00:00",
            "x3": 1.3424657534246576,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8191780821917,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-26T00:00:00",
            "x3": 0.18356164383561643,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.517808219178,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-07-08T00:00:00",
            "x3": 2.4876712328767123,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 4250000000.0,
        "tech_funds": {
          "dac": 1062500000.0,
          "saf": 1062500000.0,
          "gh": 1062500000.0,
          "ldes": 1062500000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.6904811411483479,
                1.396479939935352,
                2.113724266060281,
                2.1297907633183493,
                2.1437004737711063,
                2.155952678197954,
                2.1668868634405873,
                2.1767447274464478,
                2.1857045923253438,
                2.193901819913162,
                2.2014415756321633,
                2.208407154841773,
                2.214865609032438,
                2.2208716619162097,
                2.226470505278209,
                2.231699839363187,
                2.2365913908004362,
                2.2411720611428314,
                2.2454648091067133,
                2.2494893374376748,
                2.253262634198506,
                2.2567994040380346,
                2.260112415251575,
                2.263212781610476,
                2.266110193167001,
                2.2688131066815047,
                2.2713289039024263,
                2.2736640239332835
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                6928813.440163718,
                10851875.12897822,
                15019199.089445176,
                17256468.99975141,
                19576007.74829859,
                21979333.97708611,
                24468202.37598094,
                27044561.79802842,
                29710527.468951497,
                32468362.099138644,
                35320462.84617817,
                38269352.25392441,
                41317671.974229395,
                44468178.48640323,
                47723740.284014165,
                51087336.161934055,
                54562054.34409056,
                58151092.26489892,
                61857756.86725139,
                65685465.314927086,
                69637746.04221718,
                73718240.08158998,
                77930702.62343113,
                82279004.77168529,
                86767135.46655683,
                91399203.55096257,
                96179439.96163957,
                101112200.02902947
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.07230583086591459,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.18556079929695,
                116.23125671952457,
                114.90488725904679,
                114.3599020837049,
                113.87582442008672,
                113.44030550362939,
                113.04434943256375,
                112.68120959475262,
                112.34570259722989,
                112.03376402516584,
                111.74215078045903,
                111.46823573489007,
                111.20986245883527,
                110.96524017045061,
                110.732866288948,
                110.51146835246814,
                110.29995978747073,
                110.09740576027419,
                109.90299648316446,
                109.71602611112684,
                109.53587588583815,
                109.36200054466596,
                109.1939172669091,
                109.03119661148463,
                108.87345503213349,
                108.7203486529596,
                108.57156805890642,
                108.42683390961429
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.3006419101613085,
                0.5988214824773659,
                0.893822474830849,
                1.185130154057712,
                1.4723144043378416,
                1.7549882511709758,
                1.7423913555938977,
                1.7290305867803808,
                1.7149384109624077,
                1.7001401593645158,
                1.6846566809173287,
                1.6685060285266267,
                1.6517045763037173,
                1.6342677858122268,
                1.616210746898969,
                1.5975485684196162,
                1.578296665611383,
                1.5584709739993978,
                1.53808810944241,
                1.5171654874632396,
                1.4957214108562464,
                1.4737751318343288,
                1.451346893150805,
                1.4284579513981128,
                1.405130584830489,
                1.3813880874820443,
                1.3572547509422874,
                1.3327558348839872
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1498866063.5913532,
                2101758729.545822,
                2723271480.297055,
                3363326784.983835,
                4021927089.3623514,
                4699104920.340894,
                5279397157.220317,
                5873020826.575342,
                6479987416.901369,
                7100297433.223993,
                7733938412.211673,
                8380883319.4178095,
                9041089207.48793,
                9714496063.004532,
                10401025797.866217,
                11100581358.084316,
                11813045933.458172,
                12538282258.364954,
                13276131998.3345,
                14026415220.024647,
                14788929944.178009,
                15563451782.440998,
                16349733659.758577,
                17147505624.55162,
                17956474749.12262,
                18776325122.7772,
                19606717940.033054,
                20447291686.04364
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.1002376418757718,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1728577169669894,
                1.147183756019687,
                1.1279399321463184,
                1.1125294501871204,
                1.09966149479374,
                1.0886020619701815,
                1.080409458354129,
                1.0729737987611543,
                1.0661627219996594,
                1.0598759664945776,
                1.0540358158170027,
                1.048580846493139,
                1.0434616984002814,
                1.038638131128731,
                1.0340769245386996,
                1.0297503490712034,
                1.025635030024426,
                1.0217110901336852,
                1.0179614925253293,
                1.0143715304146497,
                1.0109284259332936,
                1.0076210112486412,
                1.004439472527138,
                1.0013751424475985,
                0.9984203306217069,
                0.9955681839027312,
                0.9928125704738163,
                0.9901479830151084
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.2707532570283365,
                0.5392197377802229,
                0.8039940876690737,
                1.0643495955081108,
                1.3198683986873887,
                1.5702983786894353,
                1.5561310280148926,
                1.5415063787280292,
                1.5265453761782624,
                1.5113386473241257,
                1.4959557722613632,
                1.4804512461838224,
                1.4648684595946342,
                1.4492424409123583,
                1.433601797806476,
                1.4179701237202242,
                1.4023670379338782,
                1.3868089684612184,
                1.3713097508521968,
                1.3558810926730507,
                1.340532938581721,
                1.3252737604249825,
                1.3101107902968538,
                1.2950502095343752,
                1.2800973033659788,
                1.2652565884356408,
                1.2505319187333308,
                1.2359265742684475
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10314696.208790842,
                14755725.729183558,
                19341733.427985933,
                24066352.00287465,
                28926539.17499467,
                33921047.21872823,
                37806046.14002051,
                41772930.01246002,
                45822279.12149113,
                49954824.9682215,
                54171421.67704069,
                58473025.50110531,
                62860679.79463031,
                67335503.78350681,
                71898684.04137795,
                76551467.93502317,
                81295158.5309189,
                86131110.60459997,
                91060727.49518773,
                96085458.6166794,
                101206797.48608539,
                106426280.16305764,
                111745484.0206651,
                117166026.78533629,
                122689565.79764909,
                128317797.45592803,
                134052456.8124351,
                139895317.29795414
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.08972751044538622,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.17993266120017,
                115.59216389668873,
                114.43380193174022,
                113.5237659497447,
                112.77497154249897,
                112.13899465367103,
                111.712501747208,
                111.32461544958481,
                110.9688115509148,
                110.64007122807305,
                110.33445361017468,
                110.04880950598051,
                109.78058392929047,
                109.52767637012603,
                109.28833971241575,
                109.0611056728167,
                108.84472884631627,
                108.63814406474103,
                108.4404334490845,
                108.25080063271301,
                108.06855036541936,
                107.89307220787455,
                107.72382737257263,
                107.56033801161638,
                107.40217842637911,
                107.24896780070235,
                107.10036415221711,
                106.95605926536116
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.07769752339042384,
                0.1548034040607056,
                0.2311161915248393,
                0.30649734364555586,
                0.38083336857906663,
                0.45402311099155124,
                0.4508335125053721,
                0.44744375942810877,
                0.4438629416190598,
                0.4400980424020893,
                0.43615472312775905,
                0.43203781596107,
                0.4277516470864693,
                0.42330025648202324,
                0.41868755194099144,
                0.4139174197103184,
                0.40899380552402437,
                0.4039207747746952,
                0.3987025575173217,
                0.39334358211008447,
                0.38784850007243576,
                0.382222203958111,
                0.3764698395002056,
                0.37059681294199615,
                0.36460879420901765,
                0.35851171642312585,
                0.3523117721328379,
                0.3460154065622398
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1447644517.1594934,
                1996608495.559669,
                2561500100.626252,
                3142237549.198517,
                3738813580.022869,
                4351250986.603302,
                4923410676.030541,
                5508890447.881884,
                6107707281.905267,
                6719868658.704978,
                7345370246.582208,
                7984194084.244628,
                8636307090.733494,
                9301659803.77689,
                9980185287.020689,
                10671798169.648249,
                11376393795.973837,
                12093847471.461634,
                12824013797.353876,
                13566726089.868406,
                14321795882.442047,
                15089012511.173012,
                15868142784.71453,
                16658930740.560167,
                17461097490.0473,
                18274341154.561687,
                19098336895.402466,
                19932737039.597992
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.03158677865798928,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6631183635407067,
                1.6539696887400643,
                1.6470284728002638,
                1.6414301818658124,
                1.6367339198217643,
                1.6326848115941979,
                1.6294206962076505,
                1.626477685127746,
                1.6237964195753178,
                1.621332662668988,
                1.6190525872903623,
                1.6169297636021687,
                1.614943160756307,
                1.6130757793344035,
                1.6113136900661527,
                1.6096453422713357,
                1.6080610561349442,
                1.606552643199149,
                1.60511311812656,
                1.6037364766288815,
                1.6024175221511738,
                1.6011517290167931,
                1.5999351332059364,
                1.5987642443348888,
                1.597635974083342,
                1.5965475775143283,
                1.5954966045961085,
                1.5944808598680547
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.7505651041668534,
                1.5617759352817242,
                2.431476012416627,
                3.3547569121231704,
                4.332477831921225,
                5.369067452576409,
                5.534414902201532,
                5.699777942854849,
                5.8093021568200545,
                5.9171884300959166,
                6.023554403691086,
                6.128452800013277,
                6.231887044310408,
                6.3338217661877145,
                6.434190071711762,
                6.532898718430328,
                6.62983189707128,
                6.724854071967837,
                6.81781217932461,
                6.908537386760701,
                6.996846556183758,
                7.082543511582507,
                7.165420186171596,
                7.245257704596129,
                7.3218274427796795,
                7.394892098469106,
                7.464206798493744,
                7.529520263354978
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5239884193.459669,
                7140095151.382627,
                9157542517.998999,
                11293677900.378244,
                13551332622.720196,
                15934166415.95531,
                18369826259.582874,
                20931654494.847885,
                23624206627.88944,
                26452293297.39209,
                29420947251.247364,
                32535398576.859722,
                35801054730.83255,
                39223483271.52173,
                42808395955.694435,
                46561633303.099304,
                50489149001.76069,
                54596993696.45288,
                58891297813.76926,
                63378253152.688545,
                68064093023.29206,
                72955070756.706,
                78057436441.73836,
                83377411771.50563,
                88921162908.91162,
                94694771304.73805,
                100704202427.44695,
                106955272390.36334
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 30,
              "pct_change_gp": 0.11138345951888626,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.787638932456654,
                3.624827871862811,
                3.49989744201802,
                3.3986216856258356,
                3.313446307635766,
                3.2399002786945106,
                3.1769468065997617,
                3.12047744071343,
                3.069231526120416,
                3.02228051353981,
                2.9789217400790236,
                2.9386113554473856,
                2.9009203170313507,
                2.8655045725250616,
                2.83208428963729,
                2.800429037166713,
                2.7703469882757505,
                2.74167690737489,
                2.7142821042756964,
                2.6880458049095552,
                2.6628675593111097,
                2.638660420696365,
                2.6153487056750113,
                2.5928661979315075,
                2.571154694202458,
                2.550162817237869,
                2.5298450390191354,
                2.51016087103814
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.09214572961037333,
                0.19365771477080598,
                0.3049782319380545,
                0.4266675422322775,
                0.559358263819387,
                0.7037392618223715,
                0.7376135511791921,
                0.7729301753196721,
                0.8097628451685563,
                0.8481833317938501,
                0.8882624622675915,
                0.9300706858345218,
                0.9736783728641684,
                1.0191559345262295,
                1.0665738121857085,
                1.1160023640235412,
                1.1675116639342817,
                1.2211712201844402,
                1.2770496165348573,
                1.3352140753319293,
                1.395729939899427,
                1.458660071997619,
                1.5240641589694777,
                1.591997924388527,
                1.6625122354365738,
                1.7356520999125369,
                1.8114555456635328,
                1.8899523753802099
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                363706361.3454911,
                525165917.20377886,
                702760482.8139172,
                897848303.678163,
                1111953962.5283775,
                1346761077.0097609,
                1555396185.6780348,
                1780771571.835963,
                2024179019.0595605,
                2287010237.4257812,
                2570763354.705142,
                2877049928.2186317,
                3207602443.6421666,
                3564282279.9810195,
                3949088125.5356245,
                4364164830.786735,
                4811812682.162832,
                5294497076.319616,
                5814858568.183238,
                6375723257.695731,
                6980113469.93712,
                7631258670.991371,
                8332606547.430898,
                9087834160.444666,
                9900859066.239223,
                10775850272.206537,
                11717238873.291573,
                12729728184.84098
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 6,
              "pct_change_gp": 0.17705973360586022,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.216717326157405,
                2.110721311697785,
                2.0309692558521557,
                1.9667020251196468,
                1.9126093246439546,
                1.8656931741282772,
                1.8313530296658307,
                1.799799382597855,
                1.7705443447547948,
                1.7432173327359337,
                1.7175306327831368,
                1.6932566937166507,
                1.6702126713472718,
                1.6482496189146638,
                1.6272447467732132,
                1.6070957643955368,
                1.5877166685425834,
                1.569034556810382,
                1.5509871817555057,
                1.533521048849068,
                1.516589919805913,
                1.5001536222203544,
                1.4841770935378884,
                1.4686296063489837,
                1.4534841354551062,
                1.4387168368540122,
                1.4243066158650892,
                1.41023476683699
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.15132208848976006,
                0.36412990168041576,
                0.6216338774286403,
                0.912092238839733,
                1.22681336959623,
                1.5590804061161914,
                1.63161078700454,
                1.691898661774147,
                1.741637915899755,
                1.782205987321407,
                1.8147441771802708,
                1.8402128182065924,
                1.8594303638748397,
                1.8731018427382715,
                1.8818400663997106,
                1.8861817686873348,
                1.8866001153599359,
                1.8835145605395682,
                1.8772987267992791,
                1.868286788446932,
                1.8567787036536125,
                1.8430445491439589,
                1.8273281462102275,
                1.8098501207784095,
                1.7908105066722866,
                1.7703909764248311,
                1.7487567658661076,
                1.726058344755098
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1418022.2258561673,
                2670859.9669061895,
                4232352.992930547,
                6068433.074837678,
                8153530.972983381,
                10467612.3182505,
                12637141.272536596,
                14956530.716394959,
                17418050.38667943,
                20014896.77627906,
                22741012.494254705,
                25590951.563886214,
                28559776.705838487,
                31642979.47927958,
                34836417.135195166,
                38136261.936345264,
                41538959.94359485,
                45041197.105024025,
                48639871.0590599,
                52332067.4658289,
                56115039.968570605,
                59986193.095686905,
                63943067.567754865,
                67983327.58864778,
                72104749.78672872,
                76305213.53849147,
                80582692.45836551,
                84935246.87848675
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3069248885059208,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                349.7064356606416,
                314.7028260824875,
                292.32341578109913,
                276.41590334445107,
                264.3433557898366,
                254.76409355686334,
                247.90419170474712,
                242.01864969043717,
                236.89366150323522,
                232.37605197034213,
                228.35280904025683,
                224.73847037727916,
                221.46703639122293,
                218.48660817309036,
                215.75572799757066,
                213.24081832855185,
                210.91434970629913,
                208.75350432358528,
                206.7391841462146,
                204.85526323475676,
                203.0880162053327,
                201.4256757625592,
                199.85808618239236,
                198.3764290622072,
                196.97300415711265,
                195.64105267114533,
                194.37461360234443,
                193.16840606530974
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.13081927269093285,
                0.3159555844940842,
                0.5405325983694843,
                0.7941728315457007,
                1.0692095941043367,
                1.3597259029136886,
                1.4237274064173135,
                1.4769419432973543,
                1.5208664852527192,
                1.5567171647701428,
                1.5855008253807439,
                1.6080640995155313,
                1.6251281370716966,
                1.6373138564079208,
                1.6451607457210509,
                1.6491411578754498,
                1.649671381509802,
                1.647120357767765,
                1.6418166445994593,
                1.6340540549119507,
                1.6240962754786,
                1.612180691690426,
                1.598521585641524,
                1.583312833945874,
                1.5667302019462823,
                1.5489333090522772,
                1.5300673238175455,
                1.5102644347990577
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1399007.4855198013,
                2625031.7106376425,
                4153054.994903223,
                5949806.283218453,
                7990307.782049693,
                10254985.960936287,
                12408743.960921887,
                14713212.433157235,
                17160553.964883767,
                19743879.68669268,
                22457062.882254038,
                25294600.341529146,
                28251506.861264113,
                31323233.374802776,
                34505602.32019349,
                37794755.84250691,
                41187113.72490095,
                44679338.81336922,
                48268308.29681978,
                51951089.62160423,
                55724920.11709427,
                59587189.6244261,
                63535425.57905687,
                67567280.11599687,
                71680518.85586365,
                75873011.09812242,
                80142721.20056048,
                84487700.96513546
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.32045544972372864,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                401.1364796418741,
                358.23128026544117,
                330.71268924214814,
                311.12316963219587,
                296.2439611331996,
                284.43158612984666,
                275.86164702115684,
                268.5213820263925,
                262.1384773625122,
                256.51848824746537,
                251.518349816227,
                247.03012530325344,
                242.9706252367252,
                239.27454639936994,
                235.88980221838023,
                232.77426273488697,
                229.8934273473396,
                227.21873039842225,
                224.7262857109577,
                222.39594164733322,
                220.21055976382738,
                218.15545705934787,
                216.21796966665184,
                214.38710789397996,
                212.65328081735282,
                211.00807441745025,
                209.44407136276496,
                207.95470349281143
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 10023413.130962498,
      "catalyzed_erp": 424.0072662346597
    },
    "4500": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.2987086454603559,
            0.7187576946869361,
            1.2270140838350132,
            1.8003057171163324,
            2.4214796466641264,
            3.0772799554839256,
            3.2204170730379236,
            3.3393932892325537,
            3.4375514358957515,
            3.517609868653077,
            3.5818209470621856,
            3.6320799013468843,
            3.670001970007422,
            3.6969785512008144,
            3.7142190548180993,
            3.7227827517817014,
            3.7236034611872597,
            3.7175090014718855,
            3.705236741440606,
            3.6874461975062163,
            3.6647293592067927,
            3.637619243666886,
            3.6065970515768333,
            3.572098206238035,
            3.5345174911452277,
            3.4942134525318256,
            3.451512197682753,
            3.40671069190079
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.4968695102948072,
            1.0003823468596404,
            1.5100470832338853,
            2.025769518466313,
            2.5476455780984737,
            3.075888338855618,
            3.0949688913210363,
            3.114556936576834,
            3.134773680516859,
            3.1557286907559305,
            3.1775245121485187,
            3.200259526873698,
            3.224029766993155,
            3.2489300661351788,
            3.275054771034185,
            3.302498143175523,
            3.3313545292276396,
            3.3617183482694184,
            3.393683924935816,
            3.427345185582628,
            3.462795226687411,
            3.500125759393863,
            3.539426430459038,
            3.5807840173484085,
            3.624281493479282,
            3.669996958490061,
            3.7180024277671686,
            3.7683624752586393
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.7877386423528514,
            1.6390972487843747,
            2.551826573164258,
            3.520779806229413,
            4.546860495874847,
            5.634717174199149,
            5.808223090982436,
            5.981747108478057,
            6.096671155167299,
            6.209877121118663,
            6.321488100525861,
            6.431559153545476,
            6.540093668736774,
            6.6470543642355535,
            6.752370906822453,
            6.855945334483729,
            6.9576560194895345,
            7.057360645434501,
            7.1548985115325126,
            7.250092377377527,
            7.342749996888283,
            7.4326654479950465,
            7.51962033599556,
            7.603384929009152,
            7.683719270132697,
            7.7603743009766175,
            7.833093023830671,
            7.9016117240699195
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            1.0093547708125787,
            2.032297827998974,
            3.062993557445306,
            3.3540772810026978,
            3.6378303416438786,
            3.914502100352853,
            3.9109113656004886,
            3.905730663411716,
            3.899269524288222,
            3.8917619424026566,
            3.8833890208344064,
            3.8742936759336777,
            3.8645905356365557,
            3.8543728080597006,
            3.8437171727369064,
            3.832687342813131,
            3.8213367105897973,
            3.809710346630198,
            3.7978465335497447,
            3.785777959232889,
            3.773532656548464,
            3.761134751737485,
            3.7486050664685573,
            3.735961606718976,
            3.7232199631920726,
            3.710393641680246,
            3.697494337730721,
            3.6845321664771014
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 278.28068276857937,
            "pct_change": 0.3812127867811425
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9457961753944564,
            "pct_change": 0.18182594726745838
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.160414753345471,
            "pct_change": 0.24725454802946797
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 68.99951258991754,
            "pct_change": 0.0891375656433194
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.0164383561646,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-01-06T00:00:00",
            "x3": 0.9863013698630136,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.854794520548,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-11-07T00:00:00",
            "x3": 1.1506849315068493,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.805479452055,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-21T00:00:00",
            "x3": 0.19726027397260273,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.454794520548,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-06-15T00:00:00",
            "x3": 2.5506849315068494,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2049.0219178082193,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-01-08T00:00:00",
            "x3": 0.9808219178082191,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.591780821918,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-08-03T00:00:00",
            "x3": 1.4136986301369863,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8109589041096,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-23T00:00:00",
            "x3": 0.1917808219178082,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.3972602739725,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-05-25T00:00:00",
            "x3": 2.6082191780821917,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 4500000000.0,
        "tech_funds": {
          "dac": 1125000000.0,
          "saf": 1125000000.0,
          "gh": 1125000000.0,
          "ldes": 1125000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.7238900826063854,
                1.4638341793828311,
                2.2154490567881124,
                2.232124086057414,
                2.246575186797741,
                2.259314229562788,
                2.2706899494290673,
                2.2809510350571562,
                2.2902812184206067,
                2.298820130711122,
                2.306676362805924,
                2.313935997432898,
                2.320668381793729,
                2.3269301501858526,
                2.332768098851582,
                2.3382212859531526,
                2.3433225951423924,
                2.3480999195406103,
                2.3525770718236103,
                2.3567744932299672,
                2.3607098126223347,
                2.364398292155153,
                2.3678531861074856,
                2.3710860323987064,
                2.3741068914380907,
                2.376924543262477,
                2.3795466514545054,
                2.3819799002774715
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                7022152.756389525,
                11045872.16821878,
                15321253.537908526,
                17569728.15508395,
                19900663.872188285,
                22315614.91290068,
                24816364.689006537,
                27404886.277006958,
                30083316.093603972,
                32853935.957120854,
                35719160.68726109,
                38681529.485181235,
                41743699.96872934,
                44908444.12014345,
                48178645.64261036,
                51557298.37606073,
                55047505.52433695,
                58652479.514678426,
                62375542.357951485,
                66220126.41141653,
                70189775.46965292,
                74288146.12652622,
                78519009.36375313,
                82886252.331024,
                87393880.2896939,
                92046018.69738233,
                96846915.41487874,
                101800943.0198533
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.07335130626726921,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.1251145185197,
                116.15705850090607,
                114.82595495251732,
                114.29022048100778,
                113.8134438448555,
                113.38382428253428,
                112.99272779142082,
                112.63365599699203,
                112.30160123555089,
                111.99262685116003,
                111.70358477224774,
                111.43191996109941,
                111.1755316316176,
                110.93267261032538,
                110.70187495877066,
                110.48189407034539,
                110.27166601499319,
                110.07027454840772,
                109.87692528151837,
                109.69092522981106,
                109.51166645665076,
                109.33861286864692,
                109.1712894639459,
                109.00927350830887,
                108.85218724012609,
                108.69969179832113,
                108.55148213606866,
                108.40728273504432
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.3174998418585596,
                0.6323808015236064,
                0.9438926507977312,
                1.251493998619117,
                1.554731578241486,
                1.8531972043227558,
                1.8398649348786236,
                1.8257270864596564,
                1.8108176667503397,
                1.7951632528367192,
                1.7787857539302088,
                1.761704169650603,
                1.7439357561413156,
                1.7254968265268626,
                1.7064033162915033,
                1.6866711919689155,
                1.6663167518453097,
                1.6453568498375102,
                1.6238090629939699,
                1.6016918163420193,
                1.5790244744700204,
                1.5558274063902036,
                1.5321220283167942,
                1.5079308277082004,
                1.483277371030873,
                1.4581862970989672,
                1.4326832974202117,
                1.4067950846925619
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1504451163.6733239,
                2113220932.0162642,
                2740901886.5240593,
                3387417129.1509967,
                4052770148.8368583,
                4736994438.324393,
                5318166030.388184,
                5912670104.579629,
                6520517495.898086,
                7141707909.577203,
                7776227966.610805,
                8424049621.265438,
                9085128833.57646,
                9759404426.860102,
                10446797087.535715,
                11147208481.000055,
                11860520467.55857,
                12586594409.002684,
                13325270560.736797,
                14076367547.226204,
                14839681920.450474,
                15614987802.310802,
                16402036612.746704,
                17200556885.791763,
                18010254176.02256,
                18830811057.88502,
                19661887220.25744,
                20503119658.357292
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.10079782848907112,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.17257175161718,
                1.1467759398647583,
                1.1274652661099205,
                1.1120126212863155,
                1.0991155715864207,
                1.0880347527621967,
                1.0798970226872395,
                1.072506249867326,
                1.0657325808776301,
                1.0594774912189597,
                1.0536645051625513,
                1.04823310864776,
                1.0431346230760683,
                1.0383293286725828,
                1.0337844097494417,
                1.0294724556206107,
                1.0253703463049753,
                1.0214584104102733,
                1.017719779214383,
                1.0141398845845675,
                1.0107060639676972,
                1.0074072461905195,
                1.004233699021246,
                1.001176824479467,
                0.9982289914523302,
                0.9953833977433172,
                0.992633955551519,
                0.9899751957596883
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.28546468820619336,
                0.5684636486161427,
                0.8475445006571934,
                1.1219531949452837,
                1.3912551548461378,
                1.6551878707900645,
                1.6402214161714213,
                1.6247796283545597,
                1.6089883058676155,
                1.5929418116915346,
                1.5767126580284825,
                1.56035767850078,
                1.5439221538428267,
                1.5274426578738478,
                1.510949073885324,
                1.4944660568599786,
                1.4780141154474047,
                1.4616104270895875,
                1.4452694617261341,
                1.429003466002922,
                1.4128228439261294,
                1.3967364595823317,
                1.3807518803610717,
                1.3648755743202694,
                1.3491130717539819,
                1.333469098417769,
                1.3179476862762156,
                1.3025522661996298
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10373458.021664746,
                14876147.214036366,
                19526613.061056443,
                24318453.067747213,
                29248624.52540465,
                34315900.294171706,
                38209279.27542428,
                42184594.71810706,
                46242444.0546422,
                50383572.51955091,
                54608845.549864404,
                58919228.95209901,
                63315774.32897778,
                67799608.17054863,
                72371923.56153226,
                77033973.79698174,
                81787067.41646735,
                86632564.31063537,
                91571872.65083614,
                96606446.45920582,
                101737783.68338597,
                106967424.67347082,
                112296950.98298971,
                117727984.43353628,
                123262186.39591633,
                128901257.25067815,
                134646935.9985023,
                140500999.9967857
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09037619438953855,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.15423986977157,
                115.55685908467771,
                114.3937217767815,
                113.48093033147833,
                112.73038825266094,
                112.09322657320878,
                111.67105142439175,
                111.2867274271728,
                110.93391028629303,
                110.60770850578578,
                110.30427379244546,
                110.02052583811067,
                109.7539619419832,
                109.5025219782346,
                109.26449050476513,
                109.03842442116952,
                108.82309858963326,
                108.61746433238342,
                108.42061732114288,
                108.2317724248395,
                108.05024378587004,
                107.87542887602403,
                107.70679561726374,
                107.54387188839598,
                107.38623690758735,
                107.23351410327174,
                107.08536517608495,
                106.94148512140927
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.08200090305059689,
                0.1633747496651613,
                0.2439099714368792,
                0.32346068657216376,
                0.4019072540666,
                0.47914298155215096,
                0.47577304401067183,
                0.4721920281574233,
                0.4684094849211339,
                0.46443275798469574,
                0.46026780569952774,
                0.45591971768317424,
                0.4513930538383516,
                0.4466920749887241,
                0.44182090457986933,
                0.4367836448709549,
                0.43158446205524603,
                0.42622764947762554,
                0.420717674918001,
                0.41505921593182593,
                0.4092571859575512,
                0.40331675307464526,
                0.3972433527352418,
                0.39104269542537523,
                0.3847207699485559,
                0.3782838428537156,
                0.37173845440432746,
                0.3650914114038378
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1450324235.6060674,
                2002110954.05469,
                2569967204.9326973,
                3153811343.033797,
                3753636616.6516976,
                4369466366.317925,
                4942054602.7959,
                5527963587.431373,
                6127210037.188759,
                6739801085.113139,
                7365731986.866723,
                8004984316.111772,
                8657524483.06193,
                9323302479.04492,
                10002250788.470913,
                10694283432.284086,
                11399295120.834501,
                12117160502.847511,
                12847733502.821627,
                13590846742.91287,
                14346311047.847473,
                15113915033.059343,
                15893424777.332355,
                16684583581.904646,
                17487111818.369637,
                18300706867.858566,
                19125043153.96051,
                19959772271.662834
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.03168533491213977,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6630651143865227,
                1.6538923127966523,
                1.646937387602706,
                1.6413302304380204,
                1.6366277249403407,
                1.6325739457873845,
                1.6293213005471172,
                1.626387544926938,
                1.6237139061637278,
                1.6212565443431894,
                1.6189819106535646,
                1.616863776062939,
                1.6148812581884324,
                1.6130174697062882,
                1.6112585675369353,
                1.6095930683407504,
                1.6080113456640726,
                1.6065052538678206,
                1.605067842362291,
                1.603693135343953,
                1.6023759598230873,
                1.6011118097808266,
                1.5998967377199746,
                1.5987272672404083,
                1.5976003219315342,
                1.5965131670587172,
                1.595463361376569,
                1.5944487170284953
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.7877386423528514,
                1.6390972487843747,
                2.551826573164258,
                3.520779806229413,
                4.546860495874847,
                5.634717174199149,
                5.808223090982436,
                5.981747108478057,
                6.096671155167299,
                6.209877121118663,
                6.321488100525861,
                6.431559153545476,
                6.540093668736774,
                6.6470543642355535,
                6.752370906822453,
                6.855945334483729,
                6.9576560194895345,
                7.057360645434501,
                7.1548985115325126,
                7.250092377377527,
                7.342749996888283,
                7.4326654479950465,
                7.51962033599556,
                7.603384929009152,
                7.683719270132697,
                7.7603743009766175,
                7.833093023830671,
                7.9016117240699195
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5242619426.175309,
                7145900159.924149,
                9166754921.868164,
                11306647618.899958,
                13568426236.863497,
                15955769791.862371,
                18392557813.897583,
                20955554395.77038,
                23649317550.021507,
                26478660068.87429,
                29448616597.87752,
                32564418922.733395,
                35831476031.54031,
                39255356864.940056,
                42841774420.56386,
                46596570318.66443,
                50525699203.65732,
                54635212525.25659,
                58931241353.43994,
                63419977956.71665,
                68107655926.770226,
                73000528672.87419,
                78104846141.8203,
                83426829646.89403,
                88972644714.98753,
                94748371885.83675,
                100759975422.71748,
                107013269920.51903
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 30,
              "pct_change_gp": 0.1114921717336714,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.787357150150157,
                3.62441131577047,
                3.4994031883697945,
                3.3980770497329726,
                3.3128661740361576,
                3.2392935179204234,
                3.1764060605049185,
                3.1199892901730597,
                3.068786227674677,
                3.0218707811545564,
                2.9785419907286004,
                2.9382572222377443,
                2.9005883239012933,
                2.865191910387248,
                2.8317886583504825,
                2.8001485314741013,
                2.770080013852455,
                2.7414221179563496,
                2.7140383538388444,
                2.6878121107931627,
                2.662643073412643,
                2.6384444067178308,
                2.615140520958574,
                2.5926652788192843,
                2.57096054413189,
                2.549974996981875,
                2.5296631586234133,
                2.5099845831129226
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.0973687653856507,
                0.2046267956708727,
                0.3222444609992748,
                0.4508148332750325,
                0.5910067457903879,
                0.7435481529807114,
                0.7793309124317405,
                0.8166378219597544,
                0.8555465288453853,
                0.8961326799345156,
                0.9384709525187821,
                0.9826356395399207,
                1.0287009570134877,
                1.0767411646195917,
                1.1268305501628122,
                1.1790433063356522,
                1.2334533153270841,
                1.2901338489542826,
                1.3491571870238452,
                1.4105941533087827,
                1.4745135662598396,
                1.5409815999290142,
                1.610061049407002,
                1.6818104942148326,
                1.7562833524998531,
                1.833526818537378,
                1.9135806759426295,
                1.9964759791622395
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                365430519.4491191,
                528898870.809375,
                708818187.7671599,
                906582185.2941005,
                1123754995.7741892,
                1362064038.8985019,
                1571928761.0656338,
                1798628607.6884623,
                2043462744.7628696,
                2307830738.929579,
                2593239089.916118,
                2901308280.380007,
                3233780310.782672,
                3592526699.1515684,
                3979556930.769764,
                4397027343.66738,
                4847250433.625705,
                5332704557.90827,
                5856044010.406068,
                6420109432.4242115,
                7027938512.922096,
                7682776919.551067,
                8388089387.168237,
                9147570873.468683,
                9965157671.780888,
                10845038348.71887,
                11791664349.093853,
                12809760082.096018
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 6,
              "pct_change_gp": 0.1781880195411016,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2153130235390774,
                2.1087379540777187,
                2.0286750642035725,
                1.964213623586492,
                1.909986657870258,
                1.8629707023233444,
                1.8288630028207613,
                1.797499523851834,
                1.7684028838063128,
                1.741209825256736,
                1.7156378941605026,
                1.691463410513273,
                1.6685064408553512,
                1.6466202680457371,
                1.6256838384177583,
                1.6055962327355293,
                1.586272545015642,
                1.5676407610453709,
                1.549639359888314,
                1.5322154469340885,
                1.5153232835942447,
                1.4989231170036308,
                1.482980239443355,
                1.4674642256618537,
                1.452348309396561,
                1.4376088698616356,
                1.4232250058767286,
                1.4091781804164265
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.1602297967771301,
                0.3848671254729699,
                0.6563504112546918,
                0.9623825146497088,
                1.2938519062899658,
                1.6437126075326927,
                1.7197308858586502,
                1.7829081581912274,
                1.8350184717934042,
                1.8775051456893717,
                1.9115645437124449,
                1.9382036634611906,
                1.9582809670344907,
                1.972536119998524,
                1.9816121681581658,
                1.9860724214994192,
                1.986413546490714,
                1.9830758853034307,
                1.9764517085170714,
                1.9668919022385194,
                1.9547114507677257,
                1.9401939798914443,
                1.9235955583015527,
                1.9051479063107593,
                1.8850611260513113,
                1.8635260414983683,
                1.8407162176036655,
                1.8167897131874307
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1427770.8505103327,
                2694256.7550880774,
                4272733.359038978,
                6128737.082826057,
                8236404.3338807365,
                10575470.80660127,
                12752918.17683202,
                15079802.004571708,
                17548445.087956343,
                20152086.259370342,
                22884702.559731983,
                25740876.471488766,
                28715694.56941362,
                31804668.65196931,
                35003673.32697591,
                38308895.88342432,
                41716795.50062392,
                45224069.66610974,
                48827626.23788974,
                52524559.982469745,
                56312132.70291859,
                60187756.27663743,
                64148978.07390993,
                68193468.34145251,
                72319009.22079127,
                76523485.1368124,
                80804874.34251343,
                85161241.44556949
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.30783608932236495,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                349.29917323812793,
                314.2559915162782,
                291.88675365053655,
                275.9980452427634,
                263.94461237918597,
                254.3828926787237,
                247.5796695174201,
                241.73785531985746,
                236.64745206748296,
                232.15776430455878,
                228.15745658122273,
                224.56223651208262,
                221.30694315794966,
                218.34029286847988,
                215.62128848765508,
                213.1167034151964,
                210.79927981856423,
                208.64641309293265,
                206.63917466075833,
                204.76157480198927,
                202.999998762917,
                201.34276993927008,
                199.77980759043047,
                198.3023557980566,
                196.9027667633016,
                195.57432600543703,
                194.31111019980037,
                193.1078706793354
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.1384788486832258,
                0.3338905692139662,
                0.5706636725803212,
                0.8379232024666236,
                1.1276277403741608,
                1.433567347951233,
                1.5006861871792732,
                1.5564851310413261,
                1.6025329641023476,
                1.640104722963705,
                1.670256403349741,
                1.693876237885694,
                1.7117210029729315,
                1.7244424312022901,
                1.7326068866599336,
                1.7367103302822822,
                1.7371899146965455,
                1.734433116168455,
                1.7287850329235346,
                1.720554295267697,
                1.710017908439067,
                1.697425263775442,
                1.6830014932752804,
                1.6669502999272758,
                1.6494563650939165,
                1.630687411033457,
                1.6107959800790876,
                1.5899209787133592
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1407590.6051808973,
                2645750.754760176,
                4188939.8820669507,
                6003522.692595041,
                8064251.498232704,
                10351342.6689492,
                12512274.437087812,
                14823529.140321773,
                17277318.349577334,
                19866792.19647644,
                22585855.62309431,
                25429031.544767637,
                28391356.62615358,
                31468300.33243595,
                34655700.97743867,
                37949714.43740634,
                41346772.47329119,
                44843548.45927974,
                48436928.90195216,
                52123989.54527495,
                55901975.14959071,
                59768282.24517744,
                63720444.317286596,
                67756118.99622895,
                71873076.91423152,
                76069191.95817906,
                80342432.699423,
                84690854.82247141
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.32133512041359236,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                400.6882878304629,
                357.7343186108736,
                330.22435253062775,
                310.65428258923276,
                295.79550911045806,
                284.0021703418785,
                275.4967758766327,
                268.2061630498027,
                261.86243574156424,
                256.27401341681497,
                251.29976080166853,
                246.83308350178135,
                242.7917517708517,
                239.11116487600844,
                235.73976106530867,
                232.63580957301338,
                229.76511829592485,
                227.0993633829759,
                224.61485060812322,
                222.29158246109182,
                220.1125455436561,
                218.06315926698656,
                216.13084436561167,
                214.30468159144132,
                212.57513910416873,
                210.93385277464384,
                209.3734476619954,
                207.88739183341747
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 10092274.789335692,
      "catalyzed_erp": 445.88560001904244
    },
    "4750": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.315244683101846,
            0.757254693209585,
            1.2914634608960913,
            1.8936677277177192,
            2.545934908637322,
            3.2343980454845336,
            3.384010885849448,
            3.5083516322598625,
            3.6109119549496063,
            3.694532530429365,
            3.7615679512296496,
            3.814000087729834,
            3.8535184595700542,
            3.8815787725618973,
            3.899446569573106,
            3.9082304627365136,
            3.9089079023595454,
            3.9023454872693315,
            3.8893152077601427,
            3.8705076069648268,
            3.8465425714767374,
            3.817978273196923,
            3.7853186509067926,
            3.749019725319166,
            3.709494972352707,
            3.6671199284700196,
            3.6222361644287586,
            3.57515473506064
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.523113985121771,
            1.0531985046108456,
            1.5897469962144104,
            2.132664409306283,
            2.6820537130205677,
            3.2381403538238405,
            3.2582057945523624,
            3.278807953385549,
            3.300073898382557,
            3.322118618707336,
            3.3450498162948015,
            3.36897087698686,
            3.3939827538304264,
            3.4201851631388083,
            3.4476773221994104,
            3.476558363783405,
            3.5069275091186265,
            3.538884049154108,
            3.5725271643325596,
            3.607955600577102,
            3.6452672110085382,
            3.6845583673732643,
            3.7259232413747205,
            3.769452953473332,
            3.8152345849150713,
            3.8633500475790195,
            3.913874805575355,
            3.9668764423222287
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.8242648863420617,
            1.7150695934168305,
            2.6700749398694157,
            3.6839002978261943,
            4.757492953596728,
            5.895717508340255,
            6.077237060629059,
            6.258777332869691,
            6.379004961333961,
            6.497435851321903,
            6.614198421925793,
            6.729350005871012,
            6.842893937153986,
            6.954791041375123,
            7.064967594545677,
            7.173320988121743,
            7.279723869757029,
            7.384027254217898,
            7.486062931634299,
            7.585645395784618,
            7.682573447782964,
            7.776631586481791,
            7.867591267019053,
            7.955212088552287,
            8.039242957817171,
            8.119423264707455,
            8.195484098405936,
            8.267149526616961
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            1.0566881173031106,
            2.127297267862402,
            3.2058424057703445,
            3.511436858846914,
            3.8093684379120907,
            4.099888283845813,
            4.095929685087233,
            4.0903270683984605,
            4.083399770919179,
            4.075389452766649,
            4.0664833160860265,
            4.056829202170188,
            4.046545766138054,
            4.0357295446940205,
            4.024459993922676,
            4.012803161306962,
            4.000814414922521,
            3.9885405071479587,
            3.9760211590540164,
            3.9632902936728045,
            3.9503770078150495,
            3.9373063463123104,
            3.924099925288868,
            3.9107764383407035,
            3.8973520713805554,
            3.883840844982738,
            3.870254899139912,
            3.8566047315806333
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 277.80462504426174,
            "pct_change": 0.38227135264935547
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9445600981549496,
            "pct_change": 0.18289523296647905
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.1597361016956147,
            "pct_change": 0.24749100815459474
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 68.9310838622012,
            "pct_change": 0.09004089314764646
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2048.9698630136986,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-12-19T00:00:00",
            "x3": 1.0356164383561643,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.797260273973,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-10-17T00:00:00",
            "x3": 1.2082191780821918,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.794520547945,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-17T00:00:00",
            "x3": 0.20821917808219179,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.33698630137,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-05-03T00:00:00",
            "x3": 2.6684931506849314,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2048.9753424657533,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-12-21T00:00:00",
            "x3": 1.0301369863013699,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.5205479452056,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-07-08T00:00:00",
            "x3": 1.484931506849315,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.8,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-19T00:00:00",
            "x3": 0.20273972602739726,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.2794520547945,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-04-12T00:00:00",
            "x3": 2.7260273972602738,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 4750000000.0,
        "tech_funds": {
          "dac": 1187500000.0,
          "saf": 1187500000.0,
          "gh": 1187500000.0,
          "ldes": 1187500000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.7566390694441907,
                1.5298461870999849,
                2.315133477431445,
                2.332394537418948,
                2.3473678461072423,
                2.3605770738268963,
                2.37237966032837,
                2.3830308968401797,
                2.3927196394883734,
                2.401589573057757,
                2.4097525474190187,
                2.4172973018450703,
                2.424295374794948,
                2.430805226589834,
                2.436875188635188,
                2.442545619632414,
                2.4478505122938654,
                2.4528187108787582,
                2.4574748477041504,
                2.4618400731766203,
                2.4659326317619725,
                2.469768321360256,
                2.4733608633612083,
                2.4767222034090293,
                2.4798627579262793,
                2.4827916176729987,
                2.4855167170576475,
                2.4880449758340517
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                7113648.263437097,
                11236003.244693536,
                15617249.454757523,
                17876672.521371957,
                20218749.367885318,
                22645067.82438229,
                25157438.6042916,
                27757857.933475584,
                30448482.570352014,
                33231612.742677547,
                36109680.3374216,
                39085240.69081311,
                42160966.91601361,
                45339646.06435909,
                48624176.64118425,
                52017567.1427135,
                55522935.37695224,
                59143508.39691599,
                62882622.91978355,
                66743726.1374278,
                70730376.84658255,
                74846246.84345631,
                79095122.53977188,
                83480906.76625486,
                88007620.73638308,
                92679406.1483454,
                97500527.40707114,
                102475373.95118609
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.07434986842077293,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.06676637239504,
                116.0858013542481,
                114.75034979358755,
                114.22333713260235,
                113.75346812032821,
                113.32944586702153,
                112.94297098894125,
                112.58777569706976,
                112.25901619370362,
                111.95287535017103,
                111.66629425170696,
                111.3967856657215,
                111.14230125592802,
                110.90113503358376,
                110.67185183356663,
                110.45323344241179,
                110.24423741493416,
                110.04396516735571,
                109.85163695671059,
                109.66657204340342,
                109.48817280449134,
                109.31591189321541,
                109.1493217723688,
                108.98798611564406,
                108.83153269221364,
                108.67962743893035,
                108.5319694908749,
                108.38828699086407
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.33426899475880983,
                0.6657616978251971,
                0.9936945345759617,
                1.3174997819453371,
                1.63670123176302,
                1.950869646206565,
                1.936802885128192,
                1.92188916019724,
                1.9061639890707263,
                1.889655173494586,
                1.8723856683524864,
                1.8543754097238525,
                1.8356425284717321,
                1.8162041839823202,
                1.7960771534581876,
                1.7752782581290347,
                1.7538246769661783,
                1.7317341802953765,
                1.709025304573681,
                1.6857174826141488,
                1.6618311390345508,
                1.6373877577458813,
                1.6124099263150355,
                1.5869213606915682,
                1.5609469128640452,
                1.5345125633805858,
                1.507645400230405,
                1.4803735852821924
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1510006850.9853632,
                2124622194.1076035,
                2758437823.335261,
                3411377495.6406145,
                4083445732.321732,
                4774676967.508302,
                5356721863.937668,
                5952100247.425957,
                6560822293.259323,
                7182886904.103624,
                7818279784.885658,
                8466971879.9430485,
                9128918059.414595,
                9804055987.164675,
                10492305128.878628,
                11193565874.897604,
                11907718762.322456,
                12634623787.3166,
                13374119802.737188,
                14126023999.016266,
                14890131468.076677,
                15666214851.29643,
                16454024073.317081,
                17253286163.94754,
                18063705170.624794,
                18884962163.912018,
                19716715338.380447,
                20558600210.961685
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.10134970055093605,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1722884211938225,
                1.1463726419238662,
                1.1269963852312654,
                1.1115024817765282,
                1.0985770207072707,
                1.0874753558930295,
                1.0793913665724906,
                1.072044612613366,
                1.065307668872023,
                1.059083696553668,
                1.0532974261510202,
                1.0478892291272581,
                1.0428110913992177,
                1.0380238014285181,
                1.0334949387541574,
                1.029197404573765,
                1.0251083282459175,
                1.0212082400481635,
                1.0174804360851344,
                1.0139104842150168,
                1.010485835047526,
                1.0071955123110492,
                1.0040298639274816,
                1.0009803600545768,
                0.9980394278487857,
                0.9952003152162513,
                0.9924569776535612,
                0.9898039836335092
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.30004904785891995,
                0.5974510807624174,
                0.8907089283388996,
                1.179042321427966,
                1.4620005918048486,
                1.7393112100189168,
                1.723550024758863,
                1.707296171558281,
                1.6906801314308053,
                1.673799879708892,
                1.656730768667008,
                1.6395319003251172,
                1.6222503913431061,
                1.6049243181041863,
                1.5875848052874884,
                1.5702575416745488,
                1.5529639026286557,
                1.5357217962692002,
                1.5185463113498658,
                1.5014502204961842,
                1.4844443760530768,
                1.4675380249520544,
                1.45073906192766,
                1.4340542349316745,
                1.4174893134542763,
                1.4010492273097397,
                1.3847381820822642,
                1.3685597557465814
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10431712.27319816,
                14995512.562998159,
                19709854.11437648,
                24568302.551443763,
                29567816.34864946,
                34707189.703378536,
                38608859.49912216,
                42592518.614723615,
                46658781.03538344,
                50808405.29335983,
                55042267.808793284,
                59361343.68537318,
                63766692.570195846,
                68259448.05112274,
                72840809.58559011,
                77512036.27963397,
                82274442.04472968,
                87129391.7979071,
                92078298.46376637,
                97122620.601301,
                102263860.52362704,
                107503562.81102882,
                112843313.14118971,
                118284737.3777472,
                123829500.87118918,
                129479307.93582416,
                135235901.47396755,
                141101062.7241947
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.0910100921604117,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.12892832485652,
                115.52217628376577,
                114.35441126675387,
                113.43896194388843,
                112.68674108566194,
                112.04844589252174,
                111.63045388563994,
                111.24958726717018,
                110.89967324628056,
                110.57594206268436,
                110.27463415863326,
                109.99273539343756,
                109.72779338514854,
                109.47778693747922,
                109.2410311861264,
                109.01610737380139,
                108.80180997691616,
                108.59710629364265,
                108.40110513623992,
                108.21303227857399,
                108.0322109865137,
                107.85804642190331,
                107.69001303308038,
                107.52764427274948,
                107.37052414743707,
                107.21828022151286,
                107.07057778613162,
                106.9271149684512
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.08627639462524599,
                0.1718902963186743,
                0.25662017435202056,
                0.34031288591943915,
                0.4228426939945943,
                0.5040974116230327,
                0.5005479259818086,
                0.4967765246128463,
                0.4927932125900712,
                0.4886056891583799,
                0.48422020620443895,
                0.4796421081925498,
                0.4748761866982747,
                0.46992692171535305,
                0.4647986509043591,
                0.45949569124631384,
                0.4540224281878923,
                0.448383381855149,
                0.44258325658212444,
                0.4366269779242418,
                0.43051971999397515,
                0.4242669250875089,
                0.4178743169911253,
                0.4113479089638698,
                0.4046940071234129,
                0.3979192097842937,
                0.39103040316154525,
                0.3840347537701286
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1452986588.116502,
                2007577591.8716953,
                2578378996.801671,
                3165309305.5382247,
                3768362272.8710337,
                4387561778.800593,
                4960575443.293237,
                5546910510.013406,
                6146583431.958901,
                6759600994.836913,
                7385958042.860302,
                8025635686.545762,
                8678599829.728907,
                9344799920.232037,
                10024167866.499096,
                10716617084.828728,
                11422041655.4872,
                12140315574.598864,
                12871292094.289452,
                13614803147.236158,
                14370658854.23029,
                15138647114.991373,
                15918533283.540016,
                16710059930.102873,
                17512946691.892544,
                18326890215.248753,
                19151564191.593353,
                19986619489.474495
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.031782755614497504,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6630123148536091,
                1.6538156664014858,
                1.646847216020635,
                1.6412313232105673,
                1.6365226727407844,
                1.632464300544511,
                1.6292229590327088,
                1.6262983313428205,
                1.6236322188594572,
                1.621181171065378,
                1.6189119124217397,
                1.61679841123662,
                1.6148199311356255,
                1.6129596951035805,
                1.6112039449246023,
                1.6095412635946869,
                1.6079620772475582,
                1.6064582824578797,
                1.6050229629043586,
                1.603650170883988,
                1.602334756660872,
                1.6010722336190717,
                1.5998586705735625,
                1.598690604932635,
                1.5975649720484897,
                1.5964790472647552,
                1.5954303980169668,
                1.5944168439624515
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.8242648863420617,
                1.7150695934168305,
                2.6700749398694157,
                3.6839002978261943,
                4.757492953596728,
                5.895717508340255,
                6.077237060629059,
                6.258777332869691,
                6.379004961333961,
                6.497435851321903,
                6.614198421925793,
                6.729350005871012,
                6.842893937153986,
                6.954791041375123,
                7.064967594545677,
                7.173320988121743,
                7.279723869757029,
                7.384027254217898,
                7.486062931634299,
                7.585645395784618,
                7.682573447782964,
                7.776631586481791,
                7.867591267019053,
                7.955212088552287,
                8.039242957817171,
                8.119423264707455,
                8.195484098405936,
                8.267149526616961
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5245307030.917802,
                7151603892.691757,
                9175806410.321499,
                11319390601.562193,
                13585220831.593786,
                15976995066.618202,
                18414891352.16634,
                20979035668.628315,
                23673988486.213707,
                26504564709.400192,
                29475800845.34976,
                32592930345.974194,
                35861363701.985695,
                39286671210.2162,
                42874567085.64019,
                46630894027.99968,
                50561607619.623726,
                54672760099.329704,
                58970483167.444145,
                63460969551.97591,
                68150453119.422714,
                73045187353.92764,
                78151422060.78928,
                83475378178.09578,
                89023220605.79633,
                94801028986.41986,
                100814766397.00717,
                107070245938.41925
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 30,
              "pct_change_gp": 0.11159879647764033,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.787080441675142,
                3.6240024118501757,
                3.4989181327624292,
                3.3975426454211575,
                3.3122970196033803,
                3.238698310516157,
                3.175875542305891,
                3.119510322963092,
                3.068349269166481,
                3.021468694032069,
                2.9781693048238194,
                2.937909658201427,
                2.9002624748599275,
                2.8648850228167326,
                2.831498477356192,
                2.7998731890958233,
                2.7698179468401705,
                2.741172006183837,
                2.7137990734561916,
                2.6875826980919184,
                2.662422696601343,
                2.638232343622778,
                2.6149361412210226,
                2.5924680295454645,
                2.570769938223483,
                2.549790603568227,
                2.529484595156986,
                2.5098115087922106
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.1025685957377152,
                0.21554651046697432,
                0.33943228728642816,
                0.47485174144150677,
                0.6225097872629536,
                0.7831732959942431,
                0.8208549834423616,
                0.8601422685754628,
                0.9011166967217596,
                0.9438577560543703,
                0.9884439417378758,
                1.0349533590704578,
                1.0834640386604195,
                1.1340540574411346,
                1.1868015178368636,
                1.241784414408056,
                1.2990804039645563,
                1.3587664870035825,
                1.4209186031767542,
                1.4856111400387113,
                1.5529163519800122,
                1.622903684539874,
                1.6956389980685596,
                1.771183683817894,
                1.849593664927613,
                1.9309182744141398,
                2.015199002183405,
                2.1024681032699077
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                367147017.29144543,
                532615024.34059435,
                714848385.7951342,
                915276142.3084323,
                1135501797.4090512,
                1377296366.0427852,
                1588384735.5992935,
                1816402624.884092,
                2062656539.2985795,
                2328553857.3379765,
                2615609403.5377936,
                2925452535.728583,
                3259834715.491782,
                3620637542.2636595,
                4009881236.3180633,
                4429733556.432585,
                4882519135.686353,
                5370729214.181136,
                5897031740.803306,
                6464281807.539211,
                7075532369.290878,
                7734045189.511063,
                8443301937.139266,
                9207015343.097872,
                10029140304.809761,
                10913884804.632368,
                11865720482.584461,
                12889392675.1107
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 6,
              "pct_change_gp": 0.179295685698942,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2139225687266295,
                2.1067795747316134,
                2.0264137527288795,
                1.9617640437866941,
                1.9074075594800304,
                1.8602957820606763,
                1.8264144785989889,
                1.7952364918414268,
                1.7662945459369577,
                1.7392324468279812,
                1.7137728218960153,
                1.6896957375547899,
                1.6668240814851853,
                1.64501330036816,
                1.6241440272306373,
                1.604116676563334,
                1.5848474105603492,
                1.5662650803775315,
                1.5483088734478176,
                1.5309264805013134,
                1.514072650808463,
                1.4977080413414514,
                1.4817982911948226,
                1.4663132705898774,
                1.4512264665881978,
                1.436514476877362,
                1.4221565897431379,
                1.4081344333343768
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.16912165416702918,
                0.40551146051279574,
                0.6908540523157999,
                1.0123086775693668,
                1.3603520388918167,
                1.7276150340309655,
                1.8070507343736635,
                1.8730579180865103,
                1.9274890929471569,
                1.9718521216773544,
                2.0073973548859048,
                2.0351775727283523,
                2.0560905105955123,
                2.070909849938861,
                2.0803083400277806,
                2.08487540905183,
                2.085130825778376,
                2.081535471230545,
                2.0744999561026467,
                2.0643916053457994,
                2.051540186256505,
                2.0362426562806863,
                2.0187671363826194,
                1.99935626559095,
                1.9782300557858943,
                1.955588338946811,
                1.9316128790744225,
                1.9064692059390558
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1437502.1278816424,
                2717548.7414545016,
                4312866.099974384,
                6188604.476251356,
                8318612.11706507,
                10682399.242112719,
                12867643.669391202,
                15201908.789786484,
                17677569.17494919,
                20287905.012536317,
                23026927.004927684,
                25889245.478299573,
                28869970.361183576,
                31964633.278317656,
                35169125.84885857,
                38479650.09560833,
                41892678.06624227,
                45404918.57189353,
                49013289.502280094,
                52714894.56554662,
                56507003.579151414,
                60387035.64030179,
                64352544.6535796,
                68401206.80491745,
                72530809.65551445,
                76739242.59393528,
                81024488.4346729,
                85384615.99055763
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3087271581059449,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                348.8960447684575,
                313.8158912323046,
                291.45778786196934,
                275.5882031097565,
                263.5539348209071,
                254.00968710195664,
                247.26166224438506,
                241.46249322357522,
                236.40585728104466,
                231.9434572636741,
                227.9655821126125,
                224.38907463761782,
                221.14958866975468,
                218.19643895513647,
                215.48907671539286,
                212.9946172865325,
                210.68606769713824,
                208.5410313743154,
                206.54074518798063,
                204.6693524876573,
                202.91334664563053,
                201.26113973626394,
                199.702724369047,
                198.22940577232345,
                196.8335874887563,
                195.50859843377594,
                194.24855218983578,
                193.04823165856772
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.14612302893481682,
                0.3517432326967893,
                0.6006094085802913,
                0.8813590501483523,
                1.1855828697455055,
                1.506783011453568,
                1.5769601514757845,
                1.635293714173352,
                1.6834228620024494,
                1.722680408752011,
                1.7541705963437448,
                1.7788225150014816,
                1.797427948974542,
                1.8106689226230364,
                1.8191382295453256,
                1.8233550536846834,
                1.8237770765811698,
                1.8208100160387866,
                1.814815251657496,
                1.8061160016190274,
                1.7950023852202321,
                1.7817356169162366,
                1.7665515145241732,
                1.749663459728216,
                1.7312649165668126,
                1.7115315895232084,
                1.6906232853543364,
                1.668685529121584
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1416156.4727874303,
                2666374.698858877,
                4224604.0389329195,
                6056852.932474004,
                8137609.1432686355,
                10446882.786094703,
                12614883.648989627,
                14932827.040423088,
                17392972.401154622,
                19988508.013641912,
                22713369.813543312,
                25562106.310877036,
                28529775.60701135,
                31611865.34318878,
                34804229.41149135,
                38103037.167589575,
                41504732.134973,
                45005998.03024409,
                48603730.51597786,
                52295013.49199066,
                56077099.02444286,
                59947390.221581474,
                63903426.51913969,
                67942870.95355168,
                72063499.08819675,
                76263189.32447086,
                80539914.38095364,
                84891733.76412061
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3221964208523207,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                400.2443478946841,
                357.2443864074253,
                329.74410963003834,
                310.19386186537804,
                295.35559950886085,
                283.5812381574148,
                275.13881040553684,
                267.8966982105946,
                261.5912799616706,
                256.0337518973343,
                251.08485240765808,
                246.63929233028097,
                242.61577617554178,
                238.95038774020566,
                235.59207712874206,
                232.49950320900362,
                229.63877519560958,
                226.9818055607328,
                224.50508778064918,
                222.1887751361564,
                220.0159766233072,
                217.972211935703,
                216.0449846816364,
                214.223444641926,
                212.4981178919512,
                210.86068922104258,
                209.30382526482123,
                207.82102963151306
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 10160984.290632429,
      "catalyzed_erp": 467.4743965876514
    },
    "5000": {
      "tech": {
        "entries": {
          "dac": "Direct Air Capture",
          "saf": "Sustainable Aviation Fuel",
          "gh": "Green Hydrogen",
          "ldes": "Long Duration Energy Storage"
        }
      },
      "erpg": {
        "years": [
          2020,
          2021,
          2022,
          2023,
          2024,
          2025,
          2026,
          2027,
          2028,
          2029,
          2030,
          2031,
          2032,
          2033,
          2034,
          2035,
          2036,
          2037,
          2038,
          2039,
          2040,
          2041,
          2042,
          2043,
          2044,
          2045,
          2046,
          2047,
          2048,
          2049,
          2050
        ],
        "erp": {
          "dac": [
            0.0,
            0.0,
            0.0,
            0.3317462691426044,
            0.7955735761712843,
            1.3555142943499612,
            1.9863549709756856,
            2.669397945223305,
            3.390175944209456,
            3.5461386238012373,
            3.675737687065495,
            3.782610606925069,
            3.8697179921445777,
            3.9395147451100883,
            3.994067798033219,
            4.03513963556999,
            4.06424915880266,
            4.0827170925514755,
            4.0917005692280615,
            4.092219955814508,
            4.085180005089384,
            4.071386776301915,
            4.051561349325256,
            4.026351071286718,
            3.9963388783841234,
            3.9620510970117966,
            3.92396402970706,
            3.8825095599442623,
            3.8380799565888,
            3.7910320201335894,
            3.741690682641304
          ],
          "saf": [
            0.0,
            0.0,
            0.0,
            0.5492222902646597,
            1.105738858341244,
            1.6690288500886779,
            2.2389967050517403,
            2.8157525783001462,
            3.3995343331458034,
            3.4205779261582623,
            3.4421875405259046,
            3.464496034084677,
            3.487623760698445,
            3.511683532119473,
            3.5367836965446924,
            3.5630300903235756,
            3.5905272768389542,
            3.6193793093065887,
            3.649690157369051,
            3.6815638820321377,
            3.7151046105223617,
            3.75041634233052,
            3.7876026047393747,
            3.826765967616627,
            3.868007421534098,
            3.91142561931615,
            3.9571159784098047,
            4.0051696395904095,
            4.055672276309495,
            4.108702748314597,
            4.164331592980181
          ],
          "gh": [
            0.0,
            0.0,
            0.0,
            0.8601606934040319,
            1.7897281795593145,
            2.786276058019927,
            3.8441943177561435,
            4.964473382791871,
            6.152190238276587,
            6.34158244408309,
            6.530998091059356,
            6.656435618481774,
            6.779999190745681,
            6.90182242786941,
            7.021964872364569,
            7.140429786791985,
            7.257176123058054,
            7.372126813986815,
            7.485174675881267,
            7.59618672338402,
            7.7050074116042815,
            7.811461146297839,
            7.9153542940502515,
            8.016476854401791,
            8.114603909837818,
            8.209496938516633,
            8.300905053361411,
            8.388566216109831,
            8.472208464045357,
            8.55155117916426,
            8.62630642327472
          ],
          "ldes": [
            0.0,
            0.0,
            0.0,
            1.1032354732753173,
            2.220701528460654,
            3.346271724304057,
            3.6662286043050276,
            3.9781951462306155,
            4.282423901221532,
            4.278087709150242,
            4.272055631591427,
            4.264656383458066,
            4.25613897083803,
            4.246696450546334,
            4.236481398424357,
            4.225616345921919,
            4.214201037097887,
            4.202317605868014,
            4.190034352783059,
            4.177408554146777,
            4.164488587505313,
            4.151315564536926,
            4.137924602787432,
            4.124345828182842,
            4.110605174297144,
            4.096725025858625,
            4.082724741749077,
            4.068621083641361,
            4.0544285699838545,
            4.04015977041724,
            4.025825552350199
          ]
        }
      },
      "gpg": {
        "details": {
          "dac": {
            "cost_parity": 0.0,
            "uom": "MtCO2",
            "initial_gp": 449.719510720004,
            "start_gp": 449.719510720004,
            "end_gp": 277.3386534475469,
            "pct_change": 0.3833074909213393
          },
          "saf": {
            "cost_parity": 0.5988,
            "uom": "L",
            "initial_gp": 1.1559840748257437,
            "start_gp": 1.1559840748257437,
            "end_gp": 0.9433453751265465,
            "pct_change": 0.1839460459100624
          },
          "gh": {
            "cost_parity": 1.1500000555523695,
            "uom": "kg",
            "initial_gp": 2.8700469032259868,
            "start_gp": 2.8700469032259868,
            "end_gp": 2.159070357021168,
            "pct_change": 0.24772297114924072
          },
          "ldes": {
            "cost_parity": 45.0,
            "uom": "MWh",
            "initial_gp": 75.75184790516712,
            "start_gp": 75.75184790516712,
            "end_gp": 68.86511566284763,
            "pct_change": 0.09091173922174041
          }
        }
      },
      "pyag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2048.9205479452053,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-12-01T00:00:00",
            "x3": 1.084931506849315,
            "y": 161271928.23069414,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.73698630137,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-09-25T00:00:00",
            "x3": 1.2684931506849315,
            "y": 50194496265.512276,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.786301369863,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-14T00:00:00",
            "x3": 0.21643835616438356,
            "y": 105781653469.65025,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.2246575342465,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-03-23T00:00:00",
            "x3": 2.780821917808219,
            "y": 215314482.68542016,
            "uom": "MWh"
          }
        }
      },
      "cryag": {
        "details": {
          "dac": {
            "x1": 2050.0027397260274,
            "x2": 2048.931506849315,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-12-05T00:00:00",
            "x3": 1.073972602739726,
            "y": 201.81470345707385,
            "uom": "MtCO2"
          },
          "saf": {
            "x1": 2050.0027397260274,
            "x2": 2048.449315068493,
            "d1": "2050-01-01T00:00:00",
            "d2": "2048-06-12T00:00:00",
            "x3": 1.5561643835616439,
            "y": 1.3395975532529445,
            "uom": "L"
          },
          "gh": {
            "x1": 2050.0027397260274,
            "x2": 2049.7917808219177,
            "d1": "2050-01-01T00:00:00",
            "d2": "2049-10-16T00:00:00",
            "x3": 0.21095890410958903,
            "y": 2.513752357850782,
            "uom": "kg"
          },
          "ldes": {
            "x1": 2050.0027397260274,
            "x2": 2047.164383561644,
            "d1": "2050-01-01T00:00:00",
            "d2": "2047-03-01T00:00:00",
            "x3": 2.841095890410959,
            "y": 108.05859465918095,
            "uom": "MWh"
          }
        }
      },
      "funds": {
        "total_fund_size": 5000000000.0,
        "tech_funds": {
          "dac": 1250000000.0,
          "saf": 1250000000.0,
          "gh": 1250000000.0,
          "ldes": 1250000000.0
        },
        "investment_year": 2022
      },
      "pgm": {
        "details": {
          "LDES2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.7887278098452679,
                1.5945165975496398,
                2.4127797956353847,
                2.430605379226795,
                2.4460824925483546,
                2.459745880109222,
                2.471961183878765,
                2.4829899369796076,
                2.4930258529643408,
                2.5022164680442525,
                2.510676734579326,
                2.5184979250330612,
                2.5257536703755408,
                2.5325041768322123,
                2.5387992451954333,
                2.544680480131696,
                2.5501829377241005,
                2.555336374814264,
                2.560166210583955,
                2.5646942765749445,
                2.568939408740479,
                2.572917919891518,
                2.576643980444778,
                2.5801299280217007,
                2.5833865213030722,
                2.586423149730958,
                2.589248007976559,
                2.5918682420127173
              ],
              "ref": [
                0.07084800000029645,
                0.5995163404650157,
                1.2026192718412858,
                1.7895490534880536,
                2.3712053750316255,
                2.9443865879563735,
                3.507432543392439,
                4.059405000394973,
                4.5997620111739295,
                5.128201720894585,
                5.644577692534144,
                6.148848869758762,
                6.641048048399386,
                7.1212610014255855,
                7.5896121044391895,
                8.046254118681542,
                8.49136073812599,
                8.925121034852054,
                9.347735244516777,
                9.759411520535139,
                10.160363402984105,
                10.550807824349594,
                10.930963524895885,
                11.301049784926526,
                11.661285405236653,
                12.011887884050886,
                12.353072751063086,
                12.685053028119198,
                13.008038792841079,
                13.322236826417575,
                13.627850330706666
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                7203299.146014622,
                11422270.185820203,
                15907193.573381834,
                18177312.083598487,
                20530276.98766406,
                22967707.90224142,
                25491441.52187075,
                28103496.214718007,
                30806048.2785007,
                33601415.685766526,
                36492046.82111152,
                39480512.65288741,
                42569501.33399966,
                45761814.56317402,
                49060365.250358954,
                52468176.16762307,
                55988379.35848108,
                59624216.140851445,
                63379037.5820334,
                67256305.35457198,
                71259592.90373437,
                75392586.87321177,
                79659088.74735986,
                84063016.67699915,
                88608407.46233974,
                93299418.67155099,
                98140330.87727445,
                103135549.9962708
              ],
              "ref": [
                192000.00000063577,
                1624705.5297195616,
                3259130.8179996535,
                4999717.971358286,
                6829663.967099829,
                8742854.721282767,
                10736828.693961134,
                12810832.2633182,
                14965064.409509324,
                17200322.666562255,
                19517815.790271405,
                21919055.90125098,
                24405792.44558398,
                26979969.868158948,
                29643699.548796043,
                32399240.737738587,
                35248987.400043875,
                38195459.073073745,
                41241294.53035274,
                44389247.45897221,
                47642183.61516121,
                51003079.08768249,
                54475019.40734899,
                58061199.31415683,
                61764923.043886594,
                65589605.03130979,
                69538770.95227392,
                73616059.0451113,
                77825221.66512659,
                82170127.03578112,
                86654761.16757452
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                130032121.21212122,
                260054242.42424244,
                390076363.6363636,
                520098484.8484849,
                650120606.0606061,
                780142727.2727273,
                910164848.4848485,
                1040186969.6969697,
                1170209090.909091,
                1300231212.1212122,
                1430253333.3333335,
                1560275454.5454545,
                1690297575.7575758,
                1820319696.969697,
                1950341818.1818182,
                2080363939.3939395,
                2210386060.6060605,
                2340408181.818182,
                2470430303.030303,
                2600452424.2424245,
                2730474545.4545455,
                2860496666.666667,
                2990518787.878788,
                3120540909.090909,
                3250563030.3030305,
                3380585151.5151515,
                3510607272.727273,
                3640629393.939394,
                3770651515.1515155,
                3900673636.3636365,
                4030695757.575758,
                4160717878.787879,
                4290740000.0,
                4420762121.212121,
                4550784242.424243,
                4680806363.636364,
                4810828484.848485,
                4940850606.060606,
                5070872727.272728,
                5200894848.484849,
                5330916969.69697,
                5460939090.909091,
                5590961212.121212,
                5720983333.333334,
                5851005454.545455,
                5981027575.757576,
                6111049696.969697,
                6241071818.181818,
                6371093939.39394,
                6501116060.606061,
                6631138181.818182,
                6761160303.030303,
                6891182424.242425,
                7021204545.454546,
                7151226666.666667,
                7281248787.878788,
                7411270909.090909,
                7541293030.303031,
                7671315151.515152,
                7801337272.727273,
                7931359393.939394,
                8061381515.151516,
                8191403636.363637,
                8321425757.575758,
                8451447878.787879,
                8581470000.0,
                8711492121.212122,
                8841514242.424242,
                8971536363.636364,
                9101558484.848486,
                9231580606.060606,
                9361602727.272728,
                9491624848.484848,
                9621646969.69697,
                9751669090.909092,
                9881691212.121212,
                10011713333.333334,
                10141735454.545456,
                10271757575.757576,
                10401779696.969698,
                10531801818.181818,
                10661823939.39394,
                10791846060.606062,
                10921868181.818182,
                11051890303.030304,
                11181912424.242424,
                11311934545.454546,
                11441956666.666668,
                11571978787.878788,
                11702000909.09091,
                11832023030.30303,
                11962045151.515152,
                12092067272.727274,
                12222089393.939394,
                12352111515.151516,
                12482133636.363636,
                12612155757.575758,
                12742177878.78788,
                12872200000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                172.0685568555433,
                107.71706586333654,
                105.90981465280687,
                104.94623036664387,
                104.30130861023993,
                103.82211020654995,
                103.44370272473473,
                103.13268891407031,
                102.869714884388,
                102.64260502697154,
                102.44322400945268,
                102.26587914890357,
                102.10643858348215,
                101.96181286696941,
                101.82963437084742,
                101.70805057889204,
                101.59558607814262,
                101.49104763817917,
                101.39345723418826,
                101.30200372296699,
                101.21600728785846,
                101.13489282098968,
                101.05816968561514,
                100.98541611401521,
                100.91626702734837,
                100.8504044180686,
                100.78754967656397,
                100.72745741055523,
                100.66991042319765,
                100.61471559969004,
                100.5617005128411,
                100.51071060252502,
                100.46160681687635,
                100.41426362777509,
                100.36856735184861,
                100.32441472246653,
                100.28171166922132,
                100.24037226987971,
                100.20031784649844,
                100.1614761826467,
                100.12378084285695,
                100.08717057876746,
                100.0515888090861,
                100.01698316268455,
                99.98330507588251,
                99.95050943642654,
                99.91855426784223,
                99.88740044881085,
                99.85701146303198,
                99.82735317569646,
                99.79839363326582,
                99.77010288370944,
                99.74245281475511,
                99.71541700803975,
                99.68897060732743,
                99.66309019921006,
                99.6377537048994,
                99.61294028190459,
                99.5886302345346,
                99.56480493229705,
                99.54144673537668,
                99.51853892647064,
                99.49606564834556,
                99.47401184655011,
                99.45236321678637,
                99.4311061564921,
                99.41022772023896,
                99.38971557859327,
                99.36955798012126,
                99.34974371626049,
                99.3302620887986,
                99.31110287973407,
                99.29225632331291,
                99.27371308005634,
                99.25546421261359,
                99.2375011632874,
                99.21981573309672,
                99.20240006225161,
                99.18524661192974,
                99.16834814725144,
                99.15169772135981,
                99.13528866052272,
                99.11911455017726,
                99.10316922184909,
                99.08744674087994,
                99.07194139490502,
                99.05664768302645,
                99.04156030563227,
                99.02667415481741,
                99.01198430536282,
                98.9974860062351,
                98.98317467257121,
                98.96904587811522,
                98.95509534807798,
                98.94131895239043,
                98.92771269932614,
                98.91427272946781,
                98.90099530999765,
                98.8878768292896,
                98.87491379178512
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.07530478782224209,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                118.01043872084223,
                116.01734528356319,
                114.67789606661638,
                114.1591154900214,
                113.69578778676691,
                113.27708029348834,
                112.89500355570057,
                112.54350429490547,
                112.21789171908941,
                111.91446065694272,
                111.6302359312765,
                111.36279414435363,
                111.11013644165921,
                110.87059576056903,
                110.6427679631891,
                110.42545986014353,
                110.21764940400509,
                110.01845479835022,
                109.82711023765522,
                109.64294664662224,
                109.46537623614313,
                109.29388000633776,
                109.12799754917104,
                108.96731866281654,
                108.81147640622223,
                108.66014130804885,
                108.51301650803313,
                108.36983365693116
              ],
              "ref": [
                140.44099999997263,
                125.61339041029485,
                121.82211190441383,
                119.70245317788243,
                118.25082049480763,
                117.15419651040118,
                116.27599435552273,
                115.54490874494381,
                114.91922954821693,
                114.37254744497609,
                113.88713332868302,
                113.4505363891781,
                113.053693561284,
                112.68981229144386,
                112.35367674022307,
                112.04119895032598,
                111.74911834279989,
                111.47479454757192,
                111.21606092757708,
                110.97111871056998,
                110.73845897477507,
                110.51680416439372,
                110.30506356849828,
                110.10229895936094,
                109.90769773973092,
                109.72055171963602,
                109.54024016869661,
                109.36621615424838,
                109.19799543221738,
                109.03514734114307,
                108.87728728264163
              ],
              "uom": "MWh"
            }
          },
          "SAF4": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.3509498806857039,
                0.6989652453980745,
                1.0432297847068488,
                1.3831497600415426,
                1.7182262272951043,
                2.0480090524363384,
                2.0332087157975303,
                2.0175203462666853,
                2.0009809411410595,
                1.9836195063615392,
                1.965460028354482,
                1.9465233697254327,
                1.9268285289606428,
                1.9063935065511781,
                1.8852359175933935,
                1.8633734350881879,
                1.8408241163613637,
                1.8176066461783227,
                1.7937405186236308,
                1.769246172567967,
                1.7441450908754608,
                1.7184598704320908,
                1.692214268023949,
                1.6654332256891282,
                1.638142878219106,
                1.6103705448202275,
                1.5821447064988217,
                1.5534949704150451
              ],
              "ref": [
                0.00926240000001144,
                1.3844143691463842,
                2.846871372168721,
                4.223499350059566,
                5.554759554407965,
                6.840241078113443,
                8.080129587325828,
                9.274864053287613,
                10.425007682737439,
                11.531188589638106,
                12.594068976641605,
                13.614327813730593,
                14.592650605039113,
                15.52972317773054,
                16.426227893863285,
                17.282841392505333,
                18.10023333524077,
                18.879065829338785,
                19.619993318988318,
                20.32366280488334,
                20.990714296016993,
                21.621781425550566,
                22.217492181107563,
                22.778469712344805,
                23.305333187280414,
                23.79869867494645,
                24.259180036299874,
                24.687389808535883,
                25.08394007034069,
                25.44944327746148,
                25.784513059420988
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1515533295.096735,
                2135962882.6505527,
                2775879874.7261605,
                3435208703.3916554,
                4113954911.01298,
                4812153848.801948,
                5395066053.709793,
                5991312705.942223,
                6600903315.228614,
                7223835979.13427,
                7860095486.288892,
                8509651772.568635,
                9172458621.002504,
                9848452539.864296,
                10537551778.877443,
                11239655458.897438,
                11954642800.107542,
                12682372439.987175,
                13422681836.400427,
                14175386753.871956,
                14940280832.931847,
                15717135243.606077,
                16505698424.886635,
                17305695912.453205,
                18116830257.114456,
                18938781036.444973,
                19771204961.950916,
                20613736083.830303
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974098843.9393941,
                1947931919.1287882,
                2921764994.3181825,
                3895598069.5075765,
                4869431144.696971,
                5843264219.886365,
                6817097295.075759,
                7790930370.265153,
                8764763445.454548,
                9738596520.643942,
                10712429595.833336,
                11686262671.02273,
                12660095746.212124,
                13633928821.401518,
                14607761896.590912,
                15581594971.780306,
                16555428046.9697,
                17529261122.159096,
                18503094197.348488,
                19476927272.537884,
                20450760347.727276,
                21424593422.91667,
                22398426498.106064,
                23372259573.29546,
                24346092648.48485,
                25319925723.674248,
                26293758798.86364,
                27267591874.053036,
                28241424949.242428,
                29215258024.431824,
                30189091099.621216,
                31162924174.81061,
                32136757250.000008,
                33110590325.1894,
                34084423400.378796,
                35058256475.56819,
                36032089550.75758,
                37005922625.946976,
                37979755701.13637,
                38953588776.32577,
                39927421851.51516,
                40901254926.70455,
                41875088001.89394,
                42848921077.08334,
                43822754152.272736,
                44796587227.46213,
                45770420302.65152,
                46744253377.84092,
                47718086453.03031,
                48691919528.2197,
                49665752603.4091,
                50639585678.598495,
                51613418753.78789,
                52587251828.97728,
                53561084904.16668,
                54534917979.35607,
                55508751054.54546,
                56482584129.734856,
                57456417204.924255,
                58430250280.11365,
                59404083355.30304,
                60377916430.49243,
                61351749505.68183,
                62325582580.87122,
                63299415656.060616,
                64273248731.250015,
                65247081806.43941,
                66220914881.6288,
                67194747956.81819,
                68168581032.00759,
                69142414107.19698,
                70116247182.38638,
                71090080257.57578,
                72063913332.76517,
                73037746407.95456,
                74011579483.14395,
                74985412558.33334,
                75959245633.52274,
                76933078708.71213,
                77906911783.90154,
                78880744859.09093,
                79854577934.28032,
                80828411009.46971,
                81802244084.6591,
                82776077159.8485,
                83749910235.03789,
                84723743310.2273,
                85697576385.41669,
                86671409460.60608,
                87645242535.79547,
                88619075610.98486,
                89592908686.17426,
                90566741761.36365,
                91540574836.55304,
                92514407911.74245,
                93488240986.93184,
                94462074062.12123,
                95435907137.31062,
                96409740212.50002
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.102833107423759,
                1.2065309304485687,
                1.152900112161703,
                1.1227771819878885,
                1.1019454335699541,
                1.0860894244060944,
                1.0733269244629533,
                1.0626698237140841,
                1.0535359229784695,
                1.0455537911438015,
                1.0384722194965184,
                1.0321135309157452,
                1.0263475196267708,
                1.021075978538649,
                1.0162230447113836,
                1.011728923837595,
                1.0075456689435693,
                1.0036342569630439,
                0.9999625126509316,
                0.9965036016717997,
                0.9932349156057538,
                0.9901372327740634,
                0.9871940770074653,
                0.9843912209228707,
                0.9817162963854908,
                0.979158485590724,
                0.9767082735750645,
                0.9743572480852449,
                0.9720979363455687,
                0.9699236708686889,
                0.9678284783282088,
                0.9658069869100602,
                0.9638543485761549,
                0.9619661734559884,
                0.9601384741701309,
                0.9583676183332838,
                0.9566502878469619,
                0.9549834438438934,
                0.95336429637374,
                0.9517902780811697,
                0.9502590212623138,
                0.9487683377959759,
                0.9473162015253638,
                0.9459007327427561,
                0.9445201844818398,
                0.9431729303730817,
                0.941857453852743,
                0.9405723385481308,
                0.9393162596899294,
                0.9380879764210183,
                0.9368863248952426,
                0.935710212068077,
                0.9345586100989183,
                0.9334305512942516,
                0.932325123529254,
                0.9312414660970467,
                0.9301787659360656,
                0.9291362541965001,
                0.9281132031092126,
                0.9271089231262077,
                0.9261227603053246,
                0.925154093913621,
                0.924202334228748,
                0.9232669205180667,
                0.9223473191799877,
                0.9214430220311496,
                0.9205535447261229,
                0.9196784252979278,
                0.9188172228073362,
                0.9179695160934908,
                0.917134902614826,
                0.9163129973736486,
                0.9155034319169139,
                0.9147058534067001,
                0.913919923755407,
                0.9131453188193512,
                0.912381727646764,
                0.9116288517753305,
                0.910886404575998,
                0.9101541106392184,
                0.9094317052001638,
                0.9087189336003542,
                0.9080155507823031,
                0.9073213208159099,
                0.9066360164532372,
                0.9059594187100788,
                0.9052913164724025,
                0.9046315061255412,
                0.9039797912054695,
                0.9033359820696408,
                0.9026998955866429,
                0.9020713548431069,
                0.9014501888669055,
                0.9008362323657231,
                0.9002293254795936,
                0.8996293135468658,
                0.8990360468823207,
                0.8984493805672914,
                0.8978691742505743,
                0.8972952919595517
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.1018934574227761,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.1720076907096033,
                1.1459737859474077,
                1.1265331796617917,
                1.1109988951038117,
                1.0980456839128832,
                1.086923695012338,
                1.078892348095806,
                1.071588769812742,
                1.0648878871557905,
                1.0586944976862644,
                1.0529345049207417,
                1.0475491427989705,
                1.0424910453158132,
                1.0377214971698325,
                1.0332084641867676,
                1.028925152666629,
                1.024848936081366,
                1.0209605422927286,
                1.0172434289971461,
                1.0136832974525818,
                1.0102677093342844,
                1.0069857815584236,
                1.0038279407878046,
                1.0007857241444191,
                0.9978516160713689,
                0.9950189137493218,
                0.9922816152700711,
                0.9896343260974237
              ],
              "ref": [
                1.7804691011737197,
                1.2705844706563698,
                1.2115214113964676,
                1.178158963853396,
                1.1548911126725077,
                1.1370168050511138,
                1.1224933319566235,
                1.1102508007116823,
                1.0996598177738481,
                1.0903193151562847,
                1.0819582974984145,
                1.0743852512043843,
                1.067459938994545,
                1.061076668207581,
                1.0551538578698096,
                1.0496272609089938,
                1.044445406981918,
                1.0395664474749462,
                1.0349559155424726,
                1.0305851005336,
                1.026429845326642,
                1.0224696412163201,
                1.0186869362686104,
                1.0150665995074486,
                1.0115955006537167,
                1.0082621767704463,
                1.0050565651166943,
                1.0019697870385214,
                0.9989939716286755,
                0.9961221106837939,
                0.9933479385195084
              ],
              "uom": "L"
            }
          },
          "LDES1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.31450766343004943,
                0.626184930911014,
                0.9334919286686723,
                1.2356232250782324,
                1.5321126536822611,
                1.8226780211123101,
                1.8061265252714767,
                1.7890656946118195,
                1.7716305304937254,
                1.7539225027937781,
                1.7360197159670074,
                1.7179834733912958,
                1.6998626755463784,
                1.6816968602656743,
                1.6635183606725805,
                1.6453538726513626,
                1.6272256164226764,
                1.6091522126910491,
                1.5911493539529706,
                1.5732303262124883,
                1.5554064194423634,
                1.5376872544056266,
                1.5200810454138465,
                1.5025948137273761,
                1.485234562338289,
                1.4680054202528965,
                1.4509117624406813,
                1.4339573103374812
              ],
              "ref": [
                0.04955520000657998,
                0.7635182525773416,
                1.5569526430335827,
                2.3116031417213527,
                3.0441535118135348,
                3.7521620713404076,
                4.434669226230716,
                5.091388107967108,
                5.722390089036391,
                6.32795590687547,
                6.908495724318512,
                7.464502275554417,
                7.996521584081278,
                8.50513375181,
                8.990939873626196,
                9.454552859713852,
                9.896590849838027,
                10.317672403887839,
                10.718412943278365,
                11.099422093965678,
                11.461301692433954,
                11.804644287619992,
                12.130032019197543,
                12.438035785254568,
                12.72921463485299,
                13.004115337000755,
                13.26327208907667,
                13.507206336163275,
                13.736426679169558,
                13.951428854199152,
                14.152695769223381
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                10489464.265617998,
                15113833.70412105,
                19891475.937441897,
                24815927.807522994,
                29884150.48727717,
                35094960.21464586,
                39004833.18818847,
                42996749.58673793,
                47071339.3850575,
                51229373.998725064,
                55471740.516558275,
                59799423.0942839,
                64213489.22753937,
                68715079.44204211,
                73305399.43459843,
                77985714.00895493,
                82757342.35058019,
                87621654.31687759,
                92580067.50900777,
                97634044.95349813,
                102785093.26548113,
                108034761.19667178,
                113384638.49392994,
                118836355.01101261,
                124391580.02863424,
                130052021.74740331,
                135819426.92541897,
                141695580.6378746
              ],
              "ref": [
                191999.99999948827,
                2958226.472572486,
                6032362.041981866,
                9233227.517977508,
                12535309.935395112,
                15928628.438026283,
                19408246.102536984,
                22971509.158510506,
                26616996.636165507,
                30344032.855424073,
                34152430.36204591,
                38042341.673886694,
                42014167.8953155,
                46068499.41495767,
                50206075.82069421,
                54427757.89453412,
                58734507.509110816,
                63127372.86843087,
                67607477.4677321,
                72176011.70605314,
                76834226.43207203,
                81583427.92593235,
                86424973.96590781,
                91360270.727193,
                96390770.327835,
                101517968.88431157,
                106743404.97313862,
                112068658.41943233,
                117495349.35138525,
                123025137.47303876,
                128659721.51784565
              ],
              "uom": "MWh"
            },
            "plcg": {
              "capacity": [
                10000.0,
                593665454.5454545,
                1187320909.090909,
                1780976363.6363635,
                2374631818.181818,
                2968287272.7272725,
                3561942727.272727,
                4155598181.8181815,
                4749253636.363636,
                5342909090.90909,
                5936564545.454545,
                6530220000.0,
                7123875454.545454,
                7717530909.090908,
                8311186363.636363,
                8904841818.181818,
                9498497272.727272,
                10092152727.272726,
                10685808181.81818,
                11279463636.363636,
                11873119090.90909,
                12466774545.454544,
                13060430000.0,
                13654085454.545454,
                14247740909.090908,
                14841396363.636362,
                15435051818.181816,
                16028707272.727272,
                16622362727.272726,
                17216018181.81818,
                17809673636.363636,
                18403329090.90909,
                18996984545.454544,
                19590640000.0,
                20184295454.545452,
                20777950909.090908,
                21371606363.63636,
                21965261818.181816,
                22558917272.727272,
                23152572727.272724,
                23746228181.81818,
                24339883636.363636,
                24933539090.90909,
                25527194545.454544,
                26120850000.0,
                26714505454.545452,
                27308160909.090908,
                27901816363.63636,
                28495471818.181816,
                29089127272.727272,
                29682782727.272724,
                30276438181.81818,
                30870093636.363632,
                31463749090.90909,
                32057404545.454544,
                32651059999.999996,
                33244715454.545452,
                33838370909.090908,
                34432026363.63636,
                35025681818.181816,
                35619337272.72727,
                36212992727.27273,
                36806648181.81818,
                37400303636.36363,
                37993959090.90909,
                38587614545.454544,
                39181270000.0,
                39774925454.54545,
                40368580909.090904,
                40962236363.63636,
                41555891818.181816,
                42149547272.72727,
                42743202727.27272,
                43336858181.81818,
                43930513636.36363,
                44524169090.90909,
                45117824545.454544,
                45711480000.0,
                46305135454.54545,
                46898790909.090904,
                47492446363.63636,
                48086101818.181816,
                48679757272.72727,
                49273412727.27272,
                49867068181.81818,
                50460723636.36363,
                51054379090.90909,
                51648034545.454544,
                52241690000.0,
                52835345454.54545,
                53429000909.090904,
                54022656363.63636,
                54616311818.181816,
                55209967272.72727,
                55803622727.27272,
                56397278181.81818,
                56990933636.36363,
                57584589090.90909,
                58178244545.454544,
                58771900000.0
              ],
              "x_axis_uom": "MWh/year",
              "cost": [
                164.7420369847251,
                102.45457896976077,
                100.54216664048971,
                99.4906704095808,
                98.77317322641592,
                98.2324082684461,
                97.80053546105404,
                97.4422381991766,
                97.13684876390938,
                96.87125700248457,
                96.63664012712465,
                96.42678519918829,
                96.23715709574799,
                96.06434737700256,
                95.90573164876497,
                95.75924758483825,
                95.62324606712708,
                95.49638838386191,
                95.37757341633929,
                95.26588491751325,
                95.16055259216844,
                95.06092286864752,
                94.96643661033131,
                94.87661188401492,
                94.7910304715449,
                94.70932719202686,
                94.63118136175171,
                94.55630989938835,
                94.48446171120233,
                94.41541308211426,
                94.34896386444017,
                94.28493430464516,
                94.22316238445502,
                94.16350157969927,
                94.10581896075699,
                94.04999357414825,
                93.99591505692102,
                93.94348244488837,
                93.89260314315558,
                93.84319203319669,
                93.79517069537405,
                93.74846672949458,
                93.70301315897808,
                93.6587479066252,
                93.61561333193242,
                93.57355582151058,
                93.53252542547723,
                93.49247553378794,
                93.45336258736944,
                93.4151458196745,
                93.37778702490387,
                93.3412503496714,
                93.30550210533191,
                93.27051059856811,
                93.23624597815252,
                93.20268009607152,
                93.16978638143134,
                93.1375397257639,
                93.10591637852237,
                93.07489385170149,
                93.04445083264736,
                93.01456710422858,
                92.98522347163875,
                92.95640169518165,
                92.92808442846375,
                92.90025516148232,
                92.872898168152,
                92.84599845786289,
                92.8195417307046,
                92.79351433603013,
                92.76790323406522,
                92.74269596030011,
                92.71788059242542,
                92.69344571959805,
                92.66938041384343,
                92.64567420341893,
                92.62231704797992,
                92.59929931540394,
                92.5766117601434,
                92.55424550298653,
                92.53219201211958,
                92.51044308539059,
                92.48899083368535,
                92.46782766533263,
                92.44694627146401,
                92.42633961225852,
                92.40600090400972,
                92.38592360695637,
                92.36610141382398,
                92.34652823902769,
                92.32719820849175,
                92.30810565004363,
                92.28924508434467,
                92.27061121632168,
                92.2521989270667,
                92.23400326617491,
                92.2160194444924,
                92.19824282724791,
                92.1806689275447,
                92.16329340018976
              ],
              "y_axis_uom": "$/MWh",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.09162974493761933,
              "cost_at_parity": 45.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.10398975613505,
                115.48809868036427,
                114.31584729220137,
                113.397833056055,
                112.64399880302501,
                112.00461861324251,
                111.59068128107923,
                111.21317165177658,
                110.8660805509729,
                110.54475469037182,
                110.24551961930716,
                109.96542479313703,
                109.70206628222016,
                109.45346043630865,
                109.21795192438519,
                108.9941455307233,
                108.78085472182224,
                108.57706227940311,
                108.38188976318143,
                108.19457353478157,
                108.01444572509429,
                107.84091897353078,
                107.67347407866288,
                107.51164991997135,
                107.35503516859576,
                107.20326142007805,
                107.05599746686244,
                106.91294449145475
              ],
              "ref": [
                139.91900000001885,
                123.24677105088799,
                119.68158390592042,
                117.6841762670866,
                116.30740026738081,
                115.26120424664819,
                114.41933371665287,
                113.7157699313232,
                113.11177885699398,
                112.58275017821796,
                112.112103218997,
                111.68815245854276,
                111.30236274171381,
                110.94831614229241,
                110.62106900871075,
                110.31673509760421,
                110.03220584543035,
                109.76495709219522,
                109.512912129257,
                109.27434250266066,
                109.04779476268149,
                108.83203543948487,
                108.62600907382405,
                108.42880576326674,
                108.23963575374421,
                108.0578093220472,
                107.88272068337005,
                107.71383499718404,
                107.55067778400293,
                107.39282623687969,
                107.23990203572028
              ],
              "uom": "MWh"
            }
          },
          "SAF3": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.09052426318127117,
                0.18035057968558854,
                0.2692476075124865,
                0.357055019952758,
                0.44364103632476,
                0.5288880170285528,
                0.5251597710913941,
                0.5211988571654045,
                0.5170157277097766,
                0.5126184328424658,
                0.5080135145935057,
                0.503206569717494,
                0.49820261945427563,
                0.4930063613180195,
                0.4876223457656801,
                0.48205510322693385,
                0.47630923720730695,
                0.4703894934539228,
                0.46430081169288706,
                0.4580483642909794,
                0.4516375847970512,
                0.44507418842486474,
                0.43836418591839765,
                0.4315138918480273,
                0.4245299280926007,
                0.41741922308161267,
                0.4101890072286498,
                0.4028468049057473
              ],
              "ref": [
                0.004927999999949861,
                0.736568709098492,
                1.5146594966797693,
                2.247085506682265,
                2.955373886262938,
                3.639306014957669,
                4.298980675239923,
                4.934631418919569,
                5.546557896498826,
                6.13509429194773,
                6.700592925903683,
                7.243415039953349,
                7.763925352137004,
                8.262488752359596,
                8.739468286940669,
                9.195223957318282,
                9.630112052606862,
                10.044484842695413,
                10.43869052038074,
                10.813073318196729,
                11.167973748787698,
                11.503728932578307,
                11.820672986320739,
                12.119137452758977,
                12.399451756231377,
                12.66194367228111,
                12.90693980165899,
                13.134766040817215,
                13.3457480422611,
                13.540211659108948,
                13.71848336897856
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1455631739.7479477,
                2013008752.8854916,
                2586736010.4775677,
                3176732172.3931494,
                3782991496.815613,
                4405538395.747165,
                4978974403.100766,
                5565732455.176252,
                6165828739.8997,
                6779269695.903656,
                7406049757.160492,
                8046149572.945165,
                8699534543.157946,
                9366153574.995615,
                10045938004.183832,
                10738800645.942562,
                11444634954.303328,
                12163314276.89194,
                12894691197.793392,
                13638596964.746832,
                14394840999.339088,
                15163210490.477234,
                15943470072.474035,
                16735361589.735561,
                17538603950.401417,
                18352893071.425453,
                19177901917.54587,
                20013280636.41245
              ],
              "ref": [
                2976609.9999776455,
                444902147.96862173,
                914884457.0642285,
                1399262198.345934,
                1897230947.967548,
                2408544139.6833415,
                2933119742.5378466,
                3470941432.037314,
                4022021449.1339355,
                4586383214.67859,
                5164051891.395951,
                5755048651.998426,
                6359386868.28682,
                6977069380.453199,
                7608086416.031206,
                8252413923.518759,
                8910012186.073298,
                9580824635.38107,
                10264776817.137096,
                10961775478.291433,
                11671707757.801653,
                12394440470.013348,
                13129819474.604246,
                13877669130.217537,
                14637791831.038744,
                15409967626.982965,
                16193953929.072033,
                16989485302.133648,
                17796273347.238323,
                18614006676.366848,
                19442350981.7137
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                974096159.4065657,
                1947926550.0631313,
                2921756940.719697,
                3895587331.3762627,
                4869417722.032828,
                5843248112.689394,
                6817078503.34596,
                7790908894.002525,
                8764739284.659092,
                9738569675.315657,
                10712400065.972221,
                11686230456.628788,
                12660060847.285355,
                13633891237.94192,
                14607721628.598484,
                15581552019.25505,
                16555382409.911617,
                17529212800.568184,
                18503043191.224747,
                19476873581.881313,
                20450703972.53788,
                21424534363.194443,
                22398364753.85101,
                23372195144.507576,
                24346025535.164143,
                25319855925.82071,
                26293686316.477272,
                27267516707.13384,
                28241347097.790405,
                29215177488.446968,
                30189007879.103535,
                31162838269.7601,
                32136668660.416668,
                33110499051.073235,
                34084329441.729797,
                35058159832.38637,
                36031990223.04293,
                37005820613.69949,
                37979651004.356064,
                38953481395.01263,
                39927311785.66919,
                40901142176.32576,
                41874972566.98232,
                42848802957.638885,
                43822633348.295456,
                44796463738.95202,
                45770294129.60859,
                46744124520.26515,
                47717954910.921715,
                48691785301.578285,
                49665615692.23485,
                50639446082.89142,
                51613276473.54798,
                52587106864.204544,
                53560937254.861115,
                54534767645.51768,
                55508598036.17424,
                56482428426.83081,
                57456258817.48737,
                58430089208.143936,
                59403919598.80051,
                60377749989.45707,
                61351580380.11364,
                62325410770.7702,
                63299241161.426765,
                64273071552.083336,
                65246901942.7399,
                66220732333.39647,
                67194562724.05303,
                68168393114.709595,
                69142223505.36617,
                70116053896.02274,
                71089884286.67929,
                72063714677.33586,
                73037545067.99243,
                74011375458.64899,
                74985205849.30556,
                75959036239.96213,
                76932866630.61868,
                77906697021.27525,
                78880527411.93182,
                79854357802.58838,
                80828188193.24495,
                81802018583.90152,
                82775848974.55807,
                83749679365.21465,
                84723509755.87122,
                85697340146.52777,
                86671170537.18434,
                87645000927.84091,
                88618831318.49748,
                89592661709.15404,
                90566492099.81061,
                91540322490.46718,
                92514152881.12373,
                93487983271.7803,
                94461813662.43687,
                95435644053.09343,
                96409474443.75
              ],
              "x_axis_uom": "L/year",
              "cost": [
                2.009460894193349,
                1.674695323608866,
                1.654664401030849,
                1.6434135922301913,
                1.635633007236746,
                1.6297108434039342,
                1.6249440944234022,
                1.6209637046596836,
                1.6175522247601142,
                1.6145709265066295,
                1.61192598432352,
                1.60955103643337,
                1.6073974514336142,
                1.6054285493201343,
                1.6036159955508429,
                1.6019374571602847,
                1.6003750261245955,
                1.5989141274639476,
                1.597542743837118,
                1.596250852713752,
                1.5950300099278119,
                1.5938730362511766,
                1.5927737778915436,
                1.591726920971215,
                1.5907278460349175,
                1.5897725126706952,
                1.5888573670736947,
                1.5879792672963244,
                1.5871354222830627,
                1.5863233417504305,
                1.5855407946823419,
                1.584785774725341,
                1.5840564711538903,
                1.583351244366748,
                1.5826686050913428,
                1.5820071966449818,
                1.5813657797295773,
                1.5807432193386506,
                1.5801384734354567,
                1.579550583121685,
                1.578978664068531,
                1.5784218990204273,
                1.577879531214772,
                1.5773508585872043,
                1.5768352286519758,
                1.5763320339659397,
                1.5758407080978618,
                1.5753607220374042,
                1.5748915809877326,
                1.5744328214931926,
                1.5739840088614214,
                1.573544734844111,
                1.5731146155463063,
                1.5726932895377785,
                1.5722804161432282,
                1.5718756738917175,
                1.5714787591075838,
                1.5710893846280147,
                1.5707072786337781,
                1.5703321835811832,
                1.5699638552251842,
                1.569602061724178,
                1.5692465828188253,
                1.5688972090774989,
                1.5685537412019037,
                1.5682159893873895,
                1.5678837727326074,
                1.5675569186943978,
                1.5672352625835924,
                1.5669186470980871,
                1.5666069218900684,
                1.5662999431641949,
                1.565997573304495,
                1.5656996805272962,
                1.5654061385580644,
                1.5651168263303166,
                1.5648316277045877,
                1.5645504312062448,
                1.5642731297803698,
                1.5639996205624618,
                1.563729804663829,
                1.5634635869703397,
                1.5632008759539124,
                1.5629415834954903,
                1.5626856247187655,
                1.5624329178339371,
                1.5621833839905854,
                1.5619369471393822,
                1.5616935339017026,
                1.5614530734467205,
                1.5612154973755146,
                1.5609807396115207,
                1.5607487362972712,
                1.5605194256966648,
                1.5602927481025652,
                1.560068645749395,
                1.5598470627302328,
                1.5596279449184742,
                1.5594112398934368,
                1.5591968968698238
              ],
              "y_axis_uom": "$/L",
              "start_index": 98,
              "end_index": 99,
              "pct_change_gp": 0.03187906124288401,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.6629599592474138,
                1.653739739072295,
                1.646757943969072,
                1.6411334433251492,
                1.636418744149566,
                1.6323558549610382,
                1.6291256543589119,
                1.6262100296919637,
                1.6235513449575383,
                1.6211065316666808,
                1.6188425826472805,
                1.6167336601661744,
                1.6147591714581393,
                1.6129024480708753,
                1.6111498153541923,
                1.6094899216561145,
                1.6079132449398372,
                1.6064117234010125,
                1.60497847451698,
                1.6036075783084736,
                1.6022939079879521,
                1.6010329960923275,
                1.5998209275421906,
                1.598654253382242,
                1.5975299205831825,
                1.596445214445026,
                1.5953977109806396,
                1.5943852372727114
              ],
              "ref": [
                1.8890590926570177,
                1.6986190097081852,
                1.6765591686550143,
                1.664098414011298,
                1.6554079549471887,
                1.6487319650813688,
                1.6433074999077206,
                1.638734958343907,
                1.6347792641564898,
                1.6312906198891235,
                1.6281678097454133,
                1.6253393040833635,
                1.6227527247813944,
                1.6203685960091252,
                1.6181564475651313,
                1.616092283398083,
                1.614156879519171,
                1.6123346056211147,
                1.610612588460551,
                1.6089801047122192,
                1.6074281317777073,
                1.6059490097290614,
                1.6045361829817988,
                1.6031840001705642,
                1.6018875571831426,
                1.6006425726536397,
                1.5994452881843613,
                1.5982923876298663,
                1.597180931234492,
                1.5961083014593884,
                1.5950721580938885
              ],
              "uom": "L"
            }
          },
          "GH1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.8601606934040319,
                1.7897281795593145,
                2.786276058019927,
                3.8441943177561435,
                4.964473382791871,
                6.152190238276587,
                6.34158244408309,
                6.530998091059356,
                6.656435618481774,
                6.779999190745681,
                6.90182242786941,
                7.021964872364569,
                7.140429786791985,
                7.257176123058054,
                7.372126813986815,
                7.485174675881267,
                7.59618672338402,
                7.7050074116042815,
                7.811461146297839,
                7.9153542940502515,
                8.016476854401791,
                8.114603909837818,
                8.209496938516633,
                8.300905053361411,
                8.388566216109831,
                8.472208464045357,
                8.55155117916426,
                8.62630642327472
              ],
              "ref": [
                5.562275041134853,
                25.38315551752021,
                47.9080789152722,
                70.46276678371476,
                93.54256916968534,
                117.20237773211882,
                141.2134607390907,
                165.62401957315058,
                190.56821989096963,
                215.73548977315448,
                241.2502480073223,
                264.54574732139065,
                287.72085159374404,
                310.7707557793199,
                333.6912745815091,
                356.47851562577176,
                379.12863326327266,
                401.6376379229151,
                424.00124488727613,
                446.2147518354515,
                468.2729379521252,
                490.1699796559499,
                511.8993795113811,
                533.4539059274109,
                554.8255419782065,
                576.0054422069791,
                596.9838966603982,
                617.7503016890389,
                638.2931372675538,
                658.5999507551741,
                678.6573471454518
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0027397260274,
                2021.0027397260274,
                2022.0027397260274,
                2023.0027397260274,
                2024.0027397260274,
                2025.0027397260274,
                2026.0027397260274,
                2027.0027397260274,
                2028.0027397260274,
                2029.0027397260274,
                2030.0027397260274,
                2031.0027397260274,
                2032.0027397260274,
                2033.0027397260274,
                2034.0027397260274,
                2035.0027397260274,
                2036.0027397260274,
                2037.0027397260274,
                2038.0027397260274,
                2039.0027397260274,
                2040.0027397260274,
                2041.0027397260274,
                2042.0027397260274,
                2043.0027397260274,
                2044.0027397260274,
                2045.0027397260274,
                2046.0027397260274,
                2047.0027397260274,
                2048.0027397260274,
                2049.0027397260274,
                2050.0027397260274
              ],
              "cat": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5247948248.047759,
                7157208993.156482,
                9184701189.238865,
                11331912780.08728,
                13601724235.025188,
                15997852143.979258,
                18436837304.435284,
                21002109287.81445,
                23698230974.68208,
                26530019341.67093,
                29502512722.536476,
                32620946204.218403,
                35890731751.97501,
                39317440993.46793,
                42906789338.15864,
                46664620544.86096,
                50596891115.86996,
                54709654063.730865,
                59009041706.47188,
                63501247221.88091,
                68192504744.708565,
                73089067830.82579,
                78197186144.599,
                83523080253.48422,
                89072914439.37299,
                94852767461.11911,
                100868601228.03943,
                107126227370.79205
              ],
              "ref": [
                410488134.19755423,
                1851709936.4289048,
                3458218877.920872,
                5184657536.860082,
                7022842582.420174,
                8971421748.097754,
                11031604087.647367,
                13205886293.35151,
                15497538883.504972,
                17910359165.080936,
                20448537385.87754,
                23116577290.9068,
                25919245181.97717,
                28861534816.492195,
                31948641427.578392,
                35185941054.722595,
                38578972894.83184,
                42133423224.55139,
                45855109931.95307,
                49749966989.80241,
                53824028386.84117,
                58083411153.1971,
                62534297196.82964,
                67182913724.93542,
                72035512066.64973,
                77098344746.95581,
                82377640690.18193,
                87879578457.31445,
                93610257446.28026,
                99575667009.54274,
                105781653469.65025
              ],
              "uom": "kg"
            },
            "plcg": {
              "capacity": [
                82506.83949265181,
                11917735433.417694,
                23835388359.995895,
                35753041286.5741,
                47670694213.1523,
                59588347139.7305,
                71506000066.3087,
                83423652992.8869,
                95341305919.4651,
                107258958846.0433,
                119176611772.6215,
                131094264699.1997,
                143011917625.7779,
                154929570552.35608,
                166847223478.9343,
                178764876405.5125,
                190682529332.0907,
                202600182258.66888,
                214517835185.2471,
                226435488111.82532,
                238353141038.4035,
                250270793964.9817,
                262188446891.5599,
                274106099818.13812,
                286023752744.7163,
                297941405671.2945,
                309859058597.8727,
                321776711524.4509,
                333694364451.0291,
                345612017377.6073,
                357529670304.18555,
                369447323230.76373,
                381364976157.3419,
                393282629083.9201,
                405200282010.4983,
                417117934937.07654,
                429035587863.6547,
                440953240790.2329,
                452870893716.81116,
                464788546643.38934,
                476706199569.9675,
                488623852496.5457,
                500541505423.1239,
                512459158349.70215,
                524376811276.28033,
                536294464202.8585,
                548212117129.43677,
                560129770056.0149,
                572047422982.5931,
                583965075909.1714,
                595882728835.7495,
                607800381762.3278,
                619718034688.9059,
                631635687615.4841,
                643553340542.0624,
                655470993468.6405,
                667388646395.2188,
                679306299321.797,
                691223952248.3751,
                703141605174.9534,
                715059258101.5316,
                726976911028.1097,
                738894563954.688,
                750812216881.2661,
                762729869807.8444,
                774647522734.4226,
                786565175661.0007,
                798482828587.579,
                810400481514.1571,
                822318134440.7354,
                834235787367.3136,
                846153440293.8917,
                858071093220.47,
                869988746147.0482,
                881906399073.6263,
                893824052000.2046,
                905741704926.7828,
                917659357853.361,
                929577010779.9392,
                941494663706.5173,
                953412316633.0956,
                965329969559.6738,
                977247622486.252,
                989165275412.8302,
                1001082928339.4083,
                1013000581265.9866,
                1024918234192.5648,
                1036835887119.143,
                1048753540045.7212,
                1060671192972.2994,
                1072588845898.8776,
                1084506498825.4558,
                1096424151752.034,
                1108341804678.6123,
                1120259457605.1904,
                1132177110531.7688,
                1144094763458.347,
                1156012416384.925,
                1167930069311.5034,
                1179847722238.0815
              ],
              "x_axis_uom": "kg/year",
              "cost": [
                21.19222563749037,
                3.373212080219745,
                3.0655037006621755,
                2.901444967441286,
                2.791642438732016,
                2.710055931517391,
                2.645629922923479,
                2.592678190014983,
                2.5479055577765997,
                2.5092390286745956,
                2.4752935005210723,
                2.4450997535393886,
                2.417954310464093,
                2.3933311732896954,
                2.3708272387328955,
                2.350127104959922,
                2.330979574488234,
                2.3131814932263577,
                2.29656634717583,
                2.2809960349393448,
                2.266354814234406,
                2.2525447700498993,
                2.2394823690552634,
                2.2270958032483,
                2.21532291621196,
                2.2041095656652447,
                2.1934083170295944,
                2.1831773911459367,
                2.173379809269188,
                2.1639826927408246,
                2.1549566850693065,
                2.1462754717168426,
                2.1379153785005776,
                2.12985503371887,
                2.1220750822930965,
                2.1145579426436307,
                2.107287598889354,
                2.1002494224126074,
                2.0934300179684677,
                2.086817090413188,
                2.080399328837753,
                2.0741663054603947,
                2.0681083870881882,
                2.062216657326635,
                2.0564828480156927,
                2.050899278615477,
                2.045458802465645,
                2.040154759008097,
                2.0349809311998492,
                2.0299315074569453,
                2.025001047565687,
                2.0201844520772827,
                2.0154769347693144,
                2.0108739978142234,
                2.0063714093431337,
                2.001965183134305,
                1.997651560190387,
                1.993426991998567,
                1.989288125293298,
                1.9852317881633994,
                1.9812549773643318,
                1.9773548467129245,
                1.9735286964561378,
                1.9697739635178353,
                1.966088212538389,
                1.9624691276313884,
                1.9589145047899976,
                1.9554222448827787,
                1.9519903471851672,
                1.9486169033984344,
                1.9453000921129069,
                1.942038173676622,
                1.938829485434483,
                1.9356724373064225,
                1.9325655076761554,
                1.929507239564835,
                1.9264962370663574,
                1.923531162023241,
                1.9206107309239517,
                1.9177337120042943,
                1.9148989225370459,
                1.9121052262954317,
                1.9093515311772868,
                1.906636786977913,
                1.9039599833006495,
                1.9013201475951167,
                1.8987163433139305,
                1.8961476681794478,
                1.8936132525527936,
                1.8911122578980433,
                1.8886438753350177,
                1.8862073242746424,
                1.8838018511313266,
                1.8814267281072152,
                1.8790812520435867,
                1.8767647433350125,
                1.8744765449022318,
                1.8722160212199874,
                1.8699825573963496,
                1.8677755583003104
              ],
              "y_axis_uom": "$/kg",
              "start_index": 3,
              "end_index": 30,
              "pct_change_gp": 0.11170339337278762,
              "cost_at_parity": 1.1500000555523695
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.786808670449398,
                3.623600949891396,
                3.4984420191723484,
                3.397018185163402,
                3.3117385334816545,
                3.2381143273346393,
                3.17535496257653,
                3.119040280628702,
                3.0679204169280387,
                3.0210740387696142,
                2.977803485827076,
                2.9375684810494738,
                2.899942599813281,
                2.8645837502751585,
                2.8312135963437455,
                2.7996028678593987,
                2.769560652300645,
                2.740926443595813,
                2.7135641405024584,
                2.687357449471038,
                2.66220631636451,
                2.638024123315989,
                2.6147354624331083,
                2.59227434983976,
                2.5705827796964864,
                2.549609543468243,
                2.5293092581330914,
                2.509641560444515
              ],
              "ref": [
                5.504117951346168,
                4.40142076773321,
                4.020046958778356,
                3.7933653922872246,
                3.6333273908799733,
                3.5100092280561817,
                3.4097861093923147,
                3.325356881703627,
                3.25237367618202,
                3.188047400675443,
                3.1304869579481687,
                3.0783538668305614,
                3.0306677495271,
                2.986690141892334,
                2.945851697236006,
                2.9077047398325773,
                2.8718912945656307,
                2.8381209236665668,
                2.806154979942117,
                2.7757951761072497,
                2.746875128728217,
                2.719253996671977,
                2.692811622773265,
                2.6674447729563657,
                2.643064189012245,
                2.6195922530946456,
                2.5969611179891094,
                2.57511119616391,
                2.5539899281411333,
                2.5335507704613573,
                2.513752357850782
              ],
              "uom": "kg"
            }
          },
          "SAF2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.10774814639768465,
                0.226423033257581,
                0.35655145786934256,
                0.49879192505743974,
                0.6538853146802819,
                0.822637263680912,
                0.8622094392693379,
                0.9034683370938146,
                0.9464993652338408,
                0.9913858214944397,
                1.0382099891714853,
                1.0870537571017658,
                1.1379989419086576,
                1.1911274089697563,
                1.2465210459475151,
                1.304261619053929,
                1.364430528463467,
                1.4271084708901163,
                1.4923750120140025,
                1.5603080678804284,
                1.630983291944115,
                1.704473362677142,
                1.7808471653738032,
                1.8601688608726488,
                1.9424968332787027,
                2.0278825084076546,
                2.1163690345871258,
                2.2079898176593886
              ],
              "ref": [
                0.0464799999999741,
                0.34572594229814513,
                0.6784045872200295,
                1.0096391548774908,
                1.349513736133445,
                1.6980944771329562,
                2.0556885572155355,
                2.422724254110631,
                2.799704419257717,
                3.1871847967780633,
                3.585762681175203,
                3.996070495319244,
                4.4187719360951165,
                4.854559546494654,
                5.304153109719043,
                5.768298520256366,
                6.247766920962666,
                6.743353968060058,
                7.255879127114875,
                7.786184926760618,
                8.335136110625312,
                8.90361863559264,
                9.492538468446082,
                10.10282013441278,
                10.735404970964769,
                11.391249038961764,
                12.071320641146611,
                12.77659739541493,
                13.50806280734125,
                14.266702283327284,
                15.05349852261916
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                368856820.6754275,
                536316478.9417833,
                720854496.571729,
                923935114.8281492,
                1147201051.8154,
                1392466735.6741407,
                1604773491.696189,
                1834103764.5783217,
                2081771360.5840793,
                2349191430.03377,
                2637887080.062609,
                2949496498.751318,
                3285780560.7083488,
                3648630895.0978827,
                4040078401.510915,
                4462302199.398879,
                4917638994.24534,
                5408592838.837771,
                5937845261.182259,
                6508265721.855013,
                7122922352.869488,
                7785092917.35091,
                8498275914.301241,
                9266201735.329905,
                10092843760.228197,
                10982429255.485071,
                11939449914.096533,
                12968671846.152355
              ],
              "ref": [
                14883049.999997618,
                110702592.20136502,
                217227396.55394167,
                333288456.26983047,
                459261100.63816804,
                595761546.3529667,
                743526073.6351506,
                903381384.7451316,
                1076236207.064201,
                1263080198.3851743,
                1464986053.4385154,
                1683113308.8121583,
                1918713211.0863225,
                2173134352.121872,
                2447828923.700525,
                2744359513.915222,
                3064406402.6383047,
                3409775330.94572,
                3782405727.614731,
                4184379378.589672,
                4617929524.530262,
                5085450368.171217,
                5589506967.687547,
                6132845484.762928,
                6718403746.629192,
                7349322069.918688,
                8028954280.624749,
                8760878848.637333,
                9548910037.028088,
                10397108945.308405,
                11309794302.084877
              ],
              "uom": "L"
            },
            "plcg": {
              "capacity": [
                265768.75,
                4869441882.828282,
                9738617996.906565,
                14607794110.984848,
                19476970225.06313,
                24346146339.14141,
                29215322453.219696,
                34084498567.297977,
                38953674681.37626,
                43822850795.454544,
                48692026909.53282,
                53561203023.61111,
                58430379137.68939,
                63299555251.76767,
                68168731365.845955,
                73037907479.92424,
                77907083594.00252,
                82776259708.0808,
                87645435822.15909,
                92514611936.23737,
                97383788050.31564,
                102252964164.39394,
                107122140278.47221,
                111991316392.55049,
                116860492506.62878,
                121729668620.70706,
                126598844734.78534,
                131468020848.86362,
                136337196962.94191,
                141206373077.0202,
                146075549191.09848,
                150944725305.17676,
                155813901419.25504,
                160683077533.3333,
                165552253647.4116,
                170421429761.48987,
                175290605875.56818,
                180159781989.64645,
                185028958103.72473,
                189898134217.803,
                194767310331.8813,
                199636486445.95956,
                204505662560.03787,
                209374838674.11615,
                214244014788.19443,
                219113190902.2727,
                223982367016.35098,
                228851543130.42926,
                233720719244.50757,
                238589895358.58585,
                243459071472.66412,
                248328247586.7424,
                253197423700.82068,
                258066599814.89896,
                262935775928.97723,
                267804952043.05554,
                272674128157.13382,
                277543304271.2121,
                282412480385.2904,
                287281656499.36865,
                292150832613.44696,
                297020008727.5252,
                301889184841.6035,
                306758360955.68176,
                311627537069.7601,
                316496713183.8384,
                321365889297.9166,
                326235065411.99493,
                331104241526.0732,
                335973417640.1515,
                340842593754.22974,
                345711769868.30804,
                350580945982.38635,
                355450122096.4646,
                360319298210.5429,
                365188474324.62115,
                370057650438.69946,
                374926826552.7778,
                379796002666.856,
                384665178780.9343,
                389534354895.0126,
                394403531009.0909,
                399272707123.1691,
                404141883237.24744,
                409011059351.32574,
                413880235465.404,
                418749411579.4823,
                423618587693.56055,
                428487763807.63885,
                433356939921.7171,
                438226116035.7954,
                443095292149.8737,
                447964468263.95197,
                452833644378.0303,
                457702820492.1085,
                462571996606.1868,
                467441172720.26514,
                472310348834.3434,
                477179524948.4217,
                482048701062.49994
              ],
              "x_axis_uom": "L/year",
              "cost": [
                6.241292293915355,
                1.585374438888381,
                1.4563885277708497,
                1.3872755254664408,
                1.34087468040618,
                1.3063183919208632,
                1.278980870151794,
                1.2564782748201102,
                1.2374269812406982,
                1.2209553882751871,
                1.2064804503445978,
                1.1935937673350103,
                1.1819986441811319,
                1.171473054969152,
                1.1618467194108228,
                1.1529863115161827,
                1.144785577022484,
                1.1371585318446134,
                1.1300346599191218,
                1.1233554464658468,
                1.1170718259220707,
                1.111142270415023,
                1.105531335720109,
                1.1002085397706842,
                1.0951474867627717,
                1.0903251752548286,
                1.0857214459204194,
                1.0813185365664868,
                1.0771007204443928,
                1.0730540098909305,
                1.0691659116873196,
                1.0654252237131554,
                1.0618218648367737,
                1.0583467317555344,
                1.054991577840237,
                1.0517489100626825,
                1.048611900874638,
                1.045574312519679,
                1.0426304317394253,
                1.0397750132140964,
                1.037003230377748,
                1.034310632488443,
                1.0316931070265603,
                1.029146846650326,
                1.0266683200642208,
                1.0242542462595654,
                1.0219015716714657,
                1.0196074498664012,
                1.0173692234327816,
                1.0151844077951093,
                1.0130506767127307,
                1.0109658492579765,
                1.0089278780970308,
                1.0069348389208979,
                1.0049849208942025,
                1.003076418006968,
                1.001207721229282,
                0.9993773113814375,
                0.9975837526430006,
                0.9958256866336178,
                0.9941018270064563,
                0.9924109545021228,
                0.9907519124170351,
                0.989123602445436,
                0.9875249808588319,
                0.9859550549906907,
                0.9844128799977168,
                0.9828975558721176,
                0.9814082246819834,
                0.9799440680192889,
                0.9785043046371439,
                0.9770881882597516,
                0.9756950055502519,
                0.9743240742230224,
                0.9729747412883446,
                0.9716463814185121,
                0.9703383954254795,
                0.9690502088410782,
                0.9677812705916603,
                0.9665310517597626,
                0.9652990444260643,
                0.9640847605854775,
                0.9628877311318126,
                0.961707504905868,
                0.9605436478022844,
                0.9593957419308846,
                0.9582633848285722,
                0.9571461887181978,
                0.9560437798110853,
                0.9549557976501845,
                0.9538818944910591,
                0.9528217347181195,
                0.9517749942937648,
                0.9507413602382117,
                0.9497205301380045,
                0.9487122116813376,
                0.9477161222184604,
                0.9467319883455669,
                0.945759545510686,
                0.9447985376401995
              ],
              "y_axis_uom": "$/L",
              "start_index": 0,
              "end_index": 6,
              "pct_change_gp": 0.18038334211727944,
              "cost_at_parity": 0.5988
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2125450081208347,
                2.1048446237647216,
                2.024183347491447,
                1.9593509845083,
                1.9048694590360564,
                1.8576656103565563,
                1.8240050081098556,
                1.7930081077142555,
                1.7642173641901084,
                1.7372834007242932,
                1.7119337587483079,
                1.687952133823849,
                1.6651641504717738,
                1.6434273572288858,
                1.6226240273680241,
                1.602655873684509,
                1.5834400991180217,
                1.5649063986544078,
                1.546994650973661,
                1.5296531183812352,
                1.5128370268266849,
                1.4965074339544249,
                1.4806303180943705,
                1.4651758386252502,
                1.450117730631093,
                1.435432805791967,
                1.4211005380485422,
                1.407102717463119
              ],
              "ref": [
                3.454370312536824,
                2.6057213168051248,
                2.3762716444257492,
                2.2428421543676635,
                2.148695263690895,
                2.0757363115292247,
                2.0159541823753555,
                1.9651223366223258,
                1.9207482274343435,
                1.8812433393637378,
                1.8455355837173029,
                1.8128687616649122,
                1.7826904668355996,
                1.7545855031118687,
                1.728234350925286,
                1.703386189553391,
                1.679840768597472,
                1.6574358680291272,
                1.6360384045043856,
                1.6155379847684161,
                1.5958421423971438,
                1.5768727579957214,
                1.5585633276933384,
                1.5408568503333815,
                1.523704173009575,
                1.5070626809978716,
                1.490895249818778,
                1.4751693991788999,
                1.4598566040783487,
                1.444931729496913,
                1.4303725631454365
              ],
              "uom": "L"
            }
          },
          "DAC2": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.17799599051621043,
                0.4260615758790416,
                0.7251451290279975,
                1.0618736321464672,
                1.4263199099647932,
                1.8107975530650007,
                1.8935822908984359,
                1.9623616640245498,
                2.019064997019333,
                2.0652634023879806,
                2.102260175397598,
                2.1311530233212856,
                2.1528782413743506,
                2.1682429238255723,
                2.177949007758597,
                2.182611594469663,
                2.182773167561792,
                2.1789148072456865,
                2.171465164715748,
                2.160807738091198,
                2.147286840960886,
                2.131212550690876,
                2.112864850488639,
                2.092497126972624,
                2.0703391473123927,
                2.046599611693258,
                2.0214683564178513,
                1.9951182670870156
              ],
              "ref": [
                0.0025905000657076016,
                0.102804165238922,
                0.4909289660427021,
                1.1443815401651227,
                2.003127536895756,
                3.017080676105763,
                4.14865220047536,
                5.368801666885097,
                6.654432388299653,
                7.986768153671224,
                9.350302232929145,
                10.732083881924302,
                12.121212661572601,
                13.508466635206169,
                14.886020432313314,
                16.247225850329585,
                17.586437362279042,
                18.898870760311038,
                20.180486838140947,
                21.427894392027213,
                22.63826840222928,
                23.809280337832906,
                24.939038283120503,
                26.026035122858087,
                27.069103415636384,
                28.06737587435089,
                29.020250590429047,
                29.927360304235854,
                30.7885451521584,
                31.603828420633885,
                32.37339491644263
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1447214.230175926,
                2740734.4244118617,
                4352751.59772673,
                6248038.738008678,
                8400161.915986404,
                10788410.200234702,
                12981333.461729782,
                15322869.659091216,
                17805443.897279955,
                20422376.770046826,
                23167711.89739184,
                26036086.854391254,
                29022634.439315658,
                32122905.703330353,
                35332808.94222217,
                38648560.629147284,
                42066645.43760312,
                45583783.29286679,
                49196901.93398874,
                52903113.85076561,
                56699696.73375169,
                60584076.7744091,
                64553814.29942896,
                68606591.33319604,
                72740200.7656521,
                76952536.86662617,
                81241586.93710975,
                85605423.92658603
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42671010.101010114,
                85332020.20202023,
                127993030.30303034,
                170654040.40404046,
                213315050.50505057,
                255976060.60606068,
                298637070.7070708,
                341298080.8080809,
                383959090.909091,
                426620101.01010114,
                469281111.1111113,
                511942121.21212137,
                554603131.3131315,
                597264141.4141417,
                639925151.5151517,
                682586161.6161618,
                725247171.7171719,
                767908181.818182,
                810569191.9191922,
                853230202.0202023,
                895891212.1212124,
                938552222.2222226,
                981213232.3232327,
                1023874242.4242427,
                1066535252.5252528,
                1109196262.626263,
                1151857272.727273,
                1194518282.8282833,
                1237179292.9292934,
                1279840303.0303035,
                1322501313.1313136,
                1365162323.2323236,
                1407823333.3333337,
                1450484343.4343438,
                1493145353.535354,
                1535806363.636364,
                1578467373.7373743,
                1621128383.8383844,
                1663789393.9393945,
                1706450404.0404046,
                1749111414.1414146,
                1791772424.2424247,
                1834433434.3434348,
                1877094444.4444451,
                1919755454.5454552,
                1962416464.6464653,
                2005077474.7474754,
                2047738484.8484855,
                2090399494.9494956,
                2133060505.0505056,
                2175721515.151516,
                2218382525.252526,
                2261043535.353536,
                2303704545.454546,
                2346365555.5555563,
                2389026565.6565666,
                2431687575.7575765,
                2474348585.858587,
                2517009595.9595966,
                2559670606.060607,
                2602331616.161617,
                2644992626.262627,
                2687653636.363637,
                2730314646.4646473,
                2772975656.5656576,
                2815636666.6666675,
                2858297676.767678,
                2900958686.8686876,
                2943619696.969698,
                2986280707.070708,
                3028941717.171718,
                3071602727.272728,
                3114263737.3737383,
                3156924747.4747486,
                3199585757.5757585,
                3242246767.676769,
                3284907777.7777786,
                3327568787.878789,
                3370229797.979799,
                3412890808.080809,
                3455551818.1818194,
                3498212828.2828293,
                3540873838.3838396,
                3583534848.4848495,
                3626195858.58586,
                3668856868.6868696,
                3711517878.78788,
                3754178888.8888903,
                3796839898.9899,
                3839500909.0909104,
                3882161919.1919203,
                3924822929.2929306,
                3967483939.3939404,
                4010144949.494951,
                4052805959.5959606,
                4095466969.696971,
                4138127979.7979813,
                4180788989.898991,
                4223450000.000001
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                906.6900575037627,
                210.19202210517076,
                193.0622569485196,
                184.2584478823435,
                178.50042293621487,
                174.29400274883508,
                171.01673878656266,
                168.35305900154074,
                166.12221213651085,
                164.2116028551119,
                162.54663895532266,
                161.07551712816309,
                159.76089085677893,
                158.57500619724553,
                157.49671242125015,
                156.50954561700283,
                155.6004558855302,
                154.75893616335426,
                153.97641028238127,
                153.24579331459208,
                152.56116936994718,
                151.9175512850324,
                151.3106985572661,
                150.73697745354633,
                150.19325214820338,
                149.67679902579027,
                149.18523850643209,
                148.71648028647087,
                148.26867896390942,
                147.84019778483213,
                147.42957880035644,
                147.03551812797892,
                146.656845310266,
                146.29250598766706,
                145.94154727011164,
                145.60310532140522,
                145.27639476888325,
                144.96069962744053,
                144.6553654868621,
                144.35979275843917,
                144.0734308141383,
                143.7957728812154,
                143.52635157912627,
                143.26473500482817,
                143.01052328789382,
                142.763345549888,
                142.5228572127175,
                142.28873760927266,
                142.06068785677394,
                141.83842895912846,
                141.62170010952948,
                141.41025716857285,
                141.203871296761,
                141.00232772309425,
                140.80542463385538,
                140.61297216792116,
                140.4247915066429,
                140.2407140478817,
                140.06058065509077,
                139.8842409734616,
                139.71155280613235,
                139.54238154422876,
                139.37659964536874,
                139.21408615576428,
                139.05472627163064,
                138.89841093613532,
                138.74503646850275,
                138.59450422226442,
                138.44672026996778,
                138.3015951119386,
                138.159043406953,
                138.0189837228404,
                137.88133830535762,
                137.74603286371024,
                137.61299637131887,
                137.48216088056685,
                137.35346135037258,
                137.22683548554275,
                137.1022235869604,
                136.97956841174823,
                136.85881504263418,
                136.73991076576917,
                136.6228049564221,
                136.50744897189696,
                136.3937960511536,
                136.28180122064083,
                136.17142120588656,
                136.0626143484313,
                135.95534052772422,
                135.84956108763333,
                135.74523876725493,
                135.64233763569285,
                135.54082303060335,
                135.4406615001899,
                135.34182074844514,
                135.24426958342363,
                135.14797786834572,
                135.05291647535117,
                134.95905724173255,
                134.86637292849164
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.30959879126552703,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                348.4970556398785,
                313.38240005854584,
                291.0363193054578,
                275.1861390420072,
                263.17106499300246,
                253.64420855033174,
                246.9499599890576,
                241.19239424183488,
                236.16873753533923,
                231.7330133685511,
                227.7770851711868,
                224.21889766980135,
                220.9948965644473,
                218.0549788054839,
                215.35903227124743,
                212.87450557300556,
                210.57466408291202,
                208.43731427535005,
                206.4438545997246,
                204.57855843424463,
                202.82802485812778,
                201.18075267921787,
                199.62680627762717,
                198.1575507342661,
                196.76543986421404,
                195.4438450891175,
                194.18691615249963,
                192.98946689516538
              ],
              "ref": [
                1193.2743607818602,
                558.8919186476966,
                416.82320778178047,
                357.20321398355327,
                323.4383102880723,
                301.14018570770634,
                285.0225206321112,
                272.6667996040472,
                262.7976015458681,
                254.67235848141956,
                247.82612374995864,
                241.95112032240095,
                236.83446358773116,
                232.32357789610637,
                228.30585627814264,
                224.6961189086979,
                221.42856861026206,
                218.4514549071728,
                215.72343114720238,
                213.21100440011523,
                210.88671067291858,
                208.72778353050225,
                206.71516576056237,
                204.83276423185094,
                203.06688020047255,
                201.4057682058546,
                199.83929057409594,
                198.3586439416905,
                196.95614068508806,
                195.62503267161847,
                194.35936796431446
              ],
              "uom": "MtCO2"
            }
          },
          "DAC1": {
            "ppeag": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                0.0,
                0.0,
                0.0,
                0.15375027862639398,
                0.36951200029224274,
                0.6303691653219635,
                0.9244813388292185,
                1.2430780352585116,
                1.5793783911444554,
                1.6525563329028015,
                1.7133760230409452,
                1.7635456099057358,
                1.8044545897565971,
                1.83725456971249,
                1.8629147747119332,
                1.8822613941956388,
                1.8960062349770876,
                1.904768084792879,
                1.9090889747583988,
                1.9094467882527155,
                1.9062651978436982,
                1.8999216115861663,
                1.8907536112340586,
                1.8790642303258318,
                1.865126327693247,
                1.8491862465231577,
                1.8314669027344364,
                1.8121704126318696,
                1.7914803448955423,
                1.769563663715738,
                1.7465724155542885
              ],
              "ref": [
                0.0025300000634160824,
                0.10040321870474145,
                0.47946353371662553,
                1.117655007382782,
                1.956345365124609,
                2.946618070082978,
                4.05176223400929,
                5.243415640694057,
                6.49902101617511,
                7.800240659633594,
                9.131929993944835,
                10.481440733939818,
                11.83812701555039,
                13.192982276422127,
                14.538363904171423,
                15.867778962104204,
                17.175713772074495,
                18.457495859327743,
                19.709180351475418,
                20.92745524486688,
                22.109561496865485,
                23.25322495839075,
                24.356597898586198,
                25.418208400240587,
                26.436916287031522,
                27.411874526965335,
                28.342495268783598,
                29.228419830037076,
                30.069492080665142,
                30.86573476325148,
                31.617328368499646
              ],
              "uom": "MMTCO2e"
            },
            "ppcig": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1424703.3684516924,
                2686901.7238577763,
                4260046.702055629,
                6109798.186729339,
                8210384.5815945305,
                10541613.486457737,
                12716581.058715578,
                15041117.686629834,
                17507529.603700392,
                20109042.419978898,
                22839622.42021972,
                25693843.19414893,
                28666783.861056264,
                31753949.892438557,
                34951210.46926711,
                38254748.18163901,
                41661018.10598233,
                45166714.12033485,
                48768740.88569456,
                52464190.31950779,
                56250321.67168506,
                60124544.5199747,
                64084404.153666,
                68127568.92825833,
                72251819.25971745,
                76455037.9927388,
                80735201.92832348,
                85090374.33571558
              ],
              "ref": [
                2750.000070059924,
                109133.9333735318,
                521156.01491278084,
                1252414.8446688957,
                2260030.134335942,
                3509302.5961655364,
                4974726.2544605015,
                6636938.395676142,
                8480661.098634662,
                10493443.655259877,
                12664873.95836153,
                14986062.767730104,
                17449292.60574692,
                20047770.163230654,
                22775446.33969572,
                25626882.008767802,
                28597145.631522093,
                31681733.636560686,
                34876507.44998473,
                38177642.948423974,
                41581589.34729277,
                45085035.36913924,
                48684881.10920756,
                52378214.41657437,
                56162290.89569785,
                60034516.84114703,
                63992434.57146707,
                68033709.74254912,
                72156120.30740014,
                76357546.85540392,
                80635964.11534707
              ],
              "uom": "MtCO2"
            },
            "plcg": {
              "capacity": [
                10000.0,
                42670909.09090909,
                85331818.18181819,
                127992727.27272728,
                170653636.36363637,
                213314545.45454547,
                255975454.54545456,
                298636363.6363636,
                341297272.72727275,
                383958181.8181819,
                426619090.90909094,
                469280000.0,
                511940909.0909091,
                554601818.1818182,
                597262727.2727273,
                639923636.3636364,
                682584545.4545455,
                725245454.5454546,
                767906363.6363637,
                810567272.7272727,
                853228181.8181819,
                895889090.909091,
                938550000.0,
                981210909.0909091,
                1023871818.1818182,
                1066532727.2727274,
                1109193636.3636365,
                1151854545.4545455,
                1194515454.5454545,
                1237176363.6363637,
                1279837272.7272727,
                1322498181.818182,
                1365159090.909091,
                1407820000.0,
                1450480909.0909092,
                1493141818.1818182,
                1535802727.2727275,
                1578463636.3636365,
                1621124545.4545455,
                1663785454.5454547,
                1706446363.6363637,
                1749107272.7272727,
                1791768181.818182,
                1834429090.909091,
                1877090000.0,
                1919750909.0909092,
                1962411818.1818182,
                2005072727.2727275,
                2047733636.3636365,
                2090394545.4545455,
                2133055454.5454547,
                2175716363.636364,
                2218377272.727273,
                2261038181.818182,
                2303699090.909091,
                2346360000.0,
                2389020909.090909,
                2431681818.1818185,
                2474342727.2727275,
                2517003636.3636365,
                2559664545.4545455,
                2602325454.5454545,
                2644986363.636364,
                2687647272.727273,
                2730308181.818182,
                2772969090.909091,
                2815630000.0,
                2858290909.0909095,
                2900951818.1818185,
                2943612727.2727275,
                2986273636.3636365,
                3028934545.4545455,
                3071595454.545455,
                3114256363.636364,
                3156917272.727273,
                3199578181.818182,
                3242239090.909091,
                3284900000.0,
                3327560909.0909095,
                3370221818.1818185,
                3412882727.2727275,
                3455543636.3636365,
                3498204545.4545455,
                3540865454.545455,
                3583526363.636364,
                3626187272.727273,
                3668848181.818182,
                3711509090.909091,
                3754170000.0,
                3796830909.0909095,
                3839491818.1818185,
                3882152727.2727275,
                3924813636.3636365,
                3967474545.4545455,
                4010135454.545455,
                4052796363.636364,
                4095457272.727273,
                4138118181.818182,
                4180779090.909091,
                4223440000.0000005
              ],
              "x_axis_uom": "MtCO2/year",
              "cost": [
                1084.5250212448836,
                228.72401601620172,
                207.67631888539452,
                196.85889639346016,
                189.7838919151022,
                184.61537633134336,
                180.58853424060501,
                177.31561573346247,
                174.5745279974736,
                172.22692281143546,
                170.18114719207148,
                168.37354934021133,
                166.75824081934937,
                165.30111978272726,
                163.97619789251746,
                162.76324559939914,
                161.64622822228992,
                160.61223551473265,
                159.65072976141033,
                158.75300556456332,
                157.91179394754812,
                157.12096707927904,
                156.37531456666613,
                155.67037156677958,
                155.00228502546258,
                154.36770838001956,
                153.76371779138597,
                153.1877448601749,
                152.6375221027409,
                152.11103840565673,
                151.6065023569078,
                151.1223118490288,
                150.65702871681788,
                150.2093574468613,
                149.7781272034737,
                149.36227657314794,
                148.9608405522291,
                148.57293939499291,
                148.19776901405797,
                147.8345926823623,
                147.48273383181615,
                147.14156978024803,
                146.8105262475424,
                146.48907254548692,
                146.17671734500706,
                145.87300494013067,
                145.57751194057408,
                145.28984433601988,
                145.00963488300815,
                144.73654077326242,
                144.47024154804097,
                144.21043722820502,
                143.95684663394417,
                143.70920587168342,
                143.46726696872963,
                143.23079663878903,
                142.99957516371032,
                142.773395378527,
                142.55206174886874,
                142.3353895306692,
                142.12320400368552,
                141.91533977121264,
                141.71164011929469,
                141.5119564295077,
                141.316147640064,
                141.1240797505753,
                140.93562536632567,
                140.75066327837354,
                140.56907807609485,
                140.39075978938675,
                140.21560355770842,
                140.04350932367342,
                139.87438154903836,
                139.70812895116578,
                139.5446642582268,
                139.38390398157784,
                139.22576820389511,
                139.0701803817846,
                138.9170671617172,
                138.76635820817148,
                138.61798604314808,
                138.47188589605727,
                138.32799556324636,
                138.1862552764273,
                138.04660757934192,
                137.9089972120566,
                137.77337100233137,
                137.6396777635544,
                137.5078681987742,
                137.37789481040977,
                137.2497118151991,
                137.12327506411432,
                136.99854196681684,
                136.87547142039162,
                136.75402374206573,
                136.63416060564975,
                136.51584498145857,
                136.39904107948723,
                136.28371429563384,
                136.1698311607774
              ],
              "y_axis_uom": "$/MtCO2",
              "start_index": 0,
              "end_index": 1,
              "pct_change_gp": 0.3230399490465574,
              "cost_at_parity": 0.0
            },
            "ppcrg": {
              "years": [
                2020.0,
                2021.0,
                2022.0,
                2023.0,
                2024.0,
                2025.0,
                2026.0,
                2027.0,
                2028.0,
                2029.0,
                2030.0,
                2031.0,
                2032.0,
                2033.0,
                2034.0,
                2035.0,
                2036.0,
                2037.0,
                2038.0,
                2039.0,
                2040.0,
                2041.0,
                2042.0,
                2043.0,
                2044.0,
                2045.0,
                2046.0,
                2047.0,
                2048.0,
                2049.0,
                2050.0
              ],
              "cat": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                399.8046828116802,
                356.76137979015493,
                329.2717820991749,
                309.741688833977,
                294.92399212098894,
                283.1685380299157,
                274.7875549831958,
                267.5928306675448,
                261.32488116671476,
                255.79759569174144,
                250.873532608956,
                246.4486722747141,
                242.44262892882344,
                238.7921535845912,
                235.44669568768313,
                232.36529450204995,
                229.51435361532202,
                226.8660165156745,
                224.39696026613194,
                222.0874857048152,
                219.9208216507739,
                217.88258601329898,
                215.96036359678808,
                214.14337183679532,
                212.4221935894233,
                210.78856161789273,
                209.23518334258705,
                207.75559724479066
              ],
              "ref": [
                1436.6568933868593,
                657.1784835524913,
                482.61581365822747,
                409.3595334184463,
                367.8719201228722,
                340.47376794243013,
                320.66967011604225,
                305.48794827186236,
                293.3614669921315,
                283.3778181350047,
                274.9657124281078,
                267.7469780727272,
                261.46003948440284,
                255.91742390692337,
                250.9807689021097,
                246.54541231312737,
                242.5305078304148,
                238.8724686082489,
                235.520491187092,
                232.43342213288471,
                229.57751588748442,
                226.9247988761851,
                224.45185512061786,
                222.1389106681618,
                219.96913359788215,
                217.9280920271765,
                216.00332959312075,
                214.18402942669223,
                212.4607455908941,
                210.8251865199864,
                209.2700389498332
              ],
              "uom": "MtCO2"
            }
          }
        }
      },
      "cost_per_ton": 10229558.938715128,
      "catalyzed_erp": 488.7796267615052
    }
  },
  "perm": {
    "e2i": {
      "1000000.0": 0.0,
      "2000000.0": 0.0,
      "3000000.0": 0.0,
      "4000000.0": 0.0,
      "5000000.0": 6.715342748930084,
      "6000000.0": 16.198784588283843,
      "7000000.0": 25.682226427637488,
      "8000000.0": 35.16566826699113,
      "9000000.0": 44.64911010634489,
      "10000000.0": 54.13255194569854,
      "11000000.0": 63.6159937850523,
      "12000000.0": 73.09943562440594,
      "13000000.0": 82.58287746375959,
      "14000000.0": 92.06631930311335,
      "15000000.0": 101.54976114246699,
      "16000000.0": 111.03320298182064,
      "17000000.0": 120.5166448211744,
      "18000000.0": 130.00008666052804,
      "19000000.0": 139.4835284998818,
      "20000000.0": 148.96697033923544,
      "21000000.0": 158.4504121785891,
      "22000000.0": 167.93385401794285,
      "23000000.0": 177.4172958572965,
      "24000000.0": 186.90073769665014,
      "25000000.0": 196.3841795360039,
      "26000000.0": 205.86762137535754,
      "27000000.0": 215.3510632147113,
      "28000000.0": 224.83450505406495,
      "29000000.0": 234.3179468934186,
      "30000000.0": 243.80138873277235,
      "31000000.0": 253.284830572126,
      "32000000.0": 262.76827241147964,
      "33000000.0": 272.2517142508334,
      "34000000.0": 281.73515609018705,
      "35000000.0": 291.2185979295408,
      "36000000.0": 300.70203976889445,
      "37000000.0": 310.1854816082481,
      "38000000.0": 319.66892344760186,
      "39000000.0": 329.1523652869555,
      "40000000.0": 338.63580712630915,
      "41000000.0": 348.1192489656629,
      "42000000.0": 357.60269080501655,
      "43000000.0": 367.0861326443703,
      "44000000.0": 376.56957448372395,
      "45000000.0": 386.0530163230776,
      "46000000.0": 395.53645816243136,
      "47000000.0": 405.019900001785,
      "48000000.0": 414.50334184113865,
      "49000000.0": 423.9867836804924,
      "50000000.0": 433.47022551984605,
      "51000000.0": 442.9536673591998,
      "52000000.0": 452.43710919855346,
      "53000000.0": 461.9205510379071,
      "54000000.0": 471.40399287726086,
      "55000000.0": 480.8874347166145,
      "56000000.0": 490.3708765559682,
      "57000000.0": 499.8543183953219,
      "58000000.0": 509.33776023467556,
      "59000000.0": 518.8212020740293,
      "60000000.0": 528.304643913383,
      "61000000.0": 537.7880857527366,
      "62000000.0": 547.2715275920903,
      "63000000.0": 556.754969431444,
      "64000000.0": 566.2384112707978,
      "65000000.0": 575.7218531101514,
      "66000000.0": 585.2052949495051,
      "67000000.0": 594.6887367888587,
      "68000000.0": 604.1721786282125,
      "69000000.0": 613.6556204675662,
      "70000000.0": 623.1390623069199,
      "71000000.0": 632.6225041462735,
      "72000000.0": 642.1059459856272,
      "73000000.0": 651.5893878249809,
      "74000000.0": 661.0728296643346,
      "75000000.0": 670.5562715036883,
      "76000000.0": 680.039713343042,
      "77000000.0": 689.5231551823956,
      "78000000.0": 699.0065970217493,
      "79000000.0": 708.490038861103,
      "80000000.0": 717.9734807004568,
      "81000000.0": 727.4569225398104,
      "82000000.0": 736.9403643791641,
      "83000000.0": 746.4238062185177,
      "84000000.0": 755.9072480578715,
      "85000000.0": 765.3906898972251,
      "86000000.0": 774.8741317365789,
      "87000000.0": 784.3575735759325,
      "88000000.0": 793.8410154152862,
      "89000000.0": 803.3244572546399,
      "90000000.0": 812.8078990939936,
      "91000000.0": 822.2913409333473,
      "92000000.0": 831.774782772701,
      "93000000.0": 841.2582246120546,
      "94000000.0": 850.7416664514084,
      "95000000.0": 860.225108290762,
      "96000000.0": 869.7085501301158,
      "97000000.0": 879.1919919694694,
      "98000000.0": 888.6754338088231,
      "99000000.0": 898.1588756481767,
      "100000000.0": 907.6423174875305,
      "101000000.0": 917.1257593268841,
      "102000000.0": 926.6092011662379,
      "103000000.0": 936.0926430055915,
      "104000000.0": 945.5760848449452,
      "105000000.0": 955.0595266842989,
      "106000000.0": 964.5429685236526,
      "107000000.0": 974.0264103630063,
      "108000000.0": 983.50985220236,
      "109000000.0": 992.9932940417136,
      "110000000.0": 1002.4767358810674,
      "111000000.0": 1011.960177720421,
      "112000000.0": 1021.4436195597747,
      "113000000.0": 1030.9270613991284,
      "114000000.0": 1040.410503238482,
      "115000000.0": 1049.8939450778357,
      "116000000.0": 1059.3773869171894,
      "117000000.0": 1068.8608287565432,
      "118000000.0": 1078.344270595897,
      "119000000.0": 1087.8277124352505,
      "120000000.0": 1097.3111542746042,
      "121000000.0": 1106.7945961139578,
      "122000000.0": 1116.2780379533117,
      "123000000.0": 1125.7614797926653,
      "124000000.0": 1135.244921632019,
      "125000000.0": 1144.7283634713726,
      "126000000.0": 1154.2118053107263,
      "127000000.0": 1163.69524715008,
      "128000000.0": 1173.1786889894338,
      "129000000.0": 1182.6621308287874,
      "130000000.0": 1192.145572668141,
      "131000000.0": 1201.6290145074947,
      "132000000.0": 1211.1124563468484,
      "133000000.0": 1220.5958981862022,
      "134000000.0": 1230.079340025556,
      "135000000.0": 1239.5627818649095,
      "136000000.0": 1249.0462237042632,
      "137000000.0": 1258.6638301905457,
      "138000000.0": 1268.2964387961158,
      "139000000.0": 1277.9290474016857,
      "140000000.0": 1287.5616560072556,
      "141000000.0": 1297.1942646128255,
      "142000000.0": 1306.8268732183953,
      "143000000.0": 1316.4594818239652,
      "144000000.0": 1326.0920904295353,
      "145000000.0": 1335.7246990351052,
      "146000000.0": 1345.3573076406751,
      "147000000.0": 1354.989916246245,
      "148000000.0": 1364.622524851815,
      "149000000.0": 1374.255133457385,
      "150000000.0": 1383.8877420629549,
      "151000000.0": 1393.5203506685248,
      "152000000.0": 1403.1529592740947,
      "153000000.0": 1412.7855678796645,
      "154000000.0": 1422.4181764852344,
      "155000000.0": 1432.0507850908045,
      "156000000.0": 1441.6833936963744,
      "157000000.0": 1451.3160023019443,
      "158000000.0": 1460.9486109075142,
      "159000000.0": 1470.581219513084,
      "160000000.0": 1480.213828118654,
      "161000000.0": 1489.846436724224,
      "162000000.0": 1499.479045329794,
      "163000000.0": 1509.2436249687196,
      "164000000.0": 1519.015749991463,
      "165000000.0": 1528.7878750142065,
      "166000000.0": 1538.5600000369502,
      "167000000.0": 1548.3321250596937,
      "168000000.0": 1558.1042500824371,
      "169000000.0": 1567.8763751051808,
      "170000000.0": 1577.6485001279243,
      "171000000.0": 1587.4206251506678,
      "172000000.0": 1597.1927501734115,
      "173000000.0": 1606.964875196155,
      "174000000.0": 1616.7370002188984,
      "175000000.0": 1626.509125241642,
      "176000000.0": 1636.2812502643856,
      "177000000.0": 1646.0533752871293,
      "178000000.0": 1655.8255003098727,
      "179000000.0": 1665.5976253326162,
      "180000000.0": 1675.3697503553599,
      "181000000.0": 1685.1418753781034,
      "182000000.0": 1694.9140004008468,
      "183000000.0": 1704.6861254235905,
      "184000000.0": 1714.458250446334,
      "185000000.0": 1724.2303754690774,
      "186000000.0": 1734.0025004918211,
      "187000000.0": 1743.7746255145646,
      "188000000.0": 1753.6009550529836,
      "189000000.0": 1763.5224261598134,
      "190000000.0": 1773.4438972666433,
      "191000000.0": 1783.3653683734733,
      "192000000.0": 1793.2868394803031,
      "193000000.0": 1803.208310587133,
      "194000000.0": 1813.129781693963,
      "195000000.0": 1823.0512528007928,
      "196000000.0": 1832.9727239076226,
      "197000000.0": 1842.8941950144524,
      "198000000.0": 1852.8156661212824,
      "199000000.0": 1862.7371372281123,
      "200000000.0": 1872.658608334942,
      "201000000.0": 1882.5800794417719,
      "202000000.0": 1892.501550548602,
      "203000000.0": 1902.4230216554317,
      "204000000.0": 1912.3444927622616,
      "205000000.0": 1922.2659638690916,
      "206000000.0": 1932.1874349759214,
      "207000000.0": 1942.1089060827512,
      "208000000.0": 1952.030377189581,
      "209000000.0": 1961.9518482964108,
      "210000000.0": 1971.8733194032409,
      "211000000.0": 1981.7947905100707,
      "212000000.0": 1991.7162616169005,
      "213000000.0": 2001.6583290249687,
      "214000000.0": 2011.704573609596,
      "215000000.0": 2021.7508181942237,
      "216000000.0": 2031.7970627788511,
      "217000000.0": 2041.8433073634785,
      "218000000.0": 2051.889551948106,
      "219000000.0": 2061.9357965327335,
      "220000000.0": 2071.982041117361,
      "221000000.0": 2082.0282857019884,
      "222000000.0": 2092.0745302866158,
      "223000000.0": 2102.120774871243,
      "224000000.0": 2112.1670194558706,
      "225000000.0": 2122.213264040498,
      "226000000.0": 2132.259508625126,
      "227000000.0": 2142.305753209753,
      "228000000.0": 2152.3519977943806,
      "229000000.0": 2162.398242379008,
      "230000000.0": 2172.4444869636354,
      "231000000.0": 2182.490731548263,
      "232000000.0": 2192.5369761328902,
      "233000000.0": 2202.5832207175176,
      "234000000.0": 2212.629465302145,
      "235000000.0": 2222.675709886773,
      "236000000.0": 2232.7219544714003,
      "237000000.0": 2242.7681990560277,
      "238000000.0": 2252.8748082556167,
      "239000000.0": 2263.1365262209865,
      "240000000.0": 2273.398244186356,
      "241000000.0": 2283.6599621517257,
      "242000000.0": 2293.9216801170955,
      "243000000.0": 2304.1833980824654,
      "244000000.0": 2314.445116047835,
      "245000000.0": 2324.706834013205,
      "246000000.0": 2334.9685519785744,
      "247000000.0": 2345.230269943944,
      "248000000.0": 2355.491987909314,
      "249000000.0": 2365.753705874684,
      "250000000.0": 2376.0154238400537,
      "251000000.0": 2386.2771418054235,
      "252000000.0": 2396.538859770793,
      "253000000.0": 2406.8005777361627,
      "254000000.0": 2417.0622957015325,
      "255000000.0": 2427.3240136669024,
      "256000000.0": 2437.585731632272,
      "257000000.0": 2447.8474495976416,
      "258000000.0": 2458.1091675630114,
      "259000000.0": 2468.370885528381,
      "260000000.0": 2478.632603493751,
      "261000000.0": 2488.894321459121,
      "262000000.0": 2499.1560394244907,
      "263000000.0": 2509.488459430426,
      "264000000.0": 2519.827215311493,
      "265000000.0": 2530.1659711925604,
      "266000000.0": 2540.5047270736277,
      "267000000.0": 2550.843482954695,
      "268000000.0": 2561.1822388357627,
      "269000000.0": 2571.52099471683,
      "270000000.0": 2581.8597505978973,
      "271000000.0": 2592.1985064789646,
      "272000000.0": 2602.537262360032,
      "273000000.0": 2612.8760182410992,
      "274000000.0": 2623.2147741221665,
      "275000000.0": 2633.553530003234,
      "276000000.0": 2643.892285884301,
      "277000000.0": 2654.2310417653684,
      "278000000.0": 2664.5697976464357,
      "279000000.0": 2674.908553527503,
      "280000000.0": 2685.247309408571,
      "281000000.0": 2695.586065289638,
      "282000000.0": 2705.9248211707054,
      "283000000.0": 2716.2635770517727,
      "284000000.0": 2726.60233293284,
      "285000000.0": 2736.9410888139073,
      "286000000.0": 2747.2798446949746,
      "287000000.0": 2757.7520320729204,
      "288000000.0": 2768.271860010372,
      "289000000.0": 2778.7916879478234,
      "290000000.0": 2789.311515885275,
      "291000000.0": 2799.8313438227265,
      "292000000.0": 2810.351171760178,
      "293000000.0": 2820.8709996976295,
      "294000000.0": 2831.390827635081,
      "295000000.0": 2841.9106555725325,
      "296000000.0": 2852.4304835099842,
      "297000000.0": 2862.9503114474355,
      "298000000.0": 2873.4701393848873,
      "299000000.0": 2883.9899673223385,
      "300000000.0": 2894.5097952597903,
      "301000000.0": 2905.0296231972416,
      "302000000.0": 2915.5494511346933,
      "303000000.0": 2926.0692790721446,
      "304000000.0": 2936.589107009596,
      "305000000.0": 2947.1089349470476,
      "306000000.0": 2957.6287628844993,
      "307000000.0": 2968.1485908219506,
      "308000000.0": 2978.668418759402,
      "309000000.0": 2989.1882466968536,
      "310000000.0": 2999.708074634305,
      "311000000.0": 3010.3784501220093,
      "312000000.0": 3021.0531225462905,
      "313000000.0": 3031.7277949705713,
      "314000000.0": 3042.402467394852,
      "315000000.0": 3053.0771398191328,
      "316000000.0": 3063.751812243414,
      "317000000.0": 3074.4264846676947,
      "318000000.0": 3085.1011570919754,
      "319000000.0": 3095.775829516256,
      "320000000.0": 3106.4505019405374,
      "321000000.0": 3117.125174364818,
      "322000000.0": 3127.799846789099,
      "323000000.0": 3138.4745192133796,
      "324000000.0": 3149.149191637661,
      "325000000.0": 3159.8238640619415,
      "326000000.0": 3170.4985364862223,
      "327000000.0": 3181.1732089105035,
      "328000000.0": 3191.8478813347842,
      "329000000.0": 3202.522553759065,
      "330000000.0": 3213.1972261833457,
      "331000000.0": 3223.871898607627,
      "332000000.0": 3234.5465710319077,
      "333000000.0": 3245.2212434561884,
      "334000000.0": 3255.973287316541,
      "335000000.0": 3266.788042257567,
      "336000000.0": 3277.6027971985936,
      "337000000.0": 3288.41755213962,
      "338000000.0": 3299.232307080647,
      "339000000.0": 3310.0470620216734,
      "340000000.0": 3320.8618169627,
      "341000000.0": 3331.676571903726,
      "342000000.0": 3342.491326844753,
      "343000000.0": 3353.3060817857795,
      "344000000.0": 3364.120836726806,
      "345000000.0": 3374.935591667833,
      "346000000.0": 3385.750346608859,
      "347000000.0": 3396.5651015498856,
      "348000000.0": 3407.379856490912,
      "349000000.0": 3418.194611431939,
      "350000000.0": 3429.0093663729654,
      "351000000.0": 3439.824121313992,
      "352000000.0": 3450.638876255018,
      "353000000.0": 3461.453631196045,
      "354000000.0": 3472.2683861370715,
      "355000000.0": 3483.083141078098,
      "356000000.0": 3493.8978960191243,
      "357000000.0": 3504.78027796648,
      "358000000.0": 3515.7502257081246,
      "359000000.0": 3526.720173449769,
      "360000000.0": 3537.6901211914137,
      "361000000.0": 3548.6600689330585,
      "362000000.0": 3559.630016674703,
      "363000000.0": 3570.5999644163476,
      "364000000.0": 3581.569912157992,
      "365000000.0": 3592.5398598996367,
      "366000000.0": 3603.509807641281,
      "367000000.0": 3614.4797553829258,
      "368000000.0": 3625.4497031245705,
      "369000000.0": 3636.419650866215,
      "370000000.0": 3647.3895986078596,
      "371000000.0": 3658.359546349504,
      "372000000.0": 3669.3294940911487,
      "373000000.0": 3680.2994418327935,
      "374000000.0": 3691.269389574438,
      "375000000.0": 3702.2393373160826,
      "376000000.0": 3713.209285057727,
      "377000000.0": 3724.1792327993717,
      "378000000.0": 3735.149180541016,
      "379000000.0": 3746.1191282826608,
      "380000000.0": 3757.1874403010734,
      "381000000.0": 3768.3096012468213,
      "382000000.0": 3779.4317621925693,
      "383000000.0": 3790.5539231383173,
      "384000000.0": 3801.676084084065,
      "385000000.0": 3812.798245029813,
      "386000000.0": 3823.9204059755607,
      "387000000.0": 3835.0425669213087,
      "388000000.0": 3846.1647278670566,
      "389000000.0": 3857.2868888128046,
      "390000000.0": 3868.4090497585526,
      "391000000.0": 3879.5312107043005,
      "392000000.0": 3890.6533716500485,
      "393000000.0": 3901.775532595796,
      "394000000.0": 3912.897693541544,
      "395000000.0": 3924.019854487292,
      "396000000.0": 3935.14201543304,
      "397000000.0": 3946.264176378788,
      "398000000.0": 3957.386337324536,
      "399000000.0": 3968.508498270284,
      "400000000.0": 3979.6306592160313,
      "401000000.0": 3990.7528201617793,
      "402000000.0": 4001.900498429865,
      "403000000.0": 4013.174025042607,
      "404000000.0": 4024.447551655349,
      "405000000.0": 4035.721078268091,
      "406000000.0": 4046.994604880833,
      "407000000.0": 4058.268131493575,
      "408000000.0": 4069.541658106317,
      "409000000.0": 4080.815184719059,
      "410000000.0": 4092.088711331801,
      "411000000.0": 4103.362237944543,
      "412000000.0": 4114.635764557285,
      "413000000.0": 4125.909291170027,
      "414000000.0": 4137.182817782769,
      "415000000.0": 4148.456344395511,
      "416000000.0": 4159.729871008253,
      "417000000.0": 4171.003397620995,
      "418000000.0": 4182.276924233737,
      "419000000.0": 4193.550450846479,
      "420000000.0": 4204.823977459221,
      "421000000.0": 4216.097504071963,
      "422000000.0": 4227.371030684705,
      "423000000.0": 4238.644557297447,
      "424000000.0": 4249.918083910189,
      "425000000.0": 4261.343799933807,
      "426000000.0": 4272.770629895531,
      "427000000.0": 4284.197459857256,
      "428000000.0": 4295.62428981898,
      "429000000.0": 4307.051119780705,
      "430000000.0": 4318.47794974243,
      "431000000.0": 4329.904779704155,
      "432000000.0": 4341.33160966588,
      "433000000.0": 4352.758439627604,
      "434000000.0": 4364.185269589329,
      "435000000.0": 4375.612099551054,
      "436000000.0": 4387.038929512779,
      "437000000.0": 4398.465759474504,
      "438000000.0": 4409.892589436228,
      "439000000.0": 4421.319419397953,
      "440000000.0": 4432.746249359678,
      "441000000.0": 4444.173079321403,
      "442000000.0": 4455.599909283127,
      "443000000.0": 4467.026739244852,
      "444000000.0": 4478.453569206577,
      "445000000.0": 4489.880399168302,
      "446000000.0": 4501.324760977227,
      "447000000.0": 4512.904841377054,
      "448000000.0": 4524.4849217768815,
      "449000000.0": 4536.065002176708,
      "450000000.0": 4547.645082576535,
      "451000000.0": 4559.225162976362,
      "452000000.0": 4570.805243376189,
      "453000000.0": 4582.385323776016,
      "454000000.0": 4593.965404175843,
      "455000000.0": 4605.545484575669,
      "456000000.0": 4617.125564975497,
      "457000000.0": 4628.705645375324,
      "458000000.0": 4640.28572577515,
      "459000000.0": 4651.8658061749775,
      "460000000.0": 4663.445886574805,
      "461000000.0": 4675.025966974631,
      "462000000.0": 4686.606047374458,
      "463000000.0": 4698.1861277742855,
      "464000000.0": 4709.766208174112,
      "465000000.0": 4721.346288573939,
      "466000000.0": 4732.926368973766,
      "467000000.0": 4744.506449373593,
      "468000000.0": 4756.167539708085,
      "469000000.0": 4767.9017475978835,
      "470000000.0": 4779.635955487682,
      "471000000.0": 4791.37016337748,
      "472000000.0": 4803.104371267278,
      "473000000.0": 4814.838579157076,
      "474000000.0": 4826.5727870468745,
      "475000000.0": 4838.306994936673,
      "476000000.0": 4850.04120282647,
      "477000000.0": 4861.775410716268,
      "478000000.0": 4873.509618606066,
      "479000000.0": 4885.243826495865,
      "480000000.0": 4896.978034385663,
      "481000000.0": 4908.712242275461,
      "482000000.0": 4920.446450165259,
      "483000000.0": 4932.180658055057,
      "484000000.0": 4943.914865944856,
      "485000000.0": 4955.649073834654,
      "486000000.0": 4967.383281724452,
      "487000000.0": 4979.11748961425,
      "488000000.0": 4990.8516975040475,
      "489000000.0": 5002.585905393846,
      "490000000.0": 5014.320113283644,
      "491000000.0": 5026.054321173442,
      "492000000.0": 5037.78852906324,
      "493000000.0": 5049.5227369530385,
      "494000000.0": 5061.256944842837,
      "495000000.0": 5072.991152732635,
      "496000000.0": 5084.725360622433,
      "497000000.0": 5096.459568512231,
      "498000000.0": 5108.1937764020295,
      "499000000.0": 5119.927984291828,
      "500000000.0": 5131.662192181626,
      "501000000.0": 5143.396400071423,
      "502000000.0": 5155.130607961221,
      "503000000.0": 5166.86481585102,
      "504000000.0": 5178.599023740818,
      "505000000.0": 5190.333231630616,
      "506000000.0": 5202.067439520414,
      "507000000.0": 5213.801647410212,
      "508000000.0": 5225.535855300011,
      "509000000.0": 5237.270063189809,
      "510000000.0": 5249.004271079607,
      "511000000.0": 5260.738478969405,
      "512000000.0": 5272.472686859202,
      "513000000.0": 5284.206894749001,
      "514000000.0": 5295.941102638799,
      "515000000.0": 5307.675310528597,
      "516000000.0": 5319.409518418395,
      "517000000.0": 5331.143726308193,
      "518000000.0": 5342.877934197992,
      "519000000.0": 5354.61214208779,
      "520000000.0": 5366.346349977588,
      "521000000.0": 5378.080557867386,
      "522000000.0": 5389.814765757184,
      "523000000.0": 5401.548973646983,
      "524000000.0": 5413.283181536781,
      "525000000.0": 5425.017389426579,
      "526000000.0": 5436.751597316376,
      "527000000.0": 5448.4858052061745,
      "528000000.0": 5460.220013095973,
      "529000000.0": 5471.954220985771,
      "530000000.0": 5483.688428875569,
      "531000000.0": 5495.422636765367,
      "532000000.0": 5507.1568446551655,
      "533000000.0": 5518.891052544964,
      "534000000.0": 5530.625260434762,
      "535000000.0": 5542.35946832456,
      "536000000.0": 5554.093676214358,
      "537000000.0": 5565.827884104156,
      "538000000.0": 5577.562091993954,
      "539000000.0": 5589.296299883752,
      "540000000.0": 5601.03050777355,
      "541000000.0": 5612.764715663348,
      "542000000.0": 5624.498923553147,
      "543000000.0": 5636.233131442945,
      "544000000.0": 5647.967339332743,
      "545000000.0": 5659.701547222541,
      "546000000.0": 5671.435755112339,
      "547000000.0": 5683.169963002138,
      "548000000.0": 5694.904170891936,
      "549000000.0": 5706.638378781734,
      "550000000.0": 5718.372586671531,
      "551000000.0": 5730.1067945613295,
      "552000000.0": 5741.841002451128,
      "553000000.0": 5753.575210340926,
      "554000000.0": 5765.309418230724,
      "555000000.0": 5777.043626120522,
      "556000000.0": 5788.7778340103205,
      "557000000.0": 5800.512041900119,
      "558000000.0": 5812.246249789917,
      "559000000.0": 5823.980457679715,
      "560000000.0": 5835.714665569512,
      "561000000.0": 5847.448873459311,
      "562000000.0": 5859.183081349109,
      "563000000.0": 5870.917289238907,
      "564000000.0": 5882.651497128705,
      "565000000.0": 5894.385705018503,
      "566000000.0": 5906.119912908302,
      "567000000.0": 5917.8541207981,
      "568000000.0": 5929.588328687898,
      "569000000.0": 5941.322536577696,
      "570000000.0": 5953.056744467494,
      "571000000.0": 5964.790952357293,
      "572000000.0": 5976.525160247091,
      "573000000.0": 5988.259368136889,
      "574000000.0": 5999.993576026687,
      "575000000.0": 6011.727783916484,
      "576000000.0": 6023.461991806283,
      "577000000.0": 6035.196199696081,
      "578000000.0": 6046.930407585879,
      "579000000.0": 6058.664615475677,
      "580000000.0": 6070.398823365475,
      "581000000.0": 6082.133031255274,
      "582000000.0": 6093.867239145072,
      "583000000.0": 6105.60144703487,
      "584000000.0": 6117.335654924668,
      "585000000.0": 6129.0698628144655,
      "586000000.0": 6140.804070704264,
      "587000000.0": 6152.538278594062,
      "588000000.0": 6164.27248648386,
      "589000000.0": 6176.006694373658,
      "590000000.0": 6187.7409022634565,
      "591000000.0": 6199.475110153255,
      "592000000.0": 6211.209318043053,
      "593000000.0": 6222.943525932851,
      "594000000.0": 6234.677733822649,
      "595000000.0": 6246.4119417124475,
      "596000000.0": 6258.146149602246,
      "597000000.0": 6269.880357492044,
      "598000000.0": 6281.614565381842,
      "599000000.0": 6293.34877327164,
      "600000000.0": 6305.082981161438,
      "601000000.0": 6316.817189051236,
      "602000000.0": 6328.551396941034,
      "603000000.0": 6340.285604830832,
      "604000000.0": 6352.01981272063,
      "605000000.0": 6363.754020610429,
      "606000000.0": 6375.488228500227,
      "607000000.0": 6387.222436390025,
      "608000000.0": 6398.956644279823,
      "609000000.0": 6410.6908521696205,
      "610000000.0": 6422.425060059419,
      "611000000.0": 6434.159267949217,
      "612000000.0": 6445.893475839015,
      "613000000.0": 6457.627683728813,
      "614000000.0": 6469.3618916186115,
      "615000000.0": 6481.09609950841,
      "616000000.0": 6492.830307398208,
      "617000000.0": 6504.564515288006,
      "618000000.0": 6516.298723177804,
      "619000000.0": 6528.0329310676025,
      "620000000.0": 6539.767138957401,
      "621000000.0": 6551.501346847199,
      "622000000.0": 6563.235554736997,
      "623000000.0": 6574.969762626795,
      "624000000.0": 6586.703970516593,
      "625000000.0": 6598.438178406391,
      "626000000.0": 6610.172386296189,
      "627000000.0": 6621.906594185987,
      "628000000.0": 6633.640802075785,
      "629000000.0": 6645.375009965584,
      "630000000.0": 6657.109217855382,
      "631000000.0": 6668.84342574518,
      "632000000.0": 6680.577633634978,
      "633000000.0": 6692.311841524775,
      "634000000.0": 6704.046049414574,
      "635000000.0": 6715.780257304372,
      "636000000.0": 6727.51446519417,
      "637000000.0": 6739.248673083968,
      "638000000.0": 6750.982880973766,
      "639000000.0": 6762.717088863565,
      "640000000.0": 6774.451296753363,
      "641000000.0": 6786.185504643161,
      "642000000.0": 6797.919712532959,
      "643000000.0": 6809.653920422757,
      "644000000.0": 6821.388128312556,
      "645000000.0": 6833.122336202354,
      "646000000.0": 6844.856544092152,
      "647000000.0": 6856.59075198195,
      "648000000.0": 6868.324959871748,
      "649000000.0": 6880.059167761547,
      "650000000.0": 6891.793375651344,
      "651000000.0": 6903.527583541142,
      "652000000.0": 6915.26179143094,
      "653000000.0": 6926.9959993207385,
      "654000000.0": 6938.730207210537,
      "655000000.0": 6950.464415100335,
      "656000000.0": 6962.198622990132,
      "657000000.0": 6973.93283087993,
      "658000000.0": 6985.667038769729,
      "659000000.0": 6997.401246659527,
      "660000000.0": 7009.135454549325,
      "661000000.0": 7020.869662439123,
      "662000000.0": 7032.603870328921,
      "663000000.0": 7044.33807821872,
      "664000000.0": 7056.072286108518,
      "665000000.0": 7067.806493998316,
      "666000000.0": 7079.540701888114,
      "667000000.0": 7091.274909777912,
      "668000000.0": 7103.009117667711,
      "669000000.0": 7114.743325557509,
      "670000000.0": 7126.477533447307,
      "671000000.0": 7138.211741337105,
      "672000000.0": 7149.945949226903,
      "673000000.0": 7161.680157116702,
      "674000000.0": 7173.414365006499,
      "675000000.0": 7185.148572896297,
      "676000000.0": 7196.882780786095,
      "677000000.0": 7208.6169886758935,
      "678000000.0": 7220.351196565692,
      "679000000.0": 7232.08540445549,
      "680000000.0": 7243.819612345288,
      "681000000.0": 7255.553820235085,
      "682000000.0": 7267.288028124884,
      "683000000.0": 7279.022236014682,
      "684000000.0": 7290.75644390448,
      "685000000.0": 7302.490651794278,
      "686000000.0": 7314.224859684076,
      "687000000.0": 7325.959067573875,
      "688000000.0": 7337.693275463673,
      "689000000.0": 7349.427483353471,
      "690000000.0": 7361.161691243269,
      "691000000.0": 7372.895899133067,
      "692000000.0": 7384.630107022866,
      "693000000.0": 7396.364314912664,
      "694000000.0": 7408.098522802462,
      "695000000.0": 7419.83273069226,
      "696000000.0": 7431.566938582058,
      "697000000.0": 7443.301146471857,
      "698000000.0": 7455.035354361655,
      "699000000.0": 7466.769562251452,
      "700000000.0": 7478.50377014125,
      "701000000.0": 7490.237978031048,
      "702000000.0": 7501.972185920847,
      "703000000.0": 7513.706393810645,
      "704000000.0": 7525.440601700443,
      "705000000.0": 7537.17480959024,
      "706000000.0": 7548.9090174800385,
      "707000000.0": 7560.643225369837,
      "708000000.0": 7572.377433259635,
      "709000000.0": 7584.111641149433,
      "710000000.0": 7595.845849039231,
      "711000000.0": 7607.5800569290295,
      "712000000.0": 7619.314264818828,
      "713000000.0": 7631.048472708626,
      "714000000.0": 7642.782680598424,
      "715000000.0": 7654.516888488222,
      "716000000.0": 7666.2510963780205,
      "717000000.0": 7677.985304267819,
      "718000000.0": 7689.719512157617,
      "719000000.0": 7701.453720047415,
      "720000000.0": 7713.187927937213,
      "721000000.0": 7724.9221358270115,
      "722000000.0": 7736.65634371681,
      "723000000.0": 7748.390551606607,
      "724000000.0": 7760.124759496405,
      "725000000.0": 7771.858967386203,
      "726000000.0": 7783.593175276002,
      "727000000.0": 7795.3273831658,
      "728000000.0": 7807.061591055598,
      "729000000.0": 7818.795798945395,
      "730000000.0": 7830.5300068351935,
      "731000000.0": 7842.264214724992,
      "732000000.0": 7853.99842261479,
      "733000000.0": 7865.732630504588,
      "734000000.0": 7877.466838394386,
      "735000000.0": 7889.2010462841845,
      "736000000.0": 7900.935254173983,
      "737000000.0": 7912.669462063781,
      "738000000.0": 7924.403669953579,
      "739000000.0": 7936.137877843377,
      "740000000.0": 7947.8720857331755,
      "741000000.0": 7959.606293622974,
      "742000000.0": 7971.340501512772,
      "743000000.0": 7983.07470940257,
      "744000000.0": 7994.808917292368,
      "745000000.0": 8006.5431251821665,
      "746000000.0": 8018.277333071965,
      "747000000.0": 8030.011540961763,
      "748000000.0": 8041.74574885156,
      "749000000.0": 8053.479956741358,
      "750000000.0": 8065.214164631157,
      "751000000.0": 8076.948372520955,
      "752000000.0": 8088.682580410753,
      "753000000.0": 8100.416788300551,
      "754000000.0": 8112.150996190348,
      "755000000.0": 8123.885204080147,
      "756000000.0": 8135.619411969945,
      "757000000.0": 8147.353619859743,
      "758000000.0": 8159.087827749541,
      "759000000.0": 8170.822035639339,
      "760000000.0": 8182.556243529138,
      "761000000.0": 8194.290451418936,
      "762000000.0": 8206.024659308734,
      "763000000.0": 8217.758867198532,
      "764000000.0": 8229.49307508833,
      "765000000.0": 8241.227282978129,
      "766000000.0": 8252.961490867927,
      "767000000.0": 8264.695698757725,
      "768000000.0": 8276.429906647523,
      "769000000.0": 8288.164114537321,
      "770000000.0": 8299.89832242712,
      "771000000.0": 8311.632530316918,
      "772000000.0": 8323.366738206714,
      "773000000.0": 8335.100946096514,
      "774000000.0": 8346.83515398631,
      "775000000.0": 8358.56936187611,
      "776000000.0": 8370.303569765907,
      "777000000.0": 8382.037777655707,
      "778000000.0": 8393.771985545503,
      "779000000.0": 8405.506193435302,
      "780000000.0": 8417.2404013251,
      "781000000.0": 8428.974609214898,
      "782000000.0": 8440.708817104696,
      "783000000.0": 8452.443024994494,
      "784000000.0": 8464.177232884293,
      "785000000.0": 8475.91144077409,
      "786000000.0": 8487.645648663889,
      "787000000.0": 8499.379856553687,
      "788000000.0": 8511.114064443485,
      "789000000.0": 8522.848272333284,
      "790000000.0": 8534.582480223082,
      "791000000.0": 8546.31668811288,
      "792000000.0": 8558.050896002678,
      "793000000.0": 8569.785103892476,
      "794000000.0": 8581.519311782275,
      "795000000.0": 8593.253519672073,
      "796000000.0": 8604.987727561871,
      "797000000.0": 8616.721935451667,
      "798000000.0": 8628.456143341467,
      "799000000.0": 8640.190351231264,
      "800000000.0": 8651.924559121064,
      "801000000.0": 8663.65876701086,
      "802000000.0": 8675.392974900658,
      "803000000.0": 8687.127182790457,
      "804000000.0": 8698.861390680255,
      "805000000.0": 8710.595598570053,
      "806000000.0": 8722.329806459851,
      "807000000.0": 8734.06401434965,
      "808000000.0": 8745.798222239448,
      "809000000.0": 8757.532430129246,
      "810000000.0": 8769.266638019044,
      "811000000.0": 8781.000845908842,
      "812000000.0": 8792.73505379864,
      "813000000.0": 8804.469261688439,
      "814000000.0": 8816.203469578237,
      "815000000.0": 8827.937677468035,
      "816000000.0": 8839.671885357833,
      "817000000.0": 8851.406093247631,
      "818000000.0": 8863.140301137428,
      "819000000.0": 8874.874509027228,
      "820000000.0": 8886.608716917024,
      "821000000.0": 8898.342924806824,
      "822000000.0": 8910.07713269662,
      "823000000.0": 8921.81134058642,
      "824000000.0": 8933.545548476217,
      "825000000.0": 8945.279756366017,
      "826000000.0": 8957.013964255813,
      "827000000.0": 8968.748172145613,
      "828000000.0": 8980.48238003541,
      "829000000.0": 8992.21658792521,
      "830000000.0": 9003.950795815006,
      "831000000.0": 9015.685003704804,
      "832000000.0": 9027.419211594603,
      "833000000.0": 9039.1534194844,
      "834000000.0": 9050.887627374199,
      "835000000.0": 9062.621835263997,
      "836000000.0": 9074.356043153795,
      "837000000.0": 9086.090251043594,
      "838000000.0": 9097.824458933392,
      "839000000.0": 9109.55866682319,
      "840000000.0": 9121.292874712988,
      "841000000.0": 9133.027082602786,
      "842000000.0": 9144.761290492585,
      "843000000.0": 9156.49549838238,
      "844000000.0": 9168.229706272181,
      "845000000.0": 9179.963914161977,
      "846000000.0": 9191.698122051777,
      "847000000.0": 9203.432329941574,
      "848000000.0": 9215.166537831374,
      "849000000.0": 9226.90074572117,
      "850000000.0": 9238.63495361097,
      "851000000.0": 9250.369161500766,
      "852000000.0": 9262.103369390567,
      "853000000.0": 9273.837577280363,
      "854000000.0": 9285.571785170161,
      "855000000.0": 9297.30599305996,
      "856000000.0": 9309.040200949757,
      "857000000.0": 9320.774408839556,
      "858000000.0": 9332.508616729354,
      "859000000.0": 9344.242824619152,
      "860000000.0": 9355.97703250895,
      "861000000.0": 9367.711240398748,
      "862000000.0": 9379.445448288547,
      "863000000.0": 9391.179656178345,
      "864000000.0": 9402.913864068143,
      "865000000.0": 9414.648071957941,
      "866000000.0": 9426.382279847738,
      "867000000.0": 9438.116487737538,
      "868000000.0": 9449.850695627334,
      "869000000.0": 9461.584903517134,
      "870000000.0": 9473.31911140693,
      "871000000.0": 9485.05331929673,
      "872000000.0": 9496.787527186527,
      "873000000.0": 9508.521735076327,
      "874000000.0": 9520.255942966123,
      "875000000.0": 9531.990150855923,
      "876000000.0": 9543.72435874572,
      "877000000.0": 9555.45856663552,
      "878000000.0": 9567.192774525316,
      "879000000.0": 9578.926982415114,
      "880000000.0": 9590.661190304912,
      "881000000.0": 9602.39539819471,
      "882000000.0": 9614.129606084509,
      "883000000.0": 9625.863813974307,
      "884000000.0": 9637.598021864105,
      "885000000.0": 9649.332229753903,
      "886000000.0": 9661.066437643702,
      "887000000.0": 9672.8006455335,
      "888000000.0": 9684.534853423298,
      "889000000.0": 9696.269061313096,
      "890000000.0": 9708.003269202894,
      "891000000.0": 9719.73747709269,
      "892000000.0": 9731.47168498249,
      "893000000.0": 9743.205892872287,
      "894000000.0": 9754.940100762087,
      "895000000.0": 9766.674308651884,
      "896000000.0": 9778.408516541684,
      "897000000.0": 9790.14272443148,
      "898000000.0": 9801.87693232128,
      "899000000.0": 9813.611140211076,
      "900000000.0": 9825.345348100876,
      "901000000.0": 9837.079555990673,
      "902000000.0": 9848.813763880473,
      "903000000.0": 9860.54797177027,
      "904000000.0": 9872.282179660067,
      "905000000.0": 9884.016387549866,
      "906000000.0": 9895.750595439664,
      "907000000.0": 9907.484803329462,
      "908000000.0": 9919.21901121926,
      "909000000.0": 9930.953219109058,
      "910000000.0": 9942.687426998857,
      "911000000.0": 9954.421634888655,
      "912000000.0": 9966.155842778453,
      "913000000.0": 9977.890050668251,
      "914000000.0": 9989.62425855805,
      "915000000.0": 10001.358466447848,
      "916000000.0": 10013.092674337644,
      "917000000.0": 10024.826882227444,
      "918000000.0": 10036.56109011724,
      "919000000.0": 10048.29529800704,
      "920000000.0": 10060.029505896837,
      "921000000.0": 10071.763713786637,
      "922000000.0": 10083.497921676433,
      "923000000.0": 10095.232129566233,
      "924000000.0": 10106.96633745603,
      "925000000.0": 10118.70054534583,
      "926000000.0": 10130.434753235626,
      "927000000.0": 10142.168961125424,
      "928000000.0": 10153.903169015222,
      "929000000.0": 10165.63737690502,
      "930000000.0": 10177.371584794819,
      "931000000.0": 10189.105792684617,
      "932000000.0": 10200.840000574415,
      "933000000.0": 10212.574208464213,
      "934000000.0": 10224.308416354012,
      "935000000.0": 10236.04262424381,
      "936000000.0": 10247.776832133608,
      "937000000.0": 10259.511040023406,
      "938000000.0": 10271.245247913204,
      "939000000.0": 10282.979455803003,
      "940000000.0": 10294.7136636928,
      "941000000.0": 10306.447871582597,
      "942000000.0": 10318.182079472397,
      "943000000.0": 10329.916287362194,
      "944000000.0": 10341.650495251994,
      "945000000.0": 10353.38470314179,
      "946000000.0": 10365.11891103159,
      "947000000.0": 10376.853118921386,
      "948000000.0": 10388.587326811186,
      "949000000.0": 10400.321534700983,
      "950000000.0": 10412.055742590783,
      "951000000.0": 10423.78995048058,
      "952000000.0": 10435.524158370377,
      "953000000.0": 10447.258366260176,
      "954000000.0": 10458.992574149974,
      "955000000.0": 10470.726782039772,
      "956000000.0": 10482.46098992957,
      "957000000.0": 10494.195197819368,
      "958000000.0": 10505.929405709167,
      "959000000.0": 10517.663613598965,
      "960000000.0": 10529.397821488763,
      "961000000.0": 10541.132029378561,
      "962000000.0": 10552.86623726836,
      "963000000.0": 10564.600445158158,
      "964000000.0": 10576.334653047954,
      "965000000.0": 10588.068860937754,
      "966000000.0": 10599.80306882755,
      "967000000.0": 10611.53727671735,
      "968000000.0": 10623.271484607147,
      "969000000.0": 10635.005692496947,
      "970000000.0": 10646.739900386743,
      "971000000.0": 10658.474108276543,
      "972000000.0": 10670.20831616634,
      "973000000.0": 10681.94252405614,
      "974000000.0": 10693.676731945936,
      "975000000.0": 10705.410939835736,
      "976000000.0": 10717.145147725532
    },
    "i2e": {
      "1000.0": 109738835.75994639,
      "1001.0": 109844282.70810506,
      "1002.0": 109949729.65626371,
      "1003.0": 110055176.60442239,
      "1004.0": 110160623.55258104,
      "1005.0": 110266070.50073971,
      "1006.0": 110371517.44889836,
      "1007.0": 110476964.39705701,
      "1008.0": 110582411.34521568,
      "1009.0": 110687858.29337434,
      "1010.0": 110793305.24153301,
      "1011.0": 110898752.18969166,
      "1012.0": 111004199.13785031,
      "1013.0": 111109646.08600898,
      "1014.0": 111215093.03416763,
      "1015.0": 111320539.98232631,
      "1016.0": 111425986.93048497,
      "1017.0": 111531433.87864363,
      "1018.0": 111636880.82680228,
      "1019.0": 111742327.77496094,
      "1020.0": 111847774.72311962,
      "1021.0": 111953221.67127827,
      "1022.0": 112058668.61943693,
      "1023.0": 112164115.56759559,
      "1024.0": 112269562.51575424,
      "1025.0": 112375009.4639129,
      "1026.0": 112480456.41207157,
      "1027.0": 112585903.36023024,
      "1028.0": 112691350.30838889,
      "1029.0": 112796797.25654756,
      "1030.0": 112902244.2047062,
      "1031.0": 113007691.15286486,
      "1032.0": 113113138.10102354,
      "1033.0": 113218585.04918219,
      "1034.0": 113324031.99734086,
      "1035.0": 113429478.94549951,
      "1036.0": 113534925.89365818,
      "1037.0": 113640372.84181683,
      "1038.0": 113745819.7899755,
      "1039.0": 113851266.73813416,
      "1040.0": 113956713.68629281,
      "1041.0": 114062160.63445148,
      "1042.0": 114167607.58261013,
      "1043.0": 114273054.5307688,
      "1044.0": 114378501.47892746,
      "1045.0": 114483948.42708611,
      "1046.0": 114589395.37524478,
      "1047.0": 114694842.32340343,
      "1048.0": 114800289.2715621,
      "1049.0": 114905736.21972077,
      "1050.0": 115011183.16787942,
      "1051.0": 115116630.11603808,
      "1052.0": 115222077.06419674,
      "1053.0": 115327524.0123554,
      "1054.0": 115432970.96051407,
      "1055.0": 115538417.90867272,
      "1056.0": 115643864.85683139,
      "1057.0": 115749311.80499004,
      "1058.0": 115854758.7531487,
      "1059.0": 115960205.70130736,
      "1060.0": 116065652.64946604,
      "1061.0": 116171099.59762469,
      "1062.0": 116276546.54578334,
      "1063.0": 116381993.493942,
      "1064.0": 116487440.44210066,
      "1065.0": 116592887.39025933,
      "1066.0": 116698334.33841799,
      "1067.0": 116803781.28657666,
      "1068.0": 116909228.23473531,
      "1069.0": 117014675.18289396,
      "1070.0": 117120122.13105263,
      "1071.0": 117225569.07921128,
      "1072.0": 117331016.02736996,
      "1073.0": 117436462.97552861,
      "1074.0": 117541909.92368726,
      "1075.0": 117647356.87184593,
      "1076.0": 117752803.82000458,
      "1077.0": 117858250.76816326,
      "1078.0": 117963697.71632192,
      "1079.0": 118069144.66448058,
      "1080.0": 118174591.61263923,
      "1081.0": 118280038.56079789,
      "1082.0": 118385485.50895655,
      "1083.0": 118490932.45711522,
      "1084.0": 118596379.40527388,
      "1085.0": 118701826.35343254,
      "1086.0": 118807273.30159119,
      "1087.0": 118912720.24974985,
      "1088.0": 119018167.19790852,
      "1089.0": 119123614.14606719,
      "1090.0": 119229061.09422584,
      "1091.0": 119334508.0423845,
      "1092.0": 119439954.99054316,
      "1093.0": 119545401.93870181,
      "1094.0": 119650848.88686047,
      "1095.0": 119756295.83501914,
      "1096.0": 119861742.78317781,
      "1097.0": 119967189.73133646,
      "1098.0": 120072636.67949513,
      "1099.0": 120178083.62765378,
      "1100.0": 120283530.57581244,
      "1101.0": 120388977.52397111,
      "1102.0": 120494424.47212976,
      "1103.0": 120599871.42028843,
      "1104.0": 120705318.36844708,
      "1105.0": 120810765.31660573,
      "1106.0": 120916212.26476441,
      "1107.0": 121021659.21292306,
      "1108.0": 121127106.16108173,
      "1109.0": 121232553.10924038,
      "1110.0": 121338000.05739905,
      "1111.0": 121443447.00555772,
      "1112.0": 121548893.95371637,
      "1113.0": 121654340.90187503,
      "1114.0": 121759787.85003369,
      "1115.0": 121865234.79819235,
      "1116.0": 121970681.746351,
      "1117.0": 122076128.69450967,
      "1118.0": 122181575.64266834,
      "1119.0": 122287022.59082699,
      "1120.0": 122392469.53898565,
      "1121.0": 122497916.4871443,
      "1122.0": 122603363.43530299,
      "1123.0": 122708810.38346164,
      "1124.0": 122814257.33162029,
      "1125.0": 122919704.27977896,
      "1126.0": 123025151.22793761,
      "1127.0": 123130598.17609628,
      "1128.0": 123236045.12425493,
      "1129.0": 123341492.07241361,
      "1130.0": 123446939.02057226,
      "1131.0": 123552385.96873091,
      "1132.0": 123657832.91688958,
      "1133.0": 123763279.86504823,
      "1134.0": 123868726.81320691,
      "1135.0": 123974173.76136556,
      "1136.0": 124079620.70952421,
      "1137.0": 124185067.65768288,
      "1138.0": 124290514.60584153,
      "1139.0": 124395961.5540002,
      "1140.0": 124501408.50215887,
      "1141.0": 124606855.45031753,
      "1142.0": 124712302.39847618,
      "1143.0": 124817749.34663484,
      "1144.0": 124923196.2947935,
      "1145.0": 125028643.24295217,
      "1146.0": 125134090.19111083,
      "1147.0": 125239537.13926949,
      "1148.0": 125344984.08742814,
      "1149.0": 125450431.0355868,
      "1150.0": 125555877.98374546,
      "1151.0": 125661324.93190412,
      "1152.0": 125766771.88006279,
      "1153.0": 125872218.82822146,
      "1154.0": 125977665.7763801,
      "1155.0": 126083112.72453876,
      "1156.0": 126188559.67269744,
      "1157.0": 126294006.62085609,
      "1158.0": 126399453.56901476,
      "1159.0": 126504900.51717341,
      "1160.0": 126610347.46533208,
      "1161.0": 126715794.41349073,
      "1162.0": 126821241.36164938,
      "1163.0": 126926688.30980806,
      "1164.0": 127032135.25796671,
      "1165.0": 127137582.20612538,
      "1166.0": 127243029.15428403,
      "1167.0": 127348476.10244268,
      "1168.0": 127453923.05060136,
      "1169.0": 127559369.99876001,
      "1170.0": 127664816.94691868,
      "1171.0": 127770263.89507733,
      "1172.0": 127875710.843236,
      "1173.0": 127981157.79139465,
      "1174.0": 128086604.7395533,
      "1175.0": 128192051.68771197,
      "1176.0": 128297498.63587062,
      "1177.0": 128402945.58402929,
      "1178.0": 128508392.53218797,
      "1179.0": 128613839.48034662,
      "1180.0": 128719286.42850529,
      "1181.0": 128824733.37666394,
      "1182.0": 128930180.3248226,
      "1183.0": 129035627.27298126,
      "1184.0": 129141074.22113992,
      "1185.0": 129246521.16929857,
      "1186.0": 129351968.11745723,
      "1187.0": 129457415.06561589,
      "1188.0": 129562862.01377457,
      "1189.0": 129668308.96193324,
      "1190.0": 129773755.91009189,
      "1191.0": 129879202.85825054,
      "1192.0": 129984649.80640921,
      "1193.0": 130090096.75456786,
      "1194.0": 130195543.70272653,
      "1195.0": 130300990.65088518,
      "1196.0": 130406437.59904385,
      "1197.0": 130511884.5472025,
      "1198.0": 130617331.49536115,
      "1199.0": 130722778.44351982,
      "1200.0": 130828225.3916785,
      "1201.0": 130933672.33983716,
      "1202.0": 131039119.28799582,
      "1203.0": 131144566.23615448,
      "1204.0": 131250013.18431313,
      "1205.0": 131355460.13247178,
      "1206.0": 131460907.08063045,
      "1207.0": 131566354.0287891,
      "1208.0": 131671800.97694777,
      "1209.0": 131777247.92510642,
      "1210.0": 131882694.8732651,
      "1211.0": 131988141.82142377,
      "1212.0": 132093588.76958242,
      "1213.0": 132199035.71774109,
      "1214.0": 132304482.66589974,
      "1215.0": 132409929.6140584,
      "1216.0": 132515376.56221706,
      "1217.0": 132620823.51037571,
      "1218.0": 132726270.45853437,
      "1219.0": 132831717.40669303,
      "1220.0": 132937164.3548517,
      "1221.0": 133042611.30301034,
      "1222.0": 133148058.25116901,
      "1223.0": 133253505.19932769,
      "1224.0": 133358952.14748634,
      "1225.0": 133464399.09564501,
      "1226.0": 133569846.04380366,
      "1227.0": 133675292.99196233,
      "1228.0": 133780739.94012098,
      "1229.0": 133886186.88827963,
      "1230.0": 133991633.8364383,
      "1231.0": 134097080.78459695,
      "1232.0": 134202527.73275562,
      "1233.0": 134307974.6809143,
      "1234.0": 134413421.62907296,
      "1235.0": 134518868.57723162,
      "1236.0": 134624315.52539027,
      "1237.0": 134729762.47354892,
      "1238.0": 134835209.4217076,
      "1239.0": 134940656.36986625,
      "1240.0": 135046103.3180249,
      "1241.0": 135151550.26618356,
      "1242.0": 135256997.2143422,
      "1243.0": 135362444.1625009,
      "1244.0": 135467891.11065954,
      "1245.0": 135573338.0588182,
      "1246.0": 135678785.00697687,
      "1247.0": 135784231.95513552,
      "1248.0": 135889678.9032942,
      "1249.0": 135995125.85145286,
      "1250.0": 136100572.7996115,
      "1251.0": 136204386.8380457,
      "1252.0": 136308200.87647986,
      "1253.0": 136412014.91491404,
      "1254.0": 136515828.95334822,
      "1255.0": 136619642.9917824,
      "1256.0": 136723457.03021657,
      "1257.0": 136827271.06865072,
      "1258.0": 136931085.10708493,
      "1259.0": 137034899.14551908,
      "1260.0": 137138713.18395326,
      "1261.0": 137242527.22238743,
      "1262.0": 137346341.2608216,
      "1263.0": 137450155.2992558,
      "1264.0": 137553969.33768994,
      "1265.0": 137657783.37612414,
      "1266.0": 137761597.41455832,
      "1267.0": 137865411.45299247,
      "1268.0": 137969225.49142668,
      "1269.0": 138073039.52986082,
      "1270.0": 138176853.568295,
      "1271.0": 138280667.6067292,
      "1272.0": 138384481.64516336,
      "1273.0": 138488295.68359753,
      "1274.0": 138592109.7220317,
      "1275.0": 138695923.7604659,
      "1276.0": 138799737.79890007,
      "1277.0": 138903551.83733425,
      "1278.0": 139007365.87576842,
      "1279.0": 139111179.91420257,
      "1280.0": 139214993.95263675,
      "1281.0": 139318807.99107093,
      "1282.0": 139422622.0295051,
      "1283.0": 139526436.06793928,
      "1284.0": 139630250.10637346,
      "1285.0": 139734064.14480764,
      "1286.0": 139837878.18324178,
      "1287.0": 139941692.221676,
      "1288.0": 140045506.26011017,
      "1289.0": 140149320.29854432,
      "1290.0": 140253134.33697852,
      "1291.0": 140356948.37541267,
      "1292.0": 140460762.41384685,
      "1293.0": 140564576.45228106,
      "1294.0": 140668390.4907152,
      "1295.0": 140772204.52914938,
      "1296.0": 140876018.56758356,
      "1297.0": 140979832.60601774,
      "1298.0": 141083646.64445192,
      "1299.0": 141187460.68288606,
      "1300.0": 141291274.72132027,
      "1301.0": 141395088.75975442,
      "1302.0": 141498902.7981886,
      "1303.0": 141602716.8366228,
      "1304.0": 141706530.87505695,
      "1305.0": 141810344.91349113,
      "1306.0": 141914158.9519253,
      "1307.0": 142017972.9903595,
      "1308.0": 142121787.02879363,
      "1309.0": 142225601.06722784,
      "1310.0": 142329415.10566202,
      "1311.0": 142433229.14409617,
      "1312.0": 142537043.18253037,
      "1313.0": 142640857.22096452,
      "1314.0": 142744671.2593987,
      "1315.0": 142848485.2978329,
      "1316.0": 142952299.33626705,
      "1317.0": 143056113.37470123,
      "1318.0": 143159927.41313538,
      "1319.0": 143263741.4515696,
      "1320.0": 143367555.49000376,
      "1321.0": 143471369.5284379,
      "1322.0": 143575183.56687212,
      "1323.0": 143678997.60530627,
      "1324.0": 143782811.64374045,
      "1325.0": 143886625.68217465,
      "1326.0": 143990439.7206088,
      "1327.0": 144094253.75904298,
      "1328.0": 144198067.79747716,
      "1329.0": 144301881.83591133,
      "1330.0": 144405695.8743455,
      "1331.0": 144509509.9127797,
      "1332.0": 144613323.95121387,
      "1333.0": 144717137.989648,
      "1334.0": 144820952.02808222,
      "1335.0": 144924766.06651637,
      "1336.0": 145028580.10495055,
      "1337.0": 145132394.14338472,
      "1338.0": 145236208.1818189,
      "1339.0": 145340022.22025308,
      "1340.0": 145443836.25868723,
      "1341.0": 145547650.29712144,
      "1342.0": 145651464.3355556,
      "1343.0": 145755278.37398976,
      "1344.0": 145859092.41242397,
      "1345.0": 145962906.45085812,
      "1346.0": 146066720.4892923,
      "1347.0": 146170534.5277265,
      "1348.0": 146274348.56616065,
      "1349.0": 146378162.60459483,
      "1350.0": 146481976.643029,
      "1351.0": 146585790.68146318,
      "1352.0": 146689604.71989736,
      "1353.0": 146793418.75833154,
      "1354.0": 146897232.7967657,
      "1355.0": 147001046.83519986,
      "1356.0": 147104860.87363404,
      "1357.0": 147208674.91206822,
      "1358.0": 147312488.9505024,
      "1359.0": 147416302.98893657,
      "1360.0": 147520117.02737075,
      "1361.0": 147623931.06580493,
      "1362.0": 147727745.10423908,
      "1363.0": 147831559.14267328,
      "1364.0": 147935373.18110746,
      "1365.0": 148039187.2195416,
      "1366.0": 148143001.25797582,
      "1367.0": 148246815.29640996,
      "1368.0": 148350629.33484414,
      "1369.0": 148454443.37327835,
      "1370.0": 148558257.4117125,
      "1371.0": 148662071.45014668,
      "1372.0": 148765885.48858085,
      "1373.0": 148869699.52701503,
      "1374.0": 148973513.5654492,
      "1375.0": 149077327.60388336,
      "1376.0": 149181141.64231756,
      "1377.0": 149284955.6807517,
      "1378.0": 149388769.7191859,
      "1379.0": 149492583.7576201,
      "1380.0": 149596397.79605424,
      "1381.0": 149700211.83448842,
      "1382.0": 149804025.8729226,
      "1383.0": 149907839.91135678,
      "1384.0": 150011653.94979095,
      "1385.0": 150115467.98822513,
      "1386.0": 150219282.0266593,
      "1387.0": 150323096.06509346,
      "1388.0": 150426910.10352767,
      "1389.0": 150530724.1419618,
      "1390.0": 150634538.180396,
      "1391.0": 150738352.2188302,
      "1392.0": 150842166.25726435,
      "1393.0": 150945980.29569852,
      "1394.0": 151049794.33413267,
      "1395.0": 151153608.37256688,
      "1396.0": 151257422.41100106,
      "1397.0": 151361236.4494352,
      "1398.0": 151465050.4878694,
      "1399.0": 151568864.52630356,
      "1400.0": 151672678.56473774,
      "1401.0": 151776492.60317194,
      "1402.0": 151880306.6416061,
      "1403.0": 151984120.68004027,
      "1404.0": 152087934.71847445,
      "1405.0": 152191748.75690863,
      "1406.0": 152295562.7953428,
      "1407.0": 152399376.83377698,
      "1408.0": 152503190.87221116,
      "1409.0": 152607004.9106453,
      "1410.0": 152710818.9490795,
      "1411.0": 152814632.98751366,
      "1412.0": 152918447.02594784,
      "1413.0": 153022261.06438202,
      "1414.0": 153126075.1028162,
      "1415.0": 153229889.14125037,
      "1416.0": 153333703.17968455,
      "1417.0": 153437517.21811873,
      "1418.0": 153541331.2565529,
      "1419.0": 153645145.29498705,
      "1420.0": 153748959.33342126,
      "1421.0": 153852773.3718554,
      "1422.0": 153956587.4102896,
      "1423.0": 154060401.4487238,
      "1424.0": 154164215.48715794,
      "1425.0": 154268029.52559212,
      "1426.0": 154371843.5640263,
      "1427.0": 154475657.60246047,
      "1428.0": 154579471.64089465,
      "1429.0": 154683285.67932883,
      "1430.0": 154787099.717763,
      "1431.0": 154890913.75619715,
      "1432.0": 154994727.79463133,
      "1433.0": 155098541.83306554,
      "1434.0": 155202355.8714997,
      "1435.0": 155306169.9099339,
      "1436.0": 155409983.94836804,
      "1437.0": 155513797.98680222,
      "1438.0": 155617612.02523637,
      "1439.0": 155721426.06367058,
      "1440.0": 155825240.10210475,
      "1441.0": 155929054.1405389,
      "1442.0": 156032868.1789731,
      "1443.0": 156136682.21740726,
      "1444.0": 156240496.25584143,
      "1445.0": 156344310.29427564,
      "1446.0": 156448124.3327098,
      "1447.0": 156551938.37114397,
      "1448.0": 156655752.40957814,
      "1449.0": 156759566.44801232,
      "1450.0": 156863380.4864465,
      "1451.0": 156967194.52488065,
      "1452.0": 157071008.56331486,
      "1453.0": 157174822.601749,
      "1454.0": 157278636.6401832,
      "1455.0": 157382450.6786174,
      "1456.0": 157486264.71705154,
      "1457.0": 157590078.7554857,
      "1458.0": 157693892.7939199,
      "1459.0": 157797706.83235407,
      "1460.0": 157901520.87078825,
      "1461.0": 158005334.90922242,
      "1462.0": 158109148.9476566,
      "1463.0": 158212962.98609075,
      "1464.0": 158316777.02452496,
      "1465.0": 158420591.0629591,
      "1466.0": 158524405.10139328,
      "1467.0": 158628219.1398275,
      "1468.0": 158732033.17826164,
      "1469.0": 158835847.21669582,
      "1470.0": 158939661.25512996,
      "1471.0": 159043475.29356417,
      "1472.0": 159147289.33199835,
      "1473.0": 159251103.37043253,
      "1474.0": 159354917.4088667,
      "1475.0": 159458731.44730085,
      "1476.0": 159562545.48573503,
      "1477.0": 159666359.52416924,
      "1478.0": 159770173.56260338,
      "1479.0": 159873987.60103756,
      "1480.0": 159977801.63947174,
      "1481.0": 160081615.67790592,
      "1482.0": 160185429.7163401,
      "1483.0": 160289243.75477427,
      "1484.0": 160393057.79320845,
      "1485.0": 160496871.8316426,
      "1486.0": 160600685.8700768,
      "1487.0": 160704499.90851095,
      "1488.0": 160808313.94694513,
      "1489.0": 160912127.9853793,
      "1490.0": 161015942.0238135,
      "1491.0": 161119756.06224766,
      "1492.0": 161223570.10068184,
      "1493.0": 161327384.13911602,
      "1494.0": 161431198.1775502,
      "1495.0": 161535012.21598434,
      "1496.0": 161638826.25441855,
      "1497.0": 161742640.2928527,
      "1498.0": 161846454.33128688,
      "1499.0": 161950268.36972108,
      "1500.0": 162054082.40815523,
      "1501.0": 162156414.2958125,
      "1502.0": 162258746.18346974,
      "1503.0": 162361078.071127,
      "1504.0": 162463409.95878425,
      "1505.0": 162565741.84644148,
      "1506.0": 162668073.73409876,
      "1507.0": 162770405.621756,
      "1508.0": 162872737.50941324,
      "1509.0": 162975069.3970705,
      "1510.0": 163077401.28472775,
      "1511.0": 163179733.172385,
      "1512.0": 163282065.06004223,
      "1513.0": 163384396.9476995,
      "1514.0": 163486728.83535677,
      "1515.0": 163589060.723014,
      "1516.0": 163691392.61067125,
      "1517.0": 163793724.49832848,
      "1518.0": 163896056.38598576,
      "1519.0": 163998388.273643,
      "1520.0": 164100720.16130024,
      "1521.0": 164203052.0489575,
      "1522.0": 164305383.93661475,
      "1523.0": 164407715.824272,
      "1524.0": 164510047.71192923,
      "1525.0": 164612379.59958652,
      "1526.0": 164714711.48724377,
      "1527.0": 164817043.374901,
      "1528.0": 164919375.26255825,
      "1529.0": 165021707.1502155,
      "1530.0": 165124039.03787276,
      "1531.0": 165226370.92553002,
      "1532.0": 165328702.81318724,
      "1533.0": 165431034.70084453,
      "1534.0": 165533366.58850175,
      "1535.0": 165635698.476159,
      "1536.0": 165738030.36381626,
      "1537.0": 165840362.25147352,
      "1538.0": 165942694.13913077,
      "1539.0": 166045026.026788,
      "1540.0": 166147357.91444525,
      "1541.0": 166249689.80210254,
      "1542.0": 166352021.68975976,
      "1543.0": 166454353.57741702,
      "1544.0": 166556685.46507424,
      "1545.0": 166659017.35273153,
      "1546.0": 166761349.24038878,
      "1547.0": 166863681.128046,
      "1548.0": 166966013.01570326,
      "1549.0": 167068344.90336052,
      "1550.0": 167170676.79101777,
      "1551.0": 167273008.67867503,
      "1552.0": 167375340.56633228,
      "1553.0": 167477672.45398954,
      "1554.0": 167580004.34164676,
      "1555.0": 167682336.22930402,
      "1556.0": 167784668.11696127,
      "1557.0": 167887000.00461853,
      "1558.0": 167989331.89227578,
      "1559.0": 168091663.779933,
      "1560.0": 168193995.6675903,
      "1561.0": 168296327.55524752,
      "1562.0": 168398659.44290477,
      "1563.0": 168500991.33056203,
      "1564.0": 168603323.21821928,
      "1565.0": 168705655.10587654,
      "1566.0": 168807986.99353376,
      "1567.0": 168910318.88119102,
      "1568.0": 169012650.7688483,
      "1569.0": 169114982.65650553,
      "1570.0": 169217314.54416278,
      "1571.0": 169319646.43182,
      "1572.0": 169421978.3194773,
      "1573.0": 169524310.20713454,
      "1574.0": 169626642.09479177,
      "1575.0": 169728973.98244902,
      "1576.0": 169831305.87010628,
      "1577.0": 169933637.75776353,
      "1578.0": 170035969.6454208,
      "1579.0": 170138301.53307804,
      "1580.0": 170240633.4207353,
      "1581.0": 170342965.30839252,
      "1582.0": 170445297.19604978,
      "1583.0": 170547629.08370706,
      "1584.0": 170649960.9713643,
      "1585.0": 170752292.85902154,
      "1586.0": 170854624.74667877,
      "1587.0": 170956956.63433605,
      "1588.0": 171059288.5219933,
      "1589.0": 171161620.40965053,
      "1590.0": 171263952.2973078,
      "1591.0": 171366284.18496504,
      "1592.0": 171468616.0726223,
      "1593.0": 171570947.96027955,
      "1594.0": 171673279.84793678,
      "1595.0": 171775611.73559406,
      "1596.0": 171877943.6232513,
      "1597.0": 171980275.51090854,
      "1598.0": 172082607.3985658,
      "1599.0": 172184939.28622305,
      "1600.0": 172287271.1738803,
      "1601.0": 172389603.06153753,
      "1602.0": 172491934.9491948,
      "1603.0": 172594266.83685204,
      "1604.0": 172696598.7245093,
      "1605.0": 172798930.61216655,
      "1606.0": 172901262.4998238,
      "1607.0": 173003594.38748106,
      "1608.0": 173105926.2751383,
      "1609.0": 173208258.16279554,
      "1610.0": 173310590.05045283,
      "1611.0": 173412921.93811005,
      "1612.0": 173515253.8257673,
      "1613.0": 173617585.71342453,
      "1614.0": 173719917.60108182,
      "1615.0": 173822249.48873907,
      "1616.0": 173924581.3763963,
      "1617.0": 174026913.26405355,
      "1618.0": 174129245.1517108,
      "1619.0": 174231577.03936806,
      "1620.0": 174333908.92702532,
      "1621.0": 174436240.81468254,
      "1622.0": 174538572.70233983,
      "1623.0": 174640904.58999705,
      "1624.0": 174743236.4776543,
      "1625.0": 174845568.3653116,
      "1626.0": 174947900.25296882,
      "1627.0": 175050232.14062607,
      "1628.0": 175152564.0282833,
      "1629.0": 175254895.91594055,
      "1630.0": 175357227.80359784,
      "1631.0": 175459559.69125506,
      "1632.0": 175561891.57891232,
      "1633.0": 175664223.46656957,
      "1634.0": 175766555.35422683,
      "1635.0": 175868887.24188405,
      "1636.0": 175971219.1295413,
      "1637.0": 176073551.0171986,
      "1638.0": 176175882.90485582,
      "1639.0": 176278214.79251307,
      "1640.0": 176380546.6801703,
      "1641.0": 176482878.56782758,
      "1642.0": 176585210.45548484,
      "1643.0": 176687542.34314206,
      "1644.0": 176789874.23079932,
      "1645.0": 176892206.11845657,
      "1646.0": 176994538.00611383,
      "1647.0": 177096869.89377108,
      "1648.0": 177199201.7814283,
      "1649.0": 177301533.6690856,
      "1650.0": 177403865.55674282,
      "1651.0": 177506197.44440007,
      "1652.0": 177608529.33205736,
      "1653.0": 177710861.21971458,
      "1654.0": 177813193.10737184,
      "1655.0": 177915524.99502906,
      "1656.0": 178017856.88268632,
      "1657.0": 178120188.7703436,
      "1658.0": 178222520.65800083,
      "1659.0": 178324852.54565808,
      "1660.0": 178427184.43331534,
      "1661.0": 178529516.3209726,
      "1662.0": 178631848.20862985,
      "1663.0": 178734180.09628707,
      "1664.0": 178836511.98394436,
      "1665.0": 178938843.87160158,
      "1666.0": 179041175.75925884,
      "1667.0": 179143507.6469161,
      "1668.0": 179245839.53457335,
      "1669.0": 179348171.4222306,
      "1670.0": 179450503.30988783,
      "1671.0": 179552835.19754508,
      "1672.0": 179655167.08520234,
      "1673.0": 179757498.9728596,
      "1674.0": 179859830.86051685,
      "1675.0": 179962162.74817407,
      "1676.0": 180064494.63583136,
      "1677.0": 180166826.52348858,
      "1678.0": 180269158.41114584,
      "1679.0": 180371490.29880312,
      "1680.0": 180473822.18646035,
      "1681.0": 180576154.0741176,
      "1682.0": 180678485.96177483,
      "1683.0": 180780817.84943208,
      "1684.0": 180883149.73708937,
      "1685.0": 180985481.6247466,
      "1686.0": 181087813.51240385,
      "1687.0": 181190145.4000611,
      "1688.0": 181292477.28771836,
      "1689.0": 181394809.1753756,
      "1690.0": 181497141.06303284,
      "1691.0": 181599472.95069012,
      "1692.0": 181701804.83834735,
      "1693.0": 181804136.7260046,
      "1694.0": 181906468.61366186,
      "1695.0": 182008800.5013191,
      "1696.0": 182111132.38897637,
      "1697.0": 182213464.2766336,
      "1698.0": 182315796.16429085,
      "1699.0": 182418128.05194813,
      "1700.0": 182520459.93960536,
      "1701.0": 182622791.8272626,
      "1702.0": 182725123.71491984,
      "1703.0": 182827455.60257712,
      "1704.0": 182929787.49023438,
      "1705.0": 183032119.3778916,
      "1706.0": 183134451.26554888,
      "1707.0": 183236783.1532061,
      "1708.0": 183339115.04086336,
      "1709.0": 183441446.92852062,
      "1710.0": 183543778.81617785,
      "1711.0": 183646110.70383513,
      "1712.0": 183748442.59149235,
      "1713.0": 183850774.4791496,
      "1714.0": 183953106.36680686,
      "1715.0": 184055438.25446412,
      "1716.0": 184157770.14212137,
      "1717.0": 184260102.0297786,
      "1718.0": 184362433.91743588,
      "1719.0": 184464765.8050931,
      "1720.0": 184567097.69275036,
      "1721.0": 184669429.58040762,
      "1722.0": 184771761.46806487,
      "1723.0": 184874093.35572213,
      "1724.0": 184976425.24337935,
      "1725.0": 185078757.13103664,
      "1726.0": 185181089.0186939,
      "1727.0": 185283420.90635112,
      "1728.0": 185385752.79400837,
      "1729.0": 185488084.6816656,
      "1730.0": 185590416.56932288,
      "1731.0": 185692748.45698014,
      "1732.0": 185795080.34463736,
      "1733.0": 185897412.23229465,
      "1734.0": 185999744.11995187,
      "1735.0": 186102076.00760913,
      "1736.0": 186204407.89526638,
      "1737.0": 186306739.78292364,
      "1738.0": 186409071.6705809,
      "1739.0": 186511403.55823812,
      "1740.0": 186613735.44589537,
      "1741.0": 186716067.33355266,
      "1742.0": 186818399.22120988,
      "1743.0": 186920731.10886714,
      "1744.0": 187023062.99652436,
      "1745.0": 187125394.88418165,
      "1746.0": 187227726.77183887,
      "1747.0": 187330058.65949613,
      "1748.0": 187432390.54715338,
      "1749.0": 187534722.43481064,
      "1750.0": 187637054.3224679,
      "1751.0": 187737845.82699704,
      "1752.0": 187838637.33152622,
      "1753.0": 187939428.8360554,
      "1754.0": 188040220.34058455,
      "1755.0": 188141011.8451137,
      "1756.0": 188241803.34964287,
      "1757.0": 188342594.85417202,
      "1758.0": 188443386.3587012,
      "1759.0": 188544177.86323035,
      "1760.0": 188644969.36775953,
      "1761.0": 188745760.87228867,
      "1762.0": 188846552.37681785,
      "1763.0": 188947343.881347,
      "1764.0": 189048135.38587618,
      "1765.0": 189148926.89040533,
      "1766.0": 189249718.39493448,
      "1767.0": 189350509.89946365,
      "1768.0": 189451301.40399283,
      "1769.0": 189552092.90852198,
      "1770.0": 189652884.41305113,
      "1771.0": 189753675.9175803,
      "1772.0": 189854467.42210948,
      "1773.0": 189955258.92663863,
      "1774.0": 190056050.43116778,
      "1775.0": 190156841.93569696,
      "1776.0": 190257633.44022614,
      "1777.0": 190358424.9447553,
      "1778.0": 190459216.44928443,
      "1779.0": 190560007.95381358,
      "1780.0": 190660799.4583428,
      "1781.0": 190761590.96287194,
      "1782.0": 190862382.4674011,
      "1783.0": 190963173.97193024,
      "1784.0": 191063965.47645944,
      "1785.0": 191164756.9809886,
      "1786.0": 191265548.48551774,
      "1787.0": 191366339.9900469,
      "1788.0": 191467131.49457607,
      "1789.0": 191567922.99910524,
      "1790.0": 191668714.5036344,
      "1791.0": 191769506.00816354,
      "1792.0": 191870297.51269272,
      "1793.0": 191971089.0172219,
      "1794.0": 192071880.52175105,
      "1795.0": 192172672.02628022,
      "1796.0": 192273463.53080937,
      "1797.0": 192374255.03533852,
      "1798.0": 192475046.5398677,
      "1799.0": 192575838.04439688,
      "1800.0": 192676629.54892603,
      "1801.0": 192777421.05345517,
      "1802.0": 192878212.55798435,
      "1803.0": 192979004.06251353,
      "1804.0": 193079795.56704268,
      "1805.0": 193180587.07157183,
      "1806.0": 193281378.576101,
      "1807.0": 193382170.08063018,
      "1808.0": 193482961.58515933,
      "1809.0": 193583753.08968848,
      "1810.0": 193684544.59421763,
      "1811.0": 193785336.09874684,
      "1812.0": 193886127.60327598,
      "1813.0": 193986919.10780513,
      "1814.0": 194087710.61233428,
      "1815.0": 194188502.1168635,
      "1816.0": 194289293.62139264,
      "1817.0": 194390085.1259218,
      "1818.0": 194490876.63045093,
      "1819.0": 194591668.1349801,
      "1820.0": 194692459.6395093,
      "1821.0": 194793251.14403844,
      "1822.0": 194894042.6485676,
      "1823.0": 194994834.15309677,
      "1824.0": 195095625.65762594,
      "1825.0": 195196417.1621551,
      "1826.0": 195297208.66668424,
      "1827.0": 195398000.17121342,
      "1828.0": 195498791.67574257,
      "1829.0": 195599583.18027174,
      "1830.0": 195700374.68480092,
      "1831.0": 195801166.18933007,
      "1832.0": 195901957.69385922,
      "1833.0": 196002749.1983884,
      "1834.0": 196103540.70291755,
      "1835.0": 196204332.20744672,
      "1836.0": 196305123.71197587,
      "1837.0": 196405915.21650505,
      "1838.0": 196506706.72103423,
      "1839.0": 196607498.22556338,
      "1840.0": 196708289.73009253,
      "1841.0": 196809081.23462167,
      "1842.0": 196909872.73915088,
      "1843.0": 197010664.24368003,
      "1844.0": 197111455.74820918,
      "1845.0": 197212247.25273833,
      "1846.0": 197313038.75726753,
      "1847.0": 197413830.26179668,
      "1848.0": 197514621.76632583,
      "1849.0": 197615413.27085498,
      "1850.0": 197716204.77538416,
      "1851.0": 197816996.27991334,
      "1852.0": 197917787.78444248,
      "1853.0": 198018579.28897163,
      "1854.0": 198119370.7935008,
      "1855.0": 198220162.29803,
      "1856.0": 198320953.80255914,
      "1857.0": 198421745.3070883,
      "1858.0": 198522536.81161746,
      "1859.0": 198623328.3161466,
      "1860.0": 198724119.8206758,
      "1861.0": 198824911.32520494,
      "1862.0": 198925702.82973412,
      "1863.0": 199026494.33426327,
      "1864.0": 199127285.83879244,
      "1865.0": 199228077.3433216,
      "1866.0": 199328868.84785077,
      "1867.0": 199429660.35237992,
      "1868.0": 199530451.8569091,
      "1869.0": 199631243.36143824,
      "1870.0": 199732034.86596742,
      "1871.0": 199832826.37049657,
      "1872.0": 199933617.87502572,
      "1873.0": 200034409.37955493,
      "1874.0": 200135200.88408408,
      "1875.0": 200235992.38861322,
      "1876.0": 200336783.89314237,
      "1877.0": 200437575.39767155,
      "1878.0": 200538366.90220073,
      "1879.0": 200639158.40672988,
      "1880.0": 200739949.91125903,
      "1881.0": 200840741.4157882,
      "1882.0": 200941532.92031738,
      "1883.0": 201042324.42484653,
      "1884.0": 201143115.92937568,
      "1885.0": 201243907.43390486,
      "1886.0": 201344698.93843403,
      "1887.0": 201445490.44296318,
      "1888.0": 201546281.94749233,
      "1889.0": 201647073.4520215,
      "1890.0": 201747864.95655066,
      "1891.0": 201848656.46107984,
      "1892.0": 201949447.96560898,
      "1893.0": 202050239.47013816,
      "1894.0": 202151030.9746673,
      "1895.0": 202251822.4791965,
      "1896.0": 202352613.98372564,
      "1897.0": 202453405.48825482,
      "1898.0": 202554196.99278396,
      "1899.0": 202654988.49731314,
      "1900.0": 202755780.0018423,
      "1901.0": 202856571.50637147,
      "1902.0": 202957363.01090062,
      "1903.0": 203058154.51542976,
      "1904.0": 203158946.01995894,
      "1905.0": 203259737.52448812,
      "1906.0": 203360529.02901727,
      "1907.0": 203461320.53354642,
      "1908.0": 203562112.0380756,
      "1909.0": 203662903.54260477,
      "1910.0": 203763695.04713392,
      "1911.0": 203864486.55166307,
      "1912.0": 203965278.05619222,
      "1913.0": 204066069.56072143,
      "1914.0": 204166861.06525058,
      "1915.0": 204267652.56977972,
      "1916.0": 204368444.0743089,
      "1917.0": 204469235.57883808,
      "1918.0": 204570027.08336723,
      "1919.0": 204670818.58789638,
      "1920.0": 204771610.09242553,
      "1921.0": 204872401.5969547,
      "1922.0": 204973193.10148388,
      "1923.0": 205073984.60601303,
      "1924.0": 205174776.1105422,
      "1925.0": 205275567.61507136,
      "1926.0": 205376359.11960053,
      "1927.0": 205477150.62412968,
      "1928.0": 205577942.12865883,
      "1929.0": 205678733.633188,
      "1930.0": 205779525.13771716,
      "1931.0": 205880316.64224634,
      "1932.0": 205981108.1467755,
      "1933.0": 206081899.65130466,
      "1934.0": 206182691.1558338,
      "1935.0": 206283482.660363,
      "1936.0": 206384274.16489217,
      "1937.0": 206485065.66942132,
      "1938.0": 206585857.17395046,
      "1939.0": 206686648.67847964,
      "1940.0": 206787440.18300882,
      "1941.0": 206888231.68753797,
      "1942.0": 206989023.19206712,
      "1943.0": 207089814.69659626,
      "1944.0": 207190606.20112547,
      "1945.0": 207291397.70565462,
      "1946.0": 207392189.21018377,
      "1947.0": 207492980.71471292,
      "1948.0": 207593772.21924213,
      "1949.0": 207694563.72377127,
      "1950.0": 207795355.22830042,
      "1951.0": 207896146.73282957,
      "1952.0": 207996938.23735875,
      "1953.0": 208097729.74188793,
      "1954.0": 208198521.24641708,
      "1955.0": 208299312.75094622,
      "1956.0": 208400104.2554754,
      "1957.0": 208500895.76000458,
      "1958.0": 208601687.26453373,
      "1959.0": 208702478.7690629,
      "1960.0": 208803270.27359205,
      "1961.0": 208904061.7781212,
      "1962.0": 209004853.28265038,
      "1963.0": 209105644.78717953,
      "1964.0": 209206436.2917087,
      "1965.0": 209307227.79623786,
      "1966.0": 209408019.30076703,
      "1967.0": 209508810.8052962,
      "1968.0": 209609602.30982536,
      "1969.0": 209710393.8143545,
      "1970.0": 209811185.3188837,
      "1971.0": 209911976.82341284,
      "1972.0": 210012768.327942,
      "1973.0": 210113559.83247116,
      "1974.0": 210214351.3370003,
      "1975.0": 210315142.84152952,
      "1976.0": 210415934.34605867,
      "1977.0": 210516725.85058782,
      "1978.0": 210617517.35511696,
      "1979.0": 210718308.85964617,
      "1980.0": 210819100.36417532,
      "1981.0": 210919891.86870447,
      "1982.0": 211020683.37323362,
      "1983.0": 211121474.8777628,
      "1984.0": 211222266.38229197,
      "1985.0": 211323057.88682112,
      "1986.0": 211423849.39135027,
      "1987.0": 211524640.89587945,
      "1988.0": 211625432.40040863,
      "1989.0": 211726223.90493777,
      "1990.0": 211827015.40946692,
      "1991.0": 211927806.9139961,
      "1992.0": 212028598.41852525,
      "1993.0": 212129389.92305443,
      "1994.0": 212230181.42758358,
      "1995.0": 212330972.93211275,
      "1996.0": 212431764.4366419,
      "1997.0": 212532555.94117108,
      "1998.0": 212633347.44570023,
      "1999.0": 212734138.9502294,
      "2000.0": 212834930.45475855,
      "2001.0": 212934470.13762972,
      "2002.0": 213034009.8205009,
      "2003.0": 213133549.50337204,
      "2004.0": 213233089.18624324,
      "2005.0": 213332628.8691144,
      "2006.0": 213432168.55198556,
      "2007.0": 213531708.23485672,
      "2008.0": 213631247.91772792,
      "2009.0": 213730787.60059905,
      "2010.0": 213830327.28347024,
      "2011.0": 213929866.9663414,
      "2012.0": 214029406.64921257,
      "2013.0": 214128946.33208373,
      "2014.0": 214228486.01495492,
      "2015.0": 214328025.69782606,
      "2016.0": 214427565.38069725,
      "2017.0": 214527105.0635684,
      "2018.0": 214626644.74643958,
      "2019.0": 214726184.42931074,
      "2020.0": 214825724.1121819,
      "2021.0": 214925263.79505306,
      "2022.0": 215024803.47792426,
      "2023.0": 215124343.1607954,
      "2024.0": 215223882.84366658,
      "2025.0": 215323422.52653775,
      "2026.0": 215422962.2094089,
      "2027.0": 215522501.89228007,
      "2028.0": 215622041.57515126,
      "2029.0": 215721581.2580224,
      "2030.0": 215821120.9408936,
      "2031.0": 215920660.62376475,
      "2032.0": 216020200.30663592,
      "2033.0": 216119739.98950708,
      "2034.0": 216219279.67237827,
      "2035.0": 216318819.3552494,
      "2036.0": 216418359.0381206,
      "2037.0": 216517898.72099176,
      "2038.0": 216617438.40386292,
      "2039.0": 216716978.0867341,
      "2040.0": 216816517.76960528,
      "2041.0": 216916057.4524764,
      "2042.0": 217015597.1353476,
      "2043.0": 217115136.81821877,
      "2044.0": 217214676.50108993,
      "2045.0": 217314216.1839611,
      "2046.0": 217413755.8668323,
      "2047.0": 217513295.54970342,
      "2048.0": 217612835.2325746,
      "2049.0": 217712374.91544577,
      "2050.0": 217811914.59831694,
      "2051.0": 217911454.2811881,
      "2052.0": 218010993.9640593,
      "2053.0": 218110533.64693043,
      "2054.0": 218210073.32980162,
      "2055.0": 218309613.01267278,
      "2056.0": 218409152.69554394,
      "2057.0": 218508692.3784151,
      "2058.0": 218608232.0612863,
      "2059.0": 218707771.74415743,
      "2060.0": 218807311.42702863,
      "2061.0": 218906851.1098998,
      "2062.0": 219006390.79277095,
      "2063.0": 219105930.4756421,
      "2064.0": 219205470.15851328,
      "2065.0": 219305009.84138444,
      "2066.0": 219404549.52425563,
      "2067.0": 219504089.2071268,
      "2068.0": 219603628.88999793,
      "2069.0": 219703168.57286912,
      "2070.0": 219802708.25574028,
      "2071.0": 219902247.93861145,
      "2072.0": 220001787.6214826,
      "2073.0": 220101327.30435377,
      "2074.0": 220200866.98722494,
      "2075.0": 220300406.67009613,
      "2076.0": 220399946.35296726,
      "2077.0": 220499486.03583845,
      "2078.0": 220599025.71870962,
      "2079.0": 220698565.40158078,
      "2080.0": 220798105.08445194,
      "2081.0": 220897644.76732314,
      "2082.0": 220997184.45019427,
      "2083.0": 221096724.13306546,
      "2084.0": 221196263.81593663,
      "2085.0": 221295803.4988078,
      "2086.0": 221395343.18167895,
      "2087.0": 221494882.86455014,
      "2088.0": 221594422.54742128,
      "2089.0": 221693962.23029247,
      "2090.0": 221793501.91316363,
      "2091.0": 221893041.5960348,
      "2092.0": 221992581.27890596,
      "2093.0": 222092120.96177715,
      "2094.0": 222191660.64464828,
      "2095.0": 222291200.32751948,
      "2096.0": 222390740.01039064,
      "2097.0": 222490279.6932618,
      "2098.0": 222589819.37613297,
      "2099.0": 222689359.05900416,
      "2100.0": 222788898.7418753,
      "2101.0": 222888438.42474648,
      "2102.0": 222987978.10761765,
      "2103.0": 223087517.7904888,
      "2104.0": 223187057.47335997,
      "2105.0": 223286597.15623116,
      "2106.0": 223386136.8391023,
      "2107.0": 223485676.5219735,
      "2108.0": 223585216.20484465,
      "2109.0": 223684755.88771582,
      "2110.0": 223784295.57058698,
      "2111.0": 223883835.25345817,
      "2112.0": 223983374.9363293,
      "2113.0": 224082914.6192005,
      "2114.0": 224182454.30207166,
      "2115.0": 224281993.98494282,
      "2116.0": 224381533.667814,
      "2117.0": 224481073.35068518,
      "2118.0": 224580613.0335563,
      "2119.0": 224680152.7164275,
      "2120.0": 224779692.39929867,
      "2121.0": 224879232.08216983,
      "2122.0": 224978771.765041,
      "2123.0": 225078311.44791216,
      "2124.0": 225177851.13078332,
      "2125.0": 225277390.8136545,
      "2126.0": 225376930.49652565,
      "2127.0": 225476470.1793968,
      "2128.0": 225576009.862268,
      "2129.0": 225675549.54513916,
      "2130.0": 225775089.22801033,
      "2131.0": 225874628.9108815,
      "2132.0": 225974168.59375265,
      "2133.0": 226073708.27662382,
      "2134.0": 226173247.959495,
      "2135.0": 226272787.64236614,
      "2136.0": 226372327.32523733,
      "2137.0": 226471867.0081085,
      "2138.0": 226571406.69097966,
      "2139.0": 226670946.37385082,
      "2140.0": 226770486.05672202,
      "2141.0": 226870025.73959315,
      "2142.0": 226969565.42246434,
      "2143.0": 227069105.1053355,
      "2144.0": 227168644.78820667,
      "2145.0": 227268184.47107783,
      "2146.0": 227367724.15394902,
      "2147.0": 227467263.83682016,
      "2148.0": 227566803.51969135,
      "2149.0": 227666343.2025625,
      "2150.0": 227765882.88543367,
      "2151.0": 227865422.56830484,
      "2152.0": 227964962.25117603,
      "2153.0": 228064501.93404716,
      "2154.0": 228164041.61691836,
      "2155.0": 228263581.29978952,
      "2156.0": 228363120.98266068,
      "2157.0": 228462660.66553184,
      "2158.0": 228562200.34840304,
      "2159.0": 228661740.03127417,
      "2160.0": 228761279.71414536,
      "2161.0": 228860819.39701653,
      "2162.0": 228960359.0798877,
      "2163.0": 229059898.76275885,
      "2164.0": 229159438.44563004,
      "2165.0": 229258978.12850118,
      "2166.0": 229358517.81137237,
      "2167.0": 229458057.49424353,
      "2168.0": 229557597.1771147,
      "2169.0": 229657136.85998586,
      "2170.0": 229756676.54285705,
      "2171.0": 229856216.22572818,
      "2172.0": 229955755.90859938,
      "2173.0": 230055295.59147054,
      "2174.0": 230154835.2743417,
      "2175.0": 230254374.95721287,
      "2176.0": 230353914.64008403,
      "2177.0": 230453454.3229552,
      "2178.0": 230552994.00582638,
      "2179.0": 230652533.68869752,
      "2180.0": 230752073.3715687,
      "2181.0": 230851613.05443987,
      "2182.0": 230951152.73731104,
      "2183.0": 231050692.4201822,
      "2184.0": 231150232.1030534,
      "2185.0": 231249771.78592452,
      "2186.0": 231349311.4687957,
      "2187.0": 231448851.15166688,
      "2188.0": 231548390.83453804,
      "2189.0": 231647930.5174092,
      "2190.0": 231747470.20028037,
      "2191.0": 231847009.88315153,
      "2192.0": 231946549.56602272,
      "2193.0": 232046089.2488939,
      "2194.0": 232145628.93176502,
      "2195.0": 232245168.6146362,
      "2196.0": 232344708.2975074,
      "2197.0": 232444247.98037854,
      "2198.0": 232543787.6632497,
      "2199.0": 232643327.3461209,
      "2200.0": 232742867.02899206,
      "2201.0": 232842406.71186322,
      "2202.0": 232941946.39473438,
      "2203.0": 233041486.07760555,
      "2204.0": 233141025.7604767,
      "2205.0": 233240565.4433479,
      "2206.0": 233340105.12621903,
      "2207.0": 233439644.80909023,
      "2208.0": 233539184.4919614,
      "2209.0": 233638724.17483255,
      "2210.0": 233738263.85770372,
      "2211.0": 233837803.5405749,
      "2212.0": 233937343.22344604,
      "2213.0": 234036882.90631723,
      "2214.0": 234136422.5891884,
      "2215.0": 234235962.27205956,
      "2216.0": 234335501.95493072,
      "2217.0": 234435041.63780192,
      "2218.0": 234534581.32067305,
      "2219.0": 234634121.00354424,
      "2220.0": 234733660.6864154,
      "2221.0": 234833200.36928657,
      "2222.0": 234932740.05215773,
      "2223.0": 235032279.73502892,
      "2224.0": 235131819.41790006,
      "2225.0": 235231359.10077125,
      "2226.0": 235330898.7836424,
      "2227.0": 235430438.46651357,
      "2228.0": 235529978.14938474,
      "2229.0": 235629517.8322559,
      "2230.0": 235729057.51512706,
      "2231.0": 235828597.19799826,
      "2232.0": 235928136.8808694,
      "2233.0": 236027676.56374058,
      "2234.0": 236127216.24661174,
      "2235.0": 236226755.9294829,
      "2236.0": 236326295.61235407,
      "2237.0": 236425835.29522526,
      "2238.0": 236525374.9780964,
      "2239.0": 236624914.6609676,
      "2240.0": 236724454.34383875,
      "2241.0": 236823994.0267099,
      "2242.0": 236923533.70958108,
      "2243.0": 237023073.39245227,
      "2244.0": 237122613.0753234,
      "2245.0": 237222152.7581946,
      "2246.0": 237321692.44106576,
      "2247.0": 237421232.12393692,
      "2248.0": 237520771.80680808,
      "2249.0": 237620311.48967925,
      "2250.0": 237719851.1725504,
      "2251.0": 237817300.7422399,
      "2252.0": 237914750.31192937,
      "2253.0": 238012199.88161883,
      "2254.0": 238109649.4513083,
      "2255.0": 238207099.0209978,
      "2256.0": 238304548.59068727,
      "2257.0": 238401998.1603767,
      "2258.0": 238499447.73006618,
      "2259.0": 238596897.29975566,
      "2260.0": 238694346.86944512,
      "2261.0": 238791796.4391346,
      "2262.0": 238889246.00882408,
      "2263.0": 238986695.57851353,
      "2264.0": 239084145.14820302,
      "2265.0": 239181594.7178925,
      "2266.0": 239279044.28758198,
      "2267.0": 239376493.85727143,
      "2268.0": 239473943.42696092,
      "2269.0": 239571392.9966504,
      "2270.0": 239668842.56633985,
      "2271.0": 239766292.13602933,
      "2272.0": 239863741.7057188,
      "2273.0": 239961191.27540827,
      "2274.0": 240058640.84509772,
      "2275.0": 240156090.4147872,
      "2276.0": 240253539.9844767,
      "2277.0": 240350989.55416614,
      "2278.0": 240448439.12385562,
      "2279.0": 240545888.6935451,
      "2280.0": 240643338.26323456,
      "2281.0": 240740787.83292404,
      "2282.0": 240838237.40261352,
      "2283.0": 240935686.972303,
      "2284.0": 241033136.54199246,
      "2285.0": 241130586.1116819,
      "2286.0": 241228035.6813714,
      "2287.0": 241325485.25106084,
      "2288.0": 241422934.82075033,
      "2289.0": 241520384.3904398,
      "2290.0": 241617833.96012926,
      "2291.0": 241715283.52981874,
      "2292.0": 241812733.09950823,
      "2293.0": 241910182.6691977,
      "2294.0": 242007632.23888716,
      "2295.0": 242105081.80857664,
      "2296.0": 242202531.37826613,
      "2297.0": 242299980.94795558,
      "2298.0": 242397430.51764506,
      "2299.0": 242494880.0873345,
      "2300.0": 242592329.657024,
      "2301.0": 242689779.22671345,
      "2302.0": 242787228.79640293,
      "2303.0": 242884678.3660924,
      "2304.0": 242982127.93578187,
      "2305.0": 243079577.50547135,
      "2306.0": 243177027.07516083,
      "2307.0": 243274476.64485028,
      "2308.0": 243371926.21453977,
      "2309.0": 243469375.78422925,
      "2310.0": 243566825.3539187,
      "2311.0": 243664274.92360818,
      "2312.0": 243761724.49329767,
      "2313.0": 243859174.06298712,
      "2314.0": 243956623.63267657,
      "2315.0": 244054073.20236605,
      "2316.0": 244151522.77205554,
      "2317.0": 244248972.341745,
      "2318.0": 244346421.91143447,
      "2319.0": 244443871.48112395,
      "2320.0": 244541321.05081344,
      "2321.0": 244638770.6205029,
      "2322.0": 244736220.19019237,
      "2323.0": 244833669.75988185,
      "2324.0": 244931119.3295713,
      "2325.0": 245028568.8992608,
      "2326.0": 245126018.46895027,
      "2327.0": 245223468.0386397,
      "2328.0": 245320917.60832918,
      "2329.0": 245418367.17801866,
      "2330.0": 245515816.74770814,
      "2331.0": 245613266.3173976,
      "2332.0": 245710715.88708708,
      "2333.0": 245808165.45677656,
      "2334.0": 245905615.026466,
      "2335.0": 246003064.5961555,
      "2336.0": 246100514.16584498,
      "2337.0": 246197963.73553443,
      "2338.0": 246295413.3052239,
      "2339.0": 246392862.8749134,
      "2340.0": 246490312.44460288,
      "2341.0": 246587762.0142923,
      "2342.0": 246685211.58398178,
      "2343.0": 246782661.15367126,
      "2344.0": 246880110.72336072,
      "2345.0": 246977560.2930502,
      "2346.0": 247075009.86273968,
      "2347.0": 247172459.43242916,
      "2348.0": 247269909.00211862,
      "2349.0": 247367358.5718081,
      "2350.0": 247464808.14149758,
      "2351.0": 247562257.71118703,
      "2352.0": 247659707.28087652,
      "2353.0": 247757156.850566,
      "2354.0": 247854606.42025545,
      "2355.0": 247952055.9899449,
      "2356.0": 248049505.5596344,
      "2357.0": 248146955.12932387,
      "2358.0": 248244404.69901332,
      "2359.0": 248341854.2687028,
      "2360.0": 248439303.8383923,
      "2361.0": 248536753.40808174,
      "2362.0": 248634202.97777122,
      "2363.0": 248731652.5474607,
      "2364.0": 248829102.11715016,
      "2365.0": 248926551.68683964,
      "2366.0": 249024001.25652912,
      "2367.0": 249121450.8262186,
      "2368.0": 249218900.39590806,
      "2369.0": 249316349.9655975,
      "2370.0": 249413799.535287,
      "2371.0": 249511249.10497645,
      "2372.0": 249608698.67466593,
      "2373.0": 249706148.2443554,
      "2374.0": 249803597.81404486,
      "2375.0": 249901047.38373435,
      "2376.0": 249998496.95342383,
      "2377.0": 250095946.5231133,
      "2378.0": 250193396.09280276,
      "2379.0": 250290845.66249225,
      "2380.0": 250388295.23218173,
      "2381.0": 250485744.80187118,
      "2382.0": 250583194.37156063,
      "2383.0": 250680643.94125012,
      "2384.0": 250778093.5109396,
      "2385.0": 250875543.08062905,
      "2386.0": 250972992.65031853,
      "2387.0": 251070442.22000802,
      "2388.0": 251167891.78969747,
      "2389.0": 251265341.35938695,
      "2390.0": 251362790.92907643,
      "2391.0": 251460240.4987659,
      "2392.0": 251557690.06845537,
      "2393.0": 251655139.63814485,
      "2394.0": 251752589.20783433,
      "2395.0": 251850038.7775238,
      "2396.0": 251947488.34721327,
      "2397.0": 252044937.91690272,
      "2398.0": 252142387.48659217,
      "2399.0": 252239837.05628166,
      "2400.0": 252337286.62597114,
      "2401.0": 252434736.1956606,
      "2402.0": 252532185.76535007,
      "2403.0": 252629635.33503956,
      "2404.0": 252727084.90472904,
      "2405.0": 252824534.4744185,
      "2406.0": 252921984.04410797,
      "2407.0": 253019433.61379746,
      "2408.0": 253116883.1834869,
      "2409.0": 253214332.7531764,
      "2410.0": 253311782.32286584,
      "2411.0": 253409231.89255533,
      "2412.0": 253506681.46224478,
      "2413.0": 253604131.03193426,
      "2414.0": 253701580.60162374,
      "2415.0": 253799030.1713132,
      "2416.0": 253896479.74100268,
      "2417.0": 253993929.31069216,
      "2418.0": 254091378.8803816,
      "2419.0": 254188828.4500711,
      "2420.0": 254286278.01976058,
      "2421.0": 254383727.58945006,
      "2422.0": 254481177.1591395,
      "2423.0": 254578626.728829,
      "2424.0": 254676076.29851848,
      "2425.0": 254773525.8682079,
      "2426.0": 254870975.43789738,
      "2427.0": 254968425.00758687,
      "2428.0": 255065874.57727632,
      "2429.0": 255163324.1469658,
      "2430.0": 255260773.71665528,
      "2431.0": 255358223.28634477,
      "2432.0": 255455672.85603422,
      "2433.0": 255553122.4257237,
      "2434.0": 255650571.99541318,
      "2435.0": 255748021.56510264,
      "2436.0": 255845471.13479212,
      "2437.0": 255942920.7044816,
      "2438.0": 256040370.27417102,
      "2439.0": 256137819.84386054,
      "2440.0": 256235269.41355,
      "2441.0": 256332718.9832395,
      "2442.0": 256430168.55292892,
      "2443.0": 256527618.12261844,
      "2444.0": 256625067.6923079,
      "2445.0": 256722517.26199737,
      "2446.0": 256819966.83168682,
      "2447.0": 256917416.40137628,
      "2448.0": 257014865.97106576,
      "2449.0": 257112315.5407552,
      "2450.0": 257209765.11044472,
      "2451.0": 257307214.68013418,
      "2452.0": 257404664.24982366,
      "2453.0": 257502113.8195131,
      "2454.0": 257599563.38920262,
      "2455.0": 257697012.95889205,
      "2456.0": 257794462.52858156,
      "2457.0": 257891912.098271,
      "2458.0": 257989361.6679605,
      "2459.0": 258086811.23764995,
      "2460.0": 258184260.8073394,
      "2461.0": 258281710.3770289,
      "2462.0": 258379159.94671834,
      "2463.0": 258476609.51640785,
      "2464.0": 258574059.0860973,
      "2465.0": 258671508.65578678,
      "2466.0": 258768958.22547624,
      "2467.0": 258866407.79516575,
      "2468.0": 258963857.3648552,
      "2469.0": 259061306.93454468,
      "2470.0": 259158756.50423414,
      "2471.0": 259256206.07392365,
      "2472.0": 259353655.64361307,
      "2473.0": 259451105.21330252,
      "2474.0": 259548554.78299204,
      "2475.0": 259646004.3526815,
      "2476.0": 259743453.92237097,
      "2477.0": 259840903.49206042,
      "2478.0": 259938353.06174994,
      "2479.0": 260035802.63143936,
      "2480.0": 260133252.20112887,
      "2481.0": 260230701.77081832,
      "2482.0": 260328151.3405078,
      "2483.0": 260425600.91019726,
      "2484.0": 260523050.47988677,
      "2485.0": 260620500.0495762,
      "2486.0": 260717949.6192657,
      "2487.0": 260815399.18895516,
      "2488.0": 260912848.7586446,
      "2489.0": 261010298.3283341,
      "2490.0": 261107747.89802355,
      "2491.0": 261205197.46771306,
      "2492.0": 261302647.03740248,
      "2493.0": 261400096.607092,
      "2494.0": 261497546.17678145,
      "2495.0": 261594995.74647093,
      "2496.0": 261692445.31616038,
      "2497.0": 261789894.8858499,
      "2498.0": 261887344.45553935,
      "2499.0": 261984794.02522883,
      "2500.0": 262082243.59491828,
      "2501.0": 262178967.03161642,
      "2502.0": 262275690.46831456,
      "2503.0": 262372413.9050127,
      "2504.0": 262469137.34171084,
      "2505.0": 262565860.778409,
      "2506.0": 262662584.21510714,
      "2507.0": 262759307.65180534,
      "2508.0": 262856031.08850348,
      "2509.0": 262952754.52520162,
      "2510.0": 263049477.96189976,
      "2511.0": 263146201.3985979,
      "2512.0": 263242924.83529603,
      "2513.0": 263339648.27199417,
      "2514.0": 263436371.7086923,
      "2515.0": 263533095.14539045,
      "2516.0": 263629818.5820886,
      "2517.0": 263726542.01878676,
      "2518.0": 263823265.4554849,
      "2519.0": 263919988.89218304,
      "2520.0": 264016712.32888123,
      "2521.0": 264113435.76557937,
      "2522.0": 264210159.2022775,
      "2523.0": 264306882.63897565,
      "2524.0": 264403606.0756738,
      "2525.0": 264500329.51237193,
      "2526.0": 264597052.94907007,
      "2527.0": 264693776.3857682,
      "2528.0": 264790499.82246637,
      "2529.0": 264887223.2591645,
      "2530.0": 264983946.69586265,
      "2531.0": 265080670.1325608,
      "2532.0": 265177393.56925893,
      "2533.0": 265274117.00595713,
      "2534.0": 265370840.44265527,
      "2535.0": 265467563.8793534,
      "2536.0": 265564287.31605154,
      "2537.0": 265661010.75274968,
      "2538.0": 265757734.18944782,
      "2539.0": 265854457.62614596,
      "2540.0": 265951181.06284413,
      "2541.0": 266047904.49954227,
      "2542.0": 266144627.9362404,
      "2543.0": 266241351.37293854,
      "2544.0": 266338074.80963668,
      "2545.0": 266434798.24633482,
      "2546.0": 266531521.68303302,
      "2547.0": 266628245.11973116,
      "2548.0": 266724968.5564293,
      "2549.0": 266821691.99312744,
      "2550.0": 266918415.42982557,
      "2551.0": 267015138.8665237,
      "2552.0": 267111862.30322188,
      "2553.0": 267208585.73992002,
      "2554.0": 267305309.17661816,
      "2555.0": 267402032.6133163,
      "2556.0": 267498756.05001444,
      "2557.0": 267595479.48671257,
      "2558.0": 267692202.9234107,
      "2559.0": 267788926.36010885,
      "2560.0": 267885649.79680705,
      "2561.0": 267982373.2335052,
      "2562.0": 268079096.67020333,
      "2563.0": 268175820.1069015,
      "2564.0": 268272543.54359964,
      "2565.0": 268369266.98029777,
      "2566.0": 268465990.4169959,
      "2567.0": 268562713.853694,
      "2568.0": 268659437.2903922,
      "2569.0": 268756160.72709036,
      "2570.0": 268852884.1637885,
      "2571.0": 268949607.60048664,
      "2572.0": 269046331.0371848,
      "2573.0": 269143054.473883,
      "2574.0": 269239777.9105811,
      "2575.0": 269336501.34727925,
      "2576.0": 269433224.7839774,
      "2577.0": 269529948.2206755,
      "2578.0": 269626671.65737367,
      "2579.0": 269723395.0940718,
      "2580.0": 269820118.53076994,
      "2581.0": 269916841.9674681,
      "2582.0": 270013565.4041662,
      "2583.0": 270110288.84086436,
      "2584.0": 270207012.2775625,
      "2585.0": 270303735.71426064,
      "2586.0": 270400459.15095884,
      "2587.0": 270497182.587657,
      "2588.0": 270593906.0243551,
      "2589.0": 270690629.46105325,
      "2590.0": 270787352.8977514,
      "2591.0": 270884076.3344496,
      "2592.0": 270980799.7711477,
      "2593.0": 271077523.20784587,
      "2594.0": 271174246.644544,
      "2595.0": 271270970.08124214,
      "2596.0": 271367693.5179403,
      "2597.0": 271464416.9546384,
      "2598.0": 271561140.39133656,
      "2599.0": 271657863.82803476,
      "2600.0": 271754587.2647329,
      "2601.0": 271851310.70143104,
      "2602.0": 271948034.1381292,
      "2603.0": 272044757.5748273,
      "2604.0": 272141481.01152545,
      "2605.0": 272238204.4482236,
      "2606.0": 272334927.8849217,
      "2607.0": 272431651.32161987,
      "2608.0": 272528374.758318,
      "2609.0": 272625098.19501615,
      "2610.0": 272721821.6317143,
      "2611.0": 272818545.0684124,
      "2612.0": 272915268.50511056,
      "2613.0": 273011991.94180876,
      "2614.0": 273108715.37850696,
      "2615.0": 273205438.8152051,
      "2616.0": 273302162.25190324,
      "2617.0": 273398885.6886014,
      "2618.0": 273495609.1252995,
      "2619.0": 273592332.56199765,
      "2620.0": 273689055.9986958,
      "2621.0": 273785779.4353939,
      "2622.0": 273882502.87209207,
      "2623.0": 273979226.3087902,
      "2624.0": 274075949.74548835,
      "2625.0": 274172673.1821865,
      "2626.0": 274269396.6188847,
      "2627.0": 274366120.0555828,
      "2628.0": 274462843.49228096,
      "2629.0": 274559566.9289791,
      "2630.0": 274656290.36567724,
      "2631.0": 274753013.8023754,
      "2632.0": 274849737.2390735,
      "2633.0": 274946460.67577165,
      "2634.0": 275043184.1124698,
      "2635.0": 275139907.54916793,
      "2636.0": 275236630.9858661,
      "2637.0": 275333354.42256427,
      "2638.0": 275430077.8592624,
      "2639.0": 275526801.2959606,
      "2640.0": 275623524.73265874,
      "2641.0": 275720248.1693569,
      "2642.0": 275816971.606055,
      "2643.0": 275913695.04275316,
      "2644.0": 276010418.4794513,
      "2645.0": 276107141.91614944,
      "2646.0": 276203865.3528476,
      "2647.0": 276300588.7895457,
      "2648.0": 276397312.22624385,
      "2649.0": 276494035.662942,
      "2650.0": 276590759.09964013,
      "2651.0": 276687482.53633827,
      "2652.0": 276784205.97303647,
      "2653.0": 276880929.4097346,
      "2654.0": 276977652.84643275,
      "2655.0": 277074376.2831309,
      "2656.0": 277171099.719829,
      "2657.0": 277267823.15652716,
      "2658.0": 277364546.5932253,
      "2659.0": 277461270.0299235,
      "2660.0": 277557993.46662164,
      "2661.0": 277654716.9033198,
      "2662.0": 277751440.3400179,
      "2663.0": 277848163.77671605,
      "2664.0": 277944887.2134142,
      "2665.0": 278041610.6501124,
      "2666.0": 278138334.0868105,
      "2667.0": 278235057.52350867,
      "2668.0": 278331780.9602068,
      "2669.0": 278428504.39690495,
      "2670.0": 278525227.8336031,
      "2671.0": 278621951.2703012,
      "2672.0": 278718674.70699936,
      "2673.0": 278815398.1436975,
      "2674.0": 278912121.58039564,
      "2675.0": 279008845.0170938,
      "2676.0": 279105568.4537919,
      "2677.0": 279202291.89049006,
      "2678.0": 279299015.32718825,
      "2679.0": 279395738.7638864,
      "2680.0": 279492462.20058453,
      "2681.0": 279589185.63728267,
      "2682.0": 279685909.0739808,
      "2683.0": 279782632.510679,
      "2684.0": 279879355.94737715,
      "2685.0": 279976079.3840753,
      "2686.0": 280072802.8207734,
      "2687.0": 280169526.25747156,
      "2688.0": 280266249.6941697,
      "2689.0": 280362973.13086784,
      "2690.0": 280459696.567566,
      "2691.0": 280556420.0042642,
      "2692.0": 280653143.4409623,
      "2693.0": 280749866.87766045,
      "2694.0": 280846590.3143586,
      "2695.0": 280943313.75105673,
      "2696.0": 281040037.18775487,
      "2697.0": 281136760.624453,
      "2698.0": 281233484.06115115,
      "2699.0": 281330207.4978493,
      "2700.0": 281426930.9345474,
      "2701.0": 281523654.37124556,
      "2702.0": 281620377.8079437,
      "2703.0": 281717101.24464184,
      "2704.0": 281813824.68134004,
      "2705.0": 281910548.1180382,
      "2706.0": 282007271.5547364,
      "2707.0": 282103994.9914345,
      "2708.0": 282200718.42813265,
      "2709.0": 282297441.8648308,
      "2710.0": 282394165.30152893,
      "2711.0": 282490888.73822707,
      "2712.0": 282587612.1749252,
      "2713.0": 282684335.61162335,
      "2714.0": 282781059.0483215,
      "2715.0": 282877782.4850196,
      "2716.0": 282974505.92171776,
      "2717.0": 283071229.35841596,
      "2718.0": 283167952.7951141,
      "2719.0": 283264676.23181224,
      "2720.0": 283361399.6685104,
      "2721.0": 283458123.1052085,
      "2722.0": 283554846.54190665,
      "2723.0": 283651569.9786048,
      "2724.0": 283748293.41530293,
      "2725.0": 283845016.8520011,
      "2726.0": 283941740.2886992,
      "2727.0": 284038463.72539735,
      "2728.0": 284135187.16209555,
      "2729.0": 284231910.5987937,
      "2730.0": 284328634.0354918,
      "2731.0": 284425357.47219,
      "2732.0": 284522080.90888816,
      "2733.0": 284618804.3455863,
      "2734.0": 284715527.78228444,
      "2735.0": 284812251.2189826,
      "2736.0": 284908974.6556807,
      "2737.0": 285005698.09237885,
      "2738.0": 285102421.529077,
      "2739.0": 285199144.96577513,
      "2740.0": 285295868.4024733,
      "2741.0": 285392591.8391714,
      "2742.0": 285489315.27586955,
      "2743.0": 285586038.7125677,
      "2744.0": 285682762.1492658,
      "2745.0": 285779485.585964,
      "2746.0": 285876209.02266216,
      "2747.0": 285972932.4593603,
      "2748.0": 286069655.89605844,
      "2749.0": 286166379.3327566,
      "2750.0": 286263102.7694547,
      "2751.0": 286358161.3584303,
      "2752.0": 286453219.9474059,
      "2753.0": 286548278.5363815,
      "2754.0": 286643337.12535703,
      "2755.0": 286738395.71433264,
      "2756.0": 286833454.3033082,
      "2757.0": 286928512.8922838,
      "2758.0": 287023571.48125935,
      "2759.0": 287118630.07023495,
      "2760.0": 287213688.6592105,
      "2761.0": 287308747.24818605,
      "2762.0": 287403805.83716166,
      "2763.0": 287498864.4261372,
      "2764.0": 287593923.0151128,
      "2765.0": 287688981.60408837,
      "2766.0": 287784040.19306403,
      "2767.0": 287879098.7820396,
      "2768.0": 287974157.3710152,
      "2769.0": 288069215.95999074,
      "2770.0": 288164274.54896635,
      "2771.0": 288259333.1379419,
      "2772.0": 288354391.72691745,
      "2773.0": 288449450.31589305,
      "2774.0": 288544508.9048686,
      "2775.0": 288639567.4938442,
      "2776.0": 288734626.08281976,
      "2777.0": 288829684.67179537,
      "2778.0": 288924743.2607709,
      "2779.0": 289019801.8497465,
      "2780.0": 289114860.4387221,
      "2781.0": 289209919.0276976,
      "2782.0": 289304977.61667323,
      "2783.0": 289400036.2056488,
      "2784.0": 289495094.7946244,
      "2785.0": 289590153.38359994,
      "2786.0": 289685211.97257555,
      "2787.0": 289780270.5615511,
      "2788.0": 289875329.1505267,
      "2789.0": 289970387.73950225,
      "2790.0": 290065446.32847786,
      "2791.0": 290160504.9174534,
      "2792.0": 290255563.50642896,
      "2793.0": 290350622.09540457,
      "2794.0": 290445680.6843801,
      "2795.0": 290540739.2733557,
      "2796.0": 290635797.8623313,
      "2797.0": 290730856.4513069,
      "2798.0": 290825915.0402825,
      "2799.0": 290920973.6292581,
      "2800.0": 291016032.21823364,
      "2801.0": 291111090.8072092,
      "2802.0": 291206149.3961848,
      "2803.0": 291301207.98516035,
      "2804.0": 291396266.57413596,
      "2805.0": 291491325.1631115,
      "2806.0": 291586383.7520871,
      "2807.0": 291681442.34106266,
      "2808.0": 291776500.9300383,
      "2809.0": 291871559.5190138,
      "2810.0": 291966618.10798943,
      "2811.0": 292061676.696965,
      "2812.0": 292156735.2859405,
      "2813.0": 292251793.87491614,
      "2814.0": 292346852.4638917,
      "2815.0": 292441911.0528673,
      "2816.0": 292536969.64184284,
      "2817.0": 292632028.23081845,
      "2818.0": 292727086.819794,
      "2819.0": 292822145.4087696,
      "2820.0": 292917203.99774516,
      "2821.0": 293012262.58672076,
      "2822.0": 293107321.1756963,
      "2823.0": 293202379.76467186,
      "2824.0": 293297438.3536475,
      "2825.0": 293392496.942623,
      "2826.0": 293487555.5315986,
      "2827.0": 293582614.1205742,
      "2828.0": 293677672.70954984,
      "2829.0": 293772731.2985254,
      "2830.0": 293867789.887501,
      "2831.0": 293962848.47647655,
      "2832.0": 294057907.0654521,
      "2833.0": 294152965.6544277,
      "2834.0": 294248024.24340326,
      "2835.0": 294343082.83237886,
      "2836.0": 294438141.4213544,
      "2837.0": 294533200.01033,
      "2838.0": 294628258.59930557,
      "2839.0": 294723317.1882812,
      "2840.0": 294818375.7772567,
      "2841.0": 294913434.36623234,
      "2842.0": 295008492.9552079,
      "2843.0": 295103551.54418343,
      "2844.0": 295198610.13315904,
      "2845.0": 295293668.7221346,
      "2846.0": 295388727.3111102,
      "2847.0": 295483785.90008575,
      "2848.0": 295578844.48906136,
      "2849.0": 295673903.0780369,
      "2850.0": 295768961.6670125,
      "2851.0": 295864020.25598806,
      "2852.0": 295959078.84496367,
      "2853.0": 296054137.4339392,
      "2854.0": 296149196.02291477,
      "2855.0": 296244254.6118904,
      "2856.0": 296339313.2008659,
      "2857.0": 296434371.78984153,
      "2858.0": 296529430.3788171,
      "2859.0": 296624488.9677927,
      "2860.0": 296719547.5567683,
      "2861.0": 296814606.1457439,
      "2862.0": 296909664.73471946,
      "2863.0": 297004723.323695,
      "2864.0": 297099781.9126706,
      "2865.0": 297194840.50164616,
      "2866.0": 297289899.09062177,
      "2867.0": 297384957.6795973,
      "2868.0": 297480016.2685729,
      "2869.0": 297575074.8575485,
      "2870.0": 297670133.4465241,
      "2871.0": 297765192.03549963,
      "2872.0": 297860250.62447524,
      "2873.0": 297955309.2134508,
      "2874.0": 298050367.80242634,
      "2875.0": 298145426.39140195,
      "2876.0": 298240484.9803775,
      "2877.0": 298335543.5693531,
      "2878.0": 298430602.15832865,
      "2879.0": 298525660.74730426,
      "2880.0": 298620719.3362798,
      "2881.0": 298715777.9252554,
      "2882.0": 298810836.51423097,
      "2883.0": 298905895.1032066,
      "2884.0": 299000953.6921821,
      "2885.0": 299096012.2811577,
      "2886.0": 299191070.8701333,
      "2887.0": 299286129.4591088,
      "2888.0": 299381188.04808444,
      "2889.0": 299476246.63706,
      "2890.0": 299571305.2260356,
      "2891.0": 299666363.8150112,
      "2892.0": 299761422.4039868,
      "2893.0": 299856480.99296236,
      "2894.0": 299951539.5819379,
      "2895.0": 300046598.1709135,
      "2896.0": 300141656.75988907,
      "2897.0": 300236715.3488647,
      "2898.0": 300331773.9378402,
      "2899.0": 300426832.52681583,
      "2900.0": 300521891.1157914,
      "2901.0": 300616949.704767,
      "2902.0": 300712008.29374254,
      "2903.0": 300807066.88271815,
      "2904.0": 300902125.4716937,
      "2905.0": 300997184.06066924,
      "2906.0": 301092242.64964485,
      "2907.0": 301187301.2386204,
      "2908.0": 301282359.827596,
      "2909.0": 301377418.41657156,
      "2910.0": 301472477.00554717,
      "2911.0": 301567535.5945227,
      "2912.0": 301662594.1834983,
      "2913.0": 301757652.7724739,
      "2914.0": 301852711.3614495,
      "2915.0": 301947769.950425,
      "2916.0": 302042828.5394006,
      "2917.0": 302137887.1283762,
      "2918.0": 302232945.71735173,
      "2919.0": 302328004.30632734,
      "2920.0": 302423062.8953029,
      "2921.0": 302518121.4842785,
      "2922.0": 302613180.07325405,
      "2923.0": 302708238.6622297,
      "2924.0": 302803297.25120527,
      "2925.0": 302898355.8401808,
      "2926.0": 302993414.4291564,
      "2927.0": 303088473.018132,
      "2928.0": 303183531.6071076,
      "2929.0": 303278590.1960831,
      "2930.0": 303373648.78505874,
      "2931.0": 303468707.3740343,
      "2932.0": 303563765.9630099,
      "2933.0": 303658824.55198544,
      "2934.0": 303753883.14096105,
      "2935.0": 303848941.7299366,
      "2936.0": 303944000.31891215,
      "2937.0": 304039058.90788776,
      "2938.0": 304134117.4968633,
      "2939.0": 304229176.0858389,
      "2940.0": 304324234.67481446,
      "2941.0": 304419293.2637901,
      "2942.0": 304514351.8527656,
      "2943.0": 304609410.4417412,
      "2944.0": 304704469.0307168,
      "2945.0": 304799527.6196924,
      "2946.0": 304894586.20866793,
      "2947.0": 304989644.7976435,
      "2948.0": 305084703.3866191,
      "2949.0": 305179761.97559464,
      "2950.0": 305274820.56457025,
      "2951.0": 305369879.1535458,
      "2952.0": 305464937.7425214,
      "2953.0": 305559996.331497,
      "2954.0": 305655054.9204726,
      "2955.0": 305750113.5094482,
      "2956.0": 305845172.0984237,
      "2957.0": 305940230.6873993,
      "2958.0": 306035289.2763749,
      "2959.0": 306130347.8653505,
      "2960.0": 306225406.45432603,
      "2961.0": 306320465.04330164,
      "2962.0": 306415523.6322772,
      "2963.0": 306510582.2212528,
      "2964.0": 306605640.81022835,
      "2965.0": 306700699.39920396,
      "2966.0": 306795757.9881795,
      "2967.0": 306890816.57715505,
      "2968.0": 306985875.16613066,
      "2969.0": 307080933.7551062,
      "2970.0": 307175992.3440818,
      "2971.0": 307271050.93305737,
      "2972.0": 307366109.522033,
      "2973.0": 307461168.1110085,
      "2974.0": 307556226.69998413,
      "2975.0": 307651285.2889597,
      "2976.0": 307746343.87793523,
      "2977.0": 307841402.46691084,
      "2978.0": 307936461.0558864,
      "2979.0": 308031519.644862,
      "2980.0": 308126578.23383754,
      "2981.0": 308221636.82281315,
      "2982.0": 308316695.4117887,
      "2983.0": 308411754.0007643,
      "2984.0": 308506812.58973986,
      "2985.0": 308601871.17871547,
      "2986.0": 308696929.7676911,
      "2987.0": 308791988.3566666,
      "2988.0": 308887046.94564223,
      "2989.0": 308982105.5346178,
      "2990.0": 309077164.1235934,
      "2991.0": 309172222.71256894,
      "2992.0": 309267281.30154455,
      "2993.0": 309362339.8905201,
      "2994.0": 309457398.4794957,
      "2995.0": 309552457.06847125,
      "2996.0": 309647515.65744686,
      "2997.0": 309742574.2464224,
      "2998.0": 309837632.83539796,
      "2999.0": 309932691.42437357,
      "3000.0": 310027750.0133491,
      "3001.0": 310121429.7030158,
      "3002.0": 310215109.39268243,
      "3003.0": 310308789.0823492,
      "3004.0": 310402468.7720158,
      "3005.0": 310496148.4616825,
      "3006.0": 310589828.1513491,
      "3007.0": 310683507.8410159,
      "3008.0": 310777187.5306825,
      "3009.0": 310870867.2203492,
      "3010.0": 310964546.9100158,
      "3011.0": 311058226.59968257,
      "3012.0": 311151906.2893492,
      "3013.0": 311245585.9790159,
      "3014.0": 311339265.6686825,
      "3015.0": 311432945.35834914,
      "3016.0": 311526625.0480159,
      "3017.0": 311620304.7376825,
      "3018.0": 311713984.4273492,
      "3019.0": 311807664.11701584,
      "3020.0": 311901343.8066826,
      "3021.0": 311995023.4963492,
      "3022.0": 312088703.1860159,
      "3023.0": 312182382.87568253,
      "3024.0": 312276062.5653492,
      "3025.0": 312369742.2550159,
      "3026.0": 312463421.9446826,
      "3027.0": 312557101.6343492,
      "3028.0": 312650781.3240159,
      "3029.0": 312744461.0136826,
      "3030.0": 312838140.7033493,
      "3031.0": 312931820.3930159,
      "3032.0": 313025500.0826826,
      "3033.0": 313119179.7723493,
      "3034.0": 313212859.4620159,
      "3035.0": 313306539.1516826,
      "3036.0": 313400218.84134924,
      "3037.0": 313493898.53101593,
      "3038.0": 313587578.2206826,
      "3039.0": 313681257.9103493,
      "3040.0": 313774937.60001594,
      "3041.0": 313868617.2896826,
      "3042.0": 313962296.9793493,
      "3043.0": 314055976.669016,
      "3044.0": 314149656.35868263,
      "3045.0": 314243336.0483493,
      "3046.0": 314337015.73801595,
      "3047.0": 314430695.4276827,
      "3048.0": 314524375.1173493,
      "3049.0": 314618054.807016,
      "3050.0": 314711734.49668264,
      "3051.0": 314805414.1863494,
      "3052.0": 314899093.876016,
      "3053.0": 314992773.5656827,
      "3054.0": 315086453.25534934,
      "3055.0": 315180132.945016,
      "3056.0": 315273812.6346827,
      "3057.0": 315367492.32434934,
      "3058.0": 315461172.01401603,
      "3059.0": 315554851.70368266,
      "3060.0": 315648531.3933494,
      "3061.0": 315742211.08301604,
      "3062.0": 315835890.7726827,
      "3063.0": 315929570.46234936,
      "3064.0": 316023250.1520161,
      "3065.0": 316116929.84168273,
      "3066.0": 316210609.5313494,
      "3067.0": 316304289.22101605,
      "3068.0": 316397968.91068274,
      "3069.0": 316491648.6003494,
      "3070.0": 316585328.2900161,
      "3071.0": 316679007.97968274,
      "3072.0": 316772687.66934943,
      "3073.0": 316866367.3590161,
      "3074.0": 316960047.0486828,
      "3075.0": 317053726.73834944,
      "3076.0": 317147406.4280161,
      "3077.0": 317241086.1176828,
      "3078.0": 317334765.80734944,
      "3079.0": 317428445.49701613,
      "3080.0": 317522125.18668276,
      "3081.0": 317615804.87634945,
      "3082.0": 317709484.56601614,
      "3083.0": 317803164.2556828,
      "3084.0": 317896843.94534945,
      "3085.0": 317990523.63501614,
      "3086.0": 318084203.32468283,
      "3087.0": 318177883.0143495,
      "3088.0": 318271562.70401615,
      "3089.0": 318365242.39368284,
      "3090.0": 318458922.0833495,
      "3091.0": 318552601.7730162,
      "3092.0": 318646281.46268284,
      "3093.0": 318739961.15234953,
      "3094.0": 318833640.84201616,
      "3095.0": 318927320.5316829,
      "3096.0": 319021000.22134954,
      "3097.0": 319114679.9110162,
      "3098.0": 319208359.60068285,
      "3099.0": 319302039.29034954,
      "3100.0": 319395718.98001623,
      "3101.0": 319489398.66968286,
      "3102.0": 319583078.35934955,
      "3103.0": 319676758.04901624,
      "3104.0": 319770437.7386829,
      "3105.0": 319864117.42834955,
      "3106.0": 319957797.11801624,
      "3107.0": 320051476.8076829,
      "3108.0": 320145156.4973496,
      "3109.0": 320238836.18701625,
      "3110.0": 320332515.87668294,
      "3111.0": 320426195.56634957,
      "3112.0": 320519875.2560163,
      "3113.0": 320613554.94568294,
      "3114.0": 320707234.63534963,
      "3115.0": 320800914.32501626,
      "3116.0": 320894594.01468295,
      "3117.0": 320988273.70434964,
      "3118.0": 321081953.39401627,
      "3119.0": 321175633.08368295,
      "3120.0": 321269312.7733496,
      "3121.0": 321362992.46301633,
      "3122.0": 321456672.15268296,
      "3123.0": 321550351.84234965,
      "3124.0": 321644031.5320163,
      "3125.0": 321737711.22168297,
      "3126.0": 321831390.91134965,
      "3127.0": 321925070.60101634,
      "3128.0": 322018750.290683,
      "3129.0": 322112429.98034966,
      "3130.0": 322206109.67001635,
      "3131.0": 322299789.35968304,
      "3132.0": 322393469.04934967,
      "3133.0": 322487148.73901635,
      "3134.0": 322580828.42868304,
      "3135.0": 322674508.11834973,
      "3136.0": 322768187.80801636,
      "3137.0": 322861867.49768305,
      "3138.0": 322955547.1873497,
      "3139.0": 323049226.87701637,
      "3140.0": 323142906.56668305,
      "3141.0": 323236586.2563497,
      "3142.0": 323330265.9460164,
      "3143.0": 323423945.63568306,
      "3144.0": 323517625.32534975,
      "3145.0": 323611305.0150164,
      "3146.0": 323704984.70468307,
      "3147.0": 323798664.39434975,
      "3148.0": 323892344.08401644,
      "3149.0": 323986023.7736831,
      "3150.0": 324079703.46334976,
      "3151.0": 324173383.1530164,
      "3152.0": 324267062.84268314,
      "3153.0": 324360742.53234977,
      "3154.0": 324454422.22201645,
      "3155.0": 324548101.9116831,
      "3156.0": 324641781.60134983,
      "3157.0": 324735461.29101646,
      "3158.0": 324829140.98068315,
      "3159.0": 324922820.6703498,
      "3160.0": 325016500.36001647,
      "3161.0": 325110180.04968315,
      "3162.0": 325203859.7393498,
      "3163.0": 325297539.4290165,
      "3164.0": 325391219.1186831,
      "3165.0": 325484898.80834985,
      "3166.0": 325578578.4980165,
      "3167.0": 325672258.18768317,
      "3168.0": 325765937.8773498,
      "3169.0": 325859617.56701654,
      "3170.0": 325953297.2566832,
      "3171.0": 326046976.94634986,
      "3172.0": 326140656.6360165,
      "3173.0": 326234336.3256832,
      "3174.0": 326328016.01534986,
      "3175.0": 326421695.70501655,
      "3176.0": 326515375.3946832,
      "3177.0": 326609055.0843499,
      "3178.0": 326702734.77401656,
      "3179.0": 326796414.46368325,
      "3180.0": 326890094.1533499,
      "3181.0": 326983773.84301656,
      "3182.0": 327077453.5326832,
      "3183.0": 327171133.2223499,
      "3184.0": 327264812.9120166,
      "3185.0": 327358492.6016832,
      "3186.0": 327452172.2913499,
      "3187.0": 327545851.9810166,
      "3188.0": 327639531.67068326,
      "3189.0": 327733211.3603499,
      "3190.0": 327826891.0500166,
      "3191.0": 327920570.7396833,
      "3192.0": 328014250.42934996,
      "3193.0": 328107930.1190166,
      "3194.0": 328201609.8086833,
      "3195.0": 328295289.4983499,
      "3196.0": 328388969.18801665,
      "3197.0": 328482648.8776833,
      "3198.0": 328576328.56735,
      "3199.0": 328670008.2570166,
      "3200.0": 328763687.94668335,
      "3201.0": 328857367.63635,
      "3202.0": 328951047.32601666,
      "3203.0": 329044727.0156833,
      "3204.0": 329138406.70535,
      "3205.0": 329232086.3950167,
      "3206.0": 329325766.0846833,
      "3207.0": 329419445.77435,
      "3208.0": 329513125.4640166,
      "3209.0": 329606805.15368336,
      "3210.0": 329700484.84335,
      "3211.0": 329794164.5330167,
      "3212.0": 329887844.2226833,
      "3213.0": 329981523.91235006,
      "3214.0": 330075203.6020167,
      "3215.0": 330168883.2916834,
      "3216.0": 330262562.98135,
      "3217.0": 330356242.67101675,
      "3218.0": 330449922.3606834,
      "3219.0": 330543602.05035007,
      "3220.0": 330637281.7400167,
      "3221.0": 330730961.4296834,
      "3222.0": 330824641.1193501,
      "3223.0": 330918320.8090167,
      "3224.0": 331012000.4986834,
      "3225.0": 331105680.18835,
      "3226.0": 331199359.87801677,
      "3227.0": 331293039.5676834,
      "3228.0": 331386719.2573501,
      "3229.0": 331480398.9470167,
      "3230.0": 331574078.6366834,
      "3231.0": 331667758.3263501,
      "3232.0": 331761438.0160168,
      "3233.0": 331855117.7056834,
      "3234.0": 331948797.3953501,
      "3235.0": 332042477.0850168,
      "3236.0": 332136156.7746835,
      "3237.0": 332229836.4643501,
      "3238.0": 332323516.1540168,
      "3239.0": 332417195.8436834,
      "3240.0": 332510875.53335017,
      "3241.0": 332604555.2230168,
      "3242.0": 332698234.9126835,
      "3243.0": 332791914.6023501,
      "3244.0": 332885594.2920168,
      "3245.0": 332979273.9816835,
      "3246.0": 333072953.6713501,
      "3247.0": 333166633.3610168,
      "3248.0": 333260313.0506835,
      "3249.0": 333353992.7403502,
      "3250.0": 333447672.4300168,
      "3251.0": 333540138.6953602,
      "3252.0": 333632604.9607035,
      "3253.0": 333725071.2260468,
      "3254.0": 333817537.4913901,
      "3255.0": 333910003.7567335,
      "3256.0": 334002470.0220768,
      "3257.0": 334094936.28742015,
      "3258.0": 334187402.55276346,
      "3259.0": 334279868.8181068,
      "3260.0": 334372335.0834501,
      "3261.0": 334464801.3487934,
      "3262.0": 334557267.6141367,
      "3263.0": 334649733.8794801,
      "3264.0": 334742200.14482343,
      "3265.0": 334834666.41016674,
      "3266.0": 334927132.67551005,
      "3267.0": 335019598.94085336,
      "3268.0": 335112065.20619667,
      "3269.0": 335204531.47154003,
      "3270.0": 335296997.73688334,
      "3271.0": 335389464.00222665,
      "3272.0": 335481930.26757,
      "3273.0": 335574396.5329133,
      "3274.0": 335666862.79825664,
      "3275.0": 335759329.0636,
      "3276.0": 335851795.3289433,
      "3277.0": 335944261.5942866,
      "3278.0": 336036727.8596299,
      "3279.0": 336129194.12497324,
      "3280.0": 336221660.39031655,
      "3281.0": 336314126.65566,
      "3282.0": 336406592.9210033,
      "3283.0": 336499059.1863466,
      "3284.0": 336591525.4516899,
      "3285.0": 336683991.7170332,
      "3286.0": 336776457.9823765,
      "3287.0": 336868924.2477199,
      "3288.0": 336961390.5130632,
      "3289.0": 337053856.77840656,
      "3290.0": 337146323.04374987,
      "3291.0": 337238789.3090932,
      "3292.0": 337331255.5744365,
      "3293.0": 337423721.83977985,
      "3294.0": 337516188.10512316,
      "3295.0": 337608654.3704665,
      "3296.0": 337701120.6358098,
      "3297.0": 337793586.9011531,
      "3298.0": 337886053.16649646,
      "3299.0": 337978519.4318398,
      "3300.0": 338070985.69718313,
      "3301.0": 338163451.96252644,
      "3302.0": 338255918.22786975,
      "3303.0": 338348384.49321306,
      "3304.0": 338440850.75855637,
      "3305.0": 338533317.02389973,
      "3306.0": 338625783.28924304,
      "3307.0": 338718249.5545864,
      "3308.0": 338810715.8199297,
      "3309.0": 338903182.085273,
      "3310.0": 338995648.35061634,
      "3311.0": 339088114.6159597,
      "3312.0": 339180580.881303,
      "3313.0": 339273047.1466463,
      "3314.0": 339365513.4119896,
      "3315.0": 339457979.677333,
      "3316.0": 339550445.9426763,
      "3317.0": 339642912.2080197,
      "3318.0": 339735378.473363,
      "3319.0": 339827844.7387063,
      "3320.0": 339920311.0040496,
      "3321.0": 340012777.2693929,
      "3322.0": 340105243.5347363,
      "3323.0": 340197709.8000796,
      "3324.0": 340290176.06542295,
      "3325.0": 340382642.33076626,
      "3326.0": 340475108.59610957,
      "3327.0": 340567574.8614529,
      "3328.0": 340660041.12679625,
      "3329.0": 340752507.39213955,
      "3330.0": 340844973.65748286,
      "3331.0": 340937439.9228262,
      "3332.0": 341029906.18816954,
      "3333.0": 341122372.45351285,
      "3334.0": 341214838.71885616,
      "3335.0": 341307304.9841995,
      "3336.0": 341399771.24954283,
      "3337.0": 341492237.51488614,
      "3338.0": 341584703.78022945,
      "3339.0": 341677170.04557276,
      "3340.0": 341769636.3109161,
      "3341.0": 341862102.5762595,
      "3342.0": 341954568.8416028,
      "3343.0": 342047035.1069461,
      "3344.0": 342139501.3722894,
      "3345.0": 342231967.6376327,
      "3346.0": 342324433.9029761,
      "3347.0": 342416900.1683194,
      "3348.0": 342509366.4336627,
      "3349.0": 342601832.699006,
      "3350.0": 342694298.9643494,
      "3351.0": 342786765.2296927,
      "3352.0": 342879231.49503607,
      "3353.0": 342971697.7603794,
      "3354.0": 343064164.0257227,
      "3355.0": 343156630.291066,
      "3356.0": 343249096.5564093,
      "3357.0": 343341562.8217526,
      "3358.0": 343434029.08709604,
      "3359.0": 343526495.35243934,
      "3360.0": 343618961.61778265,
      "3361.0": 343711427.88312596,
      "3362.0": 343803894.14846927,
      "3363.0": 343896360.4138126,
      "3364.0": 343988826.67915595,
      "3365.0": 344081292.94449925,
      "3366.0": 344173759.20984256,
      "3367.0": 344266225.47518593,
      "3368.0": 344358691.74052924,
      "3369.0": 344451158.00587255,
      "3370.0": 344543624.2712159,
      "3371.0": 344636090.5365592,
      "3372.0": 344728556.80190253,
      "3373.0": 344821023.06724584,
      "3374.0": 344913489.33258915,
      "3375.0": 345005955.59793246,
      "3376.0": 345098421.8632759,
      "3377.0": 345190888.1286192,
      "3378.0": 345283354.3939625,
      "3379.0": 345375820.6593058,
      "3380.0": 345468286.9246491,
      "3381.0": 345560753.1899924,
      "3382.0": 345653219.4553358,
      "3383.0": 345745685.7206791,
      "3384.0": 345838151.9860225,
      "3385.0": 345930618.2513658,
      "3386.0": 346023084.5167091,
      "3387.0": 346115550.7820524,
      "3388.0": 346208017.04739577,
      "3389.0": 346300483.3127391,
      "3390.0": 346392949.5780824,
      "3391.0": 346485415.8434257,
      "3392.0": 346577882.108769,
      "3393.0": 346670348.37411237,
      "3394.0": 346762814.63945574,
      "3395.0": 346855280.90479904,
      "3396.0": 346947747.17014235,
      "3397.0": 347040213.43548566,
      "3398.0": 347132679.70082897,
      "3399.0": 347225145.9661723,
      "3400.0": 347317612.23151565,
      "3401.0": 347410078.496859,
      "3402.0": 347502544.7622023,
      "3403.0": 347595011.02754563,
      "3404.0": 347687477.29288894,
      "3405.0": 347779943.55823225,
      "3406.0": 347872409.8235756,
      "3407.0": 347964876.0889189,
      "3408.0": 348057342.35426223,
      "3409.0": 348149808.61960554,
      "3410.0": 348242274.8849489,
      "3411.0": 348334741.1502922,
      "3412.0": 348427207.4156356,
      "3413.0": 348519673.6809789,
      "3414.0": 348612139.9463222,
      "3415.0": 348704606.2116655,
      "3416.0": 348797072.4770088,
      "3417.0": 348889538.7423522,
      "3418.0": 348982005.0076955,
      "3419.0": 349074471.27303886,
      "3420.0": 349166937.5383822,
      "3421.0": 349259403.8037255,
      "3422.0": 349351870.0690688,
      "3423.0": 349444336.33441216,
      "3424.0": 349536802.59975547,
      "3425.0": 349629268.8650988,
      "3426.0": 349721735.1304421,
      "3427.0": 349814201.3957854,
      "3428.0": 349906667.66112876,
      "3429.0": 349999133.9264721,
      "3430.0": 350091600.19181544,
      "3431.0": 350184066.45715874,
      "3432.0": 350276532.72250205,
      "3433.0": 350368998.98784536,
      "3434.0": 350461465.25318867,
      "3435.0": 350553931.51853204,
      "3436.0": 350646397.7838754,
      "3437.0": 350738864.0492187,
      "3438.0": 350831330.314562,
      "3439.0": 350923796.57990533,
      "3440.0": 351016262.84524864,
      "3441.0": 351108729.110592,
      "3442.0": 351201195.3759353,
      "3443.0": 351293661.6412786,
      "3444.0": 351386127.90662193,
      "3445.0": 351478594.1719653,
      "3446.0": 351571060.4373086,
      "3447.0": 351663526.702652,
      "3448.0": 351755992.9679953,
      "3449.0": 351848459.2333386,
      "3450.0": 351940925.4986819,
      "3451.0": 352033391.7640252,
      "3452.0": 352125858.0293685,
      "3453.0": 352218324.29471195,
      "3454.0": 352310790.56005526,
      "3455.0": 352403256.82539856,
      "3456.0": 352495723.0907419,
      "3457.0": 352588189.3560852,
      "3458.0": 352680655.6214285,
      "3459.0": 352773121.88677186,
      "3460.0": 352865588.15211517,
      "3461.0": 352958054.4174585,
      "3462.0": 353050520.68280184,
      "3463.0": 353142986.94814515,
      "3464.0": 353235453.21348846,
      "3465.0": 353327919.4788318,
      "3466.0": 353420385.74417514,
      "3467.0": 353512852.00951844,
      "3468.0": 353605318.27486175,
      "3469.0": 353697784.54020506,
      "3470.0": 353790250.8055484,
      "3471.0": 353882717.0708918,
      "3472.0": 353975183.3362351,
      "3473.0": 354067649.6015784,
      "3474.0": 354160115.8669217,
      "3475.0": 354252582.13226503,
      "3476.0": 354345048.39760834,
      "3477.0": 354437514.6629517,
      "3478.0": 354529980.928295,
      "3479.0": 354622447.1936384,
      "3480.0": 354714913.4589817,
      "3481.0": 354807379.724325,
      "3482.0": 354899845.9896683,
      "3483.0": 354992312.2550117,
      "3484.0": 355084778.520355,
      "3485.0": 355177244.7856983,
      "3486.0": 355269711.0510416,
      "3487.0": 355362177.316385,
      "3488.0": 355454643.5817283,
      "3489.0": 355547109.84707165,
      "3490.0": 355639576.11241496,
      "3491.0": 355732042.37775826,
      "3492.0": 355824508.6431016,
      "3493.0": 355916974.9084449,
      "3494.0": 356009441.1737882,
      "3495.0": 356101907.43913156,
      "3496.0": 356194373.70447487,
      "3497.0": 356286839.96981823,
      "3498.0": 356379306.23516154,
      "3499.0": 356471772.50050485,
      "3500.0": 356564238.76584816,
      "3501.0": 356655396.9029288,
      "3502.0": 356746555.04000944,
      "3503.0": 356837713.17709005,
      "3504.0": 356928871.31417066,
      "3505.0": 357020029.45125127,
      "3506.0": 357111187.5883319,
      "3507.0": 357202345.7254125,
      "3508.0": 357293503.8624931,
      "3509.0": 357384661.9995737,
      "3510.0": 357475820.1366543,
      "3511.0": 357566978.2737349,
      "3512.0": 357658136.41081554,
      "3513.0": 357749294.54789615,
      "3514.0": 357840452.68497676,
      "3515.0": 357931610.82205737,
      "3516.0": 358022768.959138,
      "3517.0": 358113927.09621865,
      "3518.0": 358205085.2332992,
      "3519.0": 358296243.37037987,
      "3520.0": 358387401.5074604,
      "3521.0": 358478559.6445411,
      "3522.0": 358569717.7816217,
      "3523.0": 358660875.9187023,
      "3524.0": 358752034.0557829,
      "3525.0": 358843192.1928635,
      "3526.0": 358934350.32994413,
      "3527.0": 359025508.46702474,
      "3528.0": 359116666.60410535,
      "3529.0": 359207824.74118596,
      "3530.0": 359298982.8782666,
      "3531.0": 359390141.0153472,
      "3532.0": 359481299.15242785,
      "3533.0": 359572457.2895084,
      "3534.0": 359663615.4265891,
      "3535.0": 359754773.5636696,
      "3536.0": 359845931.7007503,
      "3537.0": 359937089.83783084,
      "3538.0": 360028247.9749115,
      "3539.0": 360119406.11199206,
      "3540.0": 360210564.24907273,
      "3541.0": 360301722.3861533,
      "3542.0": 360392880.52323395,
      "3543.0": 360484038.66031456,
      "3544.0": 360575196.79739517,
      "3545.0": 360666354.9344758,
      "3546.0": 360757513.0715564,
      "3547.0": 360848671.20863706,
      "3548.0": 360939829.3457176,
      "3549.0": 361030987.4827983,
      "3550.0": 361122145.6198788,
      "3551.0": 361213303.7569595,
      "3552.0": 361304461.89404005,
      "3553.0": 361395620.0311207,
      "3554.0": 361486778.16820127,
      "3555.0": 361577936.30528194,
      "3556.0": 361669094.4423625,
      "3557.0": 361760252.57944316,
      "3558.0": 361851410.7165237,
      "3559.0": 361942568.8536044,
      "3560.0": 362033726.9906849,
      "3561.0": 362124885.1277656,
      "3562.0": 362216043.2648462,
      "3563.0": 362307201.4019268,
      "3564.0": 362398359.5390075,
      "3565.0": 362489517.67608804,
      "3566.0": 362580675.8131687,
      "3567.0": 362671833.95024925,
      "3568.0": 362762992.0873299,
      "3569.0": 362854150.2244105,
      "3570.0": 362945308.36149114,
      "3571.0": 363036466.4985717,
      "3572.0": 363127624.63565236,
      "3573.0": 363218782.7727329,
      "3574.0": 363309940.9098136,
      "3575.0": 363401099.04689413,
      "3576.0": 363492257.1839748,
      "3577.0": 363583415.3210554,
      "3578.0": 363674573.458136,
      "3579.0": 363765731.59521663,
      "3580.0": 363856889.73229724,
      "3581.0": 363948047.86937785,
      "3582.0": 364039206.00645846,
      "3583.0": 364130364.1435391,
      "3584.0": 364221522.2806197,
      "3585.0": 364312680.41770035,
      "3586.0": 364403838.5547809,
      "3587.0": 364494996.69186157,
      "3588.0": 364586154.8289421,
      "3589.0": 364677312.9660228,
      "3590.0": 364768471.10310334,
      "3591.0": 364859629.240184,
      "3592.0": 364950787.3772646,
      "3593.0": 365041945.5143452,
      "3594.0": 365133103.65142584,
      "3595.0": 365224261.78850645,
      "3596.0": 365315419.92558706,
      "3597.0": 365406578.06266767,
      "3598.0": 365497736.1997483,
      "3599.0": 365588894.3368289,
      "3600.0": 365680052.4739095,
      "3601.0": 365771210.6109901,
      "3602.0": 365862368.7480707,
      "3603.0": 365953526.8851513,
      "3604.0": 366044685.022232,
      "3605.0": 366135843.15931255,
      "3606.0": 366227001.2963932,
      "3607.0": 366318159.43347377,
      "3608.0": 366409317.57055444,
      "3609.0": 366500475.70763505,
      "3610.0": 366591633.84471565,
      "3611.0": 366682791.98179626,
      "3612.0": 366773950.1188769,
      "3613.0": 366865108.2559575,
      "3614.0": 366956266.3930381,
      "3615.0": 367047424.5301187,
      "3616.0": 367138582.6671993,
      "3617.0": 367229740.8042799,
      "3618.0": 367320898.94136053,
      "3619.0": 367412057.07844114,
      "3620.0": 367503215.21552175,
      "3621.0": 367594373.35260236,
      "3622.0": 367685531.489683,
      "3623.0": 367776689.6267636,
      "3624.0": 367867847.76384425,
      "3625.0": 367959005.9009248,
      "3626.0": 368050164.0380055,
      "3627.0": 368141322.1750861,
      "3628.0": 368232480.3121667,
      "3629.0": 368323638.4492473,
      "3630.0": 368414796.5863279,
      "3631.0": 368505954.7234085,
      "3632.0": 368597112.86048913,
      "3633.0": 368688270.99756974,
      "3634.0": 368779429.13465035,
      "3635.0": 368870587.27173096,
      "3636.0": 368961745.40881157,
      "3637.0": 369052903.5458922,
      "3638.0": 369144061.6829728,
      "3639.0": 369235219.82005346,
      "3640.0": 369326377.957134,
      "3641.0": 369417536.0942147,
      "3642.0": 369508694.2312952,
      "3643.0": 369599852.3683759,
      "3644.0": 369691010.50545645,
      "3645.0": 369782168.6425371,
      "3646.0": 369873326.7796177,
      "3647.0": 369964484.91669834,
      "3648.0": 370055643.05377895,
      "3649.0": 370146801.19085956,
      "3650.0": 370237959.32794017,
      "3651.0": 370329117.4650208,
      "3652.0": 370420275.6021014,
      "3653.0": 370511433.739182,
      "3654.0": 370602591.87626266,
      "3655.0": 370693750.0133432,
      "3656.0": 370784908.1504239,
      "3657.0": 370876066.28750443,
      "3658.0": 370967224.4245851,
      "3659.0": 371058382.56166565,
      "3660.0": 371149540.6987463,
      "3661.0": 371240698.8358269,
      "3662.0": 371331856.97290754,
      "3663.0": 371423015.1099881,
      "3664.0": 371514173.24706876,
      "3665.0": 371605331.3841493,
      "3666.0": 371696489.52123,
      "3667.0": 371787647.65831053,
      "3668.0": 371878805.7953912,
      "3669.0": 371969963.9324719,
      "3670.0": 372061122.0695524,
      "3671.0": 372152280.2066331,
      "3672.0": 372243438.34371364,
      "3673.0": 372334596.4807943,
      "3674.0": 372425754.61787486,
      "3675.0": 372516912.75495553,
      "3676.0": 372608070.8920361,
      "3677.0": 372699229.02911675,
      "3678.0": 372790387.1661973,
      "3679.0": 372881545.30327797,
      "3680.0": 372972703.4403585,
      "3681.0": 373063861.5774392,
      "3682.0": 373155019.71451974,
      "3683.0": 373246177.8516004,
      "3684.0": 373337335.988681,
      "3685.0": 373428494.1257616,
      "3686.0": 373519652.26284224,
      "3687.0": 373610810.39992285,
      "3688.0": 373701968.5370035,
      "3689.0": 373793126.67408407,
      "3690.0": 373884284.81116474,
      "3691.0": 373975442.9482453,
      "3692.0": 374066601.08532596,
      "3693.0": 374157759.2224065,
      "3694.0": 374248917.3594872,
      "3695.0": 374340075.4965677,
      "3696.0": 374431233.6336484,
      "3697.0": 374522391.77072895,
      "3698.0": 374613549.9078096,
      "3699.0": 374704708.0448902,
      "3700.0": 374795866.18197083,
      "3701.0": 374887024.31905144,
      "3702.0": 374978182.45613205,
      "3703.0": 375069340.59321266,
      "3704.0": 375160498.7302933,
      "3705.0": 375251656.8673739,
      "3706.0": 375342815.0044545,
      "3707.0": 375433973.1415351,
      "3708.0": 375525131.2786157,
      "3709.0": 375616289.4156963,
      "3710.0": 375707447.55277693,
      "3711.0": 375798605.6898576,
      "3712.0": 375889763.82693815,
      "3713.0": 375980921.9640188,
      "3714.0": 376072080.10109943,
      "3715.0": 376163238.23818004,
      "3716.0": 376254396.37526065,
      "3717.0": 376345554.51234126,
      "3718.0": 376436712.6494219,
      "3719.0": 376527870.7865025,
      "3720.0": 376619028.9235831,
      "3721.0": 376710187.0606637,
      "3722.0": 376801345.1977443,
      "3723.0": 376892503.3348249,
      "3724.0": 376983661.4719055,
      "3725.0": 377074819.60898614,
      "3726.0": 377165977.74606675,
      "3727.0": 377257135.88314736,
      "3728.0": 377348294.02022797,
      "3729.0": 377439452.1573086,
      "3730.0": 377530610.29438925,
      "3731.0": 377621768.43146986,
      "3732.0": 377712926.56855047,
      "3733.0": 377804084.7056311,
      "3734.0": 377895242.8427117,
      "3735.0": 377986400.9797923,
      "3736.0": 378077559.1168729,
      "3737.0": 378168717.2539535,
      "3738.0": 378259875.3910341,
      "3739.0": 378351033.52811474,
      "3740.0": 378442191.66519535,
      "3741.0": 378533349.80227596,
      "3742.0": 378624507.93935657,
      "3743.0": 378715666.0764372,
      "3744.0": 378806824.2135178,
      "3745.0": 378897982.3505984,
      "3746.0": 378989140.48767906,
      "3747.0": 379080298.6247596,
      "3748.0": 379171456.7618403,
      "3749.0": 379262614.89892083,
      "3750.0": 379353773.0360015,
      "3751.0": 379443683.62126255,
      "3752.0": 379533594.2065236,
      "3753.0": 379623504.7917847,
      "3754.0": 379713415.37704575,
      "3755.0": 379803325.9623068,
      "3756.0": 379893236.5475679,
      "3757.0": 379983147.13282895,
      "3758.0": 380073057.71809,
      "3759.0": 380162968.30335116,
      "3760.0": 380252878.8886122,
      "3761.0": 380342789.47387326,
      "3762.0": 380432700.0591343,
      "3763.0": 380522610.6443954,
      "3764.0": 380612521.22965646,
      "3765.0": 380702431.8149175,
      "3766.0": 380792342.4001786,
      "3767.0": 380882252.98543966,
      "3768.0": 380972163.5707007,
      "3769.0": 381062074.1559618,
      "3770.0": 381151984.74122286,
      "3771.0": 381241895.3264839,
      "3772.0": 381331805.911745,
      "3773.0": 381421716.49700606,
      "3774.0": 381511627.0822671,
      "3775.0": 381601537.6675282,
      "3776.0": 381691448.25278926,
      "3777.0": 381781358.83805037,
      "3778.0": 381871269.4233115,
      "3779.0": 381961180.0085725,
      "3780.0": 382051090.59383357,
      "3781.0": 382141001.1790947,
      "3782.0": 382230911.7643557,
      "3783.0": 382320822.34961677,
      "3784.0": 382410732.9348778,
      "3785.0": 382500643.5201389,
      "3786.0": 382590554.10539997,
      "3787.0": 382680464.690661,
      "3788.0": 382770375.2759221,
      "3789.0": 382860285.86118317,
      "3790.0": 382950196.4464442,
      "3791.0": 383040107.0317053,
      "3792.0": 383130017.61696637,
      "3793.0": 383219928.2022274,
      "3794.0": 383309838.7874885,
      "3795.0": 383399749.3727496,
      "3796.0": 383489659.9580107,
      "3797.0": 383579570.5432718,
      "3798.0": 383669481.1285328,
      "3799.0": 383759391.7137939,
      "3800.0": 383849302.299055,
      "3801.0": 383939212.884316,
      "3802.0": 384029123.4695771,
      "3803.0": 384119034.0548382,
      "3804.0": 384208944.6400992,
      "3805.0": 384298855.2253603,
      "3806.0": 384388765.8106213,
      "3807.0": 384478676.3958824,
      "3808.0": 384568586.9811435,
      "3809.0": 384658497.5664045,
      "3810.0": 384748408.1516656,
      "3811.0": 384838318.7369267,
      "3812.0": 384928229.3221877,
      "3813.0": 385018139.9074489,
      "3814.0": 385108050.49270993,
      "3815.0": 385197961.077971,
      "3816.0": 385287871.6632321,
      "3817.0": 385377782.24849313,
      "3818.0": 385467692.8337542,
      "3819.0": 385557603.4190153,
      "3820.0": 385647514.00427634,
      "3821.0": 385737424.5895374,
      "3822.0": 385827335.1747985,
      "3823.0": 385917245.76005954,
      "3824.0": 386007156.3453206,
      "3825.0": 386097066.9305817,
      "3826.0": 386186977.51584274,
      "3827.0": 386276888.1011038,
      "3828.0": 386366798.6863649,
      "3829.0": 386456709.27162594,
      "3830.0": 386546619.856887,
      "3831.0": 386636530.4421481,
      "3832.0": 386726441.0274092,
      "3833.0": 386816351.61267024,
      "3834.0": 386906262.1979313,
      "3835.0": 386996172.7831924,
      "3836.0": 387086083.36845344,
      "3837.0": 387175993.9537145,
      "3838.0": 387265904.5389756,
      "3839.0": 387355815.12423664,
      "3840.0": 387445725.7094977,
      "3841.0": 387535636.2947588,
      "3842.0": 387625546.88001984,
      "3843.0": 387715457.4652809,
      "3844.0": 387805368.050542,
      "3845.0": 387895278.63580304,
      "3846.0": 387985189.2210641,
      "3847.0": 388075099.8063252,
      "3848.0": 388165010.39158624,
      "3849.0": 388254920.97684735,
      "3850.0": 388344831.5621084,
      "3851.0": 388434742.1473695,
      "3852.0": 388524652.73263055,
      "3853.0": 388614563.3178916,
      "3854.0": 388704473.9031527,
      "3855.0": 388794384.48841375,
      "3856.0": 388884295.0736748,
      "3857.0": 388974205.6589359,
      "3858.0": 389064116.24419695,
      "3859.0": 389154026.829458,
      "3860.0": 389243937.4147191,
      "3861.0": 389333847.99998015,
      "3862.0": 389423758.5852412,
      "3863.0": 389513669.1705023,
      "3864.0": 389603579.75576335,
      "3865.0": 389693490.3410244,
      "3866.0": 389783400.9262855,
      "3867.0": 389873311.5115466,
      "3868.0": 389963222.09680766,
      "3869.0": 390053132.68206877,
      "3870.0": 390143043.2673298,
      "3871.0": 390232953.85259086,
      "3872.0": 390322864.4378519,
      "3873.0": 390412775.023113,
      "3874.0": 390502685.60837406,
      "3875.0": 390592596.1936351,
      "3876.0": 390682506.7788962,
      "3877.0": 390772417.36415726,
      "3878.0": 390862327.9494183,
      "3879.0": 390952238.5346794,
      "3880.0": 391042149.11994046,
      "3881.0": 391132059.7052015,
      "3882.0": 391221970.2904626,
      "3883.0": 391311880.87572366,
      "3884.0": 391401791.46098477,
      "3885.0": 391491702.0462459,
      "3886.0": 391581612.6315069,
      "3887.0": 391671523.21676797,
      "3888.0": 391761433.8020291,
      "3889.0": 391851344.3872901,
      "3890.0": 391941254.97255117,
      "3891.0": 392031165.5578123,
      "3892.0": 392121076.1430733,
      "3893.0": 392210986.72833437,
      "3894.0": 392300897.3135954,
      "3895.0": 392390807.8988565,
      "3896.0": 392480718.48411757,
      "3897.0": 392570629.0693786,
      "3898.0": 392660539.6546397,
      "3899.0": 392750450.23990077,
      "3900.0": 392840360.8251618,
      "3901.0": 392930271.4104229,
      "3902.0": 393020181.99568397,
      "3903.0": 393110092.5809451,
      "3904.0": 393200003.1662062,
      "3905.0": 393289913.7514672,
      "3906.0": 393379824.3367283,
      "3907.0": 393469734.9219894,
      "3908.0": 393559645.5072504,
      "3909.0": 393649556.0925115,
      "3910.0": 393739466.6777726,
      "3911.0": 393829377.2630336,
      "3912.0": 393919287.8482947,
      "3913.0": 394009198.4335558,
      "3914.0": 394099109.0188168,
      "3915.0": 394189019.6040779,
      "3916.0": 394278930.1893389,
      "3917.0": 394368840.7746,
      "3918.0": 394458751.3598611,
      "3919.0": 394548661.9451221,
      "3920.0": 394638572.5303833,
      "3921.0": 394728483.11564434,
      "3922.0": 394818393.7009054,
      "3923.0": 394908304.2861665,
      "3924.0": 394998214.87142754,
      "3925.0": 395088125.4566886,
      "3926.0": 395178036.0419497,
      "3927.0": 395267946.62721074,
      "3928.0": 395357857.2124718,
      "3929.0": 395447767.7977329,
      "3930.0": 395537678.38299394,
      "3931.0": 395627588.968255,
      "3932.0": 395717499.5535161,
      "3933.0": 395807410.13877714,
      "3934.0": 395897320.7240382,
      "3935.0": 395987231.3092993,
      "3936.0": 396077141.89456034,
      "3937.0": 396167052.4798214,
      "3938.0": 396256963.06508243,
      "3939.0": 396346873.6503436,
      "3940.0": 396436784.23560464,
      "3941.0": 396526694.8208657,
      "3942.0": 396616605.4061268,
      "3943.0": 396706515.99138784,
      "3944.0": 396796426.5766489,
      "3945.0": 396886337.16191,
      "3946.0": 396976247.74717104,
      "3947.0": 397066158.3324321,
      "3948.0": 397156068.9176932,
      "3949.0": 397245979.50295424,
      "3950.0": 397335890.0882153,
      "3951.0": 397425800.6734764,
      "3952.0": 397515711.25873744,
      "3953.0": 397605621.8439985,
      "3954.0": 397695532.4292596,
      "3955.0": 397785443.01452065,
      "3956.0": 397875353.59978175,
      "3957.0": 397965264.1850428,
      "3958.0": 398055174.7703039,
      "3959.0": 398145085.35556495,
      "3960.0": 398234995.940826,
      "3961.0": 398324906.5260871,
      "3962.0": 398414817.11134815,
      "3963.0": 398504727.6966092,
      "3964.0": 398594638.2818703,
      "3965.0": 398684548.86713135,
      "3966.0": 398774459.4523924,
      "3967.0": 398864370.0376535,
      "3968.0": 398954280.62291455,
      "3969.0": 399044191.2081756,
      "3970.0": 399134101.7934367,
      "3971.0": 399224012.37869775,
      "3972.0": 399313922.9639588,
      "3973.0": 399403833.5492199,
      "3974.0": 399493744.13448095,
      "3975.0": 399583654.71974206,
      "3976.0": 399673565.30500317,
      "3977.0": 399763475.8902642,
      "3978.0": 399853386.47552526,
      "3979.0": 399943297.0607863,
      "3980.0": 400033207.6460474,
      "3981.0": 400123118.23130846,
      "3982.0": 400213028.8165695,
      "3983.0": 400302939.4018306,
      "3984.0": 400392849.98709166,
      "3985.0": 400482760.5723527,
      "3986.0": 400572671.1576138,
      "3987.0": 400662581.74287486,
      "3988.0": 400752492.3281359,
      "3989.0": 400842402.913397,
      "3990.0": 400932313.49865806,
      "3991.0": 401022224.0839191,
      "3992.0": 401112134.6691803,
      "3993.0": 401202045.2544413,
      "3994.0": 401291955.83970237,
      "3995.0": 401381866.4249635,
      "3996.0": 401471777.0102245,
      "3997.0": 401561687.59548557,
      "3998.0": 401651598.1807467,
      "3999.0": 401741508.7660077,
      "4000.0": 401831419.35126877,
      "4001.0": 401920122.7388023,
      "4002.0": 402008826.1263359,
      "4003.0": 402097529.51386946,
      "4004.0": 402186232.90140307,
      "4005.0": 402274936.2889366,
      "4006.0": 402363639.67647016,
      "4007.0": 402452343.06400377,
      "4008.0": 402541046.4515373,
      "4009.0": 402629749.8390708,
      "4010.0": 402718453.2266044,
      "4011.0": 402807156.61413795,
      "4012.0": 402895860.00167155,
      "4013.0": 402984563.3892051,
      "4014.0": 403073266.77673864,
      "4015.0": 403161970.16427225,
      "4016.0": 403250673.5518058,
      "4017.0": 403339376.9393394,
      "4018.0": 403428080.32687294,
      "4019.0": 403516783.7144065,
      "4020.0": 403605487.1019401,
      "4021.0": 403694190.48947364,
      "4022.0": 403782893.8770071,
      "4023.0": 403871597.26454073,
      "4024.0": 403960300.6520743,
      "4025.0": 404049004.0396079,
      "4026.0": 404137707.4271414,
      "4027.0": 404226410.814675,
      "4028.0": 404315114.2022086,
      "4029.0": 404403817.5897421,
      "4030.0": 404492520.9772757,
      "4031.0": 404581224.3648093,
      "4032.0": 404669927.7523428,
      "4033.0": 404758631.1398764,
      "4034.0": 404847334.52741,
      "4035.0": 404936037.91494346,
      "4036.0": 405024741.30247706,
      "4037.0": 405113444.6900106,
      "4038.0": 405202148.0775442,
      "4039.0": 405290851.46507776,
      "4040.0": 405379554.8526113,
      "4041.0": 405468258.2401449,
      "4042.0": 405556961.62767845,
      "4043.0": 405645665.01521206,
      "4044.0": 405734368.4027456,
      "4045.0": 405823071.79027915,
      "4046.0": 405911775.17781276,
      "4047.0": 406000478.56534624,
      "4048.0": 406089181.9528798,
      "4049.0": 406177885.3404134,
      "4050.0": 406266588.72794694,
      "4051.0": 406355292.11548054,
      "4052.0": 406443995.5030141,
      "4053.0": 406532698.89054763,
      "4054.0": 406621402.27808124,
      "4055.0": 406710105.6656148,
      "4056.0": 406798809.0531484,
      "4057.0": 406887512.44068193,
      "4058.0": 406976215.8282155,
      "4059.0": 407064919.2157491,
      "4060.0": 407153622.6032826,
      "4061.0": 407242325.9908161,
      "4062.0": 407331029.3783497,
      "4063.0": 407419732.76588327,
      "4064.0": 407508436.1534169,
      "4065.0": 407597139.5409504,
      "4066.0": 407685842.92848396,
      "4067.0": 407774546.31601757,
      "4068.0": 407863249.7035511,
      "4069.0": 407951953.09108466,
      "4070.0": 408040656.47861826,
      "4071.0": 408129359.8661518,
      "4072.0": 408218063.2536854,
      "4073.0": 408306766.6412189,
      "4074.0": 408395470.02875245,
      "4075.0": 408484173.41628605,
      "4076.0": 408572876.8038196,
      "4077.0": 408661580.1913532,
      "4078.0": 408750283.57888675,
      "4079.0": 408838986.9664203,
      "4080.0": 408927690.3539539,
      "4081.0": 409016393.74148744,
      "4082.0": 409105097.129021,
      "4083.0": 409193800.5165546,
      "4084.0": 409282503.90408814,
      "4085.0": 409371207.29162174,
      "4086.0": 409459910.67915523,
      "4087.0": 409548614.0666888,
      "4088.0": 409637317.4542224,
      "4089.0": 409726020.8417559,
      "4090.0": 409814724.22928953,
      "4091.0": 409903427.6168231,
      "4092.0": 409992131.0043566,
      "4093.0": 410080834.3918902,
      "4094.0": 410169537.7794238,
      "4095.0": 410258241.1669573,
      "4096.0": 410346944.5544909,
      "4097.0": 410435647.94202447,
      "4098.0": 410524351.329558,
      "4099.0": 410613054.71709156,
      "4100.0": 410701758.1046251,
      "4101.0": 410790461.4921587,
      "4102.0": 410879164.87969226,
      "4103.0": 410967868.26722586,
      "4104.0": 411056571.6547594,
      "4105.0": 411145275.04229295,
      "4106.0": 411233978.42982656,
      "4107.0": 411322681.8173601,
      "4108.0": 411411385.20489365,
      "4109.0": 411500088.59242725,
      "4110.0": 411588791.9799608,
      "4111.0": 411677495.36749434,
      "4112.0": 411766198.7550279,
      "4113.0": 411854902.14256144,
      "4114.0": 411943605.53009504,
      "4115.0": 412032308.9176286,
      "4116.0": 412121012.3051622,
      "4117.0": 412209715.69269574,
      "4118.0": 412298419.0802293,
      "4119.0": 412387122.4677629,
      "4120.0": 412475825.85529643,
      "4121.0": 412564529.24283,
      "4122.0": 412653232.6303636,
      "4123.0": 412741936.0178971,
      "4124.0": 412830639.4054307,
      "4125.0": 412919342.7929642,
      "4126.0": 413008046.18049777,
      "4127.0": 413096749.5680314,
      "4128.0": 413185452.9555649,
      "4129.0": 413274156.3430985,
      "4130.0": 413362859.73063207,
      "4131.0": 413451563.1181656,
      "4132.0": 413540266.5056992,
      "4133.0": 413628969.89323276,
      "4134.0": 413717673.2807663,
      "4135.0": 413806376.6682999,
      "4136.0": 413895080.05583346,
      "4137.0": 413983783.443367,
      "4138.0": 414072486.83090055,
      "4139.0": 414161190.2184341,
      "4140.0": 414249893.6059677,
      "4141.0": 414338596.99350125,
      "4142.0": 414427300.38103485,
      "4143.0": 414516003.7685684,
      "4144.0": 414604707.15610194,
      "4145.0": 414693410.54363555,
      "4146.0": 414782113.9311691,
      "4147.0": 414870817.31870264,
      "4148.0": 414959520.70623624,
      "4149.0": 415048224.0937698,
      "4150.0": 415136927.48130333,
      "4151.0": 415225630.8688369,
      "4152.0": 415314334.2563704,
      "4153.0": 415403037.64390403,
      "4154.0": 415491741.0314376,
      "4155.0": 415580444.4189712,
      "4156.0": 415669147.8065047,
      "4157.0": 415757851.1940383,
      "4158.0": 415846554.5815719,
      "4159.0": 415935257.9691054,
      "4160.0": 416023961.35663897,
      "4161.0": 416112664.7441726,
      "4162.0": 416201368.1317061,
      "4163.0": 416290071.51923966,
      "4164.0": 416378774.9067732,
      "4165.0": 416467478.29430676,
      "4166.0": 416556181.68184036,
      "4167.0": 416644885.0693739,
      "4168.0": 416733588.4569075,
      "4169.0": 416822291.84444106,
      "4170.0": 416910995.2319746,
      "4171.0": 416999698.6195082,
      "4172.0": 417088402.00704175,
      "4173.0": 417177105.3945753,
      "4174.0": 417265808.7821089,
      "4175.0": 417354512.16964245,
      "4176.0": 417443215.557176,
      "4177.0": 417531918.94470954,
      "4178.0": 417620622.3322431,
      "4179.0": 417709325.7197767,
      "4180.0": 417798029.10731024,
      "4181.0": 417886732.49484384,
      "4182.0": 417975435.8823774,
      "4183.0": 418064139.26991093,
      "4184.0": 418152842.65744454,
      "4185.0": 418241546.0449781,
      "4186.0": 418330249.4325116,
      "4187.0": 418418952.82004523,
      "4188.0": 418507656.2075787,
      "4189.0": 418596359.5951123,
      "4190.0": 418685062.98264587,
      "4191.0": 418773766.3701794,
      "4192.0": 418862469.757713,
      "4193.0": 418951173.14524657,
      "4194.0": 419039876.5327801,
      "4195.0": 419128579.9203137,
      "4196.0": 419217283.30784726,
      "4197.0": 419305986.69538087,
      "4198.0": 419394690.0829144,
      "4199.0": 419483393.47044796,
      "4200.0": 419572096.85798156,
      "4201.0": 419660800.24551505,
      "4202.0": 419749503.63304865,
      "4203.0": 419838207.0205822,
      "4204.0": 419926910.40811574,
      "4205.0": 420015613.79564935,
      "4206.0": 420104317.1831829,
      "4207.0": 420193020.5707165,
      "4208.0": 420281723.95825005,
      "4209.0": 420370427.3457836,
      "4210.0": 420459130.7333172,
      "4211.0": 420547834.12085074,
      "4212.0": 420636537.5083843,
      "4213.0": 420725240.8959179,
      "4214.0": 420813944.2834514,
      "4215.0": 420902647.670985,
      "4216.0": 420991351.0585185,
      "4217.0": 421080054.4460521,
      "4218.0": 421168757.8335857,
      "4219.0": 421257461.2211192,
      "4220.0": 421346164.6086528,
      "4221.0": 421434867.9961864,
      "4222.0": 421523571.3837199,
      "4223.0": 421612274.7712535,
      "4224.0": 421700978.1587871,
      "4225.0": 421789681.5463206,
      "4226.0": 421878384.9338542,
      "4227.0": 421967088.3213877,
      "4228.0": 422055791.7089213,
      "4229.0": 422144495.09645486,
      "4230.0": 422233198.4839884,
      "4231.0": 422321901.871522,
      "4232.0": 422410605.25905555,
      "4233.0": 422499308.64658916,
      "4234.0": 422588012.0341227,
      "4235.0": 422676715.42165625,
      "4236.0": 422765418.80918986,
      "4237.0": 422854122.1967234,
      "4238.0": 422942825.58425695,
      "4239.0": 423031528.9717905,
      "4240.0": 423120232.35932404,
      "4241.0": 423208935.74685764,
      "4242.0": 423297639.1343912,
      "4243.0": 423386342.52192473,
      "4244.0": 423475045.90945834,
      "4245.0": 423563749.2969919,
      "4246.0": 423652452.68452543,
      "4247.0": 423741156.07205904,
      "4248.0": 423829859.4595926,
      "4249.0": 423918562.8471262,
      "4250.0": 424007266.23465973,
      "4251.0": 424094779.5697973,
      "4252.0": 424182292.9049348,
      "4253.0": 424269806.2400723,
      "4254.0": 424357319.57520986,
      "4255.0": 424444832.9103474,
      "4256.0": 424532346.2454849,
      "4257.0": 424619859.5806224,
      "4258.0": 424707372.9157599,
      "4259.0": 424794886.25089747,
      "4260.0": 424882399.586035,
      "4261.0": 424969912.9211725,
      "4262.0": 425057426.25631005,
      "4263.0": 425144939.5914476,
      "4264.0": 425232452.92658514,
      "4265.0": 425319966.2617227,
      "4266.0": 425407479.5968602,
      "4267.0": 425494992.9319977,
      "4268.0": 425582506.26713526,
      "4269.0": 425670019.6022728,
      "4270.0": 425757532.9374103,
      "4271.0": 425845046.27254784,
      "4272.0": 425932559.6076854,
      "4273.0": 426020072.94282293,
      "4274.0": 426107586.2779605,
      "4275.0": 426195099.61309797,
      "4276.0": 426282612.9482355,
      "4277.0": 426370126.28337306,
      "4278.0": 426457639.6185106,
      "4279.0": 426545152.9536481,
      "4280.0": 426632666.28878564,
      "4281.0": 426720179.6239232,
      "4282.0": 426807692.9590607,
      "4283.0": 426895206.2941982,
      "4284.0": 426982719.62933576,
      "4285.0": 427070232.9644733,
      "4286.0": 427157746.29961085,
      "4287.0": 427245259.6347484,
      "4288.0": 427332772.9698859,
      "4289.0": 427420286.30502343,
      "4290.0": 427507799.640161,
      "4291.0": 427595312.9752985,
      "4292.0": 427682826.310436,
      "4293.0": 427770339.64557356,
      "4294.0": 427857852.98071104,
      "4295.0": 427945366.3158486,
      "4296.0": 428032879.65098614,
      "4297.0": 428120392.9861236,
      "4298.0": 428207906.32126117,
      "4299.0": 428295419.6563987,
      "4300.0": 428382932.99153626,
      "4301.0": 428470446.32667375,
      "4302.0": 428557959.6618113,
      "4303.0": 428645472.99694884,
      "4304.0": 428732986.3320864,
      "4305.0": 428820499.6672239,
      "4306.0": 428908013.0023614,
      "4307.0": 428995526.33749896,
      "4308.0": 429083039.6726365,
      "4309.0": 429170553.00777406,
      "4310.0": 429258066.34291154,
      "4311.0": 429345579.6780491,
      "4312.0": 429433093.01318663,
      "4313.0": 429520606.3483242,
      "4314.0": 429608119.68346167,
      "4315.0": 429695633.0185992,
      "4316.0": 429783146.35373676,
      "4317.0": 429870659.6888743,
      "4318.0": 429958173.0240118,
      "4319.0": 430045686.35914934,
      "4320.0": 430133199.6942869,
      "4321.0": 430220713.0294244,
      "4322.0": 430308226.364562,
      "4323.0": 430395739.69969946,
      "4324.0": 430483253.034837,
      "4325.0": 430570766.36997455,
      "4326.0": 430658279.7051121,
      "4327.0": 430745793.0402496,
      "4328.0": 430833306.37538713,
      "4329.0": 430920819.7105247,
      "4330.0": 431008333.0456622,
      "4331.0": 431095846.3807998,
      "4332.0": 431183359.71593726,
      "4333.0": 431270873.05107474,
      "4334.0": 431358386.3862123,
      "4335.0": 431445899.72134984,
      "4336.0": 431533413.0564873,
      "4337.0": 431620926.39162487,
      "4338.0": 431708439.7267624,
      "4339.0": 431795953.06189996,
      "4340.0": 431883466.39703745,
      "4341.0": 431970979.732175,
      "4342.0": 432058493.06731254,
      "4343.0": 432146006.4024501,
      "4344.0": 432233519.73758763,
      "4345.0": 432321033.0727251,
      "4346.0": 432408546.40786266,
      "4347.0": 432496059.7430002,
      "4348.0": 432583573.07813776,
      "4349.0": 432671086.41327524,
      "4350.0": 432758599.7484128,
      "4351.0": 432846113.08355033,
      "4352.0": 432933626.4186879,
      "4353.0": 433021139.7538254,
      "4354.0": 433108653.0889629,
      "4355.0": 433196166.42410046,
      "4356.0": 433283679.759238,
      "4357.0": 433371193.09437555,
      "4358.0": 433458706.42951304,
      "4359.0": 433546219.7646506,
      "4360.0": 433633733.0997881,
      "4361.0": 433721246.4349257,
      "4362.0": 433808759.77006316,
      "4363.0": 433896273.1052007,
      "4364.0": 433983786.44033825,
      "4365.0": 434071299.7754758,
      "4366.0": 434158813.11061335,
      "4367.0": 434246326.44575083,
      "4368.0": 434333839.7808884,
      "4369.0": 434421353.1160259,
      "4370.0": 434508866.4511635,
      "4371.0": 434596379.78630096,
      "4372.0": 434683893.12143844,
      "4373.0": 434771406.456576,
      "4374.0": 434858919.79171354,
      "4375.0": 434946433.126851,
      "4376.0": 435033946.46198857,
      "4377.0": 435121459.7971261,
      "4378.0": 435208973.13226366,
      "4379.0": 435296486.4674012,
      "4380.0": 435383999.8025387,
      "4381.0": 435471513.13767624,
      "4382.0": 435559026.4728138,
      "4383.0": 435646539.80795133,
      "4384.0": 435734053.1430888,
      "4385.0": 435821566.47822636,
      "4386.0": 435909079.8133639,
      "4387.0": 435996593.14850146,
      "4388.0": 436084106.483639,
      "4389.0": 436171619.8187765,
      "4390.0": 436259133.15391403,
      "4391.0": 436346646.4890516,
      "4392.0": 436434159.8241891,
      "4393.0": 436521673.1593266,
      "4394.0": 436609186.49446416,
      "4395.0": 436696699.8296017,
      "4396.0": 436784213.16473925,
      "4397.0": 436871726.49987674,
      "4398.0": 436959239.8350143,
      "4399.0": 437046753.1701518,
      "4400.0": 437134266.5052894,
      "4401.0": 437221779.8404269,
      "4402.0": 437309293.1755644,
      "4403.0": 437396806.51070195,
      "4404.0": 437484319.8458395,
      "4405.0": 437571833.18097705,
      "4406.0": 437659346.51611453,
      "4407.0": 437746859.8512521,
      "4408.0": 437834373.1863896,
      "4409.0": 437921886.5215272,
      "4410.0": 438009399.85666466,
      "4411.0": 438096913.19180214,
      "4412.0": 438184426.5269397,
      "4413.0": 438271939.86207724,
      "4414.0": 438359453.1972148,
      "4415.0": 438446966.53235227,
      "4416.0": 438534479.8674898,
      "4417.0": 438621993.20262736,
      "4418.0": 438709506.5377649,
      "4419.0": 438797019.8729024,
      "4420.0": 438884533.20803994,
      "4421.0": 438972046.5431775,
      "4422.0": 439059559.87831503,
      "4423.0": 439147073.2134526,
      "4424.0": 439234586.54859006,
      "4425.0": 439322099.8837276,
      "4426.0": 439409613.21886516,
      "4427.0": 439497126.5540027,
      "4428.0": 439584639.8891402,
      "4429.0": 439672153.22427773,
      "4430.0": 439759666.5594153,
      "4431.0": 439847179.8945528,
      "4432.0": 439934693.2296903,
      "4433.0": 440022206.56482786,
      "4434.0": 440109719.8999654,
      "4435.0": 440197233.23510295,
      "4436.0": 440284746.5702405,
      "4437.0": 440372259.905378,
      "4438.0": 440459773.24051553,
      "4439.0": 440547286.5756531,
      "4440.0": 440634799.9107906,
      "4441.0": 440722313.2459281,
      "4442.0": 440809826.58106565,
      "4443.0": 440897339.9162032,
      "4444.0": 440984853.25134075,
      "4445.0": 441072366.5864783,
      "4446.0": 441159879.9216158,
      "4447.0": 441247393.2567533,
      "4448.0": 441334906.5918909,
      "4449.0": 441422419.92702836,
      "4450.0": 441509933.26216584,
      "4451.0": 441597446.5973034,
      "4452.0": 441684959.93244094,
      "4453.0": 441772473.2675785,
      "4454.0": 441859986.602716,
      "4455.0": 441947499.9378535,
      "4456.0": 442035013.27299106,
      "4457.0": 442122526.6081286,
      "4458.0": 442210039.94326615,
      "4459.0": 442297553.27840364,
      "4460.0": 442385066.6135412,
      "4461.0": 442472579.94867873,
      "4462.0": 442560093.2838163,
      "4463.0": 442647606.61895376,
      "4464.0": 442735119.9540913,
      "4465.0": 442822633.28922886,
      "4466.0": 442910146.6243664,
      "4467.0": 442997659.95950395,
      "4468.0": 443085173.29464144,
      "4469.0": 443172686.629779,
      "4470.0": 443260199.9649165,
      "4471.0": 443347713.3000541,
      "4472.0": 443435226.63519156,
      "4473.0": 443522739.9703291,
      "4474.0": 443610253.30546665,
      "4475.0": 443697766.6406042,
      "4476.0": 443785279.9757417,
      "4477.0": 443872793.31087923,
      "4478.0": 443960306.6460168,
      "4479.0": 444047819.9811543,
      "4480.0": 444135333.31629187,
      "4481.0": 444222846.65142936,
      "4482.0": 444310359.9865669,
      "4483.0": 444397873.32170445,
      "4484.0": 444485386.656842,
      "4485.0": 444572899.9919795,
      "4486.0": 444660413.327117,
      "4487.0": 444747926.6622546,
      "4488.0": 444835439.99739206,
      "4489.0": 444922953.33252954,
      "4490.0": 445010466.6676671,
      "4491.0": 445097980.00280464,
      "4492.0": 445185493.3379422,
      "4493.0": 445273006.6730797,
      "4494.0": 445360520.0082172,
      "4495.0": 445448033.34335476,
      "4496.0": 445535546.6784923,
      "4497.0": 445623060.01362985,
      "4498.0": 445710573.34876734,
      "4499.0": 445798086.6839049,
      "4500.0": 445885600.01904243,
      "4501.0": 445971955.2053169,
      "4502.0": 446058310.3915913,
      "4503.0": 446144665.5778657,
      "4504.0": 446231020.7641402,
      "4505.0": 446317375.9504146,
      "4506.0": 446403731.136689,
      "4507.0": 446490086.32296354,
      "4508.0": 446576441.50923795,
      "4509.0": 446662796.69551235,
      "4510.0": 446749151.8817868,
      "4511.0": 446835507.06806123,
      "4512.0": 446921862.2543357,
      "4513.0": 447008217.4406101,
      "4514.0": 447094572.6268845,
      "4515.0": 447180927.813159,
      "4516.0": 447267282.9994334,
      "4517.0": 447353638.1857078,
      "4518.0": 447439993.3719823,
      "4519.0": 447526348.5582567,
      "4520.0": 447612703.7445312,
      "4521.0": 447699058.9308056,
      "4522.0": 447785414.11708003,
      "4523.0": 447871769.3033545,
      "4524.0": 447958124.4896289,
      "4525.0": 448044479.6759033,
      "4526.0": 448130834.8621778,
      "4527.0": 448217190.0484522,
      "4528.0": 448303545.23472667,
      "4529.0": 448389900.4210011,
      "4530.0": 448476255.6072755,
      "4531.0": 448562610.79354995,
      "4532.0": 448648965.97982436,
      "4533.0": 448735321.16609883,
      "4534.0": 448821676.3523733,
      "4535.0": 448908031.5386477,
      "4536.0": 448994386.7249222,
      "4537.0": 449080741.9111966,
      "4538.0": 449167097.097471,
      "4539.0": 449253452.28374547,
      "4540.0": 449339807.4700199,
      "4541.0": 449426162.6562943,
      "4542.0": 449512517.84256876,
      "4543.0": 449598873.02884316,
      "4544.0": 449685228.21511763,
      "4545.0": 449771583.40139204,
      "4546.0": 449857938.58766645,
      "4547.0": 449944293.773941,
      "4548.0": 450030648.9602154,
      "4549.0": 450117004.1464898,
      "4550.0": 450203359.33276427,
      "4551.0": 450289714.5190387,
      "4552.0": 450376069.7053131,
      "4553.0": 450462424.89158756,
      "4554.0": 450548780.07786196,
      "4555.0": 450635135.26413643,
      "4556.0": 450721490.45041084,
      "4557.0": 450807845.63668525,
      "4558.0": 450894200.8229597,
      "4559.0": 450980556.00923413,
      "4560.0": 451066911.1955086,
      "4561.0": 451153266.38178307,
      "4562.0": 451239621.5680575,
      "4563.0": 451325976.75433195,
      "4564.0": 451412331.94060636,
      "4565.0": 451498687.12688076,
      "4566.0": 451585042.31315523,
      "4567.0": 451671397.49942964,
      "4568.0": 451757752.68570405,
      "4569.0": 451844107.8719785,
      "4570.0": 451930463.05825293,
      "4571.0": 452016818.2445274,
      "4572.0": 452103173.4308018,
      "4573.0": 452189528.6170763,
      "4574.0": 452275883.80335075,
      "4575.0": 452362238.98962516,
      "4576.0": 452448594.17589957,
      "4577.0": 452534949.36217403,
      "4578.0": 452621304.54844844,
      "4579.0": 452707659.73472285,
      "4580.0": 452794014.9209973,
      "4581.0": 452880370.10727173,
      "4582.0": 452966725.2935462,
      "4583.0": 453053080.4798206,
      "4584.0": 453139435.666095,
      "4585.0": 453225790.8523695,
      "4586.0": 453312146.03864396,
      "4587.0": 453398501.22491837,
      "4588.0": 453484856.41119283,
      "4589.0": 453571211.59746724,
      "4590.0": 453657566.7837417,
      "4591.0": 453743921.9700161,
      "4592.0": 453830277.15629053,
      "4593.0": 453916632.342565,
      "4594.0": 454002987.5288394,
      "4595.0": 454089342.7151138,
      "4596.0": 454175697.9013883,
      "4597.0": 454262053.0876627,
      "4598.0": 454348408.27393717,
      "4599.0": 454434763.46021163,
      "4600.0": 454521118.64648604,
      "4601.0": 454607473.8327605,
      "4602.0": 454693829.0190349,
      "4603.0": 454780184.20530933,
      "4604.0": 454866539.3915838,
      "4605.0": 454952894.5778582,
      "4606.0": 455039249.7641326,
      "4607.0": 455125604.9504071,
      "4608.0": 455211960.1366815,
      "4609.0": 455298315.32295597,
      "4610.0": 455384670.5092304,
      "4611.0": 455471025.6955048,
      "4612.0": 455557380.8817793,
      "4613.0": 455643736.0680537,
      "4614.0": 455730091.25432813,
      "4615.0": 455816446.4406026,
      "4616.0": 455902801.626877,
      "4617.0": 455989156.8131515,
      "4618.0": 456075511.9994259,
      "4619.0": 456161867.1857003,
      "4620.0": 456248222.37197477,
      "4621.0": 456334577.5582492,
      "4622.0": 456420932.7445236,
      "4623.0": 456507287.93079805,
      "4624.0": 456593643.11707246,
      "4625.0": 456679998.30334693,
      "4626.0": 456766353.4896214,
      "4627.0": 456852708.6758958,
      "4628.0": 456939063.8621703,
      "4629.0": 457025419.0484447,
      "4630.0": 457111774.2347191,
      "4631.0": 457198129.42099357,
      "4632.0": 457284484.607268,
      "4633.0": 457370839.79354244,
      "4634.0": 457457194.97981685,
      "4635.0": 457543550.16609126,
      "4636.0": 457629905.35236573,
      "4637.0": 457716260.53864014,
      "4638.0": 457802615.72491455,
      "4639.0": 457888970.9111891,
      "4640.0": 457975326.0974635,
      "4641.0": 458061681.2837379,
      "4642.0": 458148036.47001237,
      "4643.0": 458234391.6562868,
      "4644.0": 458320746.84256124,
      "4645.0": 458407102.02883565,
      "4646.0": 458493457.21511006,
      "4647.0": 458579812.40138453,
      "4648.0": 458666167.58765894,
      "4649.0": 458752522.77393335,
      "4650.0": 458838877.9602078,
      "4651.0": 458925233.1464822,
      "4652.0": 459011588.33275676,
      "4653.0": 459097943.51903117,
      "4654.0": 459184298.7053056,
      "4655.0": 459270653.89158005,
      "4656.0": 459357009.07785445,
      "4657.0": 459443364.26412886,
      "4658.0": 459529719.45040333,
      "4659.0": 459616074.63667774,
      "4660.0": 459702429.8229522,
      "4661.0": 459788785.0092266,
      "4662.0": 459875140.195501,
      "4663.0": 459961495.3817755,
      "4664.0": 460047850.5680499,
      "4665.0": 460134205.7543244,
      "4666.0": 460220560.94059885,
      "4667.0": 460306916.12687325,
      "4668.0": 460393271.31314766,
      "4669.0": 460479626.49942213,
      "4670.0": 460565981.68569654,
      "4671.0": 460652336.871971,
      "4672.0": 460738692.0582454,
      "4673.0": 460825047.2445198,
      "4674.0": 460911402.4307943,
      "4675.0": 460997757.6170687,
      "4676.0": 461084112.8033431,
      "4677.0": 461170467.9896176,
      "4678.0": 461256823.175892,
      "4679.0": 461343178.3621665,
      "4680.0": 461429533.54844093,
      "4681.0": 461515888.73471534,
      "4682.0": 461602243.9209898,
      "4683.0": 461688599.1072642,
      "4684.0": 461774954.29353863,
      "4685.0": 461861309.4798131,
      "4686.0": 461947664.6660875,
      "4687.0": 462034019.852362,
      "4688.0": 462120375.0386364,
      "4689.0": 462206730.2249108,
      "4690.0": 462293085.41118526,
      "4691.0": 462379440.59745973,
      "4692.0": 462465795.78373414,
      "4693.0": 462552150.9700086,
      "4694.0": 462638506.156283,
      "4695.0": 462724861.3425575,
      "4696.0": 462811216.5288319,
      "4697.0": 462897571.7151063,
      "4698.0": 462983926.9013808,
      "4699.0": 463070282.0876552,
      "4700.0": 463156637.2739296,
      "4701.0": 463242992.46020406,
      "4702.0": 463329347.6464785,
      "4703.0": 463415702.83275294,
      "4704.0": 463502058.01902735,
      "4705.0": 463588413.2053018,
      "4706.0": 463674768.3915763,
      "4707.0": 463761123.5778507,
      "4708.0": 463847478.7641251,
      "4709.0": 463933833.9503996,
      "4710.0": 464020189.136674,
      "4711.0": 464106544.3229484,
      "4712.0": 464192899.50922287,
      "4713.0": 464279254.6954973,
      "4714.0": 464365609.88177174,
      "4715.0": 464451965.06804615,
      "4716.0": 464538320.25432056,
      "4717.0": 464624675.4405951,
      "4718.0": 464711030.6268695,
      "4719.0": 464797385.8131439,
      "4720.0": 464883740.9994184,
      "4721.0": 464970096.1856928,
      "4722.0": 465056451.37196726,
      "4723.0": 465142806.55824167,
      "4724.0": 465229161.7445161,
      "4725.0": 465315516.93079054,
      "4726.0": 465401872.11706495,
      "4727.0": 465488227.30333936,
      "4728.0": 465574582.48961383,
      "4729.0": 465660937.67588824,
      "4730.0": 465747292.8621627,
      "4731.0": 465833648.0484372,
      "4732.0": 465920003.2347116,
      "4733.0": 466006358.42098606,
      "4734.0": 466092713.60726047,
      "4735.0": 466179068.7935349,
      "4736.0": 466265423.97980934,
      "4737.0": 466351779.16608375,
      "4738.0": 466438134.35235816,
      "4739.0": 466524489.53863263,
      "4740.0": 466610844.72490704,
      "4741.0": 466697199.9111815,
      "4742.0": 466783555.0974559,
      "4743.0": 466869910.2837303,
      "4744.0": 466956265.47000486,
      "4745.0": 467042620.65627927,
      "4746.0": 467128975.8425537,
      "4747.0": 467215331.02882814,
      "4748.0": 467301686.21510255,
      "4749.0": 467388041.401377,
      "4750.0": 467474396.58765143,
      "4751.0": 467559617.50834686,
      "4752.0": 467644838.4290422,
      "4753.0": 467730059.34973764,
      "4754.0": 467815280.2704331,
      "4755.0": 467900501.1911285,
      "4756.0": 467985722.1118239,
      "4757.0": 468070943.03251934,
      "4758.0": 468156163.9532147,
      "4759.0": 468241384.8739101,
      "4760.0": 468326605.7946056,
      "4761.0": 468411826.715301,
      "4762.0": 468497047.6359964,
      "4763.0": 468582268.5566918,
      "4764.0": 468667489.47738725,
      "4765.0": 468752710.3980826,
      "4766.0": 468837931.3187781,
      "4767.0": 468923152.2394735,
      "4768.0": 469008373.1601689,
      "4769.0": 469093594.0808643,
      "4770.0": 469178815.00155973,
      "4771.0": 469264035.9222551,
      "4772.0": 469349256.8429506,
      "4773.0": 469434477.763646,
      "4774.0": 469519698.6843414,
      "4775.0": 469604919.6050368,
      "4776.0": 469690140.5257322,
      "4777.0": 469775361.4464276,
      "4778.0": 469860582.36712307,
      "4779.0": 469945803.2878185,
      "4780.0": 470031024.20851386,
      "4781.0": 470116245.1292093,
      "4782.0": 470201466.0499047,
      "4783.0": 470286686.9706001,
      "4784.0": 470371907.89129555,
      "4785.0": 470457128.811991,
      "4786.0": 470542349.7326864,
      "4787.0": 470627570.65338176,
      "4788.0": 470712791.5740772,
      "4789.0": 470798012.4947726,
      "4790.0": 470883233.41546804,
      "4791.0": 470968454.33616346,
      "4792.0": 471053675.2568589,
      "4793.0": 471138896.17755425,
      "4794.0": 471224117.0982497,
      "4795.0": 471309338.0189451,
      "4796.0": 471394558.9396405,
      "4797.0": 471479779.86033595,
      "4798.0": 471565000.78103137,
      "4799.0": 471650221.70172673,
      "4800.0": 471735442.62242216,
      "4801.0": 471820663.5431176,
      "4802.0": 471905884.46381307,
      "4803.0": 471991105.38450843,
      "4804.0": 472076326.30520386,
      "4805.0": 472161547.2258993,
      "4806.0": 472246768.14659464,
      "4807.0": 472331989.06729007,
      "4808.0": 472417209.98798555,
      "4809.0": 472502430.9086809,
      "4810.0": 472587651.82937634,
      "4811.0": 472672872.75007176,
      "4812.0": 472758093.6707671,
      "4813.0": 472843314.59146255,
      "4814.0": 472928535.512158,
      "4815.0": 473013756.4328534,
      "4816.0": 473098977.3535488,
      "4817.0": 473184198.27424425,
      "4818.0": 473269419.1949397,
      "4819.0": 473354640.11563504,
      "4820.0": 473439861.03633046,
      "4821.0": 473525081.95702595,
      "4822.0": 473610302.8777213,
      "4823.0": 473695523.79841673,
      "4824.0": 473780744.71911216,
      "4825.0": 473865965.6398075,
      "4826.0": 473951186.56050295,
      "4827.0": 474036407.48119843,
      "4828.0": 474121628.4018938,
      "4829.0": 474206849.3225892,
      "4830.0": 474292070.24328464,
      "4831.0": 474377291.16398,
      "4832.0": 474462512.08467543,
      "4833.0": 474547733.0053709,
      "4834.0": 474632953.9260663,
      "4835.0": 474718174.8467617,
      "4836.0": 474803395.7674571,
      "4837.0": 474888616.68815255,
      "4838.0": 474973837.6088479,
      "4839.0": 475059058.5295434,
      "4840.0": 475144279.4502388,
      "4841.0": 475229500.3709342,
      "4842.0": 475314721.2916296,
      "4843.0": 475399942.21232504,
      "4844.0": 475485163.1330204,
      "4845.0": 475570384.0537159,
      "4846.0": 475655604.9744113,
      "4847.0": 475740825.8951067,
      "4848.0": 475826046.8158021,
      "4849.0": 475911267.7364975,
      "4850.0": 475996488.6571929,
      "4851.0": 476081709.57788837,
      "4852.0": 476166930.4985838,
      "4853.0": 476252151.41927916,
      "4854.0": 476337372.3399746,
      "4855.0": 476422593.26067,
      "4856.0": 476507814.18136543,
      "4857.0": 476593035.10206085,
      "4858.0": 476678256.0227563,
      "4859.0": 476763476.9434517,
      "4860.0": 476848697.86414707,
      "4861.0": 476933918.7848425,
      "4862.0": 477019139.7055379,
      "4863.0": 477104360.62623334,
      "4864.0": 477189581.54692876,
      "4865.0": 477274802.4676242,
      "4866.0": 477360023.38831955,
      "4867.0": 477445244.309015,
      "4868.0": 477530465.2297104,
      "4869.0": 477615686.1504058,
      "4870.0": 477700907.07110125,
      "4871.0": 477786127.9917967,
      "4872.0": 477871348.9124921,
      "4873.0": 477956569.83318746,
      "4874.0": 478041790.7538829,
      "4875.0": 478127011.6745783,
      "4876.0": 478212232.59527373,
      "4877.0": 478297453.51596916,
      "4878.0": 478382674.4366646,
      "4879.0": 478467895.35735995,
      "4880.0": 478553116.27805537,
      "4881.0": 478638337.19875085,
      "4882.0": 478723558.1194462,
      "4883.0": 478808779.04014164,
      "4884.0": 478893999.96083707,
      "4885.0": 478979220.88153243,
      "4886.0": 479064441.80222785,
      "4887.0": 479149662.7229233,
      "4888.0": 479234883.6436187,
      "4889.0": 479320104.5643141,
      "4890.0": 479405325.48500955,
      "4891.0": 479490546.405705,
      "4892.0": 479575767.32640034,
      "4893.0": 479660988.24709576,
      "4894.0": 479746209.16779125,
      "4895.0": 479831430.0884866,
      "4896.0": 479916651.00918204,
      "4897.0": 480001871.92987746,
      "4898.0": 480087092.8505728,
      "4899.0": 480172313.77126825,
      "4900.0": 480257534.69196373,
      "4901.0": 480342755.6126591,
      "4902.0": 480427976.5333545,
      "4903.0": 480513197.45404994,
      "4904.0": 480598418.3747453,
      "4905.0": 480683639.29544073,
      "4906.0": 480768860.2161362,
      "4907.0": 480854081.1368316,
      "4908.0": 480939302.057527,
      "4909.0": 481024522.9782224,
      "4910.0": 481109743.89891785,
      "4911.0": 481194964.8196132,
      "4912.0": 481280185.7403087,
      "4913.0": 481365406.6610041,
      "4914.0": 481450627.5816995,
      "4915.0": 481535848.5023949,
      "4916.0": 481621069.42309034,
      "4917.0": 481706290.3437857,
      "4918.0": 481791511.2644812,
      "4919.0": 481876732.1851766,
      "4920.0": 481961953.105872,
      "4921.0": 482047174.0265674,
      "4922.0": 482132394.9472628,
      "4923.0": 482217615.8679582,
      "4924.0": 482302836.7886537,
      "4925.0": 482388057.7093491,
      "4926.0": 482473278.63004446,
      "4927.0": 482558499.5507399,
      "4928.0": 482643720.4714353,
      "4929.0": 482728941.39213073,
      "4930.0": 482814162.31282616,
      "4931.0": 482899383.2335216,
      "4932.0": 482984604.154217,
      "4933.0": 483069825.07491237,
      "4934.0": 483155045.9956078,
      "4935.0": 483240266.9163032,
      "4936.0": 483325487.8369986,
      "4937.0": 483410708.75769407,
      "4938.0": 483495929.6783895,
      "4939.0": 483581150.59908485,
      "4940.0": 483666371.5197803,
      "4941.0": 483751592.4404757,
      "4942.0": 483836813.3611711,
      "4943.0": 483922034.28186655,
      "4944.0": 484007255.202562,
      "4945.0": 484092476.1232574,
      "4946.0": 484177697.04395276,
      "4947.0": 484262917.9646482,
      "4948.0": 484348138.8853436,
      "4949.0": 484433359.80603904,
      "4950.0": 484518580.72673446,
      "4951.0": 484603801.6474299,
      "4952.0": 484689022.56812525,
      "4953.0": 484774243.4888207,
      "4954.0": 484859464.40951616,
      "4955.0": 484944685.3302115,
      "4956.0": 485029906.25090694,
      "4957.0": 485115127.17160237,
      "4958.0": 485200348.09229773,
      "4959.0": 485285569.01299316,
      "4960.0": 485370789.9336886,
      "4961.0": 485456010.854384,
      "4962.0": 485541231.7750794,
      "4963.0": 485626452.69577485,
      "4964.0": 485711673.6164703,
      "4965.0": 485796894.53716564,
      "4966.0": 485882115.45786107,
      "4967.0": 485967336.37855655,
      "4968.0": 486052557.2992519,
      "4969.0": 486137778.21994734,
      "4970.0": 486222999.14064276,
      "4971.0": 486308220.0613381,
      "4972.0": 486393440.98203355,
      "4973.0": 486478661.90272903,
      "4974.0": 486563882.8234244,
      "4975.0": 486649103.7441198,
      "4976.0": 486734324.66481525,
      "4977.0": 486819545.5855106,
      "4978.0": 486904766.50620604,
      "4979.0": 486989987.4269015,
      "4980.0": 487075208.3475969,
      "4981.0": 487160429.2682923,
      "4982.0": 487245650.18898773,
      "4983.0": 487330871.10968316,
      "4984.0": 487416092.0303785,
      "4985.0": 487501312.951074,
      "4986.0": 487586533.8717694,
      "4987.0": 487671754.7924648,
      "4988.0": 487756975.7131602,
      "4989.0": 487842196.63385564,
      "4990.0": 487927417.554551,
      "4991.0": 488012638.4752465,
      "4992.0": 488097859.3959419,
      "4993.0": 488183080.3166373,
      "4994.0": 488268301.2373327,
      "4995.0": 488353522.1580281,
      "4996.0": 488438743.0787235,
      "4997.0": 488523963.9994189,
      "4998.0": 488609184.9201144,
      "4999.0": 488694405.8408098,
      "5000.0": 488779626.7615052
    }
  },
  "pathway_map": {
    "entries": {
      "SAF1": {
        "pathway_name": "Hydrotreated esters and fatty acids (HEFA), e.g. HVO",
        "tech_key": "saf"
      },
      "SAF2": {
        "pathway_name": "Power to liquid (PtL) using Fischer-Tropsch (FT)",
        "tech_key": "saf"
      },
      "SAF3": {
        "pathway_name": "Alcohol to jet (AtJ)",
        "tech_key": "saf"
      },
      "SAF4": {
        "pathway_name": "Biomass gasification using Fischer-Tropsch (FT)",
        "tech_key": "saf"
      },
      "DAC1": {
        "pathway_name": "Causticization with alkali hydroxides, aka HT DAC",
        "tech_key": "dac"
      },
      "DAC2": {
        "pathway_name": "Organic-inorganic hybrid sorbents (amines in porous adsorbents), aka LT DAC",
        "tech_key": "dac"
      },
      "GH1": {
        "pathway_name": "Renewables-based electrolysis (ALK, PEM or SOEC)",
        "tech_key": "gh"
      },
      "LDES1": {
        "pathway_name": "Flow batteries (chemistry agnostic)",
        "tech_key": "ldes"
      },
      "LDES2": {
        "pathway_name": "Modular pumped hydro",
        "tech_key": "ldes"
      }
    }
  }
}
