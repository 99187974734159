/* eslint-disable import/prefer-default-export */
import React from 'react';
import { clamp } from 'lodash';

export const createPathElement = d => {
  const el = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  el.setAttribute('d', d);
  return el;
};

/** @see https://stackoverflow.com/a/47935325/8971140 */
export function findY(pathEl, x) {
  const pathLength = pathEl.getTotalLength();
  let start = 0;
  let end = pathLength;
  let target = (start + end) / 2;

  const clampedX = clamp(
    x,
    pathEl.getPointAtLength(0).x,
    pathEl.getPointAtLength(pathLength).x
  );

  // Walk along the path using binary search
  // to locate the point with the supplied x value
  while (target >= start && target <= pathLength) {
    const pos = pathEl.getPointAtLength(target);

    // use a threshold instead of strict equality
    // to handle javascript floating point precision
    if (Math.abs(pos.x - clampedX) < 0.001) {
      return pos.y;
    }
    if (pos.x > clampedX) {
      end = target;
    } else {
      start = target;
    }
    target = (start + end) / 2;
  }

  return 0;
}

export function findLength(pathEl, x) {
  const pathLength = pathEl.getTotalLength();
  let start = 0;
  let end = pathLength;
  let target = (start + end) / 2;

  const clampedX = clamp(
    x,
    pathEl.getPointAtLength(0).x,
    pathEl.getPointAtLength(pathLength).x
  );

  // Walk along the path using binary search
  // to locate the point with the supplied x value
  while (target >= start && target <= pathLength) {
    const pos = pathEl.getPointAtLength(target);

    // use a threshold instead of strict equality
    // to handle javascript floating point precision
    if (Math.abs(pos.x - clampedX) < 0.001) {
      return target;
    }
    if (pos.x > clampedX) {
      end = target;
    } else {
      start = target;
    }
    target = (start + end) / 2;
  }

  return 0;
}

const addSubscriptToCarbon = root => {
  const rootWithoutSubscript = root.substring(0, root.length - 1);
  const subscriptElement = <sub>2</sub>;
  return (
    <div>
      {rootWithoutSubscript}
      {subscriptElement}
    </div>
  );
};

export const addSubscript = root => {
  switch (root) {
    case 'GH':
      return (
        <div>
          GH<sub>2</sub>
        </div>
      );
    case 'MMtCO2':
      return addSubscriptToCarbon(root);
    case 'MtCO2':
      return addSubscriptToCarbon(root);
    case '$/MtCO2':
      return addSubscriptToCarbon(root);
    case 'MtCO2/Year':
      return (
        <div>
          MtCO<sub>2</sub>/Year
        </div>
      );
    case '1 MtCO2':
      return addSubscriptToCarbon(root);
    default:
      return root;
  }
};
