import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { extent } from 'd3-array';
import { makeLayout } from 'yogurt-layout';
import Portal from '../Portal/Portal';
import useForceRender from '../../hooks/useForceRender/useForceRender';
import { stackNarrow } from '../../lib/react-composable-charts/lib/stack';
import { wideToNarrow } from '../../lib/react-composable-charts/lib/reshapeData';
import { Chart } from '../../lib/react-composable-charts/components/Chart';
import { Cartesian } from '../../lib/react-composable-charts/components/Cartesian';
import { BarData } from '../../lib/react-composable-charts/components/BarData';
import { LabelsData } from '../../lib/react-composable-charts/components/LabelsData';
import tieredFrontEndResults from '../../data/TieredFrontEndResults';
import { TECHNOLOGIES, COLORS } from '../../data/constants';
import TechnologyLabels from './TechnologyLabels';
import InvestmentLabels from './InvestmentLabels';
import CostAtParityLabels from './CostAtParityLabels';
import BarLineSeparators from './BarLineSeparators';
import XAxes from './XAxes';
import Disclaimer from './Disclaimer';
import InvestmentToggle from '../InvestmentToggle/InvestmentToggle';
import style from './GreenPremiumChangeChart.module.css';
import DebugYogurtLayout from '../DebugYogurtLayout/DebugYogurtLayout';

const COLORS_LIST = TECHNOLOGIES.slice().reverse();

const mapStateToProps = state => ({
  tier: state.investmentSlice.tier,
});

const GreenPremiumChangeChart = ({ tier }) => {
  const ref = useRef();
  const [catalystActive, setCatalystActive] = useState(true);
  useForceRender();

  const chartWidth = 500;
  const xLabelsWidth = 80;
  const padding = 8;
  const width = chartWidth + xLabelsWidth + padding * 2;
  const height = 350 + padding * 2;

  const animationDurationMs = 300;
  const animationDelayMs = 55;

  const layout = makeLayout({
    id: 'root',
    width,
    height,
    padding,
    children: [
      { id: 'labelsY', width: xLabelsWidth },
      {
        direction: 'column',
        children: [
          { id: 'header', height: 25 },
          { id: 'chart' },
          { id: 'labelsX', height: 50 },
          { id: 'unitX', height: 20 },
        ],
      },
    ],
  });

  const data = Object.entries(
    tieredFrontEndResults.tiers[tier].gpg.details
  ).map(([key, values]) => {
    const label = key;
    const {
      cost_parity: costAtParity,
      end_gp: gpEnd,
      pct_change: gpPercentage,
      start_gp: gpStart,
      initial_gp: initial,
      uom,
    } = values;

    const total = initial + costAtParity;
    const delta = catalystActive ? gpStart - gpEnd : 0;
    const cost = initial - delta;

    return {
      label,
      costAtParity,
      cost,
      investment: (initial - delta) / total,
      catalyst: delta / total,
      initial,
      unit: uom,
      gpPercentage,
      parity: -costAtParity / total,
    };
  });
  const narrowData = wideToNarrow(
    data,
    ['investment', 'catalyst', 'parity'],
    'category',
    'value'
  );

  const stackedData = stackNarrow({
    data: narrowData,
    categories: ['parity', 'investment', 'catalyst'],
    getCategory: d => d.category,
    getGroup: d => d.label,
    getValue: d => d.value,
  });

  const groups = COLORS_LIST.map(d => d.id);
  const groupsIndex = Object.fromEntries(groups.map((g, i) => [g, i]));

  const investments = stackedData.filter(d => d.category === 'investment');
  const investmentLabels = investments.map(d => ({
    group: d.group,
    percentage: d.value,
    investment: d.datum.cost,
    unit: d.datum.unit,
  }));
  const groupLabelTop = layout.labelsX.top + layout.labelsX.height / 2;
  const catalystData = stackedData.filter(d => d.category === 'catalyst');
  const parityData = stackedData.filter(d => d.category === 'parity');

  const cartesianProps = color => ({
    x: {
      scale: 'band',
      paddingInner: 0.35,
      paddingOuter: 0.15,
      domain: groups,
    },
    y: {
      scale: 'linear',
      domain: extent(stackedData.flatMap(d => [d.to, d.base])),
    },
    color: {
      scale: 'ordinal',
      domain: groups,
      range: COLORS_LIST.map(d => d[color]),
    },
  });

  return (
    <div>
      <InvestmentToggle
        isCatalystActive={catalystActive}
        onCatalystActiveChange={setCatalystActive}
      />
      <Disclaimer />

      <div className={style.wrapper}>
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <Chart
            width={layout.chart.width}
            height={layout.chart.height}
            top={layout.chart.top}
            left={layout.chart.left}
          >
            <Cartesian {...cartesianProps('color')}>
              <LabelsData
                data={catalystActive ? catalystData : []}
                dataX={d => d.group}
                dataY={d => d.to}
                text={d => `-${Math.round(d.datum.gpPercentage * 100)}%`}
                fill={COLORS.blueRibbon}
                offsetY={-10}
                textAnchor="middle"
                transform="translate(0,0)"
                opacity={1}
                enter={{
                  opacity: 0,
                  transform: 'translate(0,20)',
                }}
                delay={d => groupsIndex[d.group] * animationDelayMs}
                duration={animationDurationMs}
              />
              <BarData
                data={parityData}
                x={d => d.group}
                y={{ base: d => d.base, to: d => d.to }}
                fill={COLORS.polishedSilver}
              />
              <BarData
                data={investments}
                x={d => d.group}
                y={{ base: d => d.base, to: d => d.to }}
                fill={d => d.group}
                delay={d => groupsIndex[d.group] * animationDelayMs}
                duration={animationDurationMs}
              />
              <XAxes yValue={0} portalRef={ref} />

              <Portal el={ref}>
                <TechnologyLabels
                  investments={investmentLabels}
                  top={groupLabelTop}
                />
                <InvestmentLabels
                  investments={investmentLabels}
                  duration={animationDurationMs}
                  delay={d => groupsIndex[d.group] * animationDelayMs}
                />
                <CostAtParityLabels catalyst={catalystData} yValue={0} />
              </Portal>
            </Cartesian>
            <Cartesian {...cartesianProps('lightColor')}>
              <BarData
                data={catalystData}
                x={d => d.group}
                y={{ base: d => d.base, to: d => d.to }}
                fill={d => d.group}
                delay={d => groupsIndex[d.group] * animationDelayMs}
                duration={animationDurationMs}
              />
              <BarLineSeparators
                data={catalystData}
                delay={group => groupsIndex[group] * animationDelayMs}
                duration={animationDurationMs}
              />
            </Cartesian>
          </Chart>
          <DebugYogurtLayout layout={layout.chart} color="blue" />
          <DebugYogurtLayout layout={layout.header} color="blue" />
          <DebugYogurtLayout layout={layout.labelsX} color="blue" />
          <DebugYogurtLayout layout={layout.labelsY} color="blue" />
          <DebugYogurtLayout layout={layout.unitX} color="blue" />
          <DebugYogurtLayout layout={layout.root} color="blue" noFill />
        </svg>
        <div ref={ref} />
      </div>
    </div>
  );
};

GreenPremiumChangeChart.propTypes = {
  tier: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(GreenPremiumChangeChart);
