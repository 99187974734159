import React from 'react';
import PropTypes from 'prop-types';

const DEBUG =
  process.env.NODE_ENV === 'development' &&
  new URLSearchParams(window.location.search).has('debug-layout');

const DebugYogurtLayout = ({ layout, color, noFill }) =>
  DEBUG && (
    <rect
      x={layout.left}
      y={layout.top}
      width={layout.width}
      height={layout.height}
      fill={color}
      fillOpacity={noFill ? 0 : 0.15}
      stroke={color}
    />
  );

DebugYogurtLayout.propTypes = {
  layout: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  color: PropTypes.string.isRequired,
  noFill: PropTypes.bool,
};

DebugYogurtLayout.defaultProps = {
  noFill: false,
};
export default DebugYogurtLayout;
