import React from 'react';
import logo from '../../images/logo.svg';

import './Navbar.css';

const Navbar = () => {
  return (
    <div className="navbar">
      <img alt="Breakthrough Energy logo" src={logo} />
    </div>
  );
};

export default Navbar;
