import React from 'react';
import PropTypes from 'prop-types';
import { element } from '../../lib/react-composable-charts/components/element';
import { useCartesianContext } from '../../lib/react-composable-charts/components/internal';

const BarLineSeparators = ({ data, delay, duration }) => {
  const { xScale, yScale } = useCartesianContext();

  return data.map(({ group, base }) => {
    const y = yScale(base);
    const x1 = xScale(group);
    const x2 = x1 + xScale.bandwidth();

    return (
      <element.line
        key={group}
        x1={x1}
        x2={x2}
        y1={y}
        y2={y}
        stroke="white"
        strokeWidth={1}
        delay={delay(group)}
        duration={duration}
      />
    );
  });
};

BarLineSeparators.propTypes = {
  data: PropTypes.shape({
    group: PropTypes.string.isRequired,
  }).isRequired,
};

export default BarLineSeparators;
