import React from 'react';
import PropTypes from 'prop-types';
import { useCartesianContext } from '../../lib/react-composable-charts/components/internal';
import { computePos } from '../../lib/react-composable-charts/lib/scales';
import ChartLabel from '../ChartLabel/ChartLabel';
import { COLORS } from '../../data/constants';
import style from './Labels.module.css';
import { addSubscript } from '../../data/utils';

const TechnologyLabels = ({ investments, top }) => {
  const { xScale } = useCartesianContext();

  return Object.values(investments).map(({ group, unit }) => {
    return (
      <div
        key={group}
        className={style.label}
        style={{
          top,
          left: computePos(group, xScale, 'center'),
        }}
      >
        <div className={style.unit}>{addSubscript(`1 ${unit}`)}</div>
        <div style={{ transform: 'translateY(5px)' }}>
          <ChartLabel textAnchor="middle" dominantBaseline="hanging">
            <div style={{ color: COLORS.venusMist }}>
              {addSubscript(group.toUpperCase())}
            </div>
          </ChartLabel>
        </div>
      </div>
    );
  });
};
TechnologyLabels.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.string),
  top: PropTypes.number,
};

export default TechnologyLabels;
