/* eslint-disable import/prefer-default-export */
import React from 'react';
import { round } from 'lodash';
import { RiInformationLine } from 'react-icons/ri';
import TieredFrontEndResults from './TieredFrontEndResults';
import {
  CATALYST_PROOGRAM_DESC,
  DEFINITION_DESC,
  EMISSIONS_REDUCTION_DESC,
  GREEN_PREMIUM_DESC,
  INTRO_DESC,
  OVERVIEW_DESC,
  PATHWAY_INFO,
  PV_CASE_STUDY_DESC,
  YEARS_ACCELERATED_DESC,
} from './graphText';

export const BLOB_STORAGE_URL = 'https://cerfboard.blob.core.windows.net';
export const IMAGES_DIR = '/img';

// NOTE: this version of auth is a quick hack; the website content and password
// are fully available for anyone who looks at the page source. However, this
// quick and dirty "auth" meets our security requirements, which are minimal
// and temporary
export const AUTH_COOKIE = 'ectfauth';
export const AUTH_PW = process.env.REACT_APP_AUTH_PW;

const emailContact = 'ECTFdashboard@breakthroughenergy.org';
const subject = encodeURIComponent(
  'Interested in learning more about Catalyst and the ECTF Dashboard'
);
const body = encodeURIComponent(`
  Hello,\n
  I'm reaching out with regards to the Catalyst program and the ECTF Dashboard
  to request more information and potentially to become an investor in the program`);
export const CONTACT_US_EMAIL = `mailto:${emailContact}?subject=${subject}&body=${body}`;

export const TIERED_FRONT_END_RESULTS_URL =
  'https://cerfboard.blob.core.windows.net/site-data/tiered_front_end_results-Sep-29-2021-1222.json';

export const COLORS = {
  doctor: '#f8f9fa',
  polishedSilver: '#C5CED7',
  stormyBay: '#9EA8B2',
  venusMist: '#5B626C',
  narwhalGrey: '#0A0F14',
  blueRibbon: '#006FF9',
};

/*
 * TECHNOLOGIES
 */
export const ALL_TECH_SVG = (
  <svg width="16" height="16">
    <circle cx="3.5" cy="3.5" r="3.5" fill="#57CAFF" />
    <circle cx="12.5" cy="3.5" r="3.5" fill="#8B36FF" />
    <circle cx="3.5" cy="12.5" r="3.5" fill="#FF2370" />
    <circle cx="12.5" cy="12.5" r="3.5" fill="#FFBB45" />
  </svg>
);

export const DEFAULT_TECH = 'all_tech';
export const TECHNOLOGIES = [
  { id: 'ldes', color: '#FFBB45', lightColor: '#FFEBC7' },
  { id: 'gh', color: '#FF2370', lightColor: '#FFBDD4' },
  { id: 'dac', color: '#8B36FF', lightColor: '#DCC3FF' },
  { id: 'saf', color: '#57CAFF', lightColor: '#CDEFFF' },
];

export const TECHNOLOGIES_COLORS = Object.fromEntries(
  TECHNOLOGIES.map(({ id, color }) => [id, color])
);
export const TECHNOLOGIES_LIGHT_COLORS = Object.fromEntries(
  TECHNOLOGIES.map(({ id, lightColor }) => [id, lightColor])
);

export const MAKE_LAYOUT_CONFIG = {
  root: {
    padding: { top: 60, right: 25, bottom: 40, left: 25 },
    width: 600,
    height: 400,
  },
  labelsY: { width: 50 },
  labelsX: { height: 60 },
};

/*
 * PATHWAYS
 */

export const PATHWAYS = {
  dac: [
    { title: 'Aqueous solution', id: 'DAC1' },
    { title: 'Solid sorbent', id: 'DAC2' },
  ],
  saf: [
    { title: 'Power to liquid', id: 'SAF2' },
    { title: 'Alcohol to jet', id: 'SAF3' },
    { title: 'Biomass gasification', id: 'SAF4' },
  ],

  gh: [{ title: 'Renewables-based electrolysis', id: 'GH1' }],

  ldes: [
    { title: 'Flow Batteries', id: 'LDES1' },
    { title: 'Modular pumped hydro', id: 'LDES2' },
  ],
};

const PATHWAY_MAP = Object.fromEntries(
  Object.values(PATHWAYS)
    .flat()
    .map(p => [p.title, p.id])
);

const getPathwayId = name => PATHWAY_MAP[name];
export const getPathwayData = ({ tier, pathway }) =>
  TieredFrontEndResults.tiers[tier].pgm.details[getPathwayId(pathway)];

/*
 * INVESTMENTS AND EMISSIONS
 */

export const INVESTMENT_TIERS = Object.keys(TieredFrontEndResults.tiers).map(
  tier => parseInt(tier, 10)
);
export const MIN_INVESTMENT = Math.min(...INVESTMENT_TIERS);
export const MAX_INVESTMENT = Math.max(...INVESTMENT_TIERS);
export const DEFAULT_INVESTMENT = 3000;

export const BUTTON_TIER_NAMES = {
  50: 'Scaling Partner',
  100: 'Anchor Partner',
};
export const BUTTON_TIERS = Object.keys(BUTTON_TIER_NAMES);
export const DEFAULT_BUTTON_TIER = BUTTON_TIERS[0];

// NOTE: may need to change this if we have new tiers
export const tierToStr = tier => {
  if (tier === 0) return '$0';
  if (tier < 1000) return `$${tier}M`;
  return `$${tier / 1000}B`;
};

export const dollarsToEmissions = dollars => {
  const { i2e } = TieredFrontEndResults.perm;
  const dollarStr = `${round(dollars)}.0`;
  return round(i2e[dollarStr] / 1_000_000, 1);
};

const e2iList = Object.keys(TieredFrontEndResults.perm.e2i);
// Rounds the emissions to the nearest available increment and
// returns the corresponding dollar amount
export const emissionsToDollars = millionTonsEmissions => {
  const emissions = millionTonsEmissions * 1_000_000;
  const { e2i } = TieredFrontEndResults.perm;

  const nextHighestMatchIdx = e2iList.findIndex(e => e >= emissions);

  // emissions too low
  if (nextHighestMatchIdx === 0) {
    return e2i[e2iList[0]];
  }
  // emissions too high
  if (nextHighestMatchIdx === -1) {
    return MAX_INVESTMENT + 1;
  }

  // find nearest neighbor
  const higher = e2iList[nextHighestMatchIdx];
  const higherDiff = higher - emissions;
  const lower = e2iList[nextHighestMatchIdx - 1];
  const lowerDiff = emissions - lower;

  const closestEmissionsVal = lowerDiff < higherDiff ? lower : higher;

  return e2i[closestEmissionsVal];
};

// Was asked specifically to use $3B, the default investment
export const TOTAL_EMISSIONS_REDUCTION = dollarsToEmissions(DEFAULT_INVESTMENT);
export const COST_PER_TON = (
  DEFAULT_INVESTMENT / TOTAL_EMISSIONS_REDUCTION
).toFixed(2);

/*
 * GRAPH TITLES AND DESCRIPTIONS
 */

export const GRAPH_INFO = {
  intro: {
    description: INTRO_DESC,
    tabTitle: 'Introduction',
    title: 'Accelerating Deployment of Climate Technology',
  },
  overview: {
    description: OVERVIEW_DESC,
    tabTitle: 'Overview',
    title: `Overview`,
  },
  catalystProgram: {
    description: CATALYST_PROOGRAM_DESC,
    tabTitle: 'The Catalyst Program',
    title: `Catalyst and the Clean Industrial Revolution`,
  },
  pvCaseStudy: {
    description: PV_CASE_STUDY_DESC,
    tabTitle: 'Solar PV Case Study',
    title: ` Learning from Solar`,
  },
  definitions: {
    description: DEFINITION_DESC,
    tabTitle: 'Definitions',
    title: `Definitions`,
  },
  emissionsAvoidedAllTech: {
    description: EMISSIONS_REDUCTION_DESC,
    tabTitle: 'Emissions Avoided',
    title: `Emissions reductions add up over time`,
  },
  greenPremium: {
    description: GREEN_PREMIUM_DESC,
    tabTitle: 'Green Premium Change',
    title: `Early investment drives down technology costs`,
  },
  yearsAccelerated: {
    description: YEARS_ACCELERATED_DESC,
    tabTitle: 'Years Accelerated',
    title: `Early investment Accelerates technology deployment`,
  },
  emissionsAvoided: {
    tabTitle: 'Emissions Avoided',
    title: 'Emissions avoided',
    ...PATHWAY_INFO,
  },
  costReduction: {
    tabTitle: 'Cost Reduction',
    title: 'Cost reduced over time',
    ...PATHWAY_INFO,
  },
  learningCurve: {
    tabTitle: '',
    title: 'Cost decline as technology scales',
    ...PATHWAY_INFO,
  },
  capacityIncrease: {
    tabTitle: 'Capacity Increase',
    title: 'Capacity increased over time',
    ...PATHWAY_INFO,
  },
};

const techTabs = ['emissionsAvoided', 'capacityIncrease', 'costReduction'];

/*
 * LEFT SIDEBAR ITEMS
 */

export const CLEAN_TECH_OVERVIEW_DATA = {
  all_tech: {
    title: 'All Technologies Overview',
    color: '#006FF9',
    icon: ALL_TECH_SVG,
    pathways: [],
    tabs: ['intro', 'emissionsAvoidedAllTech', 'greenPremium'],
  },
  saf: {
    title: 'Sustainable Aviation Fuel',
    color: TECHNOLOGIES_COLORS.saf,
    pathways: PATHWAYS.saf.map(p => p.title),
    tabs: techTabs,
  },
  dac: {
    title: 'Direct Air Capture',
    color: TECHNOLOGIES_COLORS.dac,
    pathways: PATHWAYS.dac.map(p => p.title),
    tabs: techTabs,
  },
  gh: {
    title: 'Green Hydrogen',
    color: TECHNOLOGIES_COLORS.gh,
    pathways: PATHWAYS.gh.map(p => p.title),
    tabs: techTabs,
  },
  ldes: {
    title: 'Long Duration Energy Storage',
    color: TECHNOLOGIES_COLORS.ldes,
    pathways: PATHWAYS.ldes.map(p => p.title),
    tabs: techTabs,
  },
  about: {
    title: 'About the Framework',
    color: '#5b626c',
    icon: <RiInformationLine style={{ fill: '#464b53' }} />,
    pathways: [],
    tabs: ['overview', 'catalystProgram', 'pvCaseStudy', 'definitions'],
  },
};
