import React from 'react';
import PropTypes from 'prop-types';
import { useCartesianContext } from '../../lib/react-composable-charts/components/internal';
import { computePos } from '../../lib/react-composable-charts/lib/scales';
import { formatNumber } from '../../lib/utils';
import style from './Labels.module.css';

const CostAtParityLabels = ({ catalyst, yValue }) => {
  const { xScale, yScale } = useCartesianContext();
  const topOffset = 5;

  const labels = catalyst.map(datum => {
    const {
      group,
      datum: { costAtParity },
    } = datum;
    return {
      key: group,
      left: computePos(group, xScale, 'center'),
      top: yScale(yValue) + topOffset,
      label: ['$ ', formatNumber(costAtParity, 1)],
      datum,
    };
  });

  return labels.map(({ top, left, label, group }) => (
    <div
      key={group}
      style={{
        position: 'absolute',
        top,
        left,
        transform: 'translateX(-50%)',
      }}
    >
      <span className={style.dollar}>{label[0]}</span>
      <span>{label[1]}</span>
    </div>
  ));
};

CostAtParityLabels.propTypes = {
  yValue: PropTypes.number.isRequired,
  catalyst: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      investment: PropTypes.number,
      unit: PropTypes.string,
    })
  ).isRequired,
};

export default CostAtParityLabels;
