import React from 'react';
import PropTypes from 'prop-types';
import { useCartesianContext } from '../../lib/react-composable-charts/components/internal';
import { computePos } from '../../lib/react-composable-charts/lib/scales';
import Portal from '../Portal/Portal';
import style from './YAxesLabel.module.css';
import { addSubscript } from '../../data/utils';

const YAxesLabel = ({ portalRef, section, uom, x, y, xOffset, yOffset }) => {
  const { yScale, xScale } = useCartesianContext();

  const yComputed = computePos(yScale.domain()[1], yScale, 'start') + yOffset;
  const xComputed = computePos(xScale.domain()[0], xScale, 'start') + xOffset;
  return (
    <Portal el={portalRef}>
      <div
        className={style.label}
        style={{
          top: y || yComputed,
          left: x || xComputed,
          transform: 'translate(0, calc(-100% - 16px))',
        }}
      >
        <span className={style.cost}>{section}</span>
        <span>{addSubscript(uom)}</span>
      </div>
    </Portal>
  );
};

export default YAxesLabel;

YAxesLabel.propTypes = {
  section: PropTypes.string.isRequired,
  uom: PropTypes.string.isRequired,
  portalRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
  yOffset: PropTypes.number,
  xOffset: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
};
YAxesLabel.defaultProps = {
  yOffset: 0,
  xOffset: 0,
  x: null,
  y: null,
};
