import { createSlice } from '@reduxjs/toolkit';
import {
  CLEAN_TECH_OVERVIEW_DATA,
  DEFAULT_INVESTMENT,
  DEFAULT_TECH,
} from '../data/constants';

const initialState = {
  tier: DEFAULT_INVESTMENT,
  technology: DEFAULT_TECH,
  pathway: null,
  graph: CLEAN_TECH_OVERVIEW_DATA[DEFAULT_TECH].tabs[0],
};

const investmentSlice = createSlice({
  name: 'investment',
  initialState,
  reducers: {
    updateTier(state, { payload: { tier } }) {
      return { ...state, tier };
    },

    updateTechnology(state, { payload: { technology } }) {
      const tech = technology === state.technology ? DEFAULT_TECH : technology;
      const showTech = CLEAN_TECH_OVERVIEW_DATA[technology].pathways.length > 0;
      const pathway = showTech
        ? CLEAN_TECH_OVERVIEW_DATA[tech].pathways[0]
        : null;
      const graph = CLEAN_TECH_OVERVIEW_DATA[tech].tabs[0];
      return { ...state, technology: tech, pathway, graph };
    },

    updatePathway(state, { payload: { pathway } }) {
      return { ...state, pathway };
    },

    updateGraph(state, { payload: { graph } }) {
      return { ...state, graph };
    },
  },
});

export const { updateTier, updateTechnology, updatePathway, updateGraph } =
  investmentSlice.actions;
export default investmentSlice.reducer;
