import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { extent } from 'd3-array';
import { sumBy, groupBy } from 'lodash-es';
import { makeLayout } from 'yogurt-layout';
import YAxesLabel from '../YAxesLabel/YAxesLabel';
import { MAKE_LAYOUT_CONFIG } from '../../data/constants.js';
import { AreaData } from '../../lib/react-composable-charts/components/AreaData';
import { Cartesian } from '../../lib/react-composable-charts/components/Cartesian';
import { Chart } from '../../lib/react-composable-charts/components/Chart';
import { stackNarrow } from '../../lib/react-composable-charts/lib/stack';
import { Grid } from '../../lib/react-composable-charts/components/Grid';
import { Style } from '../../lib/react-composable-charts/components/Style';
import DebugYogurtLayout from '../DebugYogurtLayout/DebugYogurtLayout';
import Portal from '../Portal/Portal';
import useForceRender from '../../hooks/useForceRender/useForceRender';
import style from './EmissionAreaChart.module.css';
import EmissionAreaChartLabel from './EmissionAreaChartLabel';
import usePrevious from '../../hooks/usePrevious/usePrevious';

const EmissionAreaChart = ({
  data: wideData,
  categories,
  colors,
  formatValue,
  formatCategory,
  investment,
  yDomainIncrease,
  paddingTop,
  strokeArea,
}) => {
  useForceRender();
  const ref = useRef();

  const { root, labelsX, labelsY } = MAKE_LAYOUT_CONFIG;
  const animationDelayMs = 200;

  const layout = makeLayout({
    id: 'root',
    width: root.width,
    height: root.height,
    padding: { ...root.padding, top: paddingTop, bottom: 10 },
    children: [
      { id: 'labelsY', width: labelsY.width },
      {
        id: 'vertical-wrapper',
        direction: 'column',
        children: [{ id: 'chart' }, { id: 'labelsX', height: labelsX.height }],
      },
    ],
  });

  const data = stackNarrow({
    data: wideData,
    categories,
    getCategory: d => d.category,
    getGroup: d => d.year,
    getValue: d => d.emissions,
  });

  const xDomain = extent(data.map(d => d.datum.year));
  const yDomain = extent(data.flatMap(d => [d.base, d.to])).map(
    v => v * (1 + yDomainIncrease)
  );

  const [lazyData, setLazyData] = useState(data);
  const [lazyYDomain, setLazyYDomain] = useState(yDomain);
  const [isAnimating, setIsAnimating] = useState(false);
  const prevWideData = usePrevious(wideData);
  const prevInvestment = usePrevious(investment);

  // This hook staggers the update of data and yDomain.
  // this effect should be run only when investment changes.
  useEffect(
    () => {
      let shouldUpdate = true;
      const cancel = () => {
        shouldUpdate = false;
      };

      const updateYDomain = () => setLazyYDomain(yDomain);
      const updateData = () => setLazyData(data);

      const staggeredExec = (fn1, fn2) => {
        setIsAnimating(true);
        fn1();
        setTimeout(() => {
          if (shouldUpdate) {
            fn2();
            setIsAnimating(false);
          }
        }, animationDelayMs);
      };

      if (investment > prevInvestment) {
        staggeredExec(updateYDomain, updateData);
      } else if (investment < prevInvestment) {
        staggeredExec(updateData, updateYDomain);
      } else if (wideData !== prevWideData) {
        updateData();
        updateYDomain();
        setIsAnimating(false);
      }

      return cancel;
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [investment, wideData]
  );

  const labelsYear = 2048;

  const dataByCategory = groupBy(lazyData, d => d.category);
  const labelsData = categories
    .map((category, i) => {
      const color = colors[i];
      const categoryData = dataByCategory[category];

      const yearDatum = categoryData.find(d => d.group === labelsYear);
      const totalValue = sumBy(categoryData, d => d.datum.emissions);
      const dataY = (yearDatum.to + yearDatum.base) / 2;
      return {
        color,
        dataX: yearDatum.group,
        dataY,
        category,
        totalValue,
        labelCategory: formatCategory(category),
        labelValue: formatValue(totalValue, category),
      };
    })
    .filter(l => l.totalValue !== 0);

  const x = d => d.datum.year;
  const y = { base: d => d.base, to: d => d.to };
  const AreaStrokeWidth = d => (d === 'Catalyst' ? 0 : 1);
  return (
    <div className={style.wrapper}>
      <svg width={root.width} height={root.height}>
        <Chart
          width={layout.chart.width}
          height={layout.chart.height}
          top={layout.chart.top}
          left={layout.chart.left}
        >
          <Cartesian
            x={{ scale: 'linear', domain: xDomain }}
            y={{ scale: 'linear', domain: lazyYDomain }}
            nice="y"
          >
            <YAxesLabel
              yValue={data}
              portalRef={ref}
              x={layout.labelsY.left}
              y={layout.labelsY.top}
              uom="MMtCO2"
              section="Emissions Reduction Potential"
            />
            {categories.map((category, i) => (
              <AreaData
                key={category}
                data={lazyData.filter(d => d.category === category)}
                x={x}
                y={y}
                fill={colors[i]}
                curve="monotone-x"
                stroke={strokeArea}
                strokeWidth={AreaStrokeWidth(category)}
              />
            ))}

            <g>
              <Grid tickSize={75}>
                <Grid.YLines stroke="#0A0F14" opacity={0.08} />
                <Grid.XAxes stroke="#5B626C" />

                <Style fill="#9EA8B2" fontSize={14}>
                  <Grid.XLabels padding={10} />
                  <Grid.YLabels padding={10} />
                </Style>
              </Grid>
            </g>

            <Portal el={ref}>
              <EmissionAreaChartLabel data={isAnimating ? [] : labelsData} />
            </Portal>
          </Cartesian>
        </Chart>

        <DebugYogurtLayout layout={layout.chart} color="blue" />
        <DebugYogurtLayout layout={layout.labelsX} color="blue" />
        <DebugYogurtLayout layout={layout.labelsY} color="blue" />
        <DebugYogurtLayout layout={layout.root} color="blue" noFill />
      </svg>

      <div ref={ref} />
    </div>
  );
};

EmissionAreaChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      category: PropTypes.string,
      emissions: PropTypes.number,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  formatValue: PropTypes.func,
  formatCategory: PropTypes.func,
  investment: PropTypes.number.isRequired,
  yDomainIncrease: PropTypes.number,
  paddingTop: PropTypes.number,
  strokeArea: PropTypes.string,
};

EmissionAreaChart.defaultProps = {
  formatValue: x => x,
  formatCategory: x => x,
  yDomainIncrease: 0,
  paddingTop: 0,
  strokeArea: '',
};

export default EmissionAreaChart;
