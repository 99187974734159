export const tuple = <Args extends any[]>(...args: Args) => args;
export const strTuple = <Args extends string[]>(...args: Args) => args;

type Get<Key, From> = Key extends keyof From ? From[Key] : undefined;

type Merge<A, B> = {
  [K in keyof A | keyof B]: Get<K, A> | Get<K, B>;
};

export type Component<Props> = (props: Props) => JSX.Element;
export interface CommonStyleProps {
  stroke?: string;
  strokeWidth?: number;
  fill?: string;
  opacity?: number;
  fillOpacity?: number;
  strokeDasharray?: string | number;
  strokeDashoffset?: string | number;
  strokeLinecap?: 'butt' | 'round' | 'square';
  mask?: string;

  fontFamily?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  dominantBaseline?: 'auto' | 'middle' | 'hanging';
  textAnchor?: 'start' | 'middle' | 'end';

  transform?: 'string';
}

export interface CommonStyleGetter<T> {
  stroke?: (datum: T) => string;
  fill?: (datum: T) => string;
}

export type StyleProps<T> = Merge<CommonStyleProps, CommonStyleGetter<T>>;

export type Iteratee<T, U> = (t: T, i: number) => U;
export type DefaultedIteratee<T, U> = U | Iteratee<T, U>;

export function wrapIteratee<A, B, C>(
  iteratee: Iteratee<B, C>,
  map: Accessor<A, B>
): Iteratee<A, C> {
  return (d: A, i: number) => iteratee(map(d), i);
}

export interface CommonAnimationProps {
  delay: number;
  duration: number;
  easing: (time: number) => number;
}

export interface AnimationIteratees<T> {
  delay?: DefaultedIteratee<T, number>;
  duration?: DefaultedIteratee<T, number>;
  easing?: (time: number) => number;
}
export interface AnimationProps<T> extends AnimationIteratees<T> {
  dataKey?: KeyAccessor<T>;

  enter?: CommonStyleProps;
  // exit?: CommonStyleProps;
}

export type Accessor<T, V> = (t: T) => V;
export type Getter<T, V> = V | Accessor<T, V>;

export function isAccessor<T, V>(
  getter: Getter<T, V>
): getter is Accessor<T, V> {
  return typeof getter === 'function';
}

export function toAccessor<T, V>(getter: Getter<T, V>): Accessor<T, V> {
  return isAccessor(getter) ? getter : () => getter;
}

export type DataValue = string | number | Date;
export type DataAccessor<T> = Accessor<T, DataValue>;
export type DataGetter<T> = Getter<T, DataValue>;

export type KeyAccessor<T> = (t: T, index: number) => DataValue;

export type ScaleContinuous = ((value: number | Date) => number) & {
  invert: (value: number) => number | Date;
  nice(): ScaleContinuous;
  copy(): ScaleContinuous;
  ticks(count?: number): number[] | Date[];
  range(): number[];
};

export type ScaleCategorical = ((value: string) => number) & {
  copy(): ScaleCategorical;
  bandwidth(): number;
  domain(): string[];
  range(): number[];
};

export type ScaleOrdinal = (value: string) => string;

export const isScaleContinuous = (v: any): v is ScaleContinuous => {
  return (
    typeof v === 'function' &&
    typeof v.invert === 'function' &&
    typeof v.nice === 'function' &&
    typeof v.copy === 'function'
  );
};
export const isScaleCategorical = (v: any): v is ScaleCategorical => {
  return (
    typeof v === 'function' &&
    typeof v.copy === 'function' &&
    typeof v.bandwidth === 'function'
  );
};

export type CartesianScale = ScaleContinuous | ScaleCategorical;
