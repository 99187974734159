import React from 'react';
import PropTypes from 'prop-types';
import ChartLabel from '../ChartLabel/ChartLabel';
import style from './EmissionAreaChartLabel.module.css';
import { useCartesianContext } from '../../lib/react-composable-charts/components/internal';
import { COLORS } from '../../data/constants';
import {
  animated,
  animatedGroup,
} from '../../lib/react-composable-charts/components/react-animated-dom';
import { addSubscript } from '../../data/utils';

const EmissionAreaChartLabel = ({ data }) => {
  const { xScale, yScale } = useCartesianContext();
  return (
    <animatedGroup.div>
      {data.map(datum => (
        <animated.div
          key={datum.category}
          style={{
            position: 'absolute',
            left: `${xScale(datum.dataX)}px`,
            top: `${yScale(datum.dataY)}px`,
            opacity: { default: 1, enter: 0, exit: 0 },
          }}
        >
          <ChartLabel textAnchor="end" dominantBaseline="middle">
            <div className={style.labelContentWrapper}>
              <div className={style.circle} style={{ color: datum.color }} />
              <div className={style.labelContent}>
                <div style={{ color: COLORS.venusMist }}>
                  {addSubscript(datum.labelCategory)}
                </div>
                <div>{datum.labelValue}</div>
                <div style={{ color: COLORS.venusMist }}>
                  MMtCO<sub>2</sub>
                </div>
              </div>
            </div>
          </ChartLabel>
        </animated.div>
      ))}
    </animatedGroup.div>
  );
};

EmissionAreaChartLabel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.number,
      dataX: PropTypes.number.isRequired,
      dataY: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      labelValue: PropTypes.string.isRequired,
      labelCategory: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default EmissionAreaChartLabel;
